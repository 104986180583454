import * as Action from '../js/actionTypes';

const DEFAULT_LOOKUPS = {
  fireDepartments: [],
  constructionCompanies: [],
  trainingAgencies: [],
  settings: {}
};

export default function lookupsReducer(state = DEFAULT_LOOKUPS, action) {
  switch(action.type) {

    // Loaded once at init time, as they do not change very often, and
    // certainly not within the app.

    case Action.UPDATE_FIRE_DEPARTMENTS_LOOKUP:
      return { ...state, fireDepartments: action.fireDepartments };

    case Action.UPDATE_CONSTRUCTION_COMPANIES_LOOKUP:
      return { ...state, constructionCompanies: action.constructionCompanies };

    case Action.UPDATE_TRAINING_AGENCIES_LOOKUP:
      return { ...state, trainingAgencies: action.trainingAgencies };

    case Action.UPDATE_SETTINGS_LOOKUP:
      return { ...state, settings: action.settings };

    default:
      return state;
  }
}
