import React from 'react';

import DatePicker from 'react-datepicker';

import PropTypes from 'prop-types';

import { formatDateTime, getValidDate } from '../utils/date';

class DateControl extends React.Component {
  handleDateChange(date) {
    let dateString = formatDateTime(date);

    if (this.props.onChange) {
      this.props.onChange(this.props.name, dateString);
    }
  }

  render() {
    const { date, onChange, start, end, ...props } = this.props;

    let selected = getValidDate(date) || null;

    return (
      <DatePicker selected={ selected } onChange={ this.handleDateChange.bind(this) } autoComplete='none' { ...props }/>
    );
  }
};

DateControl.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string,
  onChange: PropTypes.func,
  format: PropTypes.string,
};

export default DateControl;
