import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form } from 'semantic-ui-react';

import { getAdminWB, updateAdminWB } from '../../api/administratorAPI';
import { getTrainingAgency, updateTrainingAgency } from '../../api/trainingAgencyAPI';

import { UPDATE_ADMIN_REGISTRATION_TA_UI } from '../../js/actionTypes';
import { ROLE_ADMIN } from '../../js/constants';
import { PATH_ID, PATH_ADMIN, PATH_REGISTRATIONS, PATH_TRAINING_AGENCY } from '../../js/paths';

import AddressInput from '../../components/AddressInput';
import BallotSegment from '../../components/BallotSegment';

import BallotEditDialog from '../../dialogs/BallotEditDialog';

import EditView from '../EditView';

const INITIAL_STATE = {
  entity_name: '',
  contact_name: '',
  contact_email: '',

  payable_to: '',

  address: {
    street: '',
    number: '',
    city: '',
    province: '',
    postalcode: ''
  },

  main_telephone_number: '',
  main_telephone_ext: '',
  second_telephone_number: '',
  second_telephone_ext: '',

  // UI

  ballots: [],

  editing: {},

  className: 'training-agency-profile',
  title: 'Agency Profile',
};

class TrainingAgencyProfile extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.isAdmin = this.props.currentUser.role === ROLE_ADMIN;

    this.state.ui = {
      ballots: {
        sortField: this.props.ui.ballots.sortField || 'voteoption',
        sortDescending: this.props.ui.ballots.sortDescending === true,
      },
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUIStateChange = this.handleUIStateChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
  }

  fetch = async () => {
    await getTrainingAgency(this.state.isAdmin ? this.props.match.params[PATH_ID] : this.props.currentUser.orgId);
    if (this.state.isAdmin && this.props.trainingAgency.status !== 'Pending') {
      await getAdminWB('TA', this.props.match.params[PATH_ID]);
    }
  }

  onLoad = () => {
    const address = {
      street: this.props.trainingAgency.address_street || '',
      number: this.props.trainingAgency.address_number || '',
      city: this.props.trainingAgency.address_city || '',
      province: this.props.trainingAgency.address_province || '',
      postalcode: this.props.trainingAgency.address_postalcode || '',
    };

    this.setState({
      ...this.props.trainingAgency,
      exitPage: this.state.isAdmin ? `/${ PATH_ADMIN }/${ PATH_REGISTRATIONS }/${ PATH_TRAINING_AGENCY }` : this.props.currentUser.homepage,
      address: address,
    });

    if (this.state.isAdmin && this.props.trainingAgency.status !== 'Pending') {
      this.setState({ ballots: this.props.adminWB.ballots || [] });
    }
  }

  handleAddressChange = (event, { name, value }) => {
    this.setState({ address: { ...this.state.address, [name]: value }}, () => { this.onChange() });
  }

  startEditing = (componentName, entry) => {
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing });
  }

  stopEditing = () => {
    this.setState({ editing: {}});
  }

  handleComponentRemove = (componentName, entry) => {
    const ballots = this.state.ballots.filter(item => {
      return entry.memberemail !== item.memberemail
    });

    this.setState({ ballots: ballots }, () => { this.onChange() });
  }

  handleComponentEdit = (componentName, entry) => {
    this.stopEditing();

    let ballots = this.state.ballots.filter(item => {
      return entry.memberemail !== item.memberemail
    });
    ballots.push(entry);

    this.setState({ ballots: ballots }, () => { this.onChange() });
  }

  isValid = () => {
    let errors = { error: false };

    this.setState({ errors: errors });

    return !errors.error;
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });

    if (this.state.isAdmin) {
      const body = { ...this.props.adminWB, ballots: data.ballots };
      await updateAdminWB(body);
      this.exit();
    } else {
      const { address, ...body } = data;

      body.address_street = address.street;
      body.address_number = address.number;
      body.address_city = address.city;
      body.address_province = address.province;
      body.address_postalcode = address.postalcode;

      await updateTrainingAgency(body);
      if (this.props.trainingAgency.id) {
        this.props.currentUser.ta = this.props.trainingAgency;
        this.exit();
      }
    }

    this.setState({ loading: false });
  }

  handleUIStateChange(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REGISTRATION_TA_UI, adminRegistrationTA: this.state.ui });
    });
  }

  renderView = () => {
    const canEdit = !this.state.isAdmin || this.state.status === 'Voting';

    return (
      <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
          { canEdit &&
            <Form.Button floated='right' className='form-top-button' positive>Update</Form.Button>
          }
          <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>{ canEdit ? 'Cancel' : 'Close' }</Form.Button>
        </Header>
        <Segment.Group size='large' className='view-segment-group'>
          { this.state.isAdmin && this.state.status !== 'Pending' &&
            <BallotSegment disabled={ !canEdit } ballots={ this.state.ballots }
              ui={ this.state.ui.ballots } onUIStateChange={ this.handleUIStateChange }
              edit={ this.startEditing } remove={ this.handleComponentRemove }
            />
          }
          <Segment disabled={ this.state.isAdmin }>
            <Form.Group>
              <Form.Input readOnly label='Agency Name' value={ this.state.entity_name } width={ 16 } />
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly={ this.state.isAdmin } required={ !this.state.isAdmin }
                label='Contact Name(s)' value={ this.state.contact_name } name='contact_name' width={ 16 }
                placeholder='Tom Jones, Bob Smith'
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly={ this.state.isAdmin } required={ !this.state.isAdmin }
                label='Contact Email(s)' value={ this.state.contact_email } name='contact_email' width={ 16 }
                placeholder='tom@abc.com; bob@abc.com'
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly={ this.state.isAdmin } required={ !this.state.isAdmin }
                label='Payable To' value={ this.state.payable_to } name='payable_to' width={ 6 }
                onChange={ this.handleChange }
              />
            </Form.Group>

            <AddressInput readOnly={ this.state.isAdmin } address={ this.state.address } onChange={ this.handleAddressChange } hideCompliment />

            <Form.Group>
              <Form.Input readOnly={ this.state.isAdmin } required={ !this.state.isAdmin }
                label='Main Phone' value={ this.state.main_telephone_number } name='main_telephone_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isAdmin }
                label='Ext' value={ this.state.main_telephone_ext } name='main_telephone_ext' width={ 2 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isAdmin }
                label='Secondary Phone' value={ this.state.second_telephone_number } name='second_telephone_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isAdmin }
                label='Ext' value={ this.state.second_telephone_ext } name='second_telephone_ext' width={ 2 }
                onChange={ this.handleChange }
              />
            </Form.Group>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }

  renderAfterView = () => {
    if (this.state.editing.ballots) {
      return (
        <BallotEditDialog open={ !!this.state.editing.ballots } ballot={ this.state.editing.ballots }
          onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
        />
      );
    }

    return null;
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    trainingAgency: storeState.models.trainingAgency,
    adminWB: storeState.models.adminWB,
    ui: storeState.ui.adminRegistrationTA
  };
}

export default connect(mapStoreStateToProps)(TrainingAgencyProfile);
