import React from 'react';

import { Grid } from 'semantic-ui-react';

import { VIEW_IS_CLEAN, VIEW_IS_DIRTY } from '../js/actionTypes';
import store from '../js/store';

import ViewLoader from '../components/ViewLoader';

class EditView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      className: 'edit-view',
      title: '',
      exitPage: '',

      loading: false,
      progressPercent: 0,
      progressText: '',

      isNew: false,

      errors: {
        error: false
      }
    };
  }

  dispatch = (action) => { return store.dispatch(action); }

  isClean = () => { return store.dispatch({ type: VIEW_IS_CLEAN }); }

  isDirty = () => { return store.dispatch({ type: VIEW_IS_DIRTY }); }

  setTitle = () => {
    let title = 'Tharrp';
    if (this.state.title) {
      title += `: ${ this.state.title }`;
    }
    document.title = title;
  }
}

EditView.prototype.componentDidMount = function() {
  this.initialize();
  this.setTitle();
  this.isClean();
}

EditView.prototype.initialize = async function() {
  if (this.state.isNew) {
    this.onNew();
  } else {
    this.setState({ loading: 'Loading' });
    await this.fetch();
    this.onLoad();
    this.setState({ loading: false });
  }
}

EditView.prototype.fetch = async function() {}

EditView.prototype.onNew = function() {}

EditView.prototype.onLoad = function() {}

EditView.prototype.onChange = function() {
  this.isDirty();
  this.validate();
}

EditView.prototype.handleChange = function(event, { name, value }) {
  this.setState({ [name]: value }, () => { this.onChange(); });
}

EditView.prototype.handleDateChange = function(name, value) {
  this.setState({ [name]: value }, () => { this.onChange(); });
}

EditView.prototype.handleCurrencyChange = function(name, value) {
  this.setState({ [name]: value }, () => { this.onChange(); });
}

EditView.prototype.handleNumberChange = function(name, value) {
  this.setState({ [name]: value }, () => { this.onChange(); });
}

EditView.prototype.validate = function() {
  if (this.state.errors.error) {
    this.isValid();
  }
}

EditView.prototype.isValid = function() {
  let errors = { error: false };

  this.setState({ errors: errors });

  return !errors.error;
}

EditView.prototype.didChange = function() {
  const storeState = store.getState();

  return storeState.ui.isDirty;
}

EditView.prototype.getData = function() {
  const { loading, progressPercent, progressText, errors, isNew, exitPage, className, title, ...data } = this.state;

  return data;
}

EditView.prototype.handleSubmit = function(event) {
  event.preventDefault();

  if (this.isValid()) {
    const data = this.getData();

    if (this.state.isNew) {
      this.create(data);
    } else if (this.didChange()) {
      this.update(data);
    } else {
      this.cancel(event);
    }
  }
}

EditView.prototype.exit = function() {
  this.isClean();
  if (this.state.exitPage) {
    this.props.history.push(this.state.exitPage);
  } else {
    this.props.history.goBack();
  }
}

EditView.prototype.create = async function(data) {}

EditView.prototype.update = async function(data) {}

EditView.prototype.cancel = function(event) {
  event.preventDefault();

  this.exit();
}

EditView.prototype.renderView = function() {}

EditView.prototype.renderAfterView = function() {}

EditView.prototype.render = function() {
  return (
    <div className={ this.state.className }>
      <Grid centered className='view-grid'>
        <Grid.Column className='view-column'>
          {(() => {
            if (this.state.loading) {
              return <ViewLoader loading={ this.state.loading } percent={ this.state.progressPercent } text={ this.state.progressText }/>;
            }
            return this.renderView();
          })()}
          { this.renderAfterView() }
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default EditView;
