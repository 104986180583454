import React from 'react';

import { Segment, Form, Message } from 'semantic-ui-react';

import CurrencyInput from '../components/CurrencyInput';
import CurrencyText from '../components/CurrencyText';
import DateControl from '../components/DateControl';
import EditDialog from '../components/EditDialog';
import FileChooserSegment from '../components/FileChooserSegment';

import { DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';

import { AIR_TRAVEL_TYPES } from '../js/lists';

import { today, isValidInterval } from '../utils/date';
import ChosenFile from '../utils/file';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  timestamp: '',
  departuredate: today(),
  returndate: today(),
  carrier: '',
  fare: 0,
  gst: 0,
  receipts: [],

  // UI
  ui: {
    sortField: 'type',
    sortDescending: true,
  },
  errors: {
    error: false
  }
};

class AirTravelEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, ...this.props.airtravel };

    this.state.type = this.state.returndate ? 'R' : 'O';

    this.state.files = [];

    this.state.receipts.forEach((receipt) => {
      let file = new ChosenFile();
      file.parse(receipt);
      if (file.id) {
        this.state.files.push(file);
      }
    });

    this.didChange = this.didChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value }, () => { this.validate(); });
  }

  handleTypeChange = (event, { name, value }) => {
    let update = { [name]: value };
    update.returndate = value === 'R' ? today() : null;
    this.setState(update, () => { this.validate(); });
  }

  handleCurrencyChange = (name, value) => {
    this.setState( { [name]: value }, () => { this.validate(); });
  }

  handleDateChange = (name, value) => {
    this.setState({ [name]: value }, () => { this.validate(); });
  }

  handleReceiptsChange = (state) => {
    let newState = { ...state };

    if (newState.files) {
      newState.receipts = newState.files.map((file) => { return file.stringify(); });
    }

    this.setState(newState);
  }

  handleUIStateChange(state) {
    this.setState({ ui: { ...this.state.ui, ...state }});
  }

  didChange = () => {
    // Always true for new entriesl
    if (!this.props.airtravel.timestamp) return true;

    if (this.state.departuredate !== this.props.airtravel.departuredate) { return true; }
    if (this.state.returndate !== this.props.airtravel.returndate) { return true; }
    if (this.state.carrier !== this.props.airtravel.carrier) { return true; }
    if (this.state.fare !== this.props.airtravel.fare) { return true; }
    if (this.state.gst !== this.props.airtravel.gst) { return true; }

    let toDelete = [];
    let toUpload = [];
    ChosenFile.syncFiles(this.props.airtravel.receipts || [], this.state.files, toDelete, toUpload);

    if (toDelete.length > 0 || toUpload.length > 0) { return true; }

    return false;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.departuredate) {
      errors.error = true;
      errors.departuredate = 'Please enter a departure date. ';
    }

    if (this.state.type === 'R') {
      if (!this.state.returndate) {
        errors.error = true;
        errors.returndate = 'Please enter a return date.';
      } else if (this.state.departuredate && !isValidInterval(this.state.departuredate, this.state.returndate)) {
        errors.error = true;
        errors.returndate = 'Return date must be after departure date.';
      }
    }

    if (!this.state.type) {
      errors.error = true;
      errors.type = 'Please select a type.';
    }

    if (!this.state.carrier) {
      errors.error = true;
      errors.carrier = 'Please enter a carrier. ';
    }

    if (!this.state.fare) {
      errors.error = true;
      errors.fare = 'Please enter a fare. ';
    }

    if (this.state.receipts.length === 0) {
      errors.error = true;
      errors.receipts = 'Please attach a receipt.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    const { type, ui, errors, ...state } = this.state;
    this.props.onSave('airtravel', { ...state });
  }

  render = () => {
    const { onSave, onClose, airtravel, ...props } = this.props;

    const amount = this.state.fare + this.state.gst;

    const types = Object.keys(AIR_TRAVEL_TYPES).map((key) => { return { key: key, value: key, text: AIR_TRAVEL_TYPES[key] }});

    return (
      <EditDialog size='small' header='Add Air Travel Expense' onSave={ this.onSave } onClose={ onClose } readOnly={ this.props.disabled }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Segment.Group size='large'>
          <Segment disabled={ this.props.disabled }>
            <Form.Group>
              { this.props.disabled &&
                <Form.Input readOnly label='Type' value={ AIR_TRAVEL_TYPES[this.state.type] } width={ 6 }/>
              }
              { !this.props.disabled &&
                <Form.Select required label='Type' name='type' value={ this.state.type } options={ types } width={ 6 }
                  error={ !!this.state.errors.type }
                  onChange={ this.handleTypeChange }
                />
              }
            </Form.Group>
            <Message error>
              { this.state.errors.type }
            </Message>
            <Form.Group>
              <Form.Field readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 6 }
                label='Departure' name='departuredate'
                control={ DateControl } date={ this.state.departuredate } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                error={ !!this.state.errors.departuredate }
                onChange={ this.handleDateChange }
              />
              { this.state.type === 'R' &&
                <Form.Field required={ this.state.type === 'R' && !this.props.disabled} readOnly={ this.props.disabled } width={ 6 }
                  label='Return' name='returndate'
                  control={ DateControl } date={ this.state.returndate } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                  error={ !!this.state.errors.returndate }
                  onChange={ this.handleDateChange }
                />
              }
            </Form.Group>
            <Message error>
              { this.state.errors.departuredate }{ this.state.errors.returndate }
            </Message>
            <Form.Group>
              <Form.Input readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 6 }
                label='Carrier' value={ this.state.carrier } name='carrier'
                error={ !!this.state.errors.carrier }
                onChange={ this.handleChange }
              />
              <Form.Field readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 4 }
                label='Fare' value={ this.state.fare } name='fare'
                error={ !!this.state.errors.fare }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field readOnly={ this.props.disabled } width={ 3 }
                label='GST/Taxes' value={ this.state.gst } name='gst'
                error={ !!this.state.errors.gst }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field label='Total' value={ amount } width={ 3 }
                control={ CurrencyText }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.carrier }{ this.state.errors.fare }
            </Message>
            <Form.Group>
              <Form.Field readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 16 }
                control={ FileChooserSegment } label='Receipts' name='files' files={ this.state.files } multiple
                ui={ this.state.ui } onUIStateChange={ this.handleUIStateChange.bind(this) }
                onChange={ this.handleReceiptsChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.receipts }
            </Message>
          </Segment>
        </Segment.Group>
      </EditDialog>
    );
  }
}

AirTravelEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  airtravel: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default AirTravelEditDialog;
