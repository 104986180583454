import { get, post, put, del, buildQuerystring } from './api';

import { API_TRAINING_AGENCY, DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { PATH_TRAINING_AGENCY, PATH_REIMBURSEMENTS, PATH_DECLARATION } from '../js/paths';

import * as Action from '../js/actionTypes';
import store from '../js/store';

import { formatDateTime, sortableDateTime } from '../utils/date';

export function parseTrainingAgency(ta) {
  return ta;
}

export async function getTrainingAgency(taid) {
  const response = await get(API_TRAINING_AGENCY, `/${ PATH_TRAINING_AGENCY }/${ taid }`);

  const trainingAgency = response.length === 1 ? parseTrainingAgency(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_TRAINING_AGENCY, trainingAgency: trainingAgency });
}

export async function updateTrainingAgency(body) {
  const response = await put(API_TRAINING_AGENCY, `/${ PATH_TRAINING_AGENCY }`, body);

  const trainingAgency = parseTrainingAgency(response);

  store.dispatch({ type: Action.UPDATE_TRAINING_AGENCY, trainingAgency: trainingAgency });
}


// REIMBURSEMENTS
///////////
export function parseReimbursement(reimbursement) {
  if (reimbursement.id) {
    const storeState = store.getState();
    const trainingAgency = storeState.lookups.trainingAgencies.find((ta) => { return ta.id === reimbursement.id; });

    reimbursement.key = reimbursement.id + '+' + reimbursement.timestamp;
    reimbursement.displayDate = formatDateTime(reimbursement.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    reimbursement.sortDate = sortableDateTime(reimbursement.timestamp);

    reimbursement.taName = trainingAgency ? trainingAgency.name : '';
  }

  return reimbursement;
}

export async function getTrainingAgencyReimbursements(taid, status) {
  let params = {};
  if (taid) params.taid = taid;
  if (status) params.status = status;

  const querystring = buildQuerystring(params);
  const response = await get(API_TRAINING_AGENCY, `/${ PATH_REIMBURSEMENTS }${ querystring }`);

  const reimbursements = response.map(reimbursement => { return parseReimbursement(reimbursement); });

  store.dispatch({ type: Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENTS, trainingAgencyReimbursements: reimbursements });
}

export async function getTrainingAgencyReimbursement(taid, timestamp) {
  let params = {};
  if (taid) params.taid = taid;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_TRAINING_AGENCY, `/${ PATH_REIMBURSEMENTS }${ querystring }`);

  const reimbursement = response.length === 1 ? parseReimbursement(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENT, trainingAgencyReimbursement: reimbursement });
}

export async function postTrainingAgencyReimbursement(body) {
  const response = await post(API_TRAINING_AGENCY, `/${ PATH_REIMBURSEMENTS }`, body);

  const reimbursement = parseReimbursement(response);

  store.dispatch({ type: Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENT, trainingAgencyReimbursement: reimbursement });
}

export async function updateTrainingAgencyReimbursement(body) {
  const response = await put(API_TRAINING_AGENCY, `/${ PATH_REIMBURSEMENTS }`, body);

  const reimbursement = parseReimbursement(response);

  store.dispatch({ type: Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENT, trainingAgencyReimbursement: reimbursement });
}

export async function deleteTrainingAgencyReimbursement(taid, timestamp) {
  let body = {};
  if (taid) body.taid = taid;
  if (timestamp) body.timestamp = timestamp;

  const response = await del(API_TRAINING_AGENCY, `/${ PATH_REIMBURSEMENTS }`, body);

  const reimbursement = parseReimbursement(response);

  store.dispatch({ type: Action.DELETE_TRAINING_AGENCY_REIMBURSEMENT, trainingAgencyReimbursement: reimbursement });
}

// DECLARATION
///////////
export async function postTrainingAgencyDeclaration(lastdeclarationyear, taid) {
  const response = await get(API_TRAINING_AGENCY, `/${ PATH_DECLARATION }/TA::${ taid }/${ lastdeclarationyear }`);
  const declarationOk = (!response);
  store.dispatch({ type: Action.TRAINING_AGENCIES_DECLARATION, trainingAgencyDeclaration: declarationOk });
}

// Lookup
export async function getTrainingAgencies() {
  const response = await get(API_TRAINING_AGENCY, `/${ PATH_TRAINING_AGENCY }`);
  const trainingAgencies = response.map(ta => { return parseTrainingAgency(ta); });

  store.dispatch({ type: Action.UPDATE_TRAINING_AGENCIES_LOOKUP, trainingAgencies: trainingAgencies });
}
