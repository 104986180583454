import React from 'react';

import { Message, Table } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import IconButton from './IconButton';

class FileSummarySegment extends React.Component {
  handleIconClick = (entry) => {
    this.download(entry);
  }

  test = async (entry) => {
    const uploadUrl = await entry.uploadUrl();
    const downloadUrl = await entry.downloadUrl();
    console.log('uploadUrl', uploadUrl);
    console.log('downloadUrl', downloadUrl);
  }

  download = async (entry) => {
    const downloadUrl = await entry.downloadUrl();

    if (downloadUrl) {
      window.open(downloadUrl);
    }
  }

  render = () => {
    return (
      (() => {
        let files = this.props.files.filter(f => { return !f.deleted });

        if (files.length === 0) {
          return <Message info compact size='tiny'>{ this.props.multiple ? 'No files' : 'No file' }</Message>;
        }

        files = files.map(f => {
          let file = { ...f };

          let size = file.size;
          let unit = 'B';
          if (size > 1024) {
            size /= 1024;
            unit = 'KB';
          }
          if (size > 1024) {
            size /= 1024;
            unit = 'MB';
          }
          if (size > 1024) {
            size /= 1024;
            unit = 'GB';
          }

          file.displaySize = parseFloat(size.toFixed(1));
          file.unit = unit;
          file.displayName = file.name;

          return file;
        });

        return (
          <Table collapsing basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>File Size</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { files.map((file, index) => (
                <Table.Row key={ index } id={ `file_${ file.timestamp }`}>
                  <Table.Cell>{ file.displayName }</Table.Cell>
                  <Table.Cell>{ file.displaySize } { file.unit }</Table.Cell>
                  <Table.Cell>
                    { file.uploaded &&
                      <IconButton name='arrow down' color='black' size='small' bordered onClick={ this.handleIconClick.bind(this, file) }/>
                    }
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        );
      })()
    );
  }
};

FileSummarySegment.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  withInput: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  files: PropTypes.array,
  ui: PropTypes.object,
  onUIStateChange: PropTypes.func,
  onChange: PropTypes.func,
  isStandAlone: PropTypes.bool,
  readOnly: PropTypes.bool,
  disallowDeletion: PropTypes.bool,
};

export default FileSummarySegment;
