import React from 'react';

import PropTypes from 'prop-types';

import ConfirmPopup from './ConfirmPopup';
import IconButton from './IconButton';

class ActionButton extends React.Component {
  render() {
    const { onConfirm, onCancel, hide, header, content, canceltext, oktext, ...props } = this.props;

    const button = (
      <IconButton name='question circle' color='blue' className={ 'action-button' + (this.props.hide ? ' hidden' : '') } { ...props }/>
    );

    const confirm = (
      <ConfirmPopup onConfirm={ onConfirm || (()=>{}) } onCancel={ onCancel } header={ header } content={ content } canceltext={ canceltext } oktext={ oktext } on='click'>
        { button }
      </ConfirmPopup>
    );

    return onConfirm ? confirm : (this.props.onClick ? button : null);
  }
};

ActionButton.propTypes = {
  onConfirm: PropTypes.func,
  onClick: PropTypes.func,
  hide: PropTypes.bool,
};

export default ActionButton;
