import React from 'react';
import { connect } from 'react-redux';

import { Grid, Segment, Header, Form } from 'semantic-ui-react';

import { getUser, updateUser } from '../../api/userAPI';

import AddressInput from '../../components/AddressInput';
import ViewLoader from '../../components/ViewLoader';

import EditView from '../EditView';

const INITIAL_STATE = {
  entity_name: '',
  contact_name: '',
  contact_email: '',

  address: {
    street: '',
    number: '',
    compliment: '',
    city: '',
    province: '',
    postalcode: ''
  },

  main_telephone_number: '',
  main_telephone_ext: '',
  second_telephone_number: '',
  second_telephone_ext: '',

  // UI

  loading: false,
  errors: {
    error: false
  }
};

class ConstructionProfile extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount = () => {
    this.initialize();
    this.isClean();
  }

  initialize = async () => {
    this.setState({ loading: 'Loading' });
    await getUser(this.props.currentUser.orgId);
    this.setState({ ...this.props.cc, loading: false });
  }

  onChange = () => {
    this.isDirty();
    this.validate();
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value }, () => { this.onChange(); });
  }

  handleAddressChange = (event, { name, value }) => {
    this.setState({ address: { ...this.state.address, [name]: value }}, () => { this.onChange(); });
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    this.setState({ errors: errors });

    return !errors.error;
  }

  didChange = () => {
    return true;
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.isValid()) {
      if (this.didChange()) {
        const { loading, errors, address, ...data } = this.state;

        data.address_street = address.street;
        data.address_number = address.number;
        data.address_compliment = address.compliment;
        data.address_city = address.city;
        data.address_province = address.province;
        data.address_postalcode = address.postalcode;

        this.update(data);
      } else {
        this.cancel();
      }
    }
  }

  goToList = () => {
    this.isClean();
    this.props.history.push(this.props.currentUser.homepage);
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await updateUser(data);
    if (this.props.cc.id) {
      this.props.currentUser.cc = this.props.cc;
      this.goToList();
    }
    this.setState({ loading: false });
  }

  cancel = event => {
    event.preventDefault();

    this.goToList();
  }

  render = () => {
    return (
      <div className='edit-view'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              return (
                <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
                  <Header size='medium'>
                    <Header.Content>Company Profile</Header.Content>
                    <Form.Button floated='right' className='form-top-button' positive>Update</Form.Button>
                    <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
                  </Header>
                  <Segment.Group size='large' className='view-segment-group'>
                    <Segment>
                      <Form.Group>
                        <Form.Input required label='Company Name' value={ this.state.entity_name } name='entity_name' width={ 16 }
                          onChange={ this.handleChange } readOnly={ this.state.entity_name && this.state.entity_name != '' }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input required label='Contact Name(s)' value={ this.state.contact_name } name='contact_name' width={ 16 }
                          placeholder='Tom Jones, Bob Smith'
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input required label='Contact Email(s)' value={ this.state.contact_email } name='contact_email' width={ 16 }
                          placeholder='tom@abc.com; bob@abc.com'
                          onChange={ this.handleChange }
                        />
                      </Form.Group>

                      <AddressInput address={ this.state.address } onChange={ this.handleAddressChange }/>

                      <Form.Group>
                        <Form.Input required label='Main Phone' value={ this.state.main_telephone_number } name='main_telephone_number' width={ 4 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input label='Ext' value={ this.state.main_telephone_ext } name='main_telephone_ext' width={ 2 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input label='Secondary Phone' value={ this.state.second_telephone_number } name='second_telephone_number' width={ 4 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input label='Ext' value={ this.state.second_telephone_ext } name='second_telephone_ext' width={ 2 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                    </Segment>
                  </Segment.Group>
                </Form>
              );
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    cc: storeState.models.user,
  };
}

export default connect(mapStoreStateToProps)(ConstructionProfile);
