// Roles
export const ROLE_ADMIN = 'Admin';
export const ROLE_CONSTRUCTION = 'Construction';
export const ROLE_FIRE_DEPARTMENT = 'FireDepartment';
export const ROLE_TRAINING_AGENCY = 'TrainingAgency';

// Date Formats
export const DATE_FULL_MONTH_DAY_YEAR = 'MMMM d, yyyy';          // February 5, 2019
export const DATE_SHORT_MONTH_DAY_YEAR = 'MMM d, yyyy';          // Feb 5, 2019
export const DATE_YEAR_SHORT_MONTH_DAY = 'yyyy-MMM-dd';          // 2019-Feb-05
export const DATE_ISO_8601 = 'yyyy-MM-dd';                       // 2019-02-05
export const DATE_TIME_ISO_8601 = "yyyy-MM-dd'T'HH:mm:ss";       // 2019-02-05T19:02:07
export const DATE_TIME_READABLE = "MMMM d, yyyy 'at' h:mm:ss a"; // February 5, 2019 at 7:02:07 PM
export const DATE_ZULU = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";         // 2019-02-05T19:02:07.321Z
export const DATE_YEAR = 'yyyy';                                 // 2019
export const DATE_SHORT_MONTH = 'MMM';                           // Feb

// Auth states
export const AUTH_SIGNED_IN = 'signedIn';
export const AUTH_SIGN_IN = 'signIn';
export const AUTH_SIGN_UP = 'signUp';
export const AUTH_FORGOT_PASS = 'forgotPassword';
export const AUTH_CHANGE_PASS = 'changePassword';
export const AUTH_CONFIRM_SIGN_UP = 'confirmSignUp';
export const AUTH_CONFIRM_SIGN_IN = 'confirmSignIn';
export const AUTH_REQUIRE_NEW_PASSWORD = 'requireNewPassword';
export const AUTH_TOTP_SETUP = 'TOTPSetup';

// API
export const API_ADMIN = 'AdministratorAPI';
export const API_CONSTRUCTION = 'ConstructionAPI';
export const API_FIRE_DEPARTMENT = 'FireDepartmentAPI';
export const API_TRAINING_AGENCY = 'TrainingAgencyAPI';
export const API_USER = 'UserAPI';
export const API_BRIDGE = 'BridgeAPI';
