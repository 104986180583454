import React from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';

// This loads the authentication configuration
import '../js/auth';

import { ConfirmSignIn, RequireNewPassword, VerifyContact, Authenticator } from 'aws-amplify-react';

import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';
import ConfirmSignUp from './Auth/ConfirmSignUp';
import ForgotPassword from './Auth/ForgotPassword';
import ChangePassword from './Auth/ChangePassword';

import Main from './Main';

import { UPDATE_AUTH_STATE, UPDATE_CURRENT_USER } from '../js/actionTypes';
import { AUTH_SIGNED_IN, ROLE_ADMIN, ROLE_CONSTRUCTION, ROLE_FIRE_DEPARTMENT, ROLE_TRAINING_AGENCY } from '../js/constants';
import { PATH_ADMIN, PATH_CONSTRUCTION, PATH_FIRE_DEPARTMENT, PATH_TRAINING_AGENCY } from '../js/paths';

import LogRocket from 'logrocket';

class AuthWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);
  }

  handleAuthStateChange(authState, user) {
    this.props.dispatch({ type: UPDATE_AUTH_STATE, authState: authState });
    if (authState === AUTH_SIGNED_IN) {
      this.updateCurrentUser(user);
    }
  }

  updateCurrentUser(user) {
    if (user && user.signInUserSession) {
      const role = user.signInUserSession.idToken.payload['cognito:groups'][0];
      let homePage, orgId;
      switch (role) {
        case ROLE_ADMIN:
          homePage = `/${ PATH_ADMIN }`;
          break;
        case ROLE_CONSTRUCTION:
          homePage = `/${ PATH_CONSTRUCTION }`;
          orgId = user.signInUserSession.idToken.payload['custom:ccid'];
          break;
        case ROLE_TRAINING_AGENCY:
          homePage = `/${ PATH_TRAINING_AGENCY }`;
          orgId = user.signInUserSession.idToken.payload['custom:taid'];
          break;
        case ROLE_FIRE_DEPARTMENT:
          homePage = `/${ PATH_FIRE_DEPARTMENT }`;
          orgId = user.signInUserSession.idToken.payload['custom:fdid'];
          break;
        default:
          homePage = '/';
          orgId = null;
      }

      const currentUser = {
        cognitoUser: user,

        username: user.username,
        email: user.signInUserSession.idToken.payload.email,
        role: role,
        homepage: homePage,
        orgId: orgId,

        jwtToken: user.signInUserSession.idToken.jwtToken
      };
		  // Identify the user with LogRocket
      console.log('LogRocket identify', {
        id: currentUser.email,
        name: currentUser.username,
        email: currentUser.email,
        application: 'BCCSA THARRP'
      });
		  LogRocket.identify({
        id: currentUser.user,
        name: currentUser.username,
        email: currentUser.email,
        application: 'BCCSA THARRP'
      });

      this.props.dispatch({ type: UPDATE_CURRENT_USER, user: currentUser });
    }
  }

  render() {
    return (
        <Authenticator onStateChange={ this.handleAuthStateChange } hideDefault={ true }>
          <SignIn />
          <SignUp />
          <ConfirmSignUp />
          <ConfirmSignIn />
          <RequireNewPassword />
          <ForgotPassword />
          <VerifyContact />
          <ChangePassword />
          <Main />
        </Authenticator>
    );
  }
}

export default withRouter(connect()(AuthWrapper));
