import React from 'react';
import { Auth } from 'aws-amplify';

import { Grid, Segment, Header, Form, Message } from 'semantic-ui-react';

import { handleAPIError } from '../../api/api';

import * as Constant from '../../js/constants';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: {}
};

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  changeAuthState = (type, event) => {
    this.setState({ error: {} });
    this.props.onStateChange(type, event);
  };

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    this.setState({ error: {} });

    let email = this.state.email.replace(' ','').toLowerCase().trim();

    Auth.signIn(email, this.state.password)
      .then(user => {
        this.setState(() => ({ ...INITIAL_STATE }));
        if (
          user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          this.changeAuthState(Constant.AUTH_CONFIRM_SIGN_IN, user);
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.changeAuthState(Constant.AUTH_REQUIRE_NEW_PASSWORD, user);
        } else if (user.challengeName === 'MFA_SETUP') {
          this.changeAuthState(Constant.AUTH_TOTP_SETUP, user);
        } else {
          this.changeAuthState(Constant.AUTH_SIGNED_IN, user);
        }
      })
      .catch(error => {
        if (error.code === 'UserNotConfirmedException') {
          this.changeAuthState(Constant.AUTH_CONFIRM_SIGN_UP, { username: this.state.email });
        } else if (error.code === 'PasswordResetRequiredException') {
          this.changeAuthState(Constant.AUTH_REQUIRE_NEW_PASSWORD, { username: this.state.email });
        }

        this.setState({ error: handleAPIError(error) });
      });

    event.preventDefault();
  }

  render() {
    if (this.props.authState !== Constant.AUTH_SIGN_IN) return null;

    return (
      <div className='auth-container signin'>
        <Grid centered className='auth-grid'>
          <Grid.Column className='auth-column'>
            <Header size='medium'>Sign In
              <Header.Subheader>Don&apos;t have an account?&nbsp;&nbsp;<a href='#signup' onClick={() => this.changeAuthState(Constant.AUTH_SIGN_UP)}>Create Account</a></Header.Subheader>
            </Header>
            <Form onSubmit={ this.handleSubmit } error={ (this.state.error.message ? true : false) }>
              <Segment size='large' secondary>
                <Form.Input label='Email Address' value={ this.state.email } name='email' placeholder='Email Address'
                  required autoComplete='email' onChange={ this.handleChange }
                />
                <Form.Input label='Password' value={ this.state.password } name='password' placeholder='Password' type='password'
                  required autoComplete='current-password' onChange={ this.handleChange } data-dd-privacy="mask"
                />

                <Form.Button positive className='centered'>Sign In</Form.Button>
                <a href='#forgotPassword' className='forgotPasswordLink' onClick={() => this.changeAuthState(Constant.AUTH_FORGOT_PASS)}>Forgot Password?</a>

                <Message error className='centered' negative size='small'>
                  { this.state.error.message }
                </Message>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default SignIn;
