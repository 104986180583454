import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form, Message } from 'semantic-ui-react';

import { getAdminOperatorReimbursement } from '../../api/administratorAPI';
import { getTrainingAgencyReimbursement, postTrainingAgencyReimbursement, updateTrainingAgencyReimbursement } from '../../api/trainingAgencyAPI';

import { UPDATE_TRAINING_AGENCY_REIMBURSEMENT, UPDATE_TRAINING_AGENCY_REIMBURSEMENT_UI } from '../../js/actionTypes';
import { PATH_ADMIN, PATH_OPERATOR_REIMBURSEMENT, PATH_TRAINING_AGENCY, PATH_REIMBURSEMENTS, PATH_NEW, PATH_ID, PATH_TIMESTAMP } from '../../js/paths';

import ChosenFile from '../../utils/file';

import CurrencyInput from '../../components/CurrencyInput';
import CurrencyText from '../../components/CurrencyText';
import FileChooserSegment from '../../components/FileChooserSegment';
import NumberInput from '../../components/NumberInput';

import TaxCalculator from '../../components/TaxCalculator';

import EditView from '../EditView';

const INITIAL_STATE = {
  status: 'Pending',

  operatorname: '',
  numdays: 0,
  amountbeforetaxes: 0,
  gst: 0,
  totalamount: 0,
  payableto: '',

  invoices: [],

  // UI

  isReadOnly: false,
  ui: null,

  className: 'training-agency-reimbursement',
  title: 'Tower Crane Operator Reimbursement',
};

class TrainingAgencyReimbursement extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.files = [];

    this.state.isNew = (props.match.params[PATH_TIMESTAMP] === PATH_NEW);

    if (this.state.isNew) {
      let payableto;
      if (this.props.currentUser.ta) {
        payableto = this.props.currentUser.ta.payable_to;
      }
      this.state.payableto = payableto || '';
    }

    this.state.isAdmin = this.props.currentUser.role === "Admin";
    this.state.isReadOnly = this.state.isAdmin;

    this.state.exitPage = this.state.isAdmin ? `/${ PATH_ADMIN }/${ PATH_OPERATOR_REIMBURSEMENT }` : `/${ PATH_TRAINING_AGENCY }/${ PATH_REIMBURSEMENTS }`;

    this.state.ui = {
      sortField: this.props.ui.sortField || 'type',
      sortDescending: this.props.ui.sortDescending === true,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleFilesChange = this.handleFilesChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleFilesUIStateChange = this.handleFilesUIStateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
  }

  fetch = async () => {
    if (this.state.isAdmin) {
      await getAdminOperatorReimbursement(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
    } else {
      await getTrainingAgencyReimbursement(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
    }
  }

  onNew = () => {
    this.dispatch({ type: UPDATE_TRAINING_AGENCY_REIMBURSEMENT, trainingAgencyReimbursement: INITIAL_STATE });
  }

  onLoad = () => {
    this.setState({ ...this.props.reimbursement});

    this.state.files.length = 0;
    this.state.invoices.forEach((invoice) => {
      let file = new ChosenFile();
      file.parse(invoice);
      if (file.id) {
        this.state.files.push(file);
      }
    });
  }

  handleFilesChange = (state) => {
    let newState = { ...state };

    if (newState.files) {
      newState.invoices = newState.files.map((file) => { return file.stringify(); });
    }

    this.setState(newState, () => { this.onChange() });
  }

  handleCurrencyChange = (name, value) => {
    let stateUpdate = {
      amountbeforetaxes: this.state.amountbeforetaxes,
      gst: this.state.gst
    };
    stateUpdate[name] = value;
    stateUpdate.totalamount = stateUpdate.amountbeforetaxes + stateUpdate.gst;

    this.setState(stateUpdate, () => { this.onChange() });
  }

  handleTaxChange = (name, value) => {
    this.setState({
      gst: value,
      totalamount: this.state.amountbeforetaxes + value
    }, () => { this.onChange() });
  }

  handleFilesUIStateChange(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_TRAINING_AGENCY_REIMBURSEMENT_UI, trainingAgencyReimbursement: this.state.ui });
    });
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.operatorname) {
      errors.error = true;
      errors.operatorname = 'Please enter an operator name. ';
    }

    if (!this.state.numdays) {
      errors.error = true;
      errors.numdays = 'Please enter the number of reimbursement days. ';
    } else if (this.state.numdays > this.props.settings.maxdaysofoperatorreimbursement) {
      errors.error = true;
      errors.numdays = `The maximum number of reimbursement days is ${ this.props.settings.maxdaysofoperatorreimbursement }. `;
    }

    const maxReimbursement = Math.min(this.state.numdays, this.props.settings.maxdaysofoperatorreimbursement) * this.props.settings.maxamoutoperatorreimbursement;

    if (!this.state.amountbeforetaxes) {
      errors.error = true;
      errors.amountbeforetaxes = 'Please enter the reimbursement amount. ';
    } else if (this.state.numdays && this.state.amountbeforetaxes > maxReimbursement) {
      errors.error = true;
      errors.amountbeforetaxes = `The maximum amount of reimbursement is $${ maxReimbursement }. `;
    }

    if (!this.state.gst) {
      errors.error = true;
      errors.gst = 'Please enter GST. ';
    }

    if (this.state.invoices.length === 0) {
      errors.error = true;
      errors.invoices = 'Please attach an invoice.';
    }

    if (!this.state.payableto) {
      errors.error = true;
      errors.payableto = 'Please enter a payable to email address.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  deleteFiles = async (toDelete) => {
    let success = true;

    for (const deleteFile of toDelete) {
      if (!success) return;

      this.setState({
        progressPercent: 50,
        progressText: deleteFile.name
      });

      success = await deleteFile.delete();

      if (!success) {
        let errors = { error: true };
        errors.files = `Failed to remove ${ deleteFile.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      } else {
        this.setState({
          progressPercent: 100,
        });
      }
    }

    return success;
  }

  uploadFiles = async (toUpload) => {
    let success = true;

    for (const uploadFile of toUpload) {
      if (!success) return;

      success = await uploadFile.upload({
        onUploadProgress: (percent) => {
          this.setState({
            progressPercent: percent,
            progressText: uploadFile.name
          });
        }
      });

      if (!success) {
        let errors = { error: true };
        errors.files = `Failed to upload ${ uploadFile.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      }
    }

    return success;
  }

  updateFiles = async (data) => {
    let toDelete = [];
    let toUpload = [];

    ChosenFile.syncFiles(this.props.reimbursement.invoices || [], data.files, toDelete, toUpload);

    let success = await this.deleteFiles(toDelete);

    if (success) {
      success = await this.uploadFiles(toUpload);

      // Update the uploaded state
      data.invoices = data.files.map((file) => { return file.stringify(); });
      this.setState({
        invoices: data.invoices
      });
    }

    return success;
  }

  create = async (data) => {
    this.setState({ loading: 'Syncing Files' });

    const success = await this.updateFiles(data);

    if (success) {
      this.setState({ loading: 'Creating' });
      await postTrainingAgencyReimbursement(data);
      if (this.props.reimbursement.id) {
        this.exit();
      }
    }

    this.setState({ loading: false });
  }

  update = async (data) => {
    this.setState({ loading: 'Syncing Files' });

    const success = await this.updateFiles(data);

    if (success) {
      this.setState({ loading: 'Updating' });
      await updateTrainingAgencyReimbursement(data);
      if (this.props.reimbursement.id) {
        this.exit();
      }
    }

    this.setState({ loading: false });
  }

  renderView = () => {
    return (
      <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
            { this.state.status === 'Pending' && !this.state.isAdmin &&
              <Form.Button floated='right' className='form-top-button' positive>{ this.state.isNew ? 'Submit' : 'Update' } Request</Form.Button>
            }
            <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
        </Header>
        <Segment.Group size='large' className='view-segment-group'>
          <Segment disabled={ this.state.isReadOnly }>
           <Form.Group>
              <Form.Input readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 8 }
                label='Operator' value={ this.state.operatorname } name='operatorname'
                error={ !!this.state.errors.operatorname }
                onChange={ this.handleChange }
              />
              <Form.Field readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 2 }
                label='Number of Days' value={ this.state.numdays } name='numdays'
                control={ NumberInput } onChange={ this.handleNumberChange }
                error={ !!this.state.errors.numdays }
              />
              <Form.Field readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 2 }
                label='Amount' value={ this.state.amountbeforetaxes } name='amountbeforetaxes'
                error={ !!this.state.errors.amountbeforetaxes }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 2 }
                value={ this.state.gst } name='gst'
                label={ <TaxCalculator disabled={ this.state.isReadOnly } label='GST' type='gst' name='gst' amount={ this.state.amountbeforetaxes } onCalculate={ this.handleTaxChange.bind(this) }/> }
                error={ !!this.state.errors.gst }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field label='Total Amount Claimed' value={ this.state.totalamount } width={ 2 }
                control={ CurrencyText }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.operatorname }{ this.state.errors.numdays }{ this.state.errors.amountbeforetaxes }{ this.state.errors.gst }
            </Message>
            <Form.Group>
              <Form.Input readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly }
                label='Payable To' value={ this.state.payableto } name='payableto' width={ 16 }
                error={ !!this.state.errors.payableto }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.payableto }
            </Message>
            <Form.Group>
              <Form.Field readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 16 }
                control={ FileChooserSegment } label='Invoices' name='files' files={ this.state.files } multiple
                ui={ this.state.ui } onUIStateChange={ this.handleFilesUIStateChange }
                onChange={ this.handleFilesChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.invoices }
            </Message>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    reimbursement: storeState.models.trainingAgencyReimbursement,
    ui: storeState.ui.trainingAgencyReimbursement,
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(TrainingAgencyReimbursement);
