import React from 'react';

import { Segment, Form, Message } from 'semantic-ui-react';

import CurrencyInput from '../components/CurrencyInput';
import CurrencyText from '../components/CurrencyText';
import EditDialog from '../components/EditDialog';
import NumberInput from '../components/NumberInput';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  timestamp: '',
  name: '',
  rank: '',
  datesworked: '',
  hoursworked: 0,
  rate: 0.00,
  amount: 0.00,

  errors: {
    error: false
  }
};

class BackfillEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, ...this.props.backfill };

    this.didChange = this.didChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleHoursChange = (name, value) => {
    this.setState({
      hoursworked: value,
      amount: this.state.rate * value
    }, () => { this.validate(); });
  }

  handleCurrencyChange = (name, value) => {
    let update = { [name]: value };
    if (name === 'rate') {
      update.amount = this.state.hoursworked * value;
    }
    this.setState(update, () => { this.validate(); });
  }

  didChange = () => {
    // Always true for new entries
    if (!this.props.backfill.timestamp) return true;

    if (this.state.name !== this.props.backfill.name) { return true; }
    if (this.state.rank !== this.props.backfill.rank) { return true; }
    if (this.state.datesworked !== this.props.backfill.datesworked) { return true; }
    if (this.state.hoursworked !== this.props.backfill.hoursworked) { return true; }
    if (this.state.rate !== this.props.backfill.rate) { return true; }
    if (this.state.amount !== this.props.backfill.amount) { return true; }

    return false;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.hoursworked) {
      errors.error = true;
      errors.hoursworked = 'Please enter hours worked. ';
    }

    if (!this.state.rate) {
      errors.error = true;
      errors.rate = 'Please enter a rate.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    const { errors, ...state } = this.state;
    this.props.onSave('backfill', { ...state });
  }

  render = () => {
    const { onSave, onClose, backfill, ...props } = this.props;

    return (
      <EditDialog size='small' header='Add Backfill Worker' onSave={ this.onSave } onClose={ onClose } readOnly={ this.props.disabled }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Segment.Group size='large'>
          <Segment disabled={ this.props.disabled }>
            <Form.Group>
              <Form.Input readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 8 }
                label='Name' value={ this.state.name } name='name'
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 8 }
                label='Rank' value={ this.state.rank } name='rank'
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 16 }
                label='Dates Worked' value={ this.state.datesworked } name='datesworked'
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Field readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 4 }
                label='Hours Worked' value={ this.state.hoursworked } name='hoursworked'
                control={ NumberInput } onChange={ this.handleHoursChange }
                error={ !!this.state.errors.hoursworked }
              />
              <Form.Field readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 4 }
                label='Rate' value={ this.state.rate } name='rate'
                error={ !!this.state.errors.rate }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field label='Amount' value={ this.state.amount } width={ 4 }
                control={ CurrencyText }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.hoursworked }{ this.state.errors.rate }
            </Message>
          </Segment>
        </Segment.Group>
      </EditDialog>
    );
  }
}

BackfillEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  backfill: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default BackfillEditDialog;
