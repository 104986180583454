import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import SidebarMenu from '../../components/SidebarMenu';

import FireDepartmentHome from './FireDepartmentHome';
import FireDepartmentSiteSurveyList from './FireDepartmentSiteSurveyList';
import FireDepartmentSiteSurvey from './FireDepartmentSiteSurvey';
import FireDepartmentReimbursements from './FireDepartmentReimbursements';
import FireDepartmentReimbursement from './FireDepartmentReimbursement';
import FireDepartmentRescueService from './FireDepartmentRescueService';
import FireDepartmentRescueServices from './FireDepartmentRescueServices';
import FireDepartmentJointTraining from './FireDepartmentJointTraining';
import FireDepartmentSpecialPayments from './FireDepartmentSpecialPayments';
import FireDepartmentSpecialPayment from './FireDepartmentSpecialPayment';
import FireDepartmentAnnualSubmission from './FireDepartmentAnnualSubmission';
import FireDepartmentAnnualSubmissions from './FireDepartmentAnnualSubmissions';
import FireDepartmentTrainers from './FireDepartmentTrainers';
import FireDepartmentTrainer from './FireDepartmentTrainer';
import FireDepartmentTrainerIncrease from './FireDepartmentTrainerIncrease';
import FireDepartmentTrainersEquivalency from './FireDepartmentTrainersEquivalency';
import FireDepartmentTrainerEquivalency from './FireDepartmentTrainerEquivalency';
import FireDepartmentProfile from './FireDepartmentProfile';

import { PATH_FIRE_DEPARTMENT, PATH_ID, PATH_TIMESTAMP, PATH_SITE_SURVEY, PATH_REIMBURSEMENTS, PATH_RESCUE, PATH_JOINT_TRAINING, PATH_ANNUAL } from '../../js/paths';
import { PATH_SPECIAL_PAYMENTS, PATH_TRAINER, PATH_TRAINING_EXPENSES, PATH_PROFILE, PATH_INCREASE, PATH_TRAINER_EQUIVALENCY, PATH_NEW } from '../../js/paths';

class FireDepartment extends React.Component {
  render() {
    const dashboardPath = `/${ PATH_FIRE_DEPARTMENT }`;
    const siteSurveysPath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_SITE_SURVEY }`;
    const trainersPath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_TRAINER }`;
    const trainersEquivalencyPath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_TRAINER_EQUIVALENCY }`;
    const trainersIncreasePath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_TRAINER }/${ PATH_INCREASE }`;
    const trainingExpensesPath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_REIMBURSEMENTS }/${ PATH_TRAINING_EXPENSES }`;
    const jointTrainingPath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_REIMBURSEMENTS }/${ PATH_JOINT_TRAINING }`;
    const specialPaymentsPath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_REIMBURSEMENTS }/${ PATH_SPECIAL_PAYMENTS }`;
    const rescueInformationPath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_RESCUE }/${ PATH_NEW }`;
    const rescuesPath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_RESCUE }`;
    const annualSubmissionsPath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_ANNUAL }`;
    const annualSubmissionPath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_ANNUAL }`;
    const profilePath = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_PROFILE }`;

    let reimbursementItems = [
      { name: 'Training Expenses', path: trainingExpensesPath },
    ];

    // if (this.props.currentUser.fd && this.props.currentUser.fd.jointtrainingid) {
    //   reimbursementItems.push({ name: 'Joint Training', path: jointTrainingPath });
    // }

    const menus = [{
      header: 'Menu',
      items: [
        { name: 'Dashboard', path: dashboardPath },
      ]
    }, {
      header: 'Reimbursements',
      items: reimbursementItems
    }, {
      header: 'Instructors Management',
      items: [
        { name: 'Instructors List', path: trainersPath }, // TODO: Inactive/Active area
        { name: 'Prior Training', path: trainersEquivalencyPath },
      ]
    }, {
      header: 'Special Requests',
      items: [
        { name: 'Equipment', path: specialPaymentsPath },
        { name: 'Additional Instructors', path: trainersIncreasePath }
      ]
    }, {
      header: 'Surveys',
      items: [
        { name: 'Site Surveys', path: siteSurveysPath }
      ]
    }, {
      header: 'Reporting',
      items: [
        { name: 'Work Site Rescues', path: rescuesPath },
        { name: 'Annual Submission', path: annualSubmissionsPath }
      ]
    }, {
      header: 'Account',
      items: [
        { name: 'Profile', path: profilePath }
      ]
    }];

    return (
      <SidebarMenu menus={ menus } location={ this.props.location }>
        <main className='pg-content'>
          <Route
            render={() => (
              <Switch>
                <Route exact path={ dashboardPath } component={ FireDepartmentHome }/>

                <Route exact path={ siteSurveysPath } component={ FireDepartmentSiteSurveyList }/>
                <Route exact path={ `${ siteSurveysPath }/:${ PATH_TIMESTAMP }` } component={ FireDepartmentSiteSurvey }/>

                <Route exact path={ trainingExpensesPath } component={ FireDepartmentReimbursements }/>
                <Route exact path={ `${ trainingExpensesPath }/:${ PATH_TIMESTAMP }` } component={ FireDepartmentReimbursement }/>
                <Route exact path={ `${ trainingExpensesPath }/:${ PATH_TIMESTAMP }/:${ PATH_ID }` } component={ FireDepartmentReimbursement }/>

                <Route exact path={ rescueInformationPath } component={ FireDepartmentRescueService }/>
                <Route exact path={ rescuesPath } component={ FireDepartmentRescueServices }/>
                <Route exact path={ jointTrainingPath} component={ FireDepartmentJointTraining }/>
                <Route exact path={ annualSubmissionsPath } component={ FireDepartmentAnnualSubmissions }/>
                <Route exact path={ `${ annualSubmissionPath }/:${ PATH_TIMESTAMP }` } component={ FireDepartmentAnnualSubmission }/>

                <Route exact path={ specialPaymentsPath } component={ FireDepartmentSpecialPayments }/>
                <Route exact path={ `${ specialPaymentsPath }/:${ PATH_TIMESTAMP }` } component={ FireDepartmentSpecialPayment }/>
                <Route exact path={ `${ specialPaymentsPath }/:${ PATH_TIMESTAMP }/:${ PATH_ID }` } component={ FireDepartmentSpecialPayment }/>

                <Route exact path={ trainersIncreasePath } component={ FireDepartmentTrainerIncrease }/>

                <Route exact path={ trainersPath } component={ FireDepartmentTrainers }/>
                <Route exact path={ `${ trainersPath }/:${ PATH_ID }` } component={ FireDepartmentTrainer }/>

                <Route exact path={ trainersEquivalencyPath } component={ FireDepartmentTrainersEquivalency }/>
                <Route exact path={ `${ trainersEquivalencyPath }/:${ PATH_ID }` } component={ FireDepartmentTrainerEquivalency }/>

                <Route exact path={ profilePath } component={ FireDepartmentProfile }/>
              </Switch>
            )}
          />
        </main>
      </SidebarMenu>
    );
  }
}
 
function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
  };
}

export default connect(mapStoreStateToProps)(FireDepartment);
