import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form } from 'semantic-ui-react';

import { getAdminCommittee, updateAdminCommittee } from '../../api/administratorAPI';

import { UPDATE_ADMIN_COMMITTEE_UI } from '../../js/actionTypes';

import MemberSegment from '../../components/MemberSegment';

import MemberEditDialog from '../../dialogs/MemberEditDialog';

import EditView from '../EditView';

const INITIAL_STATE = {
  members: [],

  // UI

  editing: {},

  className: 'admin-committee',
  title: 'Committee Membership',
};

class AdminCommittee extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.ui = {
      members: {
        sortField: this.props.ui.members.sortField || 'name',
        sortDescending: this.props.ui.members.sortDescending === true,
      },
    };

    this.state.exitPage = this.props.currentUser.homepage;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUIStateChange = this.handleUIStateChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
  }

  fetch = async () => {
    await getAdminCommittee();
  }

  onLoad = () => {
    this.setState({ ...this.props.adminCommittee });
  }

  startEditing = (componentName, entry) => {
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing });
  }

  stopEditing = () => {
    this.setState({ editing: {}});
  }

  handleComponentRemove = (componentName, entry) => {
    const members = this.state.members.filter(item => {
      return entry.memberemail !== item.memberemail
    });

    this.setState({ members: members }, () => { this.onChange() });
  }

  handleComponentEdit = (componentName, entry) => {
    this.stopEditing();

    let members = this.state.members.filter(item => {
      return entry.memberemail !== item.memberemail
    });
    members.push(entry);

    this.setState({ members: members }, () => { this.onChange() });
  }

  isValid = () => {
    let errors = { error: false };

    this.setState({ errors: errors });

    return !errors.error;
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });

    const {ui, editing, ...body } = data;

    await updateAdminCommittee(body);
    if (this.props.adminCommittee.id) {
      this.exit();
    }

    this.setState({ loading: false });
  }

  handleUIStateChange(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_COMMITTEE_UI, adminCommittee: this.state.ui });
    });
  }

  renderView = () => {
    return (
      <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
          <Form.Button floated='right' className='form-top-button' positive>Update</Form.Button>
          <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
        </Header>
        <Segment.Group size='large' className='view-segment-group'>
          <MemberSegment members={ this.state.members }
            ui={ this.state.ui.members } onUIStateChange={ this.handleUIStateChange }
            edit={ this.startEditing } remove={ this.handleComponentRemove }
          />
        </Segment.Group>
      </Form>
    );
  }

  renderAfterView = () => {
    if (this.state.editing.members) {
      return (
        <MemberEditDialog open={ !!this.state.editing.members } member={ this.state.editing.members }
          onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
        />
      );
    }

    return null;
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    adminCommittee: storeState.models.adminCommittee,
    ui: storeState.ui.adminCommittee
  };
}

export default connect(mapStoreStateToProps)(AdminCommittee);
