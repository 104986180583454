import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SidebarMenu from '../../components/SidebarMenu';

import AdminHome from './AdminHome';
import AdminUsers from './AdminUsers';
import AdminCCUser from './AdminCCUser';
import AdminReportCrane from './AdminReportCrane';
import AdminReportRescues from './AdminReportRescues';
import AdminReportTraining from './AdminReportTraining';
import AdminReportFDFinancial from './AdminReportFDFinancial';
import AdminReportFDFinancialDetails from './AdminReportFDFinancialDetails';
import AdminReportFireDepartments from './AdminReportFireDepartments';
import AdminReportFireDepartment from './AdminReportFireDepartment';
import AdminReportFireDepartmentInstructor from './AdminReportFireDepartmentInstructor';
import AdminReportAnnualSubmissions from './AdminReportAnnualSubmissions';
import AdminEmails from './AdminEmails';
import AdminCustomBallot from './AdminCustomBallot';
import AdminCustomBallots from './AdminCustomBallots';
import AdminSettings from './AdminSettings';
import AdminCommittee from './AdminCommittee';
import AdminCostCenter from './AdminCostCenter';
import AdminCostCenters from './AdminCostCenters';
import AdminJointTraining from './AdminJointTraining';
import AdminReimbursements from './AdminReimbursements';
import AdminReimbursementSummary from './AdminReimbursementSummary';
import AdminRegistrationsFD from './AdminRegistrationsFD';
import AdminRegistrationsTA from './AdminRegistrationsTA';
import AdminSpecialPayments from './AdminSpecialPayments';
import AdminAnnualSubmissions from './AdminAnnualSubmissions';
import AdminAnnualSubmission from './AdminAnnualSubmission';
import AdminReimbursementsTA from './AdminReimbursementsTA';
import AdminPPE from './AdminPPE';
import AdminPPEDetails from './AdminPPEDetails';
import AdminTRs from './AdminTRs';
import AdminTU from './AdminTU';
import AdminTUs from './AdminTUs';
import FireDepartmentReimbursement from '../FireDepartment/FireDepartmentReimbursement';
import FireDepartmentSpecialPayment from '../FireDepartment/FireDepartmentSpecialPayment';
import FireDepartmentJointTraining from '../FireDepartment/FireDepartmentJointTraining';
import FireDepartmentProfile from '../FireDepartment/FireDepartmentProfile';
import FireDepartmentTrainerIncrease from '../FireDepartment/FireDepartmentTrainerIncrease';
import TrainingAgencyProfile from '../TrainingAgency/TrainingAgencyProfile';
import TrainingAgencyReimbursement from '../TrainingAgency/TrainingAgencyTowerCraneReimbursement';

import { PATH_ADMIN, PATH_USERS, PATH_REPORTS, PATH_TIMESTAMP, PATH_EMAILS, PATH_CUSTOM_BALLOTS, PATH_REIMBURSEMENTS } from '../../js/paths';
import { PATH_SPECIAL_PAYMENTS, PATH_SETTINGS, PATH_COMMITTEE, PATH_COST_CENTERS, PATH_JOINT_TRAINING, PATH_ID, PATH_SUMMARY } from '../../js/paths';
import { PATH_TRAINING_EXPENSES, PATH_PPE, PATH_TR, PATH_EQUIVALENCY, PATH_REGISTRATIONS, PATH_FIRE_DEPARTMENT, PATH_USER, PATH_CONSTRUCTION } from '../../js/paths';
import { PATH_CRANE, PATH_RESCUE, PATH_FINANCIAL_SUMMARY, PATH_ANNUAL, PATH_TRAINING_AGENCY, PATH_OPERATOR_REIMBURSEMENT } from '../../js/paths';

class Admin extends React.Component {
  render() {

    const dashboardPath = `/${ PATH_ADMIN }`;
    const reimbursementsPath = `/${ PATH_ADMIN }/${ PATH_REIMBURSEMENTS }`;
    const specialPaymentsPath = `/${ PATH_ADMIN }/${ PATH_SPECIAL_PAYMENTS }`;
    const annualSubmissionsPath = `/${ PATH_ADMIN }/${ PATH_ANNUAL }`;
    const annualSubmissionPath = `/${ PATH_ADMIN }/${ PATH_ANNUAL }/:${ PATH_ID }/:${ PATH_TIMESTAMP }`;
    const ppePath = `/${ PATH_ADMIN }/${ PATH_PPE }`;
    const ppeDetailsPath = `/${ PATH_ADMIN }/${ PATH_PPE }/:${ PATH_ID }/:${ PATH_TIMESTAMP }`;
    const trPath = `/${ PATH_ADMIN }/${ PATH_TR }/:${ PATH_ID }/:${ PATH_TIMESTAMP }`;
    const trsPath = `/${ PATH_ADMIN }/${ PATH_TR }`;
    const tuPath = `/${ PATH_ADMIN }/${ PATH_EQUIVALENCY }/:${ PATH_ID }/:${ PATH_TIMESTAMP }`;
    const tusPath = `/${ PATH_ADMIN }/${ PATH_EQUIVALENCY }`;
    const usersPath = `/${ PATH_ADMIN }/${ PATH_USERS }`;
    const ccUserPath = `/${ PATH_ADMIN }/${ PATH_CONSTRUCTION }/${ PATH_USER }/:${ PATH_ID }`;
    const operatorReimbursementPath = `/${ PATH_ADMIN }/${ PATH_OPERATOR_REIMBURSEMENT }`;
    const registrationsFDPath = `/${ PATH_ADMIN }/${ PATH_REGISTRATIONS }/${ PATH_FIRE_DEPARTMENT }`;
    const registrationsTAPath = `/${ PATH_ADMIN }/${ PATH_REGISTRATIONS }/${ PATH_TRAINING_AGENCY }`;
    const reportsCranePath = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_CRANE }`;
    const reportsRescuesPath = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_RESCUE }`;
    const reportsTrainingPath = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_TR }`;
    const reportsFDFinancialPath = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }/${ PATH_FINANCIAL_SUMMARY }`;
    const reportsFDFinancialYearPath = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }/${ PATH_FINANCIAL_SUMMARY }/:${ PATH_TIMESTAMP }`;
    const reportsFDFinancialDetailsPath = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }/${ PATH_FINANCIAL_SUMMARY }/:${ PATH_ID }/:${ PATH_TIMESTAMP }`;
    const reportsFireDepartmentsPath = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }`;
    const reportsFireDepartmentPath = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }/:${ PATH_ID }`;
    const reportsFireDepartmentInstructorPath = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }/:${ PATH_ID }/:${ PATH_TIMESTAMP }`;
    const reportsAnnualSubmissionsPath = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_ANNUAL }`;
    const emailsPath = `/${ PATH_ADMIN }/${ PATH_EMAILS }`;
    const customBallotsPath = `/${ PATH_ADMIN }/${ PATH_CUSTOM_BALLOTS }`;
    const settingsPath = `/${ PATH_ADMIN }/${ PATH_SETTINGS }`;
    const committeePath = `/${ PATH_ADMIN }/${ PATH_COMMITTEE }`;
    const costCentersPath = `/${ PATH_ADMIN }/${ PATH_COST_CENTERS }`;
    const jointTrainingPath = `/${ PATH_ADMIN }/${ PATH_JOINT_TRAINING }`;

    const menus = [{
      header: 'Menu',
      items: [
        { name: 'Dashboard', path: dashboardPath },
      ]
    }, {
      header: 'Fire Department Requests',
      items: [
        { name: 'New FD Applications', path: registrationsFDPath },
        { name: 'Training Reimbursements', path: reimbursementsPath },
        { name: 'Special Payments', path: specialPaymentsPath },
        { name: 'Increase Instructors', path: trsPath },
        { name: 'Instructor Prior Training', path: tusPath },
        { name: 'PPEs', path: ppePath },
        { name: 'Joint Training Reimbursements', path: jointTrainingPath },
        { name: 'Annual Submission', path: annualSubmissionsPath },
      ]
    }, {
      header: 'Training Agency Requests',
      items: [
        { name: 'New TA Applications', path: registrationsTAPath },
        { name: 'Tower Crane Operator Reimbursements', path: operatorReimbursementPath },
      ]
    }, {
      header: 'System Maintenance',
      items: [
        { name: 'Settings', path: settingsPath },
        { name: 'Committee Members', path: committeePath },
        { name: 'Payment Cost Centers', path: costCentersPath },
        { name: 'Users', path: usersPath },
        // { name: 'Emails', path: emailsPath },
      ]
    }, {
      header: 'Reports',
      items: [
        { name: 'Fire Departments', path: reportsFireDepartmentsPath },
        { name: 'FD Financial Summary', path: reportsFDFinancialPath },
        { name: 'FD Work Rescues', path: reportsRescuesPath },
        { name: 'Site Surveys', path: reportsCranePath },
        { name: 'Training Agencies', path: reportsTrainingPath },
        { name: 'Annual Submissions', path: reportsAnnualSubmissionsPath },
      ]
    }, {
      header: 'Custom Ballots',
      items: [
        { name: 'Custom Ballots', path: customBallotsPath },
      ]
    }];

    return (
      <SidebarMenu menus={ menus } location={ this.props.location }>
        <main className='pg-content'>
          <Route
            render={() => (
              <Switch>
                <Route exact path={ dashboardPath } component={ AdminHome } />
                <Route exact path={ usersPath } component={ AdminUsers } />
                <Route exact path={ ccUserPath } component={ AdminCCUser } />
                <Route exact path={ reimbursementsPath } component={ AdminReimbursements } />
                <Route exact path={ specialPaymentsPath } component={ AdminSpecialPayments } />
                <Route exact path={ annualSubmissionsPath } component={ AdminAnnualSubmissions } />
                <Route exact path={ annualSubmissionPath } component={ AdminAnnualSubmission } />
                <Route exact path={ ppePath } component={ AdminPPE } />
                <Route exact path={ ppeDetailsPath } component={ AdminPPEDetails } />
                <Route exact path={ trPath } component={ FireDepartmentTrainerIncrease } />
                <Route exact path={ trsPath } component={ AdminTRs } />
                <Route exact path={ tuPath } component={ AdminTU } />
                <Route exact path={ tusPath } component={ AdminTUs } />
                <Route exact path={ reportsCranePath } component={ AdminReportCrane } />
                <Route exact path={ reportsRescuesPath } component={ AdminReportRescues } />
                <Route exact path={ reportsTrainingPath } component={ AdminReportTraining } />
                <Route exact path={ reportsFDFinancialPath } component={ AdminReportFDFinancial } />
                <Route exact path={ reportsFDFinancialYearPath } component={ AdminReportFDFinancial } />
                <Route exact path={ reportsFDFinancialDetailsPath } component={ AdminReportFDFinancialDetails } />
                <Route exact path={ reportsFireDepartmentsPath } component={ AdminReportFireDepartments } />
                <Route exact path={ reportsFireDepartmentPath } component={ AdminReportFireDepartment } />
                <Route exact path={ reportsFireDepartmentInstructorPath } component={ AdminReportFireDepartmentInstructor } />
                <Route exact path={ reportsAnnualSubmissionsPath } component={ AdminReportAnnualSubmissions } />

                <Route exact path={ registrationsFDPath } component={ AdminRegistrationsFD } />
                <Route exact path={ `${ registrationsFDPath }/:${ PATH_ID }` } component={ FireDepartmentProfile }/>

                <Route exact path={ registrationsTAPath } component={ AdminRegistrationsTA } />
                <Route exact path={ `${ registrationsTAPath }/:${ PATH_ID }` } component={ TrainingAgencyProfile }/>

                <Route exact path={ operatorReimbursementPath } component={ AdminReimbursementsTA } />
                <Route exact path={ `${ operatorReimbursementPath }/:${PATH_ID}/:${ PATH_TIMESTAMP }` } component={ TrainingAgencyReimbursement }/>

                <Route exact path={ emailsPath } component={ AdminEmails } />

                <Route exact path={ `${ customBallotsPath }/:${ PATH_ID }` } component={ AdminCustomBallot } />
                <Route exact path={ `${ customBallotsPath }/:${ PATH_ID }/:${ PATH_TIMESTAMP }` } component={ AdminCustomBallot } />
                <Route exact path={ customBallotsPath } component={ AdminCustomBallots } />

                <Route exact path={ settingsPath } component={ AdminSettings } />
                <Route exact path={ committeePath } component={ AdminCommittee } />
                <Route exact path={ costCentersPath } component={ AdminCostCenters } />
                <Route exact path={ `${ costCentersPath }/:${ PATH_ID }` } component={ AdminCostCenter }/>
                <Route exact path={ jointTrainingPath } component={ AdminJointTraining } />
                <Route exact path={ `${ reimbursementsPath }/${ PATH_SUMMARY }/:${PATH_ID}/:${ PATH_TIMESTAMP }` } component={ AdminReimbursementSummary }/>
                <Route exact path={ `${ reimbursementsPath }/${ PATH_TRAINING_EXPENSES }/:${PATH_ID}/:${ PATH_TIMESTAMP }` } component={ FireDepartmentReimbursement }/>
                <Route exact path={ `${ reimbursementsPath }/${ PATH_SPECIAL_PAYMENTS }/:${ PATH_ID }/:${ PATH_TIMESTAMP }` } component={ FireDepartmentSpecialPayment }/>
                <Route exact path={ `${ reimbursementsPath }/${ PATH_JOINT_TRAINING }/:${PATH_ID}/:${ PATH_TIMESTAMP }` } component={ FireDepartmentJointTraining }/>
              </Switch>
            )}
          />
        </main>
      </SidebarMenu>
    );
  }
}

export default Admin;
