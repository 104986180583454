import React from 'react';

import { Container, Progress, Loader } from 'semantic-ui-react';

import PropTypes from 'prop-types';

class ViewLoader extends React.Component {
  render() {
    const showProgress = this.props.percent > 0 && this.props.percent < 100;
    return (
      <Container style={{ width: '40%' }}>
        <Loader active size='huge' style={{ marginTop: (showProgress || this.props.home) ? '0em' : '6em' }}>
          { this.props.loading !== true ? this.props.loading : '' }
        </Loader>
        { showProgress &&
          <Progress percent={ this.props.percent } style={{ marginTop: '14em' }} progress color='green'>
            { this.props.text }
          </Progress>
        }
      </Container>
    );
  }
};

ViewLoader.propTypes = {
  loading: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
  percent: PropTypes.number,
  text: PropTypes.string,
  home: PropTypes.bool,
};

export default ViewLoader;
