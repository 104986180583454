import React from 'react';

import { Segment, Form, Message } from 'semantic-ui-react';

import EditDialog from '../components/EditDialog';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  contact_email: '',
  errors: {
    error: false
  }
};

class EmailEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, contact_email: this.props.contact_email };

    this.didChange = this.didChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  didChange = () => {
    if (this.state.contact_email !== this.props.contact_email) { return true; }

    return false;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    this.props.onSave('contact_email', this.state.contact_email);
  }

  render = () => {
    const { onSave, onClose, contact_email, ...props } = this.props;

    return (
      <EditDialog size='small' header='Update FD Logon ID' onSave={ this.onSave } onClose={ onClose } isNotSubmit
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Segment.Group size='large'>
          <Segment>
            <Form.Group>
              <Form.Input
                label='FD Logon ID' value={ this.state.contact_email } name='contact_email'
                onChange={ this.handleChange } width={ 16 }
              />
            </Form.Group>
            <Message info compact size='tiny' color='yellow'>Changing this value will change the logon user ID for the department. Would you like to proceed?</Message>
          </Segment>
        </Segment.Group>
      </EditDialog>
    );
  }
}

EmailEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  contact_email: PropTypes.string.isRequired,
};

export default EmailEditDialog;
