import React from 'react';

import { Popup } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import IconButton from './IconButton';

class InfoButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const { className, children, pathname, ...props } = this.props;

    const button = (
      <IconButton name='question circle' color='blue' className={ 'info-button' + (className || '') } { ...props }/>
    );

    const popup = (
      <Popup on='click' wide='very' size='large' position='right center'
        onOpen={ this.handleOpen } onClose={ this.handleClose } open={ this.state.open } trigger={ button } children={ children }
      />
    );

    return pathname ? <a target='blank' href={ pathname }>{ button }</a> : popup;
  }
};

InfoButton.propTypes = {
  pathname: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default InfoButton;
