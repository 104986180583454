import React from 'react';

import NumberFormat from 'react-number-format';

import PropTypes from 'prop-types';

class CurrencyText extends React.Component {
  render = () => {
    const { style, ...props } = this.props;

    return (
      <NumberFormat thousandSeparator={ true } prefix={ '$' } decimalScale={ 2 } fixedDecimalScale={ true }
        allowNegative={ false } displayType={ 'text' } renderText={ value => <div className='currency-text' style={ style || {} }>{ value }</div> }
        { ...props }
      />
    );
  }
};

CurrencyText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CurrencyText;
