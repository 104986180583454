import React from 'react';

import Top from './Top';
import AuthWrapper from './AuthWrapper';
import Bottom from './Bottom';

class App extends React.Component {
  render() {
    return (
      <div className='app-container'>
        <Top />
        <div className='app-body'>
          <AuthWrapper />
        </div>
        <Bottom />
      </div>
    );
  }
}

export default App;
