import React from 'react';

import { Segment, Header, Message } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import AddButton from './AddButton';
import CurrencyInput from './CurrencyInput';
import CurrencyText from './CurrencyText';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import SortTable from './SortTable';
import TaxCalculator from './TaxCalculator';

import { DATE_SHORT_MONTH_DAY_YEAR } from '../js/constants';
import { VEHICLE_TYPES } from '../js/lists';

import { formatDateTime, sortableDateTime } from '../utils/date';
import { sort } from '../utils/utils';

class VehicleSegment extends React.Component {
  updateUIState = (uiState) => {
    this.props.onUIStateChange({ vehicle: uiState });
  }

  render = () => {
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Vehicle Expenses</Header.Content>
          <AddButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'vehicle', {}) }/>
        </Header>

        {(() => {
          if (this.props.vehicle.length === 0) {
            return <Message info compact size='tiny'>No vehicle expenses</Message>;
          }

          let vehicles = this.props.vehicle.map(v => {
            let vehicle = { ...v };
            vehicle.displayType = VEHICLE_TYPES[vehicle.type];
            vehicle.sortableDate = sortableDateTime(vehicle.date);
            vehicle.numReceipts = vehicle.receipts && vehicle.receipts.length;
            vehicle.displayDate = formatDateTime(vehicle.date, DATE_SHORT_MONTH_DAY_YEAR);
            return vehicle;
          });

          vehicles = sort(vehicles, this.props.ui.sortField);
          if (this.props.ui.sortDescending) {
            vehicles.reverse();
          }

          const right = { textAlign: 'right' };
          const totalbeforetax = vehicles.reduce((sum, v) => { return sum + v.amountbeforetaxes }, 0);

          return <SortTable collapsing sortField={ this.props.ui.sortField } sortDescending={ this.props.ui.sortDescending }
            onSort={ this.updateUIState } size='small'
            headers={[
              { field: 'displayType',           title: 'Type',     width: '3'               },
              { field: 'sortableDate',          title: 'Date',     width: '4', style: right },
              { field: 'numReceipts',           title: 'Receipts', width: '3', style: right },
              { field: 'amountbeforetaxes',     title: 'Amount',   width: '4', style: right },
              { field: 'buttons', noSort: true, title: '',         width: '2'               },
            ]}
            footers={[
              [ null, null, <div style={ right }><strong>Total Before Tax:</strong></div>, <CurrencyText value={ totalbeforetax } style={ right }/>, null ],
              [
                <div style={ right }><TaxCalculator disabled={ this.props.disabled } type='gst' name='vehiclegst' amount={ totalbeforetax } onCalculate={ this.props.onTaxCalculate }/></div>,
                <CurrencyInput readOnly={ this.props.disabled } value={ this.props.vehiclegst } name='vehiclegst' onChange={ this.props.onCurrencyStateChange }/>,
                <div style={ right }><TaxCalculator disabled={ this.props.disabled } type='pst' name='vehiclepst' amount={ totalbeforetax } onCalculate={ this.props.onTaxCalculate }/></div>,
                <CurrencyInput readOnly={ this.props.disabled } value={ this.props.vehiclepst } name='vehiclepst' onChange={ this.props.onCurrencyStateChange }/>,
                null
              ],
              [ null, null, <div style={ right }><strong>Total After Tax:</strong></div>, <CurrencyText value={ this.props.totalvehicleaftertax } style={ right }/>, null ]
            ]}
          >
            { vehicles.map((vehicle, index) => (
              <SortTable.Row key={ index } id={ `vehicle_${ vehicle.timestamp }`}>
                <SortTable.Cell>{ vehicle.displayType }</SortTable.Cell>
                <SortTable.Cell textAlign='right'>{ vehicle.displayDate }</SortTable.Cell>
                <SortTable.Cell textAlign='right'>{ vehicle.numReceipts }</SortTable.Cell>
                <SortTable.Cell textAlign='right'><CurrencyText value={ vehicle.amountbeforetaxes }/></SortTable.Cell>
                <SortTable.Cell textAlign='right'>
                  <EditButton view={ this.props.disabled } onClick={ this.props.edit.bind(this, 'vehicle', vehicle) }/>
                  <DeleteButton hide={ this.props.disabled } content='Delete the vehicle expense?' onConfirm={ this.props.remove.bind(this, 'vehicle', vehicle) }/>
                </SortTable.Cell>
              </SortTable.Row>
            ))}
          </SortTable>;
        })()}
      </Segment>
    );
  }
};

VehicleSegment.propTypes = {
  vehicle: PropTypes.array.isRequired,
  vehiclegst: PropTypes.number,
  vehiclepst: PropTypes.number,
  totalvehicleaftertax: PropTypes.number.isRequired,
  ui: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onUIStateChange: PropTypes.func.isRequired,
  onCurrencyStateChange: PropTypes.func.isRequired,
  onTaxCalculate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default VehicleSegment;
