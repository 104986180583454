import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import CommonOrganizationSelectionModal from './OrganizationSelectionModal';
//props are: input { id, label, required, placeholder }, isEdit, isAdmin, selectedOrg, onSelection
export default class CommonOrganizationSelectionInput extends React.Component {
  //Actions
  handleOrgSelected(orgObj) { if (this.props.onSelection) this.props.onSelection(orgObj); }
  handleChangeSelection(e) { 
    e.preventDefault();
    if (this.selectionModal) this.selectionModal.show(); 
  }
  //UI
  render() {
    return (
      <>
        <CommonOrganizationSelectionModal requiresWorksafeValidation={!this.props.isAdmin} 
          allowOrgsListing={this.props.isAdmin} ref={(r) => this.selectionModal = r } onSelection={this.handleOrgSelected.bind(this)}/>
        <div className="fields">
          <div className="required sixteen wide field">
            <label>{this.props.input.label}</label>
            <div className="fake_input">
              {this.props.selectedOrg && this.props.selectedOrg.name}
              {this.props.isEdit && !this.props.selectedOrg &&
                <Button style={{marginLeft: 10}} basic size='mini' onClick={this.handleChangeSelection.bind(this)}>
                  <Icon style={{opacity: .5}} name='search' color='orange'/>
                  <span style={{opacity: .5}}>{this.props.input.placeholder}</span>
                </Button>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}