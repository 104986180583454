import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form, Message, Grid } from 'semantic-ui-react';

import { UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES_UI } from '../../js/actionTypes';

import { REIMBURSEMENT_COURSES } from '../../js/lists';
import { PATH_FIRE_DEPARTMENT, PATH_TRAINER_EQUIVALENCY, PATH_ID, ADM3EQUIVALENCIES } from '../../js/paths';

import { getFireDepartmentTrainer, getFireDepartmentTrainerEquivalencies, postFireDepartmentTrainerEquivalency } from '../../api/fireDepartmentAPI';

import EditView from '../EditView';

import EquivalencySegment from '../../components/EquivalencySegment';
import ExternalButton from '../../components/ExternalButton';

import EquivalencyEditDialog from '../../dialogs/EquivalencyEditDialog';

const INITIAL_STATE = {
  trid: '',
  trainername: '',
  courses: [],

  ui: null,
  editing: {},

  courselist: [],

  title: 'Prior Training',
  className: 'fire-department-trainer',
  exitPage: `/${ PATH_FIRE_DEPARTMENT }/${ PATH_TRAINER_EQUIVALENCY }`,
};

class FireDepartmentTrainerEquivalency extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.ui = {
      equivalencies: {
        sortField: this.props.ui.equivalencies.sortField || 'displayCourse',
        sortDescending: this.props.ui.equivalencies.sortDescending === true,
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  fetch = async () => {
    await getFireDepartmentTrainer(this.props.match.params[PATH_ID]);
    await getFireDepartmentTrainerEquivalencies(this.props.match.params[PATH_ID]);
  }

  onLoad = () => {
    // Build a list of available courses
    let courselist = Object.keys(REIMBURSEMENT_COURSES).map((key) => { return { key: key, value: key, text: REIMBURSEMENT_COURSES[key] }});

    // Remove update
    courselist = courselist.filter(course => { return course.key !== 'update' });

    // Remove completed equivalency courses
    this.props.equivalencies.forEach(equivalency => {
      if (equivalency.status != 'Rejected') {
        const coursekeys = equivalency.courses.map(course => { return course.coursename });
        courselist = courselist.filter(course => { return coursekeys.indexOf(course.key) === -1 });
      }
    });

    // Remove completed trainer courses
    const coursekeys = this.props.trainer.courses.map(course => { return course.coursename });
    courselist = courselist.filter(course => { return coursekeys.indexOf(course.key) === -1 });

    this.setState({
      courselist: courselist,
      trid: this.props.trainer.trainerId,
      trainername: this.props.trainer.trainername
    });
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.courses.length) {
      errors.error = true;
      errors.courses = 'Please add a training equivalency.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  startEditing = (componentName, entry) => {
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing });
  }

  stopEditing = () => {
    this.setState({ editing: {}}, );
  }

  handleComponentRemove = (componentName, entry) => {
    const courses = this.state.courses.filter(item => {
      return entry.coursename !== item.coursename
    });

    this.setState({ courses: courses }, () => { this.onChange(); });
  }

  handleComponentEdit = (componentName, entry) => {
    this.stopEditing();

    let courses = this.state.courses.filter(item => {
      return entry.coursename !== item.coursename
    });
    courses.push(entry);

    this.setState({ courses: courses }, () => { this.onChange(); });
  }

  handleComponentUIStateChange = (componentUI) => {
    this.setState({ ui: { ...this.state.ui, ...componentUI }}, () => {
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES_UI, fireDepartmentTrainerEquivalencies: this.state.ui });
    });
  }

  uploadFiles = async (files) => {
    let success = true;

    for (const file of files) {
      if (!success) return;

      success = await file.upload({
        onUploadProgress: (percent) => {
          this.setState({
            progressPercent: percent,
            progressText: file.name
          });
        }
      });

      if (!success) {
        let errors = { error: true };
        errors.courses = `Failed to upload ${ file.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      }
    }

    return success;
  }

  updateFiles = async (data) => {
    let files = [];
    data.courses.forEach((course) => {
      if (course.files) { files = [ ...files, ...course.files ]; }
    });

    let success = await this.uploadFiles(files);

    if (files.length > 0) {
      // Update the uploaded state
      data.courses.forEach((course) => {
        course.certificate = course.files.map((file) => { return file.stringify(); });
      });
      this.setState({ courses: data.courses });
    }

    return success;
  }

  update = async (dataIn) => {
    this.setState({ loading: 'Uploading Files' });

    const { courselist, editing, ui, trainername, ...data } = dataIn;

    const success = await this.updateFiles(data);

    if (success) {
      this.setState({ loading: 'Submitting' });
      for (const course of data.courses) {
        delete course.fdid;
        delete course.trid;
      }
      await postFireDepartmentTrainerEquivalency(data);
      if (this.props.equivalency.status === 'Pending') {
        this.exit();
      }
    }

    this.setState({ loading: false });
  }

  renderView = () => {
    return (
      <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
      <Header size='medium'>
        <Grid columns='equal' verticalAlign='bottom'>
          <Grid.Column>
          <Header.Content>{ this.state.title }</Header.Content>
            <Header.Subheader>
              Review the Standard Operating Procedure(s) Related to this page
              <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ ADM3EQUIVALENCIES } label="ADM3" />
            </Header.Subheader>
          </Grid.Column>
          <Grid.Column>
          <Form.Button floated='right' className='form-top-button' positive>Submit Request</Form.Button>
          <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
          </Grid.Column>
        </Grid>
      </Header>
        <Segment.Group size='large' className='view-segment-group'>
          <Segment>
            <Form.Group>
              <Form.Input readOnly label='Trainer Name' value={ this.state.trainername } width={ 8 } />
            </Form.Group>
          </Segment>
          <EquivalencySegment courses={ this.state.courses }
            ui={ this.state.ui.equivalencies } onUIStateChange={ this.handleComponentUIStateChange }
            edit={ this.startEditing } remove={ this.handleComponentRemove }
          />
          <Message error>
            { this.state.errors.courses }
          </Message>
        </Segment.Group>
      </Form>
    );
  }

  renderAfterView = () => {
    if (this.state.editing.equivalency) {
      return (
        <EquivalencyEditDialog open={ !!this.state.editing.equivalency } equivalency={ this.state.editing.equivalency }
          courselist={ this.state.courselist } courses={ this.state.courses }
          onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
        />
      );
    }

    return null;
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    trainer: storeState.models.fireDepartmentTrainer,
    equivalencies: storeState.models.fireDepartmentTrainerEquivalencies,
    equivalency: storeState.models.fireDepartmentTrainerEquivalency,
    ui: storeState.ui.fireDepartmentTrainerEquivalencies
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentTrainerEquivalency);
