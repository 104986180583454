import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Button } from 'semantic-ui-react';

import { getFireDepartmentsFinancialSummary } from '../../api/administratorAPI';
import { getFireDepartments } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_REPORT_FDFINANCIAL_UI } from '../../js/actionTypes';
import { PATH_ADMIN, PATH_REPORTS, PATH_FIRE_DEPARTMENT, PATH_FINANCIAL_SUMMARY, PATH_TIMESTAMP } from '../../js/paths';

import { sort, downloadArrayBuffer, xlsxAutoWidth } from '../../utils/utils';

import ViewLoader from '../../components/ViewLoader';
import SortTable from '../../components/SortTable';
import EditButton from '../../components/EditButton';
import SearchableFilter from '../../components/SearchableFilter';
import CurrencyText from '../../components/CurrencyText';

import ListView from '../ListView';

import * as ExcelJS from 'exceljs/dist/exceljs';

class AdminReportFDFinancial extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: 'fdName',
        sortDescending: false,
      }
    };

    if (this.props.match.params[PATH_TIMESTAMP]) this.state.selectedYear = parseInt(this.props.match.params[PATH_TIMESTAMP]);
    else this.state.selectedYear = new Date().getFullYear();

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    if (this.filterObjYrs !== undefined) {
      this.filterObjYrs.setYears(()=> {
        this.fetch();
        this.isClean();
      });
    }
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    if (!this.props.fireDepartments || this.props.fireDepartments.length === 0) {
      await getFireDepartments();
    }
    await getFireDepartmentsFinancialSummary(this.filterObjYrs.getSelectedText());
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REPORT_FDFINANCIAL_UI });
    });
  }

  //Filter support
  handleFilterChange(filter,value) { this.fetch(); }

  handleExportXLSX() {
    this._exportToXLSX();
  }

  //UI
  safeNum(value) { return (value !== undefined && !isNaN(parseFloat(value)) ? parseFloat(value) : 0); }
  sumTotal(summary) {
    let total = 0;
    total += this.safeNum(summary.initialequippaid);
    total += this.safeNum(summary.trainingreimbursementaftertax);
    total += this.safeNum(summary.updatetrainingpaidaftertax);
    total += this.safeNum(summary.totalspecialpaymentsaftertax);
    total += this.safeNum(summary.totalfiredepartmentstipend);
    total += this.safeNum(summary.ppepaid);
    return total;
  }

  checkFIsSurveyTotals(summaries) {
    summaries = summaries.map((fi) => {
      if(!fi.totalSiteSurveys) {
        fi.totalSiteSurveys = 0;
        for (const key in fi.numcranesurveys) {
          fi.totalSiteSurveys += fi.numcranesurveys[key];
        }
        for (const key in fi.numcranesurveysMutualAid) {
          fi.totalSiteSurveys += fi.numcranesurveysMutualAid[key];
        }
      }
      return fi;
    });
    return summaries;
  }

  render() {
    return (
      <div className='fire-department-report-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={6} floated='left'>
            <Header size='medium'>Reports - Fire Departments Financial Summary</Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingBottom: 0}} width={8} floated='right'>
            <Button primary disabled={!this.props.fireDepartmentsFinancialSummaries || this.props.fireDepartmentsFinancialSummaries.length === 0} onClick={this.handleExportXLSX.bind(this)}>Export</Button>
            <SearchableFilter ref={(r)=>this.filterObjYrs=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={this.state.selectedYear}/>
          </Grid.Column>
          <Grid.Column width={15}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              let summaries = sort(this.props.fireDepartmentsFinancialSummaries, this.state.ui.sortField);
              let selectedYear = (this.filterObjYrs !== undefined ? this.filterObjYrs.getSelectedText() : '');

              console.log("summaries:", summaries)

              if (summaries && !summaries.length) {
                return <Message info size='large'>
                  No Financial summaries for the year.
                </Message>;
              }

              summaries = this.checkFIsSurveyTotals(summaries);

              const center = { textAlign: 'center' };

              if (this.state.ui.sortDescending) { summaries.reverse(); }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'fdName',                 title: 'Fire Department',                width: '3' },
                { field: 'chief_name',             title: 'Initial Equipment Purchase',     width: '3', style: {...center}},
                { field: 'acknowledgementdate',    title: 'Training Reimbursements',        width: '3', style: {...center}},
                { field: 'numtrainers',            title: 'Update Training',                width: '3', style: {...center}},
                { field: 'maxallowedtrainers',     title: 'Special Payments',               width: '3', style: {...center}},
                { field: 'maxallowedtrainers2',    title: 'Stipend',                        width: '2', style: {...center}},
                { field: 'maxallowedtrainers3',    title: 'Site Surveys',                   width: '2', style: {...center}},
                { field: 'maxallowedtrainers4',    title: 'Total',                          width: '3', style: {...center}},
                { field: 'buttons', noSort: true,  title: '',                               width: '1' },
              ]}>
                { summaries.map((fd, index) => (
                  <SortTable.Row key={ fd.id + "" + index }>
                    <SortTable.Cell>{ fd.fdName }</SortTable.Cell>
                    <SortTable.Cell {...center}><CurrencyText value={ this.safeNum(fd.initialequippaid) }/></SortTable.Cell>
                    <SortTable.Cell {...center}><CurrencyText value={ this.safeNum(fd.trainingreimbursementaftertax) }/></SortTable.Cell>
                    <SortTable.Cell {...center}><CurrencyText value={ this.safeNum(fd.updatetrainingpaidaftertax) }/></SortTable.Cell>
                    <SortTable.Cell {...center}><CurrencyText value={ this.safeNum(fd.totalspecialpaymentsaftertax) }/></SortTable.Cell>
                    <SortTable.Cell {...center}><CurrencyText value={ this.safeNum(fd.totalfiredepartmentstipend) }/></SortTable.Cell>
                    <SortTable.Cell {...center}>{ this.safeNum(fd.totalSiteSurveys) }</SortTable.Cell>
                    <SortTable.Cell {...center}><CurrencyText value={ this.sumTotal(fd) }/></SortTable.Cell>
                    <SortTable.Cell {...center}>
                      <EditButton view={ true } pathname={ `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }/${ PATH_FINANCIAL_SUMMARY }/${ fd.id }/${ selectedYear }` }/>
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  /* Private XLSX */
  async _exportToXLSX() {
    let summaries = sort(this.props.fireDepartmentsFinancialSummaries, this.state.ui.sortField);
    if (!summaries || summaries.length <= 0) return;
    summaries = this.checkFIsSurveyTotals(summaries);
    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');
    // Generate XLSX header
    ws.addRow(['Fire Department', 'Initial Equipment Purchase', 'Training Reimbursements', 'Update Training', 
               'Special Payments', 'Stipend', 'Total', 'Site Surveys']);
    // Generate XLSX rows
    summaries.forEach(({ fdName, initialequippaid, trainingreimbursementaftertax, updatetrainingpaidaftertax, totalspecialpaymentsaftertax, totalfiredepartmentstipend, totalSiteSurveys }) => {
      let total = 0;
      total += this.safeNum(initialequippaid);
      total += this.safeNum(trainingreimbursementaftertax);
      total += this.safeNum(updatetrainingpaidaftertax);
      total += this.safeNum(totalspecialpaymentsaftertax);
      total += this.safeNum(totalfiredepartmentstipend);
      ws.addRow([
        fdName, this.safeNum(initialequippaid), this.safeNum(trainingreimbursementaftertax), this.safeNum(updatetrainingpaidaftertax), this.safeNum(totalspecialpaymentsaftertax), this.safeNum(totalfiredepartmentstipend), total, this.safeNum(totalSiteSurveys)
      ]);
    });
    // Auto width
    xlsxAutoWidth(ws, 75);
    // Generate buffer and save it
    const buffer = await wb.xlsx.writeBuffer();
    downloadArrayBuffer(buffer, `financial-report`, 'xlsx');
  }
}

function mapStoreStateToProps(storeState) {
  return {
    fireDepartmentsFinancialSummaries: storeState.models.fireDepartmentsFinancialSummaries
  };
}

export default connect(mapStoreStateToProps)(AdminReportFDFinancial);
