import React from 'react';
import { connect } from 'react-redux';

import { Grid, Button, Header, Message } from 'semantic-ui-react';

import * as ExcelJS from 'exceljs/dist/exceljs';

import { getWorkSiteRescues } from '../../api/administratorAPI';
import { getFireDepartments } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_REPORT_FDRESCUES_UI } from '../../js/actionTypes';

import { sort, downloadArrayBuffer, xlsxAutoWidth } from '../../utils/utils';

import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';
import SearchableFilter from '../../components/SearchableFilter';

import ListView from '../ListView';

class AdminReportRescues extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      year: new Date().getFullYear(),
      areFdsSet: false,

      ui : {
        sortField: 'sortDate',
        sortDescending: false,
      }
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    if (this.filterObjYrs !== undefined) this.filterObjYrs.setYears();
    if (this.filterObjFds !== undefined) {
      this.props.fireDepartments.sort((a,b)=>a.name.localeCompare(b.name));
      this.filterObjFds.setFDs(this.props.fireDepartments, true, ()=>{
        this.fetch();
        this.isClean();
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.areFdsSet && this.filterObjFds && nextProps.fireDepartments && nextProps.fireDepartments.length > 0) {
      const { filterObjFds, filterObjYrs } = this;
      this.filterObjFds.setFDs(nextProps.fireDepartments, true, () => {
        this.fetch(filterObjFds, filterObjYrs);
        this.isClean();
      });

      this.setState({ areFdsSet: true });
    }
  }

  fetch = async (objFds, objYrs) => {
    this.setState({ loading: 'Loading' });
    //get filters
    const filterObjFds = objFds ? objFds : this.filterObjFds;
    const filterObjYrs = objYrs ? objYrs : this.filterObjYrs;
    //
    if (!this.props.fireDepartments || this.props.fireDepartments.length === 0) {
      await getFireDepartments();
    }
    let selectedFDID = this.props.fireDepartments[filterObjFds.getSelectedIndex()-1];
    if (selectedFDID !== undefined) selectedFDID = selectedFDID.id;
    //
    await getWorkSiteRescues(selectedFDID, filterObjYrs.getSelectedText());
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REPORT_FDRESCUES_UI, workSiteRescues: this.state.ui });
    });
  }

  //Filter support
  handleFilterChange(filter,value) {
    if (filter === this.filterObjFds) { this.fetch(); }
    else { this.forceUpdate(); }
  }

  handleChangeYear(filter, value) {
    const year = this.filterObjYrs.getSelectedText();
    this.setState({ year });
    this.fetch();
  }

  handleExportXLSX() {
    this._exportToXLSX();
  }

  render() {
    const rescues = this.getFilteredRescues();
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={6} floated='left'>
            <Header size='medium'>Reports - Rescues per Fire Department</Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingBottom: 0}} width={8} floated='right'>
            <Button primary disabled={!rescues || rescues.length === 0} onClick={this.handleExportXLSX.bind(this)}>Export</Button>
            <SearchableFilter ref={(r)=>this.filterObjFds=r} handleFilterChange={this.handleFilterChange.bind(this)}/>
            <SearchableFilter ref={(r)=>this.filterObjYrs=r} handleFilterChange={this.handleChangeYear.bind(this)} defaultValue={new Date().getFullYear()}/>
          </Grid.Column>
          <Grid.Column width={15}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (this.props.workSiteRescues && !this.props.workSiteRescues.length) {
                return <Message info size='large'>
                  You don&apos;t have any Rescues.
                </Message>;
              }

              const center = { textAlign: 'center' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',                 title: 'Rescue Date',           width: '3' },
                { field: 'fdName',                   title: 'Fire Department',       width: '5' },
                { field: 'comments',                 title: 'Description',           width: '10' },
                { field: 'ccname',                   title: 'Company Name',          width: '3', style: { ...center } },
              ]}>
                { rescues.map(({ key, displayDate, fdName, comments, ccname}) => (
                  <SortTable.Row key={ key }>
                    <SortTable.Cell>{ displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ fdName }</SortTable.Cell>
                    <SortTable.Cell>{ comments }</SortTable.Cell>
                    <SortTable.Cell {...center}>{ ccname }</SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  /* Private helper */
  getFilteredRescues() {
    let rescues = sort(this.props.workSiteRescues, this.state.ui.sortField);
    for (const rescue of rescues) {
      const fd = this.props.fireDepartments.find(fd => fd.id === rescue.id);
      rescue.fdName = fd.name;
    }
    if (this.state.ui.sortDescending) { rescues.reverse(); }
    return rescues;
  }

  /* Private XLSX */
  async _exportToXLSX() {
    const rescues = this.getFilteredRescues();
    if (!rescues || rescues.length <= 0) return;
    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');
    // Generate XLSX header
    ws.addRow(['Fire Department', 'Rescue Date', 'Description', 'Company Name']);
    // Generate XLSX rows
    rescues.forEach(({ fdName, displayDate, comments, ccname }) => {
      ws.addRow([
        fdName, displayDate, comments, ccname
      ]);
    });
    // Auto width
    xlsxAutoWidth(ws, 75);
    // Generate buffer and save it
    const buffer = await wb.xlsx.writeBuffer();
    downloadArrayBuffer(buffer, `work-rescues-report`, 'xlsx');
  }
}

function mapStoreStateToProps(storeState) {
  return {
    fireDepartments: storeState.lookups.fireDepartments,
    workSiteRescues: storeState.models.workSiteRescues,
  };
}

export default connect(mapStoreStateToProps)(AdminReportRescues);
