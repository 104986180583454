import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form } from 'semantic-ui-react';

import { getUser, updateUser } from '../../api/userAPI';

import { CC_CATEGORIES } from '../../js/lists';
import { PATH_USERS, PATH_ID, PATH_ADMIN } from '../../js/paths';

import MultiSelectInput from '../../components/MultiSelectInput';

import EditView from '../EditView';

const INITIAL_STATE = {
  contact_email: '',
  displayDate: '',
  entity_name: '',
  cus: [],
  category: '',
  contact_name: '',
  address_number: '',
  address_street: '',
  address_compliment: '',
  address_city: '',
  address_province: '',
  address_postalcode: '',
  main_telephone_number: '',
  main_telephone_ext: '',
  second_telephone_number: '',
  second_telephone_ext: '',

  // UI
  title: 'Edit Construction Company'
};

class AdminCCUser extends EditView {
  constructor(props) {
    super(props);
    
    this.state = { ...this.state, ...INITIAL_STATE };
    this.state.exitPage = `/${ PATH_ADMIN }/${ PATH_USERS }`;

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
  }

  fetch = async () => {
    await getUser(this.props.match.params[PATH_ID]);
  }

  onLoad = () => {
    this.setState({
      ...this.props.cc,
    });
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.entity_name) {
      errors.error = true;
      errors.entity_name = 'Please enter a name.';
    }
    if (!this.state.cus || (Array.isArray(this.state.cus) && this.state.cus.length === 0)) {
      errors.error = true;
      errors.cus = 'Please enter at least one CU.';
    }
    if (!this.state.category) {
      errors.error = true;
      errors.category = 'Please enter a category.';
    }
    if (!this.state.contact_name) {
      errors.error = true;
      errors.contact_name = 'Please enter a contact name.';
    }
    if (!this.state.address_number) {
      errors.error = true;
      errors.address_number = 'Please enter a number.';
    }
    if (!this.state.address_street) {
      errors.error = true;
      errors.address_street = 'Please enter a street.';
    }
    if (!this.state.address_city) {
      errors.error = true;
      errors.address_city = 'Please enter a city.';
    }
    if (!this.state.address_province) {
      errors.error = true;
      errors.address_province = 'Please enter a province.';
    }
    if (!this.state.address_postalcode) {
      errors.error = true;
      errors.address_postalcode = 'Please enter a postal code.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  update = async (data) => {
    //Make request
    this.setState({ loading: 'Updating' });
    await updateUser(data);
    if (this.props.cc.id) {
      this.exit();
    }
    this.setState({ loading: false });
  }

  handleClose(event) {
    this.cancel(event);
  }

  handleCUsChange = (event, { name, value }) => {
    const stateUpdate = { [name]: value.map(v => v) };
    this.setState(stateUpdate, () => { this.isDirty(); this.validate(); });
  }

  handleCategoryChange = function(event, { name, value }) {
    const selectedCategory = Object.keys(CC_CATEGORIES).find(k => k === value);
    this.setState({ [name]: selectedCategory }, () => { this.onChange(); });
  }

  componentDidMount() {
    super.componentDidMount();
  }

  renderView = () => {
    const categories = Object.keys(CC_CATEGORIES).map(k => { return { key: k, text: CC_CATEGORIES[k], value: k } });
    if (!this.state.category) this.setState({ category: Object.keys(CC_CATEGORIES)[1] });

    return (
      <Form>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
          <Form.Button floated='right' className='form-top-button' positive type='button' onClick={this.handleSubmit}>Update</Form.Button>
          <Form.Button floated='right' className='form-top-button' negative type='button' onClick={this.handleClose}>Cancel</Form.Button>
        </Header>
        <Segment.Group id="printable" size='large' className='view-segment-group'>
          <Segment>
            <Form.Group>
              <Form.Input readOnly label='Email' value={ this.state.contact_email } name='contact_email' width={ 8 }/>
              <Form.Input readOnly label='Registration Date' value={ this.state.displayDate } name='displayDate' width={ 8 }/>
            </Form.Group>
            <Form.Group>
              <Form.Input required
                label='Name' value={ this.state.entity_name } name='entity_name' width={ 8 }
                onChange={ this.handleChange } error={ !!this.state.errors.entity_name }
              />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group>
              <Form.Field required label='CUs' value={ this.state.cus } name='cus' width={ 16 }
                          error={ !!this.state.errors.cus }
                          control={ MultiSelectInput } onChange={ this.handleCUsChange }
                          optionsdata={ this.state.cus.map(v => { return { key: v, text: v, value: v } }) }
              />
            </Form.Group>
            <Form.Group>
              <Form.Dropdown search selection required disabled={ !!this.viewAsAdmin }
                label='Category' name='category' value={ this.state.category } options={ categories } width={ 8 }
                onChange={ this.handleCategoryChange.bind(this) }
              />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group>
              <Form.Input required
                label='Contact Name(s)' value={ this.state.contact_name } name='contact_name' width={ 8 }
                onChange={ this.handleChange } error={ !!this.state.errors.contact_name }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input required
                label='Address Number' value={ this.state.address_number } name='address_number' width={ 4 }
                onChange={ this.handleChange } error={ !!this.state.errors.address_number }
              />
              <Form.Input required
                label='Street' value={ this.state.address_street } name='address_street' width={ 8 }
                onChange={ this.handleChange } error={ !!this.state.errors.address_street }
              />
              <Form.Input
                label='Compliment' value={ this.state.address_compliment } name='address_compliment' width={ 4 }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input required
                label='City' value={ this.state.address_city } name='address_city' width={ 8 }
                onChange={ this.handleChange } error={ !!this.state.errors.address_city }
              />
              <Form.Input required
                label='Province' value={ this.state.address_province } name='address_province' width={ 4 }
                onChange={ this.handleChange } error={ !!this.state.errors.address_province }
              />
              <Form.Input required
                label='Postal Code' value={ this.state.address_postalcode } name='address_postalcode' width={ 4 }
                onChange={ this.handleChange } error={ !!this.state.errors.address_postalcode }
              />
            </Form.Group>
          </Segment>
          <Segment>
            <Form.Group>
              <Form.Input
                label='Main Phone' value={ this.state.main_telephone_number } name='main_telephone_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input
                label='Ext' value={ this.state.main_telephone_ext } name='main_telephone_ext' width={ 2 }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label='Secondary Phone' value={ this.state.second_telephone_number } name='second_telephone_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input
                label='Ext' value={ this.state.second_telephone_ext } name='second_telephone_ext' width={ 2 }
                onChange={ this.handleChange }
              />
            </Form.Group>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    cc: storeState.models.user
  };
}

export default connect(mapStoreStateToProps)(AdminCCUser);
