import React from 'react';

import { Segment, Header, Message } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import AddButton from './AddButton';
import CurrencyInput from './CurrencyInput';
import CurrencyText from './CurrencyText';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import SortTable from './SortTable';
import TaxCalculator from './TaxCalculator';

import { ACCOMMODATION_TYPES } from '../js/lists';

import { sort } from '../utils/utils';

class AccommodationSegment extends React.Component {
  updateUIState = (uiState) => {
    this.props.onUIStateChange({ accommodation: uiState });
  }

  render = () => {
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Accommodation Expenses</Header.Content>
          <AddButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'accommodation', {}) }/>
        </Header>

        {(() => {
          if (this.props.accommodation.length === 0) {
            return <Message info compact size='tiny'>No accommodation expenses</Message>;
          }

          let accommodations = this.props.accommodation.map(a => {
            let accommodation = { ...a };
            accommodation.displayType = ACCOMMODATION_TYPES[accommodation.type];
            return accommodation;
          });

          accommodations = sort(accommodations, this.props.ui.sortField);
          if (this.props.ui.sortDescending) {
            accommodations.reverse();
          }

          const right = { textAlign: 'right' };

          return <SortTable collapsing sortField={ this.props.ui.sortField } sortDescending={ this.props.ui.sortDescending }
            onSort={ this.updateUIState } size='small'
            headers={[
              { field: 'displayType',           title: 'Type',   width: '3',              },
              { field: 'numberofnights',        title: 'Nights', width: '4', style: right },
              { field: 'rate',                  title: 'Rate',   width: '3', style: right },
              { field: 'amount',                title: 'Amount', width: '4', style: right },
              { field: 'buttons', noSort: true, title: '',       width: '2'               },
            ]}
            footers={[
              [ null, null, <div style={ right }><strong>Total Before Tax:</strong></div>, <CurrencyText value={ this.props.totalbeforetax } style={ right }/>, null ],
              [
                <div style={ right }><TaxCalculator disabled={ this.props.disabled } type='gst' name='gst' amount={ this.props.totalbeforetax } onCalculate={ this.props.onTaxCalculate }/></div>,
                <CurrencyInput readOnly={ this.props.disabled } value={ this.props.gst } name='gst' onChange={ this.props.onCurrencyStateChange }/>,
                <div style={ right }>Hotel Tax</div>,
                <CurrencyInput readOnly={ this.props.disabled } value={ this.props.hoteltax } name='hoteltax' onChange={ this.props.onCurrencyStateChange }/>,
                null
              ],
              [ null, null, <div style={ right }><strong>Total After Tax:</strong></div>, <CurrencyText value={ this.props.totalaftertax } style={ right }/>, null ],
            ]}
          >
            { accommodations.map((accommodation, index) => (
              <SortTable.Row key={ index } id={ `accommodation_${ accommodation.timestamp }`}>
                <SortTable.Cell>{ accommodation.displayType }</SortTable.Cell>
                <SortTable.Cell textAlign='right'>{ accommodation.numberofnights }</SortTable.Cell>
                <SortTable.Cell textAlign='right'><CurrencyText value={ accommodation.rate }/></SortTable.Cell>
                <SortTable.Cell textAlign='right'><CurrencyText value={ accommodation.amount }/></SortTable.Cell>
                <SortTable.Cell textAlign='right'>
                  <EditButton view={ this.props.disabled } onClick={ this.props.edit.bind(this, 'accommodation', accommodation) }/>
                  <DeleteButton hide={ this.props.disabled } content='Delete the accommodation expense?' onConfirm={ this.props.remove.bind(this, 'accommodation', accommodation) }/>
                </SortTable.Cell>
              </SortTable.Row>
            ))}
          </SortTable>;
        })()}
      </Segment>
    );
  }
};

AccommodationSegment.propTypes = {
  accommodation: PropTypes.array.isRequired,
  totalbeforetax: PropTypes.number.isRequired,
  gst: PropTypes.number,
  hoteltax: PropTypes.number,
  totalaftertax: PropTypes.number.isRequired,
  ui: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onUIStateChange: PropTypes.func.isRequired,
  onCurrencyStateChange: PropTypes.func.isRequired,
  onTaxCalculate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AccommodationSegment;
