import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon } from 'semantic-ui-react';

import { changeAdminSpecialPayment, getAdminSpecialPayments } from '../../api/administratorAPI';

import { UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS_UI } from '../../js/actionTypes';
import { PATH_SPECIAL_PAYMENTS, PATH_ADMIN, PATH_REIMBURSEMENTS } from '../../js/paths';

import { sort } from '../../utils/utils';

import ActionMultiButton from '../../components/ActionMultiButton';
import CurrencyText from '../../components/CurrencyText';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import RejectReasonEditDialog from '../../dialogs/RejectReasonEditDialog';

import ListView from '../ListView';

class AdminSpecialPayments extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortSubmissionDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      title: 'Special Payments',

      editing: null
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  fetch = async () => {
    await getAdminSpecialPayments('Pending|Voting');
  }

  closeDialog = () => { this.setState({ editing: null }); }

  saveRejectReason = async (rejectreason) => {
    let data = { ...this.state.editing };
    data.rejectreason = rejectreason;
    data.status = 'Rejected';
    this.closeDialog();
    await this.update(data);
  }

  getRejectReason = (data) => {
    this.setState({ editing: data });
  }

  changeStatus = async (specialPayment, status) => {
    if (status === 'Rejected') {
      this.getRejectReason(specialPayment);
    } else {
      specialPayment.status = status;
      await this.update(specialPayment);
    }
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await changeAdminSpecialPayment(data);
    await this.fetch();
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS_UI, fireDepartmentSpecialPayments: this.state.ui });
    });
  }

  render() {
    return (
      <div className='admin-special-payments'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>{ this.state.title }</Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (this.props.specialPayments && !this.props.specialPayments.length) {
                return <Message info size='large'>
                  You don&apos;t have any special payment requests.
                </Message>;
              }

              let specialPayments = sort(this.props.specialPayments, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                specialPayments.reverse();
              }

              const right = { textAlign: 'right' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortSubmissionDate',    title: 'Submission Date', width: '2'               },
                { field: 'fdName',                title: 'Fire Department', width: '4'               },
                { field: 'description',           title: 'Description',     width: '4'               },
                { field: 'status',                title: 'Status',          width: '2'               },
                { field: 'amountaftertax',        title: 'Total Amount',    width: '2', style: right },
                { field: 'buttons', noSort: true, title: '',                width: '2'               },
              ]}>
                { specialPayments.map((specialPayment) => (
                  <SortTable.Row key={ specialPayment.key }>
                    <SortTable.Cell>{ specialPayment.displaySubmissionDate }</SortTable.Cell>
                    <SortTable.Cell>{ specialPayment.fdName }</SortTable.Cell>
                    <SortTable.Cell>{ specialPayment.description }</SortTable.Cell>
                    <SortTable.Cell>{ specialPayment.status }</SortTable.Cell>
                    <SortTable.Cell { ...right }>{ <CurrencyText value={ specialPayment.amountaftertax }/> }</SortTable.Cell>
                    <SortTable.Cell { ...right }>
                      { (specialPayment.status === 'Pending' || specialPayment.status === 'Voting') &&
                        <ActionMultiButton header='Change Status'
                          selectOptions={ specialPayment.status === 'Pending' ? [ 'Voting', 'Approved', 'Rejected' ] : [ 'Approved', 'Rejected' ] }
                          onConfirm={ status => this.changeStatus(specialPayment, status) }
                        />
                      }
                      { specialPayment.status === 'Approved' &&
                        <Icon name='clipboard check' size='large' color='green' />
                      }
                      { specialPayment.status === 'Rejected' &&
                        <Icon name='clipboard' size='large' color='red' />
                      }
                      <EditButton view={ specialPayment.status === 'Approved' || specialPayment.status === 'Rejected' }
                        pathname={ `/${ PATH_ADMIN }/${ PATH_REIMBURSEMENTS }/${ PATH_SPECIAL_PAYMENTS }/${ specialPayment.id }/${ specialPayment.timestamp }` }
                      />
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.state.editing &&
              <RejectReasonEditDialog open={ !!this.state.editing } onSave={ this.saveRejectReason } onClose={ this.closeDialog } />
            }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    specialPayments: storeState.models.fireDepartmentSpecialPayments,
    ui: storeState.ui.fireDepartmentSpecialPayments,
  };
}

export default connect(mapStoreStateToProps)(AdminSpecialPayments);
