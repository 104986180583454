import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message } from 'semantic-ui-react';

import { UPDATE_COST_CENTERS_UI } from '../../js/actionTypes';
import { getCostCenters } from '../../api/administratorAPI';
import { PATH_ADMIN, PATH_COST_CENTERS, PATH_NEW } from '../../js/paths';

import { sort } from '../../utils/utils';

import AddButton from '../../components/AddButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

class AdminCostCenters extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'type',
        sortDescending: this.props.ui.sortDescending === true,
      },
    };

    this.handleNew = this.handleNew.bind(this);
    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    // TODO: Fix this later
    await getCostCenters();
    this.setState({ loading: false });
  }

  // <DeleteButton content='Delete the Cost Center?' onConfirm={ this.handleRemove.bind(this, item.id, item.timestamp) }/>
  // remove = async (id, timestamp) => {
  //   this.setState({ loading: 'Removing' });
  //   await deleteCostCenter(id, timestamp);
  //   this.setState({ loading: false });
  // }
  // handleRemove = async (id, timestamp) => {
  //   await this.remove(id, timestamp);
  //   await this.fetch();
  // }

  handleNew() {
    this.props.history.push(`/${ PATH_ADMIN }/${ PATH_COST_CENTERS }/${ PATH_NEW }`);
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_COST_CENTERS_UI, costCenters: this.state.ui });
    });
  }
  render() {
    return (
      <div className='fire-department-reimbursement-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>
              <Header.Content>Cost Centers</Header.Content>
              <AddButton onClick={ this.handleNew.bind(this) }/>
            </Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              console.log("costCenters:", this.props.costCenters)

              if (!this.props.costCenters.length) {
                return <Message info size='large'>
                  You don&apos;t have any Cost Centers.
                </Message>;
              }

              let costCenters = sort(this.props.costCenters, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                costCenters.reverse();
              }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'type',              title: 'Type',        width: '3' },
                { field: 'glaccount',        title: 'GL Account',  width: '4' },
                { field: 'description',       title: 'Description', width: '3' },
                { field: 'buttons', noSort: true, title: '',        width: '2' },
              ]}>
                { costCenters.map((item) => (
                  <SortTable.Row key={ item.key }>
                    <SortTable.Cell>{ item.type }</SortTable.Cell>
                    <SortTable.Cell>{ item.glaccount }</SortTable.Cell>
                    <SortTable.Cell>{ item.description }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      <EditButton pathname={ `/${ PATH_ADMIN }/${ PATH_COST_CENTERS }/${ item.id }` }/>

                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    costCenters: storeState.models.costCenters,
    ui: storeState.ui.costCenters,
  };
}

export default connect(mapStoreStateToProps)(AdminCostCenters);
