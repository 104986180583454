import React from "react";
import { connect } from 'react-redux';

import { Grid, Header, Message } from 'semantic-ui-react';

import { getFireDepartmentTrainers } from '../../api/fireDepartmentAPI';

import { UPDATE_FIRE_DEPARTMENT_TRAINERS_EQUIVALENCY_UI } from '../../js/actionTypes';
import { PATH_FIRE_DEPARTMENT, PATH_TRAINER_EQUIVALENCY } from '../../js/paths';

import { sort } from '../../utils/utils';

import AddButton from '../../components/AddButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

class FireDepartmentTrainersEquivalency extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      title: 'Prior Training'
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartmentTrainers(this.props.currentUser.orgId);
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_TRAINERS_EQUIVALENCY_UI, fireDepartmentTrainersEquivalency: this.state.ui });
    });
  }

  render() {
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>
              <Grid>
                <Grid.Column>
                  <Header.Content>{ this.state.title }</Header.Content>
                </Grid.Column>
              </Grid>
            </Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.trainerList.length) {
                return <Message info compact size='large'>
                  You don&apos;t have any trainers.
                </Message>;
              }

              let trainers = sort(this.props.trainerList, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                trainers.reverse();
              }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                  { field: 'trainername',              title: 'Name',               width: '3' },
                  { field: 'displayType',              title: 'Status',             width: '2' },
                  { field: 'sortCertDate',             title: 'Certification Date', width: '2' },
                  { field: 'sortDate',                 title: 'Registration Date',  width: '2' },
                  { field: 'courselist', noSort: true, title: 'Courses',            width: '6' },
                  { field: 'buttons', noSort: true,    title: '',                   width: '1' },
              ]}>
                { trainers.map((trainer) => (
                  <SortTable.Row key={ trainer.key }>
                    <SortTable.Cell>{ trainer.trainername }</SortTable.Cell>
                    <SortTable.Cell>{ trainer.displayType }</SortTable.Cell>
                    <SortTable.Cell>{ trainer.displayCertDate }</SortTable.Cell>
                    <SortTable.Cell>{ trainer.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ trainer.courselist }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      <AddButton pathname={ `/${ PATH_FIRE_DEPARTMENT }/${ PATH_TRAINER_EQUIVALENCY }/${ trainer.trainerId }` }/>
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    trainerList: storeState.models.fireDepartmentTrainers,
    ui: storeState.ui.fireDepartmentTrainers,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentTrainersEquivalency);
