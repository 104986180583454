import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message } from 'semantic-ui-react';

import { getTrainingAgencyReimbursements, deleteTrainingAgencyReimbursement } from '../../api/trainingAgencyAPI';

import { UPDATE_TRAINING_AGENCY_REIMBURSEMENTS_UI } from '../../js/actionTypes';
import { PATH_TRAINING_AGENCY, PATH_REIMBURSEMENTS, PATH_NEW } from '../../js/paths';

import { sort } from '../../utils/utils';

import AddButton from '../../components/AddButton';
import CurrencyText from '../../components/CurrencyText';
import DeleteButton from '../../components/DeleteButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

class TrainingAgencyTowerCraneReimbursements extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      title: 'Tower Crane Operator Reimbursements'
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  fetch = async () => {
    await getTrainingAgencyReimbursements(this.props.currentUser.orgId, 'Pending');
  }

  remove = async (id, timestamp) => {
    this.setState({ loading: 'Removing' });
    await deleteTrainingAgencyReimbursement(id, timestamp);
    this.setState({ loading: false });
  }

  handleRemove = async (id, timestamp) => {
    await this.remove(id, timestamp);
    await this.fetch();
  }

  handleNew() {
    this.props.history.push(`/${ PATH_TRAINING_AGENCY }/${ PATH_REIMBURSEMENTS }/${ PATH_NEW }`);
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_TRAINING_AGENCY_REIMBURSEMENTS_UI, trainingAgencyReimbursements: this.state.ui });
    });
  }

  render() {
    return (
      <div className='training-agency-reimbursement-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>
              <Header.Content>{ this.state.title }</Header.Content>
              <AddButton onClick={ this.handleNew.bind(this) }/>
            </Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.reimbursements.length) {
                return <Message info compact size='large'>
                  You don&apos;t have any pending reimbursements.
                </Message>;
              }

              let reimbursements = sort(this.props.reimbursements, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                reimbursements.reverse();
              }

              const right = { textAlign: 'right' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',              title: 'Submission Date', width: '3'               },
                { field: 'operatorName',          title: 'Operator',        width: '4'               },
                { field: 'status',                title: 'Status',          width: '3'               },
                { field: 'numdays',               title: 'Days',            width: '2', style: right },
                { field: 'totalamount',           title: 'Amount',          width: '2', style: right },
                { field: 'buttons', noSort: true, title: '',                width: '2'               },
              ]}>
                { reimbursements.map((reimbursement) => (
                  <SortTable.Row key={ reimbursement.key }>
                    <SortTable.Cell>{ reimbursement.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.operatorname }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.status }</SortTable.Cell>
                    <SortTable.Cell { ...right }>{ reimbursement.numdays }</SortTable.Cell>
                    <SortTable.Cell { ...right }><CurrencyText value={ reimbursement.totalamount }/></SortTable.Cell>
                    <SortTable.Cell { ...right }>
                      <EditButton view={ reimbursement.status !== 'Pending' } pathname={ `/${ PATH_TRAINING_AGENCY }/${ PATH_REIMBURSEMENTS }/${ reimbursement.timestamp }` }/>
                      <DeleteButton content='Delete the training expense request?' hide={ true || reimbursement.status !== 'Pending' }
                        onConfirm={ this.handleRemove.bind(this, reimbursement.id, reimbursement.timestamp) }
                      />
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    reimbursements: storeState.models.trainingAgencyReimbursements,
    ui: storeState.ui.trainingAgencyReimbursements,
  };
}

export default connect(mapStoreStateToProps)(TrainingAgencyTowerCraneReimbursements);
