import React from 'react';

import { Segment, Header, Message } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import AddButton from './AddButton';
import CurrencyText from './CurrencyText';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import SortTable from './SortTable';

import { sort } from '../utils/utils';

class BackfillSegment extends React.Component {
  updateUIState = (uiState) => {
    this.props.onUIStateChange({ backfill: uiState });
  }

  render = () => {
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Backfill Expenses</Header.Content>
          <AddButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'backfill', {}) }/>
        </Header>

        {(() => {
          if (this.props.backfill.length === 0) {
            return <Message info compact size='tiny'>No backfill expenses</Message>;
          }

          let backfills = this.props.backfill.map(b => {
            let backfill = { ...b };
            return backfill;
          });

          backfills = sort(backfills, this.props.ui.sortField);
          if (this.props.ui.sortDescending) {
            backfills.reverse();
          }

          const right = { textAlign: 'right' };

          return <SortTable collapsing sortField={ this.props.ui.sortField } sortDescending={ this.props.ui.sortDescending }
            onSort={ this.updateUIState } size='small'
            headers={[
              { field: 'name',                  title: 'Name',   width: '5'               },
              { field: 'hoursworked',           title: 'Hours',  width: '3', style: right },
              { field: 'rate',                  title: 'Rate',   width: '3', style: right },
              { field: 'amount',                title: 'Amount', width: '3', style: right },
              { field: 'buttons', noSort: true, title: '',       width: '2'               },
            ]}
            footers={[
              [ null, null, <div style={ right }><strong>Total:</strong></div>, <CurrencyText value={ this.props.totbackfillamount } style={ right }/>, null ]
            ]}
          >
            { backfills.map((backfill, index) => (
              <SortTable.Row key={ index } id={ `backfill_${ backfill.timestamp }`}>
                <SortTable.Cell>{ backfill.name }</SortTable.Cell>
                <SortTable.Cell textAlign='right'>{ backfill.hoursworked }</SortTable.Cell>
                <SortTable.Cell textAlign='right'><CurrencyText value={ backfill.rate }/></SortTable.Cell>
                <SortTable.Cell textAlign='right'><CurrencyText value={ backfill.amount }/></SortTable.Cell>
                <SortTable.Cell textAlign='right'>
                  <EditButton view={ this.props.disabled } onClick={ this.props.edit.bind(this, 'backfill', backfill) }/>
                  <DeleteButton hide={ this.props.disabled } content='Delete the backfill expense?' onConfirm={ this.props.remove.bind(this, 'backfill', backfill) }/>
                </SortTable.Cell>
              </SortTable.Row>
            ))}
          </SortTable>;
        })()}
      </Segment>
    );
  }
};

BackfillSegment.propTypes = {
  backfill: PropTypes.array.isRequired,
  totbackfillamount: PropTypes.number.isRequired,
  ui: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onUIStateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default BackfillSegment;
