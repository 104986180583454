import React from 'react';

import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import IconButton from './IconButton';

class EditButton extends React.Component {
  render() {
    const { pathname, view, className, ...props } = this.props;

    const button = (
      <IconButton name={ this.props.view ? 'eye' : 'edit' } color='green' className={ 'edit-button ' + (className || '') } { ...props }/>
    );

    return pathname ? <NavLink to={ pathname }>{ button }</NavLink> : button;
  }
};

EditButton.propTypes = {
  pathname: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  // view as opposed to edit, i.e. read only
  view: PropTypes.bool,
};

export default EditButton;
