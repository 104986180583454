import React from 'react';
import { connect } from 'react-redux';

import { Grid, Segment, Header, Form, Message, Icon, Input, Checkbox, Accordion } from 'semantic-ui-react';

import AddressInput from '../../components/AddressInput';
import NumberInput from '../../components/NumberInput';
import FileChooserSegment from '../../components/FileChooserSegment';
import ViewLoader from '../../components/ViewLoader';

import { signUpUser } from '../../api/userAPI';
import { getSettings } from '../../api/administratorAPI';

import { EXTERNAL_PATH_FD_GUIDELINES, EXTERNAL_PATH_FD_SOP } from '../../js/paths';
import { CC_CATEGORIES } from '../../js/lists';

import { AUTH_CONFIRM_SIGN_UP, AUTH_SIGNED_IN, AUTH_SIGN_IN, AUTH_SIGN_UP } from '../../js/constants';
import CommonOrganizationSelectionInput from '../../components/OrganizationSelectionInput';

const INITIAL_STATE = {
  type: 'Construction',
  password: '',
  email: '',
  contact_name: '',
  contact_email: '',
  entity_name: '',
  address: {
    street: '',
    number: '',
    compliment: '',
    city: '',
    province: '',
    postalcode: ''
  },

  main_telephone_number: '',
  main_telephone_ext: '',
  second_telephone_number: '',
  second_telephone_ext: '',
  checkstandards: false,

  // CC only
  selectedOrg: null,

  // FD only

  chief_name: '',
  payable_to: '',
  maxallowedtrainers: 0,
  justification: '',
  applicationDocs: [],

  // UI
  activeAccordion: -1,
  loading: false,
  errors: {
    error: false
  },
  ui: {
    sortField: 'type',
    sortDescending: true,
  },
};

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleApplicationDocsChange = this.handleApplicationDocsChange.bind(this);
    this.state = { ...INITIAL_STATE };
  }

  changeAuthState(type, event) {
    this.setState({ errors: { error: false } });
    this.props.onStateChange(type, event);
  }

  handleCheckboxChange = () => {
    this.setState({ checkstandards: !this.state.checkstandards });
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleAddressChange = (event, { name, value }) => {
    this.setState({ address: { ...this.state.address, [name]: value }});
  }

  handleNumberChange = (name, value) => {
    this.setState({ maxallowedtrainers: value });
  }

  handleAccordion = (event, value) => {
    value = value.index;
    if (value !== this.state.activeAccordion) this.setState({ activeAccordion: value });
    else this.setState({ activeAccordion: -1 });
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (this.state.type === 'TrainingAgency' && !this.state.checkstandards) {
      errors.error = true;
      errors.checkstandards = 'You cannot submit a request without this guarantee.';
    }

    if (this.state.contact_email) {
      const emails = this.state.contact_email.split(';').filter(Boolean).map((item) => item.trim());
      const hasInvalidEmails = emails.map((email) => this._validateEmail(email)).filter((item) => item !== true).length > 0;
      
      if (hasInvalidEmails) {
        errors.error = true;
        errors.contact_email = 'One or more e-mails are invalid.';
      }
    }

    if (this.state.type === 'Construction' && !this.state.selectedOrg) {
      errors.error = true;
      errors.checkOrg = 'Please, select a company below.';
    }
    

    this.setState({ errors: errors });

    return !errors.error;
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.isValid()) {
      const { loading, errors, ui, ...newUser } = this.state;
      this.signUp(newUser);
    }
  }

  handleOrganizationSelected(orgSelected) {
    this.setState({ selectedOrg: orgSelected });
  }

  handleApplicationDocsChange = (state) => {
    this.setState(state);
  }

  signUp = async (newUser) => {
    this.setState({ loading: true });
    // Sync files
    const uploadedApplications = await this.processApplicationDocs(newUser);
    if (!uploadedApplications) {
      this.setState({ loading: false });
      return;
    }
    // Signup
    newUser.email = newUser.email.replace(' ','').toLowerCase().trim();
    if (this.state.type === 'Construction' && this.state.selectedOrg) {
      newUser.entity_name = this.state.selectedOrg.name;
      newUser.orgID = this.state.selectedOrg.id;
      //get organization CUs from org module and set this.state.cc.category to MARITIME if any of its CUs is in the settings.maritimeCUs list, otherwise set it to CONSTRUCTION
      await getSettings();
      let orgCUs = [];
      if (this.state.selectedOrg && this.state.selectedOrg.cus && this.state.selectedOrg.cus.length > 0) {
        orgCUs = this.state.selectedOrg.cus.filter((cu) => this.props.settings.maritimeCUs.includes(cu.id));
      }
      if (orgCUs.length > 0) newUser.category = Object.keys(CC_CATEGORIES)[0]; //MARITIME
      else newUser.category = Object.keys(CC_CATEGORIES)[1]; //CONSTRUCTION
    }
    if (uploadedApplications && uploadedApplications.length > 0) newUser.applicationDocs = uploadedApplications;
    const response = await signUpUser(newUser);
    this.setState({ loading: false });
    // 
    if (response.UserConfirmed) {
      this.changeAuthState(AUTH_SIGNED_IN);
    } else if (response.UserConfirmed === false) {
      this.changeAuthState(AUTH_CONFIRM_SIGN_UP, { username: this.state.email });
    }
  }

  processApplicationDocs = async (data) => {
    const success = await this._uploadFiles(data.applicationDocs);
    if (!success) return false;
    return data.applicationDocs.map((file) => { return file.stringify(); });
  }


  render() {
    if (this.props.authState !== AUTH_SIGN_UP) return null;

    let entityLabel;
    let emailLabel;

    switch (this.state.type) {
      case 'Construction':
        entityLabel = 'Company Name';
        emailLabel = 'Company Email Address';
        break;
      case 'FireDepartment':
        entityLabel = 'Department Name';
        emailLabel = 'Department Email Address';
        break;
      case 'TrainingAgency':
        entityLabel = 'Agency Name';
        emailLabel = 'Agency Email Address';
        break;
      default:
        entityLabel = 'Name';
        emailLabel = 'Email Address';
      break;
    }

    return (
      <div className='auth-container signup'>
        <Grid centered className='auth-grid'>
          <Grid.Column className='auth-column'>
            <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
              <Header size='medium'>
                <Header.Content>
                  Create A New Account
                </Header.Content>
                <Form.Group inline className='type'>
                  <Form.Field>
                    <Input id='type_con' type='radio' name='type' value='Construction'
                      checked={ this.state.type === 'Construction' } onChange={ this.handleChange }
                    />
                    <label htmlFor='type_con'><Icon name='building outline' />Employer</label>
                  </Form.Field>
                  <Form.Field>
                    <Input id='type_fd' type='radio' name='type' value='FireDepartment'
                      checked={ this.state.type === 'FireDepartment' } onChange={ this.handleChange } />
                    <label htmlFor='type_fd'><Icon name='fire extinguisher' />Fire Department</label>
                  </Form.Field>
                  <Form.Field>
                    <Input id='type_ta' type='radio' name='type' value='TrainingAgency'
                      checked={ this.state.type === 'TrainingAgency' } onChange={ this.handleChange } />
                    <label htmlFor='type_ta'><Icon name='wrench' />Training Agency</label>
                  </Form.Field>
                </Form.Group>
                <Header.Subheader>Already have an account?&nbsp;&nbsp;<a href='#signin' onClick={() => this.changeAuthState(AUTH_SIGN_IN)}>Sign In</a></Header.Subheader>
                <Header.Subheader style={{margin: '4px' , textDecoration: 'underline'}}>
                  <a style={{color: 'rgba(0,0,0,.6)'}} target="_blank" rel="noopener noreferrer" href={EXTERNAL_PATH_FD_SOP}>
                    Click Here to Review the Standard Operating Procedures for the THARRP Program
                  </a>
                </Header.Subheader>
                <Header.Subheader style={{margin: '4px', fontSize: '13px'}}>
                  If you are a Construction company or Maritime industry employer, please select the “Employer” tab to create your account. Note, you must have your WorkSafeBC account number available to create an account. If you require assistance, please contact tharrp@bccsa.ca
                </Header.Subheader>
              </Header>
              <Segment secondary>
                <Form.Group>
                  <Form.Input type="email" required label={ emailLabel } value={ this.state.email } name='email' width={ 8 }
                    autoComplete='email' onChange={ this.handleChange }
                  />
                  <Form.Input required label='Password' value={ this.state.password } name='password' width={ 8 }
                    type='password' autoComplete='new-password' onChange={ this.handleChange } data-dd-privacy="mask"
                  />
                </Form.Group>

                {this.state.type === 'Construction' ? 
                  <CommonOrganizationSelectionInput input={{ id: 'orgID', label: 'Company Name', required: true, placeholder: 'Select a company' }} 
                    isEdit selectedOrg={this.state.selectedOrg} onSelection={this.handleOrganizationSelected.bind(this)}/> : 
                  <Form.Group>
                    <Form.Input required label={ entityLabel } value={ this.state.entity_name } name='entity_name' width={ 16 } onChange={this.handleChange}/>
                  </Form.Group>}
                  <Message error>
                    { this.state.errors.checkOrg }
                  </Message>

                <Form.Group>
                  <Form.Input required label='Contact Name(s)' value={ this.state.contact_name } name='contact_name' width={ 16 }
                    placeholder='Tom Jones, Bob Smith'
                    onChange={ this.handleChange }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input required label='Contact Email(s)' value={ this.state.contact_email } name='contact_email' width={ 16 }
                    placeholder='tom@abc.com; bob@abc.com'
                    onChange={ this.handleChange }
                  />
                  <Message error>
                    { this.state.errors.contact_email }
                  </Message>
                </Form.Group>

                { this.state.type === 'TrainingAgency' &&
                  <Form.Group>
                    <Form.Input required label='Payable To' value={ this.state.payable_to } name='payable_to' width={ 16 }
                        onChange={ this.handleChange }
                    />
                  </Form.Group>
                }

                { this.state.type === 'FireDepartment' &&
                  <Form.Group>
                    <Form.Input required label='Fire Chief' value={ this.state.chief_name } name='chief_name' width={ 6 }
                      onChange={ this.handleChange }
                    />
                    <Form.Input required label='Payable To' value={ this.state.payable_to } name='payable_to' width={ 6 }
                      onChange={ this.handleChange }
                    />
                    <Form.Field required
                      label='Number Of Trainers' value={ this.state.maxallowedtrainers } name='maxallowedtrainers' width={ 4 }
                      control={ NumberInput } onChange={ this.handleNumberChange }
                    />
                  </Form.Group>
                }

                <AddressInput address={ this.state.address } onChange={ this.handleAddressChange } hideCompliment={ this.state.type === 'FireDepartment' } />

                <Form.Group>
                  <Form.Input required label='Main Phone' value={ this.state.main_telephone_number } name='main_telephone_number' width={ 4 }
                    onChange={ this.handleChange }
                  />
                  <Form.Input label='Ext' value={ this.state.main_telephone_ext } name='main_telephone_ext' width={ 2 }
                    onChange={ this.handleChange }
                  />
                  <Form.Input label='Secondary Phone' value={ this.state.second_telephone_number } name='second_telephone_number' width={ 4 }
                    onChange={ this.handleChange }
                  />
                  <Form.Input label='Ext' value={ this.state.second_telephone_ext } name='second_telephone_ext' width={ 2 }
                    onChange={ this.handleChange }
                  />
                </Form.Group>
                { this.state.type === 'FireDepartment' &&
                  <>

                    <Form.Group>
                      <Form.Field required={ false } width={ 16 }
                        control={ FileChooserSegment } customLabel={this.state.applicationDocs.length >= 4 ? ' ' : 'Add files'} label='Supporting Documentation' name='applicationDocs' 
                        error={ !!this.state.errors.applicationDocs } ui={this.state.ui} maxFiles={4}
                        files={ this.state.applicationDocs } multiple onChange={ this.handleApplicationDocsChange }
                      />
                    </Form.Group>
                    {this.state.loading && <ViewLoader loading={ this.state.loading } percent={ this.state.progressPercent } text={ this.state.progressText }/>}
                    <Message error>{this.state.errors.applicationDocs}</Message>

                    <h5 style={{color: "black"}}>Justification<a target="_blank" rel="noopener noreferrer" href={EXTERNAL_PATH_FD_GUIDELINES}> ?</a></h5>
                    <Accordion>
                      <Accordion.Title active={this.state.activeAccordion === 0} index={0} onClick={this.handleAccordion}>
                        <Icon name='dropdown' />
                        Please provide a detailed justification for your request. Your answer must address the following:
                      </Accordion.Title>
                      <Accordion.Content active={this.state.activeAccordion === 0}>
                          <ul>Does specialized rescue, such as high angle rope rescue, fall within the fire department’s mandated level of service?</ul>
                          <ul>Options for mutual aid or a regional rescue team have been explored with neighbouring municipalities.</ul>
                          <ul>The department has sufficient full-time staff to provide and maintain an effective technical high angle rope rescue service.</ul>
                          <ul>There are sufficient funding CU’s within the jurisdiction where high angle rope rescue may be required.</ul>
                          <ul>There are sufficient expected projects involving work by funding CU’s</ul>
                          <ul>There are sufficient geographic changes that may affect construction activities of population served.</ul>
                          <ul>The applicant guarantees that THARR training by approved instructors will meet current NFPA 1670 and NFPA 1006 Standards.</ul>
                          <ul>Number of rope rescue equipment packages required</ul>
                      </Accordion.Content>
                    </Accordion>
                    <Form.TextArea value={ this.state.justification } placeholder='Your justification here...'
                                   rows='3' onChange={ this.handleChange } required name='justification' maxLength={ 512 }/>

                    <Form.Field style={{textAlign: "justify"}}>
                      <Checkbox checked={ this.state.checkstandards } name='checkstandards' onChange={ this.handleCheckboxChange }
                        label='The information provided below has been approved, in writing, by the Fire Chief or City Manager in the jurisdiction under which the applicant Fire Department operates' />
                    </Form.Field>
                  </>
                }

                { this.state.type === 'TrainingAgency' &&
                  <Form.Group>
                    <Form.Checkbox style={{ fontSize: '1em' }} checked={ this.state.checkstandards } onChange={ this.handleCheckboxChange } width={ 16 }
                      label='This training agency provides technical high angle rope rescue training to the applicable and current NFPA Standards'
                    />
                    <Message error>
                      { this.state.errors.checkstandards }
                    </Message>
                  </Form.Group>
                }

                <Form.Button positive disabled={((this.state.type === 'FireDepartment' || this.state.type === 'TrainingAgency') && !this.state.checkstandards)}
                                      className='centered'>Create Account</Form.Button>

                <Message error className='centered' negative size='small'>
                  { this.state.errors.authError }
                </Message>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  /* Private */
  _uploadFiles = async (toUpload) => {
    let success = true;
    for (const uploadFile of toUpload) {
      if (!success) return;
      uploadFile.isRegistrationFile = true;
      success = await uploadFile.upload({
        noAuth: true,
        onUploadProgress: (percent) => {
          this.setState({
            progressPercent: percent,
            progressText: uploadFile.name
          });
        }
      });
      if (!success) {
        let errors = { error: true };
        errors.files = `Failed to upload ${ uploadFile.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      }
    }
    return success;
  }

  _validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }
}

function mapStoreStateToProps(storeState) {
  return {
    settings: storeState.lookups.settings
  };
}

export default connect(mapStoreStateToProps)(SignUp);
