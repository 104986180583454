import React from 'react';

import PropTypes from 'prop-types';

import ConfirmPopup from './ConfirmPopup';
import IconButton from './IconButton';

class DeleteButton extends React.Component {
  render() {
    const { onConfirm, className, header, content, ...props } = this.props;

    const button = (
      <IconButton name='trash alternate' color='red' className={ 'delete-button' + (className || '') } { ...props }/>
    );

    const confirm = (
      <ConfirmPopup onConfirm={ onConfirm || (()=>{}) } header={ header } content={ content } on='click'>
        { button }
      </ConfirmPopup>
    );

    return onConfirm ? (this.props.disabled ? button : confirm) : (this.props.onClick ? button : null);
  }
};

DeleteButton.propTypes = {
  onConfirm: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default DeleteButton;
