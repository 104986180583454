import React from 'react';

import { Message, Table } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import CurrencyText from './CurrencyText';

class BackfillSegment extends React.Component {

  render = () => {
    return (
      (() => {
        if (this.props.backfill.length === 0) {
          return <Message info compact size='tiny'>No backfill expenses</Message>;
        }

        let backfills = this.props.backfill.map(b => {
          let backfill = { ...b };
          return backfill;
        });

        return <Table collapsing basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Hours</Table.HeaderCell>
              <Table.HeaderCell>Rate</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { backfills.map((backfill, index) => (
              <Table.Row key={ index } id={ `backfill_${ backfill.timestamp }`}>
                <Table.Cell>{ backfill.name }</Table.Cell>
                <Table.Cell>{ backfill.hoursworked }</Table.Cell>
                <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ backfill.rate }/></Table.Cell>
                <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ backfill.amount }/></Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>;
      })()
    );
  }
};

BackfillSegment.propTypes = {
  backfill: PropTypes.array.isRequired,
  totbackfillamount: PropTypes.number.isRequired,
};

export default BackfillSegment;
