import React from 'react';

import PropTypes from 'prop-types';

import IconButton from './IconButton';

class DuplicateButton extends React.Component {
  render() {
    const { className, ...props } = this.props;
    const button = (
      <IconButton name='clone' color='blue' className={ 'duplicate-button' + (className || '') } { ...props }/>
    );
    return (this.props.onClick ? button : null);
  }
};

DuplicateButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default DuplicateButton;
