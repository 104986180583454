import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message } from 'semantic-ui-react';

import { getFireDepartmentWorkSiteRescues } from '../../api/fireDepartmentAPI';

import { UPDATE_FIRE_DEPARTMENT_RESCUE_SERVICES_UI } from '../../js/actionTypes';
import { PATH_FIRE_DEPARTMENT, PATH_RESCUE, PATH_NEW } from '../../js/paths';

import { sort } from '../../utils/utils';

import AddButton from '../../components/AddButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';
import SearchableFilter, {MONTHNAMES} from '../../components/SearchableFilter';

import ListView from '../ListView';

class FireDepartmentSiteRescueServices extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: 'sortDate',
        sortDescending: true,
      },
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    if (this.filterObjYrs !== undefined) this.filterObjYrs.setYears();
    if (this.filterObjMonths !== undefined) this.filterObjMonths.setMonths(()=> {
      this.fetch();
      this.isClean();
    });
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    let selectedYear = this.filterObjYrs.getSelectedText();
    //format
    let selectedMonth = (this.filterObjMonths.getSelectedIndex() + 1);
    selectedMonth = String("0" + selectedMonth).slice(-2);
    //
    await getFireDepartmentWorkSiteRescues(undefined/*fdid*/, (selectedYear + "-" + selectedMonth));
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_RESCUE_SERVICES_UI, fireDepartmentSurveys: this.state.ui });
    });
  }
  //Filter support
  handleFilterChange(filter,value) { this.fetch(); }

  handleNew() {
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_RESCUE }/${ PATH_NEW }`);
  }

  render() {
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={ 7 }>
            <Header size='medium'>
              <Header.Content>Work Site Rescues</Header.Content>
              <AddButton onClick={ this.handleNew.bind(this) }/>
            </Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingTop: 30}} width={ 7 } floated='right'>
            <SearchableFilter ref={(r)=>this.filterObjMonths=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={MONTHNAMES[new Date().getMonth()]}/>
            <SearchableFilter ref={(r)=>this.filterObjYrs=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={new Date().getFullYear()}/>
          </Grid.Column>
          <Grid.Column width={15}>
            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.rescues.length) {
                return <Message info compact size='large'>
                  You don&apos;t have any rescues.
                </Message>;
              }

              let rescues = sort(this.props.rescues, this.state.ui.sortField);

              if (this.state.ui.sortDescending) {
                rescues.reverse();
              }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                  { field: 'sort_date',          title: 'Date', width: '4' },
                  { field: 'description',        title: 'Decription', width: '8' },
                  { field: 'ccname',             title: 'Construction Company',  width: '4' },
              ]}>
                { rescues.map((rescue) => (
                  <SortTable.Row key={ rescue.key }>
                    <SortTable.Cell>{ rescue.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ rescue.comments }</SortTable.Cell>
                    <SortTable.Cell>{ rescue.ccname ? rescue.ccname : '' }</SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    rescues: storeState.models.fireDepartmentWorkSiteRescues,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentSiteRescueServices);
