import { request } from './http';
import { obtainGetURL as obtainGetURLFD, obtainPutURL as obtainPutURLFD, obtainDelURL as obtainDelURLFD } from '../api/fireDepartmentAPI';
import { obtainGetURL as obtainGetURLUser, obtainPutURL as obtainPutURLUser, obtainDelURL as obtainDelURLUser } from '../api/userAPI';

import { sortableDateTime } from '../utils/date';
const md5 = require('md5');

class ChosenFile {
  constructor(file) {
    if (file instanceof File || file instanceof Blob) {
      this.name = file.name;
      this.size = file.size;
      this.type = file.type;
      this.lastModified = file.lastModified || (file.lastModifiedDate ? file.lastModifiedDate.getTime() : 0);
      this.timestamp = sortableDateTime(new Date());
      this.id = md5(`${ this.timestamp }_${ this.lastModified }_${ this.size }_${ this.name }`);
      this.uploaded = false;
      this.deleted = false;
      this.isRegistrationFile = false;
      this.progress = 0;
      this.file = file;
    } else {
      this.setDefaults();
    }
  }

  setDefaults = () => {
    this.name = '';
    this.size = 0;
    this.type = '';
    this.lastModified = 0;
    this.timestamp = sortableDateTime(new Date());
    this.id = '';
    this.uploaded = false;
    this.progress = 0;
    this.deleted = false;
    this.file = null;
    this.isRegistrationFile = false;
  }

  parse = (json) => {
    try {
      const file = JSON.parse(json);
      this.name = file.name;
      this.size = file.size;
      this.type = file.type;
      this.lastModified = file.lastModified;
      this.timestamp = file.timestamp;
      this.id = file.id;
      this.uploaded = file.uploaded;
      this.deleted = file.deleted;
      this.progress = file.progress;
      this.isRegistrationFile = file.isRegistrationFile;
    } catch(e) {
      console.error('Not valid JSON', json);
      this.setDefaults();
    }
  }
  duplicate = (json) => {
    try {
      const file = JSON.parse(json);
      //save original
      this.original = new ChosenFile();
      this.original.parse(json);
      //
      this.name = file.name;
      this.size = file.size;
      this.type = file.type;
      this.lastModified = file.lastModified;
      this.timestamp = sortableDateTime(new Date());
      this.id = md5(`${ this.timestamp }_${ this.lastModified }_${ this.size }_${ this.name }`);
      this.uploaded = false;
      this.deleted = false;
      this.isRegistrationFile = file.isRegistrationFile;
      this.progress = 0;
    } catch(e) {
      console.error('Not valid JSON', json);
      this.setDefaults();
    }
  }
  stringify = () => {
    const { file, ...rest } = this;
    return JSON.stringify(rest);
  }

  isEqual = (file) => {
    const lastModified = file.lastModified || (file.lastModifiedDate ? file.lastModifiedDate.getTime() : 0);
    return this.name === file.name && this.size === file.size && this.type === file.type && this.lastModified === lastModified;
  }

  upload = async (opts) => {
    if (!this.id || this.deleted) return false;
    if (this.uploaded) return true;

    try {
      // Obtain the PUT link to S3
      const putUrl = await ((this.isRegistrationFile ? obtainPutURLUser : obtainPutURLFD)(this.id, opts ? opts.noAuth : false));
      console.log('>',putUrl);
      //check for duplication, if have original, must download original and attach to upload content
      if (this.original) this.file = await this.original.download();
      // Now use it to upload
      const options = {
        method: 'PUT',
        headers: { 'Content-Type': this.type },
        file: this.file,
        onUploadProgress: (percent) => {
          this.progress = percent;
          if (opts.onUploadProgress) {
            opts.onUploadProgress(percent);
          }
        }
      };

      await request(putUrl, options);

      this.uploaded = true;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  uploadUrl = async () => {
    if (!this.id || this.deleted) return false;
    if (this.uploaded) return true;

    try {
      // Obtain the PUT link from S3
      // Note: Only good for 5 minutes!
      const putUrl = await ((this.isRegistrationFile ? obtainPutURLUser : obtainPutURLFD)(this.id));

      return putUrl;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  download = async () => {
    if (!this.id || !this.uploaded || this.deleted) return null;

    try {
      // Obtain the GET link from S3
      const getUrl = await ((this.isRegistrationFile ? obtainGetURLUser : obtainGetURLFD)(this.id));

      // Now use it to download
      const options = {
        method: 'GET',
        file: true,
        headers: { 'Content-Type': this.type },
      };

      const response = await request(getUrl, options);

      return response.response;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  downloadUrl = async () => {
    if (!this.id || !this.uploaded || this.deleted) return null;

    try {
      // Obtain the GET link from S3
      // Note: Only good for 5 minutes!
      const getUrl = await ((this.isRegistrationFile ? obtainGetURLUser : obtainGetURLFD)(this.id));

      return getUrl;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  delete = async () => {
    if (!this.id || !this.uploaded) return false;

    try {
      // Obtain the DEL link from S3
      const delUrl = await ((this.isRegistrationFile ? obtainDelURLUser : obtainDelURLFD)(this.id));

      // Now use it to delete
      const options = {
        method: 'DELETE',
      };

      await request(delUrl, options);

      this.uploaded = false;
      this.deleted = true;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  // * Static function *
  // Given the current state of files (as an array of stringified ChosenFiles) and
  // a new state of files (as an array of ChosenFiles), determine which have been removed
  // (to be deleted) and which have been added (to be uploaded), and return these files
  // in the given arrays.

  static syncFiles = (currentFileStateArray, newFilesArray, toDeleteArray, toUploadArray) => {
    if (!Array.isArray(currentFileStateArray) || !Array.isArray(newFilesArray) || !Array.isArray(toDeleteArray) || !Array.isArray(toUploadArray)) return;

    // Get the files from the current file state
    let currentFilesArray = [];
    currentFileStateArray.forEach((currentFileState) => {
      let currentFile = new ChosenFile();
      currentFile.parse(currentFileState);
      if (currentFile.id) {
        currentFilesArray.push(currentFile);
      }
    });

    // Which files have been removed from the current state?
    toDeleteArray.length = 0;
    currentFilesArray.forEach((currentFile) => {
      if (currentFile.deleted) return;

      const found = newFilesArray.find((newFile) => { return newFile.id === currentFile.id; });
      if (!found) {
        toDeleteArray.push(currentFile);
      }
    });

    // Which files have been added to the current state?
    toUploadArray.length = 0;
    newFilesArray.forEach((newFile) => {
      if (newFile.uploaded) return;

      const found = currentFilesArray.find((currentFile) => { return newFile.id === currentFile.id; });
      if (!found) {
        toUploadArray.push(newFile);
      }
    });
  }
}

export default ChosenFile;
