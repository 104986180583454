import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SidebarMenu from '../../components/SidebarMenu';

import ConstructionCraneSurvey from './ConstructionCraneSurvey';
import ConstructionCraneSurveyList from './ConstructionCraneSurveyList';
import ConstructionProfile from './ConstructionProfile';

import { PATH_CONSTRUCTION, PATH_SURVEY, PATH_ID, PATH_TIMESTAMP, PATH_PROFILE } from '../../js/paths';

class Construction extends React.Component {
  render() {
    const surveyListPath = `/${ PATH_CONSTRUCTION }/${ PATH_SURVEY }`;
    const profilePath = `/${ PATH_CONSTRUCTION }/${ PATH_PROFILE }`;

    const menus = [{
      header: 'Crane Surveys',
      items: [
        { name: 'Survey List', path: surveyListPath }
      ]
    }, {
      header: 'Account',
      items: [
        { name: 'Profile', path: profilePath }
      ]
    }];

    return (
      <SidebarMenu menus={ menus } location={ this.props.location }>
        <main className='pg-content'>
          <Route render={() => (
            <Switch>
              <Redirect exact from={ `/${ PATH_CONSTRUCTION }` } to={ surveyListPath } />

              <Route exact path={ surveyListPath } component={ ConstructionCraneSurveyList }/>
              <Route exact path={ `${ surveyListPath }/:${ PATH_TIMESTAMP }` } component={ ConstructionCraneSurvey }/>
              <Route exact path={ `${ surveyListPath }/:${ PATH_TIMESTAMP }/:${ PATH_ID }` } component={ ConstructionCraneSurvey }/>

              <Route exact path={ profilePath } component={ ConstructionProfile }/>
            </Switch>
          )}/>
        </main>
      </SidebarMenu>
    );
  }
}

export default Construction;
