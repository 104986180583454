import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Grid, Button, Icon, Image, Popup, Message, Dropdown } from 'semantic-ui-react';

import { UPDATE_CURRENT_USER, UPDATE_SIDEBAR_UI } from '../js/actionTypes';
import { AUTH_SIGNED_IN, ROLE_CONSTRUCTION, ROLE_FIRE_DEPARTMENT, ROLE_TRAINING_AGENCY } from '../js/constants';

import logo from '../images/bccsa_logo.png';

import ChangePasswordModal from './Auth/ChangePasswordModal';

class Top extends React.Component {
  constructor(props) {
    super(props);

    this.signOut = this.signOut.bind(this);
  }

  signOut = () => {
    // Clearing the current user will force the authentication to "signIn" mode
    this.props.dispatch({ type: UPDATE_CURRENT_USER, user: { cognitoUser: null }});
  }

  hamburgerClick = () => {
    this.props.dispatch({ type: UPDATE_SIDEBAR_UI, showSidebar: this.props.showSidebar ? false : true });
  }

  handleLogoClick() {
    if (!this.props.user || !this.props.user.cognitoUser) { window.location.assign('/'); }
  }

  handleDropdownChange() { this.changePassModal.handleShow(); }

  render = () => {
    let iconName;

    switch (this.props.currentUser.role) {
      case ROLE_CONSTRUCTION:
        iconName = 'building outline';
        break;
      case ROLE_FIRE_DEPARTMENT:
        iconName = 'fire extinguisher';
        break;
      case ROLE_TRAINING_AGENCY:
        iconName = 'wrench';
        break;
      default:
        iconName = 'user';
    }

    return (
      <Grid padded verticalAlign='middle' className='app-header'>
        {/* Remove from this line */}
        {/* <div style={{ background: '#F9AD10', padding: '24px', color: '#333' }}>
          Please note that the BCCSA is performing routine maintenance on the THARRP Portal on <strong>Oct 20th, from 4:00 pm</strong> until approximately <strong>6:00 pm</strong>. Please ensure any requests you have initiated in the portal are submitted or saved prior to <strong>3:45 pm</strong>. We apologize for any inconvenience this may cause and thank you for your patience.
        </div> */}
        {/* To this line */}
        <Grid.Column floated='left' width={ 6 }>
          { this.props.authState === AUTH_SIGNED_IN &&
            <Icon name='bars' size='big' className='burger' onClick={ this.hamburgerClick }/>
          }
          <Link to={ this.props.currentUser.homepage || '/' } onClick={ this.handleLogoClick.bind(this) }>
            <Image src={ logo } size='medium' className='brand'/>
          </Link>
        </Grid.Column>
        <Grid.Column textAlign='center' width={ 4 }>
          <Button.Group>
            { (this.props.error.status || this.props.error.method) &&
              <Popup on='click' position='bottom center' wide='very'
                trigger={
                  <Button basic color='red' size='large'>
                    <Icon name='exclamation triangle' />
                      Error&nbsp;
                    <Icon name='triangle down' size='small' />
                  </Button>
                }
                content={
                  <Message error size='small'>
                    <Message.Header textalign='center'>{ `API Error Status ${ this.props.error.status }` }</Message.Header>
                    <Message.Content>
                    { this.props.error.method &&
                      <p>{ this.props.error.method } { this.props.error.path }</p>
                    }
                      <p>{ this.props.error.message }</p>
                    </Message.Content>
                  </Message>
                }
              />
            }
            { this.props.waiting &&
              <Button basic color='green'>
                <Icon loading name='redo alternate' color='green' size='large' style={{ margin: 0 }}/>
              </Button>
            }
          </Button.Group>
        </Grid.Column>
        <Grid.Column floated='right' width={ 6 }>
          <Button.Group floated='right'>
            { this.props.currentUser.role &&
              <Button.Group>
                <Button>
                  <Icon name={iconName} />{ this.props.currentUser.role }
                </Button>
                <Dropdown className='button icon' floating options={
                    [{ key: 'changePass', icon: 'edit outline', text: 'Change Password', value: 'changePass' }]
                  } onChange={this.handleDropdownChange.bind(this)} trigger={<React.Fragment />}/>
              </Button.Group>
            }
            { this.props.authState === AUTH_SIGNED_IN &&
              <Button positive onClick={ this.signOut }>
                <Icon name='sign-out alternate'/>Sign Out
              </Button>
            }
          </Button.Group>
          <ChangePasswordModal ref={(ref) => this.changePassModal = ref} currentUser={this.props.currentUser}/>
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    authState: storeState.auth.authState,
    currentUser: storeState.auth.currentUser,
    showSidebar: storeState.ui.showSidebar,
    error: storeState.ui.requests.error,
    waiting: storeState.ui.requests.waiting,
  };
}

export default connect(mapStoreStateToProps)(Top);
