import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Button } from 'semantic-ui-react';

import { getTrainingAgencies } from '../../api/trainingAgencyAPI.js';

import { UPDATE_ADMIN_REPORT_TAS_UI } from '../../js/actionTypes';
import { DATE_FULL_MONTH_DAY_YEAR, DATE_ISO_8601 } from '../../js/constants';

import { formatDateTime } from '../../utils/date';
import { sort, downloadArrayBuffer, xlsxAutoWidth } from '../../utils/utils';

import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

import * as ExcelJS from 'exceljs/dist/exceljs';

class AdminReportTraining extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: 'name',
        sortDescending: true,
      }
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getTrainingAgencies();
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REPORT_TAS_UI });
    });
  }

  handleExportXLSX() {
    this._exportToXLSX();
  }

  render() {
    return (
      <div className='fire-department-ta-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={6} floated='left'>
            <Header size='medium'>Reports - Training Agencies</Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingBottom: 0}} width={8} floated='right'>
            <Button primary disabled={!this.props.trainingAgencies || this.props.trainingAgencies.length === 0} onClick={this.handleExportXLSX.bind(this)}>Export</Button>
          </Grid.Column>
          <Grid.Column width={15}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              console.log("TAs:", this.props.trainingAgencies);

              let trainingAgencies = sort(this.props.trainingAgencies, this.state.ui.sortField);

              if (trainingAgencies && !trainingAgencies.length) {
                return <Message info size='large'>
                  You don&apos;t have any Training Agencies.
                </Message>;
              }

              if (this.state.ui.sortDescending) { trainingAgencies.reverse(); }

              const center = { textAlign: 'center' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'name',              title: 'Agency Name',                  width: '5'},
                { field: 'registrationdate',    title: 'Registration Date',            width: '3', style: {...center} },
                { field: 'lastdeclarationdate', title: 'Last Annual declaration date', width: '6', style: {...center} },
              ]}>
                { trainingAgencies.map(({ id, name, registration_date, lastdeclarationdate }) => (
                  <SortTable.Row key={ id }>
                    <SortTable.Cell>{ name }</SortTable.Cell>
                    <SortTable.Cell {...center}>{ (!registration_date ? "-" : formatDateTime(registration_date, DATE_FULL_MONTH_DAY_YEAR)) }</SortTable.Cell>
                    <SortTable.Cell {...center}>{ (!lastdeclarationdate ? "-" : formatDateTime(lastdeclarationdate, DATE_FULL_MONTH_DAY_YEAR)) }</SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  /* Private XLSX */
  async _exportToXLSX() {
    const trainingAgencies = sort(this.props.trainingAgencies, this.state.ui.sortField);
    if (!trainingAgencies || trainingAgencies.length <= 0) return;
    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');
    // Generate XLSX header
    ws.addRow(['Agency Name', 'Registration Date', 'Last Annual declaration date']);
    // Generate XLSX rows
    trainingAgencies.forEach(({ name, registration_date, lastdeclarationdate }) => {
      ws.addRow([
        name, formatDateTime(registration_date, DATE_ISO_8601), formatDateTime(lastdeclarationdate, DATE_ISO_8601)
      ]);
    });
    // Auto width
    xlsxAutoWidth(ws, 75);
    // Generate buffer and save it
    const buffer = await wb.xlsx.writeBuffer();
    downloadArrayBuffer(buffer, `training-agencies-report`, 'xlsx');
  }
}

function mapStoreStateToProps(storeState) {
  return {
    trainingAgencies: storeState.lookups.trainingAgencies,
  };
}

export default connect(mapStoreStateToProps)(AdminReportTraining);
