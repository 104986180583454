import React from 'react';

import { Segment, Header, Message } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import AddButton from './AddButton';
import CurrencyText from './CurrencyText';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import SortTable from './SortTable';

import { DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { MEAL_TYPES } from '../js/lists';

import { formatDateTime, sortableDateTime } from '../utils/date';
import { sort } from '../utils/utils';

class MealSegment extends React.Component {
  updateUIState = (uiState) => {
    this.props.onUIStateChange({ meal: uiState });
  }

  render = () => {
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Meal Expenses</Header.Content>
          <AddButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'meal', {}) }/>
        </Header>

        {(() => {
          if (this.props.meal.length === 0) {
            return <Message info compact size='tiny'>No meal expenses</Message>;
          }

          let meals = this.props.meal.map(m => {
            let meal = { ...m };
            meal.displayType = MEAL_TYPES[meal.type];
            meal.sortableDate = sortableDateTime(meal.date);
            meal.displayDate = formatDateTime(meal.date, DATE_FULL_MONTH_DAY_YEAR);
            return meal;
          });

          meals = sort(meals, this.props.ui.sortField);
          if (this.props.ui.sortDescending) {
            meals.reverse();
          }

          const right = { textAlign: 'right' };

          return <SortTable collapsing sortField={ this.props.ui.sortField } sortDescending={ this.props.ui.sortDescending }
            onSort={ this.updateUIState } size='small'
            headers={[
              { field: 'displayType',           title: 'Type',   width: '5'               },
              { field: 'sortableDate',          title: 'Date',   width: '6', style: right },
              { field: 'rate',                  title: 'Rate',   width: '3', style: right },
              { field: 'buttons', noSort: true, title: '',       width: '2'               },
            ]}
            footers={[
              [ null, <div style={ right }><strong>Total:</strong></div>, <CurrencyText value={ this.props.totalmealamount } style={ right }/>, null ]
            ]}
          >
            { meals.map((meal, index) => (
              <SortTable.Row key={ index } id={ `meal_${ meal.timestamp }`}>
                <SortTable.Cell>{ meal.displayType }</SortTable.Cell>
                <SortTable.Cell textAlign='right'>{ meal.displayDate }</SortTable.Cell>
                <SortTable.Cell textAlign='right'><CurrencyText value={ meal.rate }/></SortTable.Cell>
                <SortTable.Cell textAlign='right'>
                  <EditButton view={ this.props.disabled } onClick={ this.props.edit.bind(this, 'meal', meal) }/>
                  <DeleteButton hide={ this.props.disabled } content='Delete the meal expense?' onConfirm={ this.props.remove.bind(this, 'meal', meal) }/>
                </SortTable.Cell>
              </SortTable.Row>
            ))}
          </SortTable>;
        })()}
      </Segment>
    );
  }
};

MealSegment.propTypes = {
  meal: PropTypes.array.isRequired,
  totalmealamount: PropTypes.number.isRequired,
  ui: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onUIStateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MealSegment;
