import React from 'react';

import { Message, Table } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import CurrencyText from './CurrencyText';

import { DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { MEAL_TYPES } from '../js/lists';

import { formatDateTime } from '../utils/date';

class MealSegment extends React.Component {

  render = () => {
    return (
      (() => {
        if (this.props.meal.length === 0) {
          return <Message info compact size='tiny'>No meal expenses</Message>;
        }

        let meals = this.props.meal.map(m => {
          let meal = { ...m };
          meal.displayType = MEAL_TYPES[meal.type];
          meal.displayDate = formatDateTime(meal.date, DATE_FULL_MONTH_DAY_YEAR);

          return meal;
        });

        return <Table collapsing basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { meals.map((meal, index) => (
              <Table.Row key={ index } id={ `meal_${ meal.timestamp }`}>
                <Table.Cell>{ meal.displayType }</Table.Cell>
                <Table.Cell>{ meal.displayDate }</Table.Cell>
                <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ meal.rate }/></Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>;
      })()
    );
  }
};

MealSegment.propTypes = {
  meal: PropTypes.array.isRequired,
  totalmealamount: PropTypes.number.isRequired,
};

export default MealSegment;
