import React from 'react';

import { Segment, Form, Message } from 'semantic-ui-react';

import CurrencyInput from '../components/CurrencyInput';
import DateControl from '../components/DateControl';
import EditDialog from '../components/EditDialog';
import FileChooserSegment from '../components/FileChooserSegment';

import { DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { VEHICLE_TYPES } from '../js/lists';

import { today } from '../utils/date';
import ChosenFile from '../utils/file';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  timestamp: '',
  type: '',
  date: today(),
  amountbeforetaxes: 0,
  receipts: [],

  // UI
  ui: {
    sortField: 'type',
    sortDescending: true,
  },
  errors: {
    error: false
  }
};

class VehicleEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, ...this.props.vehicle };

    this.state.files = [];

    this.state.receipts.forEach((receipt) => {
      let file = new ChosenFile();
      file.parse(receipt);
      if (file.id) {
        this.state.files.push(file);
      }
    });

    this.didChange = this.didChange.bind(this);
    this.isValid = this.isValid.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleTypeChange = (event, { value }) => {
    this.setState({ type: value }, () => { this.validate(); });
  }

  handleDateChange = (name, value) => {
    this.setState({ [name]: value }, () => { this.validate(); });
  }

  handleCurrencyChange = (name, value) => {
    this.setState({ [name]: value }, () => { this.validate(); });
  }

  handleReceiptsChange = (state) => {
    let newState = { ...state };

    if (newState.files) {
      newState.receipts = newState.files.map((file) => { return file.stringify(); });
    }

    this.setState(newState);
  }

  handleUIStateChange(state) {
    this.setState({ ui: { ...this.state.ui, ...state }});
  }

  didChange = () => {
    // Always true for new entriesl
    if (!this.props.vehicle.timestamp) return true;

    if (this.state.type !== this.props.vehicle.type) { return true; }
    if (this.state.date !== this.props.vehicle.date) { return true; }
    if (this.state.amountbeforetaxes !== this.props.vehicle.amountbeforetaxes) { return true; }

    let toDelete = [];
    let toUpload = [];
    ChosenFile.syncFiles(this.props.vehicle.receipts || [], this.state.files, toDelete, toUpload);

    if (toDelete.length > 0 || toUpload.length > 0) { return true; }

    return false;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.date) {
      errors.error = true;
      errors.date = 'Please enter a receipt date. ';
    }

    if (!this.state.type) {
      errors.error = true;
      errors.type = 'Please select a type. ';
    }

    if (!this.state.amountbeforetaxes) {
      errors.error = true;
      errors.amountbeforetaxes = 'Please enter an amount.';
    }

    if (this.state.receipts.length === 0) {
      errors.error = true;
      errors.receipts = 'Please attach a receipt.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    const { ui, errors, ...state } = this.state;
    this.props.onSave('vehicle', { ...state });
  }

  render = () => {
    const { onSave, onClose, vehicle, ...props } = this.props;

    const types = Object.keys(VEHICLE_TYPES).map((key) => { return { key: key, value: key, text: VEHICLE_TYPES[key] }});

    return (
      <EditDialog size='small' header='Add Vehicle Expense' onSave={ this.onSave } onClose={ onClose } readOnly={ this.props.disabled }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Segment.Group size='large'>
          <Segment disabled={ this.props.disabled }>
            <Form.Group>
              <Form.Field readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 6 }
                label='Receipt Date' name='date'
                control={ DateControl } date={ this.state.date } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                error={ !!this.state.errors.date }
                onChange={ this.handleDateChange }
              />
              { this.props.disabled &&
                <Form.Input readOnly label='Type' value={ VEHICLE_TYPES[this.state.type] } width={ 6 }/>
              }
              { !this.props.disabled &&
                <Form.Select required label='Type' value={ this.state.type } name='type' options={ types } width={ 6 }
                  error={ !!this.state.errors.type }
                  onChange={ this.handleTypeChange }
                />
              }
              <Form.Field readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 4 }
                label='Amount' value={ this.state.amountbeforetaxes } name='amountbeforetaxes'
                error={ !!this.state.errors.amountbeforetaxes }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.date }{ this.state.errors.type }{ this.state.errors.amountbeforetaxes }
            </Message>
            <Form.Group>
              <Form.Field readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 16 }
                control={ FileChooserSegment } label='Receipts' name='files' files={ this.state.files } multiple
                ui={ this.state.ui } onUIStateChange={ this.handleUIStateChange.bind(this) }
                onChange={ this.handleReceiptsChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.receipts }
            </Message>
          </Segment>
        </Segment.Group>
      </EditDialog>
    );
  }
}

VehicleEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicle: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default VehicleEditDialog;
