import React from 'react';
import { connect } from 'react-redux';

import { Segment, Form, Message } from 'semantic-ui-react';

import CurrencyInput from '../components/CurrencyInput';
import CurrencyText from '../components/CurrencyText';
import EditDialog from '../components/EditDialog';
import FileChooserSegment from '../components/FileChooserSegment';
import NumberInput from '../components/NumberInput';

import { ACCOMMODATION_TYPES } from '../js/lists';

import ChosenFile from '../utils/file';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  timestamp: '',
  type: 'H',
  numberofnights: 0,
  rate: 0.00,
  amount: 0.00,
  receipts: [],

  // UI
  ui: {
    sortField: 'type',
    sortDescending: true,
  },

  errors: {
    error: false
  }
};

class AccommodationEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, ...this.props.accommodation };

    this.state.files = [];

    this.state.receipts.forEach((receipt) => {
      let file = new ChosenFile();
      file.parse(receipt);
      if (file.id) {
        this.state.files.push(file);
      }
    });

    this.didChange = this.didChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleTypeChange = (event, { name, value }) => {
    let update = { [name]: value };
    if (value === 'P') {
      update.rate = this.props.settings.nightprivateaccommodamount;
    } else {
      update.rate = 0.00;
    }
    this.setState(update);
  }

  handleNightsChange = (name, value) => {
    this.setState({
      numberofnights: value,
      amount: this.state.rate * value
    });
  }

  handleCurrencyChange = (name, value) => {
    let update = { [name]: value };
    if (name === 'rate') {
      update.amount = this.state.numberofnights * value;
    }
    this.setState(update, () => { this.validate(); });
  }

  handleReceiptsChange = (state) => {
    let newState = { ...state };

    if (newState.files) {
      newState.receipts = newState.files.map((file) => { return file.stringify(); });
    }

    this.setState(newState);
  }

  handleUIStateChange(state) {
    this.setState({ ui: { ...this.state.ui, ...state }});
  }

  didChange = () => {
    // Always true for new entries
    if (!this.props.accommodation.timestamp) return true;

    if (this.state.type !== this.props.accommodation.type) { return true; }
    if (this.state.numberofnights !== this.props.accommodation.numberofnights) { return true; }
    if (this.state.rate !== this.props.accommodation.rate) { return true; }
    if (this.state.amount !== this.props.accommodation.amount) { return true; }

    let toDelete = [];
    let toUpload = [];
    ChosenFile.syncFiles(this.props.accommodation.receipts || [], this.state.files, toDelete, toUpload);

    if (toDelete.length > 0 || toUpload.length > 0) { return true; }

    return false;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (this.state.rate > this.props.settings.nighthotelaccommodamount) {
      errors.error = true;
      errors.rate = `Rate cannot exceed $${ this.props.settings.nighthotelaccommodamount }.`;
    }

    if (this.state.type === 'H' && this.state.receipts.length === 0) {
      errors.error = true;
      errors.receipts = 'Please attach a receipt.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    const { ui, errors, ...state } = this.state;
    this.props.onSave('accommodation', { ...state });
  }

  render = () => {
    const { onSave, onClose, accommodation, settings, dispatch, ...props } = this.props;

    const types = Object.keys(ACCOMMODATION_TYPES).map((key) => { return { key: key, value: key, text: ACCOMMODATION_TYPES[key] }});

    return (
      <EditDialog size='small' header='Add Accommodation Expense' onSave={ this.onSave } onClose={ onClose } readOnly={ this.props.disabled }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Segment.Group size='large'>
          <Segment disabled={ this.props.disabled }>
            <Form.Group>
              { this.props.disabled &&
                <Form.Input readOnly label='Type' value={ ACCOMMODATION_TYPES[this.state.type] } width={ 8 }/>
              }
              { !this.props.disabled &&
                <Form.Select required label='Type' value={ this.state.type } name='type' options={ types } width={ 8 }
                  onChange={ this.handleTypeChange }
                />
              }
            </Form.Group>
            <Form.Group>
              <Form.Field readOnly={ this.props.disabled } width={ 4 }
                label='Nights' value={ this.state.numberofnights } name='numberofnights'
                control={ NumberInput } onChange={ this.handleNightsChange }
              />
              <Form.Field readOnly={ this.props.disabled }
                label='Rate' value={ this.state.rate } name='rate' width={ 4 }
                disabled={ this.state.type === 'P' }
                error={ !!this.state.errors.rate }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field label='Amount' value={ this.state.amount } width={ 4 }
                control={ CurrencyText }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.rate }
            </Message>
            <Form.Group>
              <Form.Field required={ !this.props.disabled } readOnly={ this.props.disabled } width={ 16 }
                control={ FileChooserSegment } label='Receipts' name='files' files={ this.state.files } multiple
                ui={ this.state.ui } onUIStateChange={ this.handleUIStateChange.bind(this) }
                onChange={ this.handleReceiptsChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.receipts }
            </Message>
          </Segment>
        </Segment.Group>
      </EditDialog>
    );
  }
}

AccommodationEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  accommodation: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

function mapStoreStateToProps(storeState) {
  return {
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(AccommodationEditDialog);
