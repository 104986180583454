import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Button } from 'semantic-ui-react';

import { getAdminAnnualSubmissions, getWorkSiteRescues } from '../../api/administratorAPI';
import { getFireDepartmentSurveys, getFireDepartments } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_ANNUAL_SUBMISSIONS_UI } from '../../js/actionTypes';
import { SHORT_MONTHS } from '../../js/lists';
import { DATE_YEAR, DATE_SHORT_MONTH } from '../../js/constants';

import { formatDateTime } from '../../utils/date';
import { sort, downloadArrayBuffer, xlsxAutoWidth } from '../../utils/utils';

import ViewLoader from '../../components/ViewLoader';
import SortTable from '../../components/SortTable';
import IconButton from '../../components/IconButton';
import SearchableFilter from '../../components/SearchableFilter';
import CurrencyText from '../../components/CurrencyText';

import MonthlyTableDialog from '../../dialogs/MonthlyTableDialog';

import ListView from '../ListView';

import * as ExcelJS from 'exceljs/dist/exceljs';

class AdminReportAnnualSubmissions extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: 'fdName',
        sortDescending: false,
      },

      editing: {},
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    if (this.filterObjYrs !== undefined) {
      this.filterObjYrs.setManyYears(()=> {
        this.fetch();
        this.isClean();
      });
    }
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    if (!this.props.fireDepartments || this.props.fireDepartments.length === 0) {
      await getFireDepartments();
    }
    await getAdminAnnualSubmissions(null, null, null, null, this.filterObjYrs.getSelectedText());
    this.sumTotals();
    console.log(this.props);
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_ANNUAL_SUBMISSIONS_UI });
    });
  }

  //Filter support
  handleFilterChange(filter,value) { this.fetch(); }

  //UI
  safeNum(value) { return (value !== undefined && !isNaN(parseFloat(value)) ? parseFloat(value) : 0); }
  sumTotals() {
    let totalFd = 0, totalTrainer = 0, totalSurvey = 0, totalTraining = 0;
    for (const sub of this.props.annualSubmissions) {
      totalFd = totalFd + this.safeNum(sub.firedeptstipendamount);
      totalTrainer = totalTrainer + this.safeNum(sub.trainersstipendamount);
      totalSurvey = totalSurvey + this.safeNum(sub.sitesurveyreimbamount);
      totalTraining = totalTraining + this.safeNum(sub.trainerskillstrainingamount);
    }
    this.setState({ totalFd, totalTrainer, totalSurvey, totalTraining });
  }

  handleTrainingClick = (submission) => {
    this.setState({ editing: { training: submission } });
  }

  handleRescuesClick = async (submission) => {
    this.setState({ loading: 'Loading' });

    await getWorkSiteRescues(submission.id, submission.timestamp.substr(0, 4), true);

    submission.rescues = {};

    SHORT_MONTHS.forEach(mon => {
      submission.rescues[mon] = 0;
    });

    this.props.rescues.forEach(rescue => {
      // Increment the count for the survey's month
      submission.rescues[formatDateTime(rescue.timestamp, DATE_SHORT_MONTH)]++;
    });

    this.setState({
      loading: false,
      editing: { rescues: submission }
    });
  }

  handleSurveysClick = async (submission) => {
    this.setState({ loading: 'Loading' });

    await getFireDepartmentSurveys({ status: 'Completed', fdid: submission.id, year: submission.timestamp.substr(0, 4), shift: true });

    submission.surveys = {};

    SHORT_MONTHS.forEach(mon => {
      submission.surveys[mon] = 0;
    });

    this.props.surveys.forEach(survey => {
      // Increment the count for the survey's month
      submission.surveys[formatDateTime(survey.timestamp, DATE_SHORT_MONTH)]++;
    });

    this.setState({
      loading: false,
      editing: { surveys: submission }
    });
  }

  closeDialog = () => { this.setState({ editing: {} }); }

  handleExportXLSX() {
    this._exportToXLSX();
  }

  render() {
    return (
      <div className='fire-department-report-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={6} floated='left'>
            <Header size='medium'>Reports - Annual Submissions</Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingBottom: 0}} width={8} floated='right'>
            <Button primary disabled={!this.props.annualSubmissions || this.props.annualSubmissions.length === 0} onClick={this.handleExportXLSX.bind(this)}>Export</Button>
            <SearchableFilter ref={(r)=>this.filterObjYrs=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={new Date().getFullYear()}/>
          </Grid.Column>
          <Grid.Column width={15}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              let annualSubmissions = sort(this.props.annualSubmissions, this.state.ui.sortField);

              console.log("submissions:", annualSubmissions)

              if (annualSubmissions && !annualSubmissions.length) {
                return <Message info size='large'>
                  No Annual Submissions for the year.
                </Message>;
              }

              const center = { textAlign: 'center' };

              if (this.state.ui.sortDescending) { annualSubmissions.reverse(); }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',                     title: 'Submission Date',        width: '3' },
                { field: 'year',                         title: 'Year',                   width: '1' },
                { field: 'status',                       title: 'Status',                 width: '2' },
                { field: 'fdName',                       title: 'Fire Department',        width: '4', style: {...center}},
                { field: 'firedeptstipendamount',        title: 'FD Stipend',             width: '2', style: {...center}},
                { field: 'trainersstipendamount',        title: 'Instructor Stipend',     width: '2', style: {...center}},
                { field: 'sitesurveyreimbamount',        title: 'Crane Surveys',          width: '2', style: {...center}},
                { field: 'trainerskillstrainingamount',  title: 'Training Skills',        width: '2', style: {...center}},
                { field: 'buttons', noSort: true,        title: '',                       width: '2' },
              ]}
              footers={[
                [ null, 
                  null, 
                  null, 
                  null, 
                  <CurrencyText value={ this.state.totalFd }/>, 
                  <CurrencyText value={ this.state.totalTrainer }/>, 
                  <CurrencyText value={ this.state.totalSurvey }/>, 
                  <CurrencyText value={ this.state.totalTraining }/>, 
                  null ]
              ]}>
                { annualSubmissions.map((submission, index) => (
                  <SortTable.Row key={ submission.id + "" + index }>
                    <SortTable.Cell>{ submission.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ submission.timestamp.substr(0, 4) }</SortTable.Cell>
                    <SortTable.Cell>{ submission.status }</SortTable.Cell>
                    <SortTable.Cell>{ submission.fdName }</SortTable.Cell>
                    <SortTable.Cell {...center}><CurrencyText value={ this.safeNum(submission.firedeptstipendamount) }/></SortTable.Cell>
                    <SortTable.Cell {...center}><CurrencyText value={ this.safeNum(submission.trainersstipendamount) }/></SortTable.Cell>
                    <SortTable.Cell {...center}><CurrencyText value={ this.safeNum(submission.sitesurveyreimbamount) }/></SortTable.Cell>
                    <SortTable.Cell {...center}><CurrencyText value={ this.safeNum(submission.trainerskillstrainingamount) }/></SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      <IconButton name='life ring' color='green' style={{ marginRight: '0.3em' }}
                        popup='View work rescues per month'
                        onClick={ this.handleRescuesClick.bind(this, submission) }
                      />
                      <IconButton name='clock' color='green' style={{ marginRight: '0.3em' }}
                        popup='View training hours per month'
                        onClick={ this.handleTrainingClick.bind(this, submission) }
                      />
                      <IconButton name='file text' color='green' style={{ marginRight: '0.3em' }}
                        popup='View crane surveys per month'
                        onClick={ this.handleSurveysClick.bind(this, submission) }
                      />
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.state.editing.rescues &&
              <MonthlyTableDialog open={ !!this.state.editing.rescues } label='Work Rescues' header={ this.state.editing.rescues.fdName }
                onClose={ this.closeDialog } data={ this.state.editing.rescues.rescues }
              />
            }
            { this.state.editing.training &&
              <MonthlyTableDialog open={ !!this.state.editing.training } label='Training Conducted' header={ this.state.editing.training.fdName }
                onClose={ this.closeDialog } data={ this.state.editing.training.traininghours }
              />
            }
            { this.state.editing.surveys &&
              <MonthlyTableDialog open={ !!this.state.editing.surveys } label='Crane Surveys' header={ this.state.editing.surveys.fdName }
                onClose={ this.closeDialog } data={ this.state.editing.surveys.surveys }
              />
            }
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  /* Private XLSX */
  async _exportToXLSX() {
    if (!this.props.annualSubmissions || this.props.annualSubmissions.length <= 0) return;
    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');
    // Generate XLSX header
    ws.addRow(['Submission Date', 'Status', 'Fire Department', 'FD Stipend', 'Instructor Stipend', 'Crane Surveys', 'Training skills']);
    // Generate XLSX rows
    for (const submission of this.props.annualSubmissions) {
      ws.addRow([
        submission.displayDate, submission.status, submission.fdName, this.safeNum(submission.firedeptstipendamount), this.safeNum(submission.trainersstipendamount), this.safeNum(submission.sitesurveyreimbamount), this.safeNum(submission.trainerskillstrainingamount)
      ]);
    }
    // Auto width
    xlsxAutoWidth(ws, 75);
    // Generate buffer and save it
    const buffer = await wb.xlsx.writeBuffer();
    downloadArrayBuffer(buffer, `site-surveys-report`, 'xlsx');
  }
}

function mapStoreStateToProps(storeState) {
  return {
    annualSubmissions: storeState.models.fireDepartmentAnnualSubmissions,
    surveys: storeState.models.fireDepartmentSurveys,
    rescues: storeState.models.workSiteRescues,
    fireDepartments: storeState.lookups.fireDepartments,
  };
}

export default connect(mapStoreStateToProps)(AdminReportAnnualSubmissions);
