export const PATH_HOME = '/';

// Admin

export const PATH_ADMIN = 'admin';

export const PATH_USERS = 'users';
export const PATH_REPORTS = 'reports';
export const PATH_EMAILS = 'emails';
export const PATH_CUSTOM_BALLOTS = 'customBallots';
export const PATH_SETTINGS = 'settings';
export const PATH_COMMITTEE = 'committee';
export const PATH_COST_CENTERS = 'costCenters';
export const PATH_JOINT_TRAINING = 'jointTraining';
export const PATH_PPE = 'ppe';
export const PATH_TR = 'tr'; // Trainer requests
export const PATH_VOTE = 'vote'; // Voting
export const PATH_CRANE = 'crane'; // Crane
export const PATH_EQUIVALENCY = 'equivalency';
export const PATH_REGISTRATIONS = 'registrations';
export const PATH_OPERATOR_REIMBURSEMENT = 'operatorReimbursement';
export const PATH_SUMMARY = 'summary';

export const EXTERNAL_PATH_FD_GUIDELINES = "http://www.bccsa.ca/";
export const EXTERNAL_PATH_FD_SOP = "https://www.bccsa.ca/THARRP.html";
export const EXTERNAL_PATH_FD_ADD_TRAINER_GUIDELINES = "https://www.bccsa.ca/_customelements/uploadedResources/FDInstructorReplacement.pdf";
export const EXTERNAL_PATH_FIN1 = "https://www.bccsa.ca/_customelements/uploadedResources/FIN1EquipmentReimbursementGuideline.pdf";
export const EXTERNAL_PATH_FIN2 = "https://www.bccsa.ca/_customelements/uploadedResources/FIN2ReimbursementGuidelinesRateTable.pdf";
export const EXTERNAL_PATH_FIN3 = "https://www.bccsa.ca/_customelements/uploadedResources/FIN3InstructorReplacement.pdf";
export const EXTERNAL_PATH_FIN4 = "https://www.bccsa.ca/_customelements/uploadedResources/FIN4AnnualSubmission.pdf";
export const ADM3EQUIVALENCIES = "https://www.bccsa.ca/_customelements/uploadedResources/ADM3Equivalencies.pdf";
export const EXTERNAL_PATH_TRG1 = "https://www.bccsa.ca/_customelements/uploadedResources/TRG1TrainingProviderRequirements.pdf";
export const EXTERNAL_PATH_TRG2 = "https://www.bccsa.ca/_customelements/uploadedResources/TRG2CraneSites.pdf";
export const EXTERNAL_PATH_ADM2 = "https://www.bccsa.ca/_customelements/uploadedResources/ADM2Responsibility.pdf";

// User
export const PATH_USER = 'user';
export const PATH_USER_RESET = 'userReset';

// Construction
export const PATH_CONSTRUCTION = 'cc';

export const PATH_COMPANY = 'company';
export const PATH_SURVEY = 'survey';


// Fire Department
export const PATH_FIRE_DEPARTMENT = 'fireDepartment';

export const PATH_SITE_SURVEY = 'siteSurvey';
export const PATH_REIMBURSEMENTS = 'reimbursement';
export const PATH_TRAINING_EXPENSES = 'trainingExpenses';
export const PATH_RESCUE = 'rescue';
export const PATH_ANNUAL = 'annualSubmission';
export const PATH_SPECIAL_PAYMENTS = 'specialPayment';
export const PATH_FINANCIAL_SUMMARY = 'financialSummary';
export const PATH_FINANCIAL = 'financial';
export const PATH_DECLARATION = 'declaration';

export const PATH_TRAINER = 'trainer';
export const PATH_TRAINER_EQUIVALENCY = 'trainerEquivalency';
export const PATH_INCREASE = 'increase';

// Training Agency
export const PATH_TRAINING_AGENCY = 'trainingAgency';

// Common
export const PATH_DUPLICATE = 'duplicate';
export const PATH_NEW = 'new';
export const PATH_ID = 'id';
export const PATH_TIMESTAMP = 'timestamp';
export const PATH_YEAR_MONTH = 'yearmonth';

export const PATH_PROFILE = 'profile';
export const PATH_FILE = 'file';
