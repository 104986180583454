import React from 'react';

import { Segment, Form, Message } from 'semantic-ui-react';

import EditDialog from '../components/EditDialog';
import FileChooserSegment from '../components/FileChooserSegment';
import ViewLoader from '../components/ViewLoader';

import { updateFireDepartmentTrainer } from '../api/fireDepartmentAPI';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  uploading: false,
  files: [],

  errors: {
    error: false
  }
};

class CertificateEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    if (this.props.course.files) {
      this.state.files = this.props.course.files;
    }
  
    this.didChange = this.didChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleFilesChange = (state) => {
    console.log(this.state);
    let newState = { ...state };

    if (newState.files) {
      newState.certificate = newState.files.length === 1 ? newState.files[0].stringify() : '';
    }
    newState.hasChanges = true;

    this.setState(newState, () => { this.validate(); });
  }

  didChange = () => {
    return !!this.state.hasChanges;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.files.length) {
      errors.error = true;
      errors.files = 'Please attach a certificate.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = async () => {
    this.setState({ uploading: true });
    let files = [ ...this.state.files ];
    let coursename = this.props.course.coursename;;

    let success = await this.uploadFiles(files);
    this.state.certificate = this.state.files.map((file) => { return file.stringify(); });
    let updatedTrainer = this.props.trainer;
    updatedTrainer.courses = this.props.trainer.courses.map((course) => { 
      if (course.coursename === coursename) return { ...course, files: this.state.files, certificate: this.state.certificate };
      return { ...course };
    });
    if (success) success = await updateFireDepartmentTrainer(updatedTrainer);
    if (success) this.props.onDidSave();
    this.setState({ uploading: false });
    this.props.onDidSave();
  }

  uploadFiles = async (toUpload, componentName) => {
    let success = true;

    for (const uploadFile of toUpload) {
      if (!success) return;

      success = await uploadFile.upload({
        onUploadProgress: (percent) => {
          this.setState({
            progressPercent: percent,
            progressText: uploadFile.name
          });
        }
      });

      if (!success) {
        let errors = { error: true };
        errors[componentName] = `Failed to upload ${ uploadFile.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      }
    }

    return success;
  }

  render = () => {
    const { onDidSave, onClose, isadmin, ...props } = this.props;
    delete props.dispatch;
    return (
      <EditDialog size='small' header='Update training certificate' onSave={ this.onSave } onClose={ onClose } readOnly={ this.props.disabled }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } isNotSubmit { ...props }
      >
        <Segment.Group size='large'>
          <Segment disabled={ this.props.disabled || this.state.uploading }>
            <Form.Group>
              <Form.Field required width={ 16 }
                label='Certificate' name='files'
                control={ FileChooserSegment } files={ this.state.files } withInput hideDownload disallowDeletion
                onChange={ this.handleFilesChange } disabled={ this.props.disabled || this.state.uploading }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.files }
            </Message>
          </Segment>
        </Segment.Group>
        {this.state.uploading && <ViewLoader loading={ this.state.uploading } percent={ this.state.progressPercent } text={ this.state.progressText }/>}
      </EditDialog>
    );
  }
}

CertificateEditDialog.propTypes = {
  onDidSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isadmin: PropTypes.bool,
};


export default CertificateEditDialog;
