import React from 'react';

import { Table } from 'semantic-ui-react';

import PropTypes from 'prop-types';

class SortTable extends React.Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
  }

  handleSort(e) {
    this.props.onSort({
      sortField: e.currentTarget.id,
      sortDescending: this.props.sortField !== e.currentTarget.id ? false : !this.props.sortDescending,
    });
  }

  render() {
    const { headers, sortField, sortDescending, onSort, children, footers, ...props } = this.props;

    return (
      <Table striped selectable sortable fixed singleLine compact='very' { ...props }>
        <Table.Header>
          <Table.Row>
            {
              headers.map(header => {
                let sorted = header.field === sortField ? (sortDescending ? 'descending' : 'ascending') : null;
                return (
                  <Table.HeaderCell id={ header.field } key={ header.field } content={ header.title } width={ header.width }
                    style={ header.style } sorted={ sorted } onClick={ header.noSort ? null : this.handleSort }
                  />
                );
              })
            }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { children }
        </Table.Body>
        { footers &&
          <Table.Footer>
            {
              footers.map((footer, row) => {
                return (
                  <Table.Row id={ `footer_${ row }` } key={ `footer_${ row }` }>
                    {
                      footer.map((cell, col) => {
                        return (
                          <Table.HeaderCell id={ `footer_${ row }_${ col }` } key={ `footer_${ row }_${ col }` }
                            content={ cell }
                          />
                        );
                      })
                    }
                  </Table.Row>
                );
              })
            }
          </Table.Footer>
        }
      </Table>
    );
  }
}

SortTable.Row = Table.Row;
SortTable.Cell = Table.Cell;

SortTable.propTypes = {
  // Array of objects with field, title, noSort, style fields
  headers: PropTypes.array.isRequired,
  // Current sorted field: this should be a from a state.ui object
  sortField: PropTypes.string.isRequired,
  // Current sorted direction: this should be a from a state.ui object
  sortDescending: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  children: PropTypes.node,
  footers: PropTypes.array,
};

export default SortTable;
