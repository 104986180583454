import React from 'react';

import { Segment, Header, Message } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import AddButton from './AddButton';
import CurrencyText from './CurrencyText';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import SortTable from './SortTable';

import { DATE_SHORT_MONTH_DAY_YEAR } from '../js/constants';

import { formatDateTime, sortableDateTime } from '../utils/date';
import { sort } from '../utils/utils';

class AirTravelSegment extends React.Component {
  updateUIState = (uiState) => {
    this.props.onUIStateChange({ airtravel: uiState });
  }

  render = () => {
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Air Travel Expenses</Header.Content>
          <AddButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'airtravel', {}) }/>
        </Header>

        {(() => {
          if (this.props.airtravel.length === 0) {
            return <Message info compact size='tiny'>No air travel expenses</Message>;
          }

          let airtravels = this.props.airtravel.map(at => {
            let airtravel = { ...at };
            airtravel.sortableDepartureDate = sortableDateTime(airtravel.departuredate);
            airtravel.displayDepartureDate = formatDateTime(airtravel.departuredate, DATE_SHORT_MONTH_DAY_YEAR);
            airtravel.sortableReturnDate = sortableDateTime(airtravel.returndate);
            airtravel.displayReturnDate = formatDateTime(airtravel.returndate, DATE_SHORT_MONTH_DAY_YEAR);
            airtravel.amount = airtravel.fare + airtravel.gst;
            return airtravel;
          });

          airtravels = sort(airtravels, this.props.ui.sortField);
          if (this.props.ui.sortDescending) {
            airtravels.reverse();
          }

          const right = { textAlign: 'right' };

          return <SortTable collapsing sortField={ this.props.ui.sortField } sortDescending={ this.props.ui.sortDescending }
            onSort={ this.updateUIState } size='small'
            headers={[
              { field: 'sortableDepartureDate', title: 'Depart',  width: '3', style: right },
              { field: 'sortableReturnDate',    title: 'Return',  width: '3', style: right },
              { field: 'carrier',               title: 'Carrier', width: '5'               },
              { field: 'amount',                title: 'Fare',    width: '3', style: right },
              { field: 'buttons', noSort: true, title: '',        width: '2'               },
            ]}
            footers={[
              [ null, null, <div style={ right }><strong>Total Before Tax:</strong></div>, <CurrencyText value={ this.props.totalairtravelamount } style={ right }/>, null ],
              [ null, null, <div style={ right }><strong>GST/Taxes:</strong></div>, <CurrencyText value={ this.props.totalairtravelgst } style={ right }/>, null ],
              [ null, null, <div style={ right }><strong>Total After Tax:</strong></div>, <CurrencyText value={ this.props.totalairtravelaftertax } style={ right }/>, null ]
            ]}
          >
            { airtravels.map((airtravel, index) => (
              <SortTable.Row key={ index } id={ `airtravel_${ airtravel.timestamp }`}>
                <SortTable.Cell textAlign='right'>{ airtravel.displayDepartureDate }</SortTable.Cell>
                <SortTable.Cell textAlign='right'>{ airtravel.displayReturnDate }</SortTable.Cell>
                <SortTable.Cell>{ airtravel.carrier }</SortTable.Cell>
                <SortTable.Cell textAlign='right'><CurrencyText value={ airtravel.fare }/></SortTable.Cell>
                <SortTable.Cell textAlign='right'>
                  <EditButton view={ this.props.disabled } onClick={ this.props.edit.bind(this, 'airtravel', airtravel) }/>
                  <DeleteButton hide={ this.props.disabled } content='Delete the air travel expense?' onConfirm={ this.props.remove.bind(this, 'airtravel', airtravel) }/>
                </SortTable.Cell>
              </SortTable.Row>
            ))}
          </SortTable>;
        })()}
      </Segment>
    );
  }
};

AirTravelSegment.propTypes = {
  airtravel: PropTypes.array.isRequired,
  totalairtravelamount: PropTypes.number.isRequired,
  totalairtravelgst: PropTypes.number.isRequired,
  totalairtravelaftertax: PropTypes.number.isRequired,
  ui: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onUIStateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AirTravelSegment;
