import React from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import { Header, Loader, Button, Modal, Icon } from 'semantic-ui-react';

export default class ConstructionCraneSurveyAckModal extends React.Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {loaded: false, visible: false};
  }
  //Public
  show() {
    this.setState({visible: true});
  }

  //Actions
  handleCheckmark(e) {
    this.setState({visible: false}, () => {
      if (this.props.handleSubmit) this.props.handleSubmit(e);
    });
  }
  handleCancel() {
    this.setState({visible: false});
  }
  onDocumentLoad() {
    this.setState({loaded: true});
  }

  render() {
    return (
      <Modal open={this.state.visible} basic size='small'>
        <Header content='Acknowledgement'/>
        <Modal.Content>
          {!this.state.loaded && <Loader>Loading</Loader>}
          <Document file={process.env.PUBLIC_URL + "/documents/ADM2PortalContent.pdf"}
                    onLoadSuccess={this.onDocumentLoad.bind(this)}>
            <Page pageNumber={1}/>
          </Document>
        </Modal.Content>
        <Modal.Actions>
          { this.props.isViewOnly &&
            <Button basic color='blue' inverted onClick={this.handleCancel.bind(this)}>
                <Icon name='checkmark'/> Close
            </Button>}
          { !this.props.isViewOnly &&
            <Button basic color='green' inverted disabled={!this.state.loaded} onClick={this.handleCheckmark.bind(this)}>
              <Icon name='checkmark'/> Agree
            </Button>}
        </Modal.Actions>
      </Modal>
    );
  }
}
