import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon } from 'semantic-ui-react';

import { getAdminEquivalencies, deleteAdminEquivalency } from '../../api/administratorAPI';
import { getFireDepartments, postFireDepartmentTrainerEquivalency } from '../../api/fireDepartmentAPI';

import { UPDATE_TUS_UI } from '../../js/actionTypes';
import { PATH_ADMIN, PATH_EQUIVALENCY } from '../../js/paths';
import { REIMBURSEMENT_COURSES } from '../../js/lists';

import { sort } from '../../utils/utils';

import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';
import DeleteButton from '../../components/DeleteButton';
import AddButton from '../../components/AddButton';

import EquivalencyEditDialog from '../../dialogs/EquivalencyEditDialog';

import EditView from '../EditView';

class AdminTUs extends EditView {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      title: 'Prior Training Requests',

      editing: {},
      courses: [],
      exitPage: `/${ PATH_ADMIN }/${ PATH_EQUIVALENCY }`,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateUIState = this.updateUIState.bind(this);
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getAdminEquivalencies('Pending');
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_TUS_UI, adminTUs: this.state.ui });
    });
  }

  startEditing = async (componentName, entry) => {
    await getFireDepartments(); //reload lookup just in case..
    let fdlist = this.props.fireDepartments.map((fd) => { return { text: fd.name, value: fd.id, key: fd.id  } });
    // Build a list of available courses
    let courselist = Object.keys(REIMBURSEMENT_COURSES).map((key) => { return { key: key, value: key, text: REIMBURSEMENT_COURSES[key] }});
    // Remove update
    courselist = courselist.filter(course => { return course.key !== 'update' });
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing, courselist, fdlist: fdlist });
  }

  stopEditing = () => {
    this.setState({ editing: {}}, );
  }

  handleNew = async (componentName, entry) => {
    this.stopEditing();
    let newCourses = this.state.courses.filter(item => {
      return entry.coursename !== item.coursename
    });
    newCourses.push(entry);
    this.setState({ courses: newCourses, trid: entry.trid }, () => { this.onChange(); });
    if (this.isValid()) {
      const data = this.getData();
      data.courses = newCourses;
      data.fdid = entry.fdid;
      data.trid = entry.trid;
      for (const course of data.courses) {
        delete course.fdid;
        delete course.trid;
        delete course.trainerlist;
        delete course.courselist;
      }
      console.log(data);
      await this.update(data);
    }
    await this.fetch();
  }

  remove = async (equivalency) => {
    this.setState({ loading: 'Removing' });
    await deleteAdminEquivalency(equivalency.id, equivalency.timestamp, equivalency.courses, equivalency.trainername);
    this.setState({ loading: false });
  }
  handleRemove = async (equivalency) => {
    await this.remove(equivalency);
    await this.fetch();
  }

  uploadFiles = async (files) => {
    let success = true;

    for (const file of files) {
      if (!success) return;

      success = await file.upload({
        onUploadProgress: (percent) => {
          this.setState({
            progressPercent: percent,
            progressText: file.name
          });
        }
      });

      if (!success) {
        let errors = { error: true };
        errors.courses = `Failed to upload ${ file.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      }
    }

    return success;
  }

  updateFiles = async (data) => {
    let files = [];
    data.courses.forEach((course) => {
      if (course.files) { files = [ ...files, ...course.files ]; }
    });

    let success = await this.uploadFiles(files);

    if (files.length > 0) {
      // Update the uploaded state
      data.courses.forEach((course) => {
        course.certificate = course.files.map((file) => { return file.stringify(); });
      });
      this.setState({ courses: data.courses });
    }

    return success;
  }

  update = async (dataIn) => {
    this.setState({ loading: 'Uploading Files' });

    const success = await this.updateFiles(dataIn);

    if (success) {
      this.setState({ loading: 'Submitting' });
      await postFireDepartmentTrainerEquivalency(dataIn);
      this.exit();
    }

    this.setState({ loading: false });
  }

  render() {
    return (
      <div className='admin-prior-training-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>
              <Header.Content>{ this.state.title }</Header.Content>
              <AddButton onClick={ this.startEditing.bind(this, 'equivalency', {}) }/>
            </Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (this.props.equivalencies && !this.props.equivalencies.length) {
                return <Message info size='large'>
                  You don&apos;t have any pending prior training requests.
                </Message>;
              }

              let equivalencies = sort(this.props.equivalencies, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                equivalencies.reverse();
              }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',              title: 'Submission Date', width: '2' },
                { field: 'fdName',                title: 'Fire Department', width: '5' },
                { field: 'trainername',           title: 'Instructor',      width: '5' },
                { field: 'status',                title: 'Status',          width: '4' },
                { field: 'buttons', noSort: true, title: '',                width: '1' },
              ]}>
                { equivalencies.map((equivalency) => (
                  <SortTable.Row key={ equivalency.key }>
                    <SortTable.Cell>{ equivalency.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ equivalency.fdName }</SortTable.Cell>
                    <SortTable.Cell>{ equivalency.trainername }</SortTable.Cell>
                    <SortTable.Cell>{ equivalency.status }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                    { equivalency.status === 'Pending' &&
                      <EditButton pathname={ `/${ PATH_ADMIN }/${ PATH_EQUIVALENCY }/${ equivalency.id }/${ equivalency.timestamp }` }/>
                    }
                    { equivalency.status === 'Approved' &&
                      <Icon name='clipboard check' size='large' color='green' />
                    }
                    { equivalency.status === 'Rejected' &&
                      <Icon name='clipboard' size='large' color='red' />
                    }
                    {
                      <DeleteButton hide={ this.props.disabled } content='Delete the course equivalency request?' onConfirm={ this.handleRemove.bind(this, equivalency) }/>
                    }
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.renderEditDialog() }
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderEditDialog = () => {
    if (this.state.editing.equivalency) {
      return (
        <EquivalencyEditDialog isadmin={ true } open={ !!this.state.editing.equivalency }
          equivalency={ this.state.editing.equivalency }
          courselist={ this.state.courselist } courses={ this.state.courses }
          fdlist={ this.state.fdlist }
          onSave={ this.handleNew } onClose={ this.stopEditing }
        />
      );
    }

    return null;
  }
}

function mapStoreStateToProps(storeState) {
  return {
    equivalencies: storeState.models.fireDepartmentTrainerEquivalencies,
    fireDepartments: storeState.lookups.fireDepartments,
    ui: storeState.ui.adminTUs,
    trainers: storeState.models.fireDepartmentTrainers,
  };
}

export default connect(mapStoreStateToProps)(AdminTUs);
