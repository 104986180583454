import React from 'react';

import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import IconButton from './IconButton';

class ViewButton extends React.Component {
  render() {
    const { pathname, className, ...props } = this.props;

    const button = (
      <IconButton name={ 'table' } color='blue' className={ 'view-button ' + (className || '') } { ...props }/>
    );

    return pathname ? <NavLink to={ pathname }>{ button }</NavLink> : button;
  }
};

ViewButton.propTypes = {
  pathname: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ViewButton;
