import React from 'react';

import { Form, Message } from 'semantic-ui-react';

import EditDialog from '../components/EditDialog';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  rejectreason: '',

  errors: {
    error: false
  }
};

class RejectReasonEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.didChange = this.didChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  didChange = () => {
    return this.state.rejectreason !== '';
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.rejectreason) {
      errors.error = true;
      errors.rejectreason = 'Please enter a rejection reason.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    this.props.onSave(this.state.rejectreason);
  }

  render = () => {
    const { onSave, onClose, ...props } = this.props;

    return (
      <EditDialog header='Please Enter a Rejection Reason' size='small' saveText='Reject' closeText='Cancel'
        onSave={ this.onSave } onClose={ onClose }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Form.Group>
          <Form.TextArea autoFocus value={ this.state.rejectreason } name='rejectreason' width={ 16 } onChange={ this.handleChange } />
        </Form.Group>
        <Message error>
          { this.state.errors.rejectreason }
        </Message>
      </EditDialog>
    );
  }
}

RejectReasonEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RejectReasonEditDialog;
