import React from 'react';
import { connect } from 'react-redux';

import { Segment, Form, Message } from 'semantic-ui-react';

import { getFireDepartmentTrainers, getFireDepartmentTrainerEquivalencies } from '../api/fireDepartmentAPI';

import DateControl from '../components/DateControl';
import EditDialog from '../components/EditDialog';
import FileChooserSegment from '../components/FileChooserSegment';

import { DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { REIMBURSEMENT_COURSES } from '../js/lists';

import { today } from '../utils/date';
import ChosenFile from '../utils/file';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  coursename: '',
  dateattended: today(),
  certificate: '',
  fdid: '',
  trid: '',

  files: [],

  errors: {
    error: false
  }
};

class EquivalencyEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, ...this.props.equivalency };

    this.state.files = [];

    if (this.state.certificate) {
      let file = new ChosenFile();
      file.parse(this.state.certificate);
      if (file.id) {
        this.state.files.push(file);
      }
    }

    this.didChange = this.didChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleCourseChange = (event, { name, value }) => {
    this.setState({ [name]: value }, () => { this.validate(); });
  }

  handleFDChange = async (event, { name, value }) => {
    await getFireDepartmentTrainers(value, 'A');
    let trainerlist = this.props.trainers.map((t) => { return { text: t.trainername, value: t.trainerId, key: t.trainerId  } });
    this.setState({ [name]: value, trainerlist }, () => { this.validate(); });
  }

  handleInstructorChange = async (event, { name, value }) => {
    await getFireDepartmentTrainerEquivalencies(value, null, true);
    const trainer = this.props.trainers.find(t => t.trainerId === value);

    // Build a list of available courses
    let courselist = Object.keys(REIMBURSEMENT_COURSES).map((key) => { return { key: key, value: key, text: REIMBURSEMENT_COURSES[key] }});
    // Remove update
    courselist = courselist.filter(course => { return course.key !== 'update' });
    // Remove completed equivalency courses
    this.props.equivalencies2.forEach(equivalency => {
      if (equivalency.status !== 'Rejected') {
        const coursekeys = equivalency.courses.map(course => { return course.coursename });
        courselist = courselist.filter(course => { return coursekeys.indexOf(course.key) === -1 });
      }
    });
    // Remove completed trainer courses
    const coursekeys = trainer.courses.map(course => { return course.coursename });
    courselist = courselist.filter(course => { return coursekeys.indexOf(course.key) === -1 });

    this.setState({ [name]: value, courselist }, () => { this.validate(); });
  }

  handleFilesChange = (state) => {
    let newState = { ...state };

    if (newState.files) {
      newState.certificate = newState.files.length === 1 ? newState.files[0].stringify() : '';
    }

    this.setState(newState, () => { this.validate(); });
  }

  handleDateChange = (name, value) => {
    this.setState({ [name]: value }, () => { this.validate(); });
  }

  didChange = () => {
    // Always true for new entries
    if (!this.props.equivalency.timestamp) return true;

    if (this.state.coursename !== this.props.equivalency.coursename) { return true; }
    if (this.state.dateattended !== this.props.equivalency.dateattended) { return true; }

    let toDelete = [];
    let toUpload = [];
    ChosenFile.syncFiles(this.props.equivalency.files || [], this.state.files, toDelete, toUpload);

    if (toDelete.length > 0 || toUpload.length > 0) { return true; }

    return false;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.coursename) {
      errors.error = true;
      errors.coursename = 'Please select a course. ';
    } else {
      // No duplicate courses
      const coursenames = this.props.courses.map(course => { return course.coursename });
      if (coursenames.indexOf(this.state.coursename) !== -1 && this.props.equivalency.coursename !== this.state.coursename) {
        errors.error = true;
        errors.coursename = `Training ${ REIMBURSEMENT_COURSES[this.state.coursename] } already exists for this instructor. `;
      }
    }

    if (!this.state.dateattended) {
      errors.error = true;
      errors.dateattended = 'Please choose a date.';
    }

    if (!this.state.files.length) {
      errors.error = true;
      errors.files = 'Please attach a certificate.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    const { ui, errors, ...state } = this.state;
    this.props.onSave('equivalency', { ...state });
  }

  render = () => {
    const { onSave, onClose, equivalency, courses, isadmin, ...props } = this.props;
    delete props.dispatch;
    return (
      <EditDialog size='small' header='Add Prior Training Request' onSave={ this.onSave } onClose={ onClose } readOnly={ this.props.disabled }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Segment.Group size='large'>
          <Segment disabled={ this.props.disabled }>
            <Form.Group>
              { this.props.isadmin &&
                <Form.Select required label='Fire Department' value={ this.state.fdid } name='fdid'
                options={ this.props.fdlist || [] } width={ 16 } onChange={ this.handleFDChange } 
                />
              }
            </Form.Group>
            <Form.Group>
              { this.props.isadmin &&
                <Form.Select disabled={ !this.state.fdid } required label='Instructor' value={ this.state.trid } name='trid'
                options={ this.state.trainerlist || [] } width={ 16 } onChange={ this.handleInstructorChange } 
                />
              }
            </Form.Group>
            <Form.Group>
              { this.props.disabled &&
                <Form.Input readOnly label='Course' value={ REIMBURSEMENT_COURSES[this.state.coursename] } width={ 8 }/>
              }
              { !this.props.disabled &&
                <Form.Select disabled={ this.props.isadmin && !this.state.trid } required label='Course' value={ this.state.coursename } name='coursename' options={ this.state.courselist || this.props.courselist } width={ 8 }
                  onChange={ this.handleCourseChange }
                />
              }
              <Form.Field disabled={ this.props.isadmin && !this.state.trid } readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 6 }
                label='Date Attended' name='dateattended'
                control={ DateControl } date={ this.state.dateattended } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                error={ !!this.state.errors.dateattended }
                onChange={ this.handleDateChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.coursename }{ this.state.errors.dateattended }
            </Message>
            <Form.Group>
              <Form.Field disabled={ this.props.isadmin && !this.state.trid } readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 16 }
                label='Certificate' name='files'
                control={ FileChooserSegment } files={ this.state.files } withInput
                onChange={ this.handleFilesChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.files }
            </Message>
          </Segment>
        </Segment.Group>
      </EditDialog>
    );
  }
}

EquivalencyEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  equivalency: PropTypes.object.isRequired,
  courselist: PropTypes.array.isRequired,
  courses: PropTypes.array.isRequired,
  fdlist: PropTypes.array,
  disabled: PropTypes.bool,
  isadmin: PropTypes.bool,
};

function mapStoreStateToProps(storeState) {
  return {
    trainers: storeState.models.fireDepartmentTrainers,
    equivalencies2: storeState.models.fireDepartmentTrainerEquivalencies2,
  };
}

export default connect(mapStoreStateToProps)(EquivalencyEditDialog);
