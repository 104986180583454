import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form, Message, Grid } from 'semantic-ui-react';

import { getAdminTR, changeAdminTR, getAdminWB, updateAdminWB } from '../../api/administratorAPI';
import {  } from '../../api/administratorAPI';
import { getFireDepartment, requestFireDepartmentTrainerIncrease } from '../../api/fireDepartmentAPI';

import { UPDATE_TR_UI } from '../../js/actionTypes';
import { ROLE_ADMIN, DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { PATH_ADMIN, PATH_TR, PATH_ID, PATH_TIMESTAMP, ADM3EQUIVALENCIES } from '../../js/paths';

import { today } from '../../utils/date';

import BallotSegment from '../../components/BallotSegment';
import DateControl from '../../components/DateControl';
import InfoButton from '../../components/InfoButton';
import NumberInput from '../../components/NumberInput';
import ExternalButton from '../../components/ExternalButton';

import BallotEditDialog from '../../dialogs/BallotEditDialog';

import EditView from '../EditView';

const INITIAL_STATE = {
  entity_name: '',
  maxallowedtrainers: 0,
  addnumberoftrainers: 0,
  timestamp: today(),
  checkstandards: false,
  comment: '',

  // UI

  ballots: [],

  editing: {},

  className: 'fire-department-trainer-increase',
  title: 'Additional Instructors',
};

class FireDepartmentTrainerIncrease extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.isAdmin = this.props.currentUser.role === ROLE_ADMIN;
    this.state.exitPage = this.state.isAdmin ? `/${ PATH_ADMIN }/${ PATH_TR }` : this.props.currentUser.homepage;

    this.state.ui = {
      ballots: {
        sortField: this.props.ui.ballots.sortField || 'voteoption',
        sortDescending: this.props.ui.ballots.sortDescending === true,
      },
    }

    this.cancel = this.cancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleUIStateChange = this.handleUIStateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  fetch = async () => {
    await getFireDepartment(this.state.isAdmin ? this.props.match.params[PATH_ID] : this.props.currentUser.orgId);
    if (this.state.isAdmin) {
      await getAdminTR(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
      if (this.props.adminTR.status !== 'Pending') {
        await getAdminWB('FN', this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
      }
    }
  }

  onLoad = () => {
    this.setState({
      entity_name: this.props.fireDepartment.entity_name,
      maxallowedtrainers: this.props.fireDepartment.maxallowedtrainers,
    });
    if (this.state.isAdmin) {
      this.setState({ ...this.props.adminTR });
      if (this.props.adminTR.status !== 'Pending') {
        this.setState({ ballots: this.props.adminWB.ballots || [] });
      }
    }
  }

  handleCheckboxChange = () => {
    this.setState({ checkstandards: !this.state.checkstandards }, () => { this.onChange(); });
  }

  startEditing = (componentName, entry) => {
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing });
  }

  stopEditing = () => {
    this.setState({ editing: {}});
  }

  handleComponentRemove = (componentName, entry) => {
    const ballots = this.state.ballots.filter(item => {
      return entry.memberemail !== item.memberemail
    });

    this.setState({ ballots: ballots }, () => { this.onChange() });
  }

  handleComponentEdit = (componentName, entry) => {
    this.stopEditing();

    let ballots = this.state.ballots.filter(item => {
      return entry.memberemail !== item.memberemail
    });
    ballots.push(entry);

    this.setState({ ballots: ballots }, () => { this.onChange() });
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.addnumberoftrainers) {
      errors.error = true;
      errors.addnumberoftrainers = 'Please enter an amount to increase the maximum allowed trainers.';
    }

    if (!this.state.checkstandards) {
      errors.error = true;
      errors.checkstandards = 'You cannot submit a request without this guarantee.';
    }

    if (!this.state.comment) {
      errors.error = true;
      errors.comment = 'Please enter a justification.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });

    if (this.state.isAdmin) {
      const { ballots, trBody } = data;
      await changeAdminTR(trBody);

      const wbBody = { ...this.props.adminWB, ballots: ballots };
      await updateAdminWB(wbBody);

      this.exit();
    } else {
      await requestFireDepartmentTrainerIncrease(data);
      if (this.props.fireDepartmentTrainerIncrease.id) {
        this.exit();
      }
    }

    this.setState({ loading: false });
  }

  handleUIStateChange(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_TR_UI, adminTR: this.state.ui });
    });
  }

  renderView = () => {
    const canEdit = !this.state.isAdmin || this.state.status === 'Voting';

    return (
      <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
        <Header size='medium'>
          <Grid columns='equal' verticalAlign='bottom'>
            <Grid.Column>
            <Header.Content>{ this.state.title }</Header.Content>
              <Header.Subheader>
                Review the Standard Operating Procedure(s) Related to this page
                <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ ADM3EQUIVALENCIES } label="ADM3" />
              </Header.Subheader>
            </Grid.Column>
            <Grid.Column>
            { canEdit &&
              <Form.Button floated='right' className='form-top-button' positive>Update</Form.Button>
            }
            <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>{ canEdit ? 'Cancel' : 'Close' }</Form.Button>
            </Grid.Column>
          </Grid>
        </Header>
        <Segment.Group size='large' className='view-segment-group'>
          { this.state.isAdmin && this.state.status !== 'Pending' &&
            <BallotSegment disabled={ !canEdit } ballots={ this.state.ballots }
              ui={ this.state.ui.ballots } onUIStateChange={ this.handleUIStateChange }
              edit={ this.startEditing } remove={ this.handleComponentRemove }
            />
          }
          <Segment disabled={ this.state.isAdmin }>
            <Form.Group>
              <Form.Input readOnly label='Department Name' value={ this.state.entity_name } width={ 16 } />
              <Form.Field readOnly label='Request Date' name='timestamp' width={ 4 }
                control={ DateControl } date={ this.state.timestamp } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly label='Maximum Allowed Trainers' value={ this.state.maxallowedtrainers } width={ 4 } />
              <Form.Field readOnly={ this.state.isAdmin } required={ !this.state.isAdmin }
                label='Increase Maximum By' value={ this.state.addnumberoftrainers } name='addnumberoftrainers' width={ 4 }
                control={ NumberInput } onChange={ this.handleNumberChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.addnumberoftrainers }
            </Message>
            <Form.Group>
              <div className="ui form">
                <div className="ui message">
                  <div className="header">Please provide a detailed justification for your request. Your answer must address the following:</div>
                  <ul className="list">
                    <li>Does the Fire Department foresee expected projects involving work by funding CU’s that justify the request for additional trainers?</li>
                    <li>Have there been sufficient geographic changes that may affect construction activities of the population served to justify this request.</li>
                    <li>Options for mutual aid or a regional rescue team have been explored with neighbouring municipalities.</li>
                    <li>The department has sufficient full-time staff to provide and maintain an effective technical high angle rope rescue service</li>
                    <li>The applicant guarantees that THARR training by approved instructors will meet current, applicable NFPA Standards</li>
                  </ul>
                </div>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.TextArea readOnly={ this.state.isAdmin } required={ !this.state.isAdmin }
                label={
                  <label>Justification
                    <InfoButton style={{ marginLeft: '0.5rem', marginRight: '-2.5rem' }} pathname={ this.props.settings.guidelineslocation } />
                  </label>
                }
                value={ this.state.comment } name='comment' width={ 16 }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.comment }
            </Message>
            <Form.Group>
              <Form.Checkbox readOnly={ this.state.isAdmin } style={{ fontSize: '1em' }} checked={ this.state.checkstandards } width={ 16 }
                label='The department guarantees that THARR training by approved instructors will meet the current applicable NFPA Standards'
                onChange={ this.handleCheckboxChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.checkstandards }
            </Message>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }

  renderAfterView = () => {
    if (this.state.editing.ballots) {
      return (
        <BallotEditDialog open={ !!this.state.editing.ballots } ballot={ this.state.editing.ballots }
          onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
        />
      );
    }

    return null;
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    fireDepartment: storeState.models.fireDepartment,
    adminTR: storeState.models.adminTR,
    fireDepartmentTrainerIncrease: storeState.models.fireDepartmentTrainerIncrease,
    settings: storeState.lookups.settings,
    adminWB: storeState.models.adminWB,
    ui: storeState.ui.adminTR,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentTrainerIncrease);
