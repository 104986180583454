import { Auth, API } from 'aws-amplify';
import '../js/auth';
import LogRocket from 'logrocket';

import { REQUESTS_BEGIN, REQUESTS_END, REQUESTS_ERROR } from '../js/actionTypes';

import store from '../js/store';

var requestCount = 0;

export function startRequest() {
  requestCount++;
  if (requestCount === 1) {
    store.dispatch({ type: REQUESTS_BEGIN });
  }
}

export function endRequest() {
  requestCount--;
  if (requestCount <= 0) {
    requestCount = 0;
    store.dispatch({ type: REQUESTS_END });
  }
}

async function sendRequest(request, apiName, path, options) {
  try {
    startRequest();

    let params = {};

    // options.noAuthRequired = true for non-authenticated calls
    if (!options || options.noAuthRequired !== true) {
      const auth = await Auth.currentSession();

      params.headers = {
        Authorization: 'Bearer ' + auth.idToken.jwtToken,
        ...(options ? options.headers || {} : {})
      };
    }

    if (options && options.body) {
      params.body = options.body;
    }

    const response = await request(apiName, path, params);

    endRequest();

    return response;
  } catch(err) {
    const apiError = handleAPIError(err);
    endRequest();

    if (apiError.pass) {
      throw apiError;
    }

    return [];
  }
}

export async function get(apiName, path, params) {
  return sendRequest((apiName, path, params) => {
    return API.get(apiName, path, params)
  }, apiName, path, params);
}

export async function post(apiName, path, body, options) {
  return sendRequest((apiName, path, params) => {
    return API.post(apiName, path, params)
  }, apiName, path, { body: body, ...options });
}

export async function put(apiName, path, body, options) {
  return sendRequest((apiName, path, params) => {
    return API.put(apiName, path, params)
  }, apiName, path, { body: body, ...options });
}

export async function del(apiName, path, body, options) {
  return sendRequest((apiName, path, params) => {
    return API.del(apiName, path, params)
  }, apiName, path, { body: body, ...options });
}

export function buildQuerystring(params) {
  console.log('*-*- received filters', params);
  let qs = Object.keys(params).map((key) => { return `${ encodeURIComponent(key) }=${ encodeURIComponent(params[key]) }` }).join('&');
  return qs ? `?${ qs }` : '';
}

export function handleAPIError(error) {
  let message = 'There was an error processing your request.';
  if (error.response && error.response.data && error.response.data.message) {
    message = error.response.data.message;
  } else if (error.response && error.response.data && error.response.data.err && typeof error.response.data.err === 'string') {
    message = error.response.data.err;
  } else if (error.message) {
    message = error.message;
  } else if (typeof error === 'string') {
    message = error;
  }
      
  //Log rocket
  LogRocket.captureException(error);
  LogRocket.captureMessage(`[${error.message}]`, { tags: { type: 'Error Message' }});  

  let method, path;
  if (error.response && error.response.config) {
    method = error.response.config.method ? error.response.config.method.toUpperCase() : '';
    path = (error.response.config.path ? error.response.config.path : error.response.config.url);
  } else if (error.config) {
    method = error.config.method ? error.config.method.toUpperCase() : '';
    path = error.config.path;
  }

  const status = error.response ? error.response.status : 'Unknown';

  // Some API errors need to be handled by the caller,
  // so look for those exceptions here and pass them on
  let pass = false;

  if (status === 400) {
    // Need a better way of doing this, ideally triggered by API response
    if (path && path.endsWith('reimbursement') && message && message.includes('already exists')) {
      pass = true;
    }
  }

  const apiError = {
    message: message,
    method: method,
    path: path,
    status: status,
    pass: pass,
  };

  if (!pass) {
    store.dispatch({ type: REQUESTS_ERROR, error: apiError });
  }

  return apiError;
}
