import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Segment, Form, Button } from 'semantic-ui-react';

import { getFireDepartmentTrainer, getFireDepartment } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_REPORT_FDTR_UI } from '../../js/actionTypes';
import { DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { PATH_ID, PATH_ADMIN, PATH_REPORTS, PATH_FIRE_DEPARTMENT, PATH_TIMESTAMP } from '../../js/paths';
import { REIMBURSEMENT_COURSES } from '../../js/lists';

import ChosenFile from '../../utils/file';
import { formatDateTime } from '../../utils/date';
import { sort } from '../../utils/utils';

import CurrencyText from '../../components/CurrencyText';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';
import IconButton from '../../components/IconButton';

import ListView from '../ListView';

class AdminReportFireDepartmentInstructor extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: 'dateattended',
        sortDescending: true,
      }
    };

    this.updateUIState = this.updateUIState.bind(this);
  }
  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartment(this.props.match.params[PATH_ID]);
    await getFireDepartmentTrainer(this.props.match.params[PATH_TIMESTAMP], undefined, this.props.match.params[PATH_ID]);
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REPORT_FDTR_UI });
    });
  }

  //Sum support
  sumReimbursements(trainer) {
    if (trainer.totalreimbursements !== undefined && trainer.totalreimbursements.length > 0) {
      let sum = 0;
      trainer.totalreimbursements.forEach(obj => {
        sum += (obj.totalamount !== undefined ? obj.totalamount : 0);
      }); return sum;
    } else return 0;
  }

  //back action
  goToList = () => {
    this.isClean();
    this.props.history.push(`/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }/${ this.props.match.params[PATH_ID] }`);
  }
  cancel = event => {
    event.preventDefault();
    // Back to the list
    this.goToList();
  }

  //download stuff
  handleIconClick = (file) => {
    if (file.uploaded) {
      this.download(file);
    }
  }
  download = async (file) => {
    const downloadUrl = await file.downloadUrl();

    if (downloadUrl) {
      window.open(downloadUrl);
    }
  }

  render() {
    let fd = this.props.fireDepartment;
    let trainer = this.props.trainer;
    //ui
    let fdName = (fd !== undefined && fd.entity_name ? fd.entity_name : "");
    let instructorName = (trainer !== undefined && trainer.name ? trainer.name : "");
    //
    return (
      <div className='fire-department-instructor-report-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={15} floated='left'>
            <Header size='medium'>
              <Header.Content>Report - Fire Department of {fdName} - Instructor {instructorName}</Header.Content>
              <Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Button>
            </Header>

          </Grid.Column>
          <Grid.Column width={15}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              let courses = sort(this.props.trainer.courses, this.state.ui.sortField);

              console.log("FD:", fd)
              console.log("TR:", trainer)

              if (!trainer) {
                return <Message info size='large'>
                  Instructor not found!
                </Message>;
              }

              if (this.state.ui.sortDescending) { courses.reverse(); }

              const right = { textAlign: 'right' };
              const center = { textAlign: 'center' };

              return (
              <Segment.Group size='large' className='view-segment-group'>
                <Segment>
                  <Header content='Instructor details' size='tiny'/>
                  <Form>
                    <Form.Group>
                      <Form.Input readOnly label='Name' value={ trainer.name } width={ 9 } />
                      <Form.Input readOnly label='Full certification date' value={ trainer.displayCertDate } width={ 3 } />
                      <Form.Input readOnly label='Status' value={ trainer.displayType } width={ 3 } />
                    </Form.Group>
                  </Form>
                </Segment>
                <Segment>
                  <Header content='Instructors course details' size='tiny' />
                  <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                      { field: 'coursename',               title: 'Course Name',                   width: '10' },
                      { field: 'dateattended',             title: 'Date Attended',                 width: '9' },
                      { field: 'displayType',noSort: true, title: 'Certificate',                   width: '2' },
                  ]} footers={[
                    [ null, <div style={ right }><strong>Total of reimbursements:</strong></div>, <CurrencyText value={ this.sumReimbursements(trainer) } style={ right }/>, null ]
                  ]}>
                    { courses.map((course, index) => {
                      let cert = new ChosenFile();
                      if (course.certificate && course.certificate[0]) { cert.parse(course.certificate[0]); }
                      return (
                        <SortTable.Row key={ course.coursename + "" + index }>
                          <SortTable.Cell>{ (!REIMBURSEMENT_COURSES[course.coursename] ? course.coursename : REIMBURSEMENT_COURSES[course.coursename]) }</SortTable.Cell>
                          <SortTable.Cell>{ (!course.dateattended ? "-" : formatDateTime(course.dateattended, DATE_FULL_MONTH_DAY_YEAR)) }</SortTable.Cell>
                          <SortTable.Cell {...center}>
                            <IconButton name={(!cert.uploaded ? 'ban' : 'cloud download')} color={(!cert.uploaded ? 'red' : 'green')} style={{ marginRight: '0.3em' }}
                              onClick={ this.handleIconClick.bind(this, cert) }/>
                          </SortTable.Cell>
                        </SortTable.Row>
                      );
                    })}
                  </SortTable>
                </Segment>
              </Segment.Group>);
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    fireDepartment: storeState.models.fireDepartment,
    trainer: storeState.models.fireDepartmentTrainer,
  };
}

export default connect(mapStoreStateToProps)(AdminReportFireDepartmentInstructor);
