import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Segment, Form, Button } from 'semantic-ui-react';

import { getFireDepartment, getFireDepartmentTrainers, updateFireDepartment, updateFireDepartmentTrainerStatus } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_REPORT_FD_UI } from '../../js/actionTypes';
import { DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { PATH_ID, PATH_ADMIN, PATH_REPORTS, PATH_FIRE_DEPARTMENT } from '../../js/paths';

import { formatDateTime } from '../../utils/date';
import { sort } from '../../utils/utils';
import ChosenFile from '../../utils/file';

import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';
import AddressInput from '../../components/AddressInput';
import EditButton from '../../components/EditButton';
import ActionButton from '../../components/ActionButton';
import CurrencyText from '../../components/CurrencyText';
import FileChooserSegment from '../../components/FileChooserSegment';

import EmailEditDialog from '../../dialogs/EmailEditDialog';

import EditView from '../EditView';

class AdminReportFireDepartment extends EditView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      applicationDocs: [],
      address: {},
      chief_name: '',
      contact_name: '',
      contact_email: '',
      other_emails: '',
      payable_to: '',
      main_telephone_number: '',
      main_telephone_ext: '',
      second_telephone_number: '',
      second_telephone_ext: '',
      fdWarning: '',
      ui : {
        sortField: 'displayCertDate',
        sortDescending: true,
      },
      errors: {},
      editing: {}
    };

    this.updateUIState = this.updateUIState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  async componentDidMount() {
    await this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartment(this.props.match.params[PATH_ID]);
    await getFireDepartmentTrainers(this.props.match.params[PATH_ID]);
    //parse app docs
    const applicationDocs = [];
    (this.props.fireDepartment.applicationDocs || []).forEach((_file) => {
      let file = new ChosenFile();
      file.parse(_file);
      if (file.id) applicationDocs.push(file);
    });
    this.onLoad();
    //
    this.setState({ loading: false, applicationDocs });
  }

  onLoad = ()=> {
    const address = {
      street: this.props.fireDepartment.address_street || '',
      number: this.props.fireDepartment.address_number || '',
      city: this.props.fireDepartment.address_city || '',
      province: this.props.fireDepartment.address_province || '',
      postalcode: this.props.fireDepartment.address_postalcode || '',
    };

    this.setState({
      id: this.props.fireDepartment.id || '',
      entity_name: this.props.fireDepartment.entity_name || '',
      chief_name: this.props.fireDepartment.chief_name || '',
      contact_name: this.props.fireDepartment.contact_name || '',
      contact_email: this.props.fireDepartment.contact_email || '',
      other_emails: this.props.fireDepartment.other_emails || '',
      payable_to: this.props.fireDepartment.payable_to || '',
      address: address,
      main_telephone_number: this.props.fireDepartment.main_telephone_number || '',
      main_telephone_ext: this.props.fireDepartment.main_telephone_ext || '',
      second_telephone_number: this.props.fireDepartment.second_telephone_number || '',
      second_telephone_ext: this.props.fireDepartment.second_telephone_ext || '',
      fdWarning: this.props.fireDepartment.fdWarning || '',
    });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REPORT_FD_UI });
    });
  }

  //back action
  goToList = () => {
    this.isClean();
    this.props.history.push(`/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }`);
  }

  cancel = event => {
    event.preventDefault();
    // Back to the list
    this.goToList();
  }

  //Sum support
  sumReimbursements(trainer) {
    if (trainer.totalreimbursements !== undefined && trainer.totalreimbursements.length > 0) {
      let sum = 0;
      trainer.totalreimbursements.forEach(obj => {
        sum += (obj.totalamount !== undefined ? obj.totalamount : 0);
      }); return sum;
    } else return 0;
  }

  getActiveTrainersCount() {
    let count = 0;
    for (let trainer of this.props.trainerList) if (trainer.type === 'A') count++;
    return count;
  }

  changeStatus = async (trid, status, reason) => {
    this.setState({ loading: 'Updating' });
    let data = {
      fdid: this.props.fireDepartment.id,
      trainerId: trid,
      type: status,
      reason: reason
    }
    await updateFireDepartmentTrainerStatus(data);
    await this.fetch();
    this.setState({ loading: false });
  }

  isValid = () => {
    let errors = { error: false };

    if (this.state.fdWarning && this.state.fdWarning.length > 900) {
      errors.error = true;
      errors.fdWarning = 'Warning Message maximum length exceeded (900 characters).';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  // Always update
  didChange = () => { return true; }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    data.contact_email = data.contact_email.replace(' ','').toLowerCase().trim();
    let { loading, applicationDocs, ui, errors, sortDate, key, address, editing, ...body } = data;
    body = { address_number: address.number, address_street: address.street, address_city: address.city, address_province: address.province, address_postalcode: address.postalcode, ...body };
    await updateFireDepartment(body);
    if (this.props.fireDepartment.id) {
      this.goToList();
    }
    this.setState({ loading: false });
  }

  handleAddressChange = (event, { name, value }) => {
    this.setState({ address: { ...this.state.address, [name]: value }});
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleSubmit = () => {
    if (this.isValid()) {
      const data = this.getData();
      if (this.didChange()) {
        this.update(data);
      }
    }
  }

  startEditing = (componentName, entry) => {
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing });
  }

  stopEditing = () => {
    this.setState({ editing: {}});
  }

  handleComponentEdit = (componentName, entry) => {
    this.stopEditing();
    this.setState({ contact_email: entry } );
  }

  render() {
    let fd = this.props.fireDepartment;
    let fdName = (fd !== undefined && fd.entity_name ? fd.entity_name : "");
    return (
      <div className='fire-department-detailed-report-list'>
        <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
          <Grid centered className='view-grid'>
            <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={15} floated='left'>
              <Header size='medium'>
                <Header.Content>Report - Fire Department of {fdName || this.state.entity_name}</Header.Content>
                <Form.Button floated='right' className='form-top-button' positive>Update</Form.Button>
                <Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Button>
              </Header>

            </Grid.Column>
            <Grid.Column width={15}>

              {(() => {
                if (this.state.loading) {
                  return <ViewLoader loading={ this.state.loading }/>;
                }

                let trainers = sort(this.props.trainerList, this.state.ui.sortField);

                // console.log("FD:", fd)
                // console.log("TRs:", trainers)

                if (!fd) {
                  return <Message info size='large'>
                    Fire Departments not found!
                  </Message>;
                }

                if (this.state.ui.sortDescending) { trainers.reverse(); }

                const center = { textAlign: 'center' };

                return (
                <Segment.Group size='large' className='view-segment-group'>
                  <Segment>
                    <Header content='Fire Department details' size='tiny'/>
                    <Form.Group>
                      <Form.Input readOnly label='Department Name' value={ fd.entity_name || this.state.entity_name } width={ 10 } />
                      <Form.Input label='Fire Chief' value={ this.state.chief_name } name='chief_name' width={ 6 } onChange={ this.handleChange }/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Input label='Contact Name(s)' value={ this.state.contact_name } name='contact_name' width={ 16 }
                        placeholder='Tom Jones, Bob Smith' onChange={ this.handleChange }/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Input readOnly label='FD Logon ID' value={ this.state.contact_email } name='contact_email' width={ 6 }
                        placeholder='tom@abc.com; bob@abc.com' onChange={ this.handleChange }/>
                      <EditButton onClick={ this.startEditing.bind(this, 'contact_email', this.state.contact_email) } style={{ position: 'relative', bottom: -32 }}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Input label='Other Email(s)' value={ this.state.other_emails } name='other_emails' width={ 16 }
                        placeholder='tom@abc.com; bob@abc.com' onChange={ this.handleChange }/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Input label='Payable to' value={ this.state.payable_to } name='payable_to' width={ 16 } onChange={ this.handleChange }/>
                    </Form.Group>
                    <AddressInput address={ this.state.address } onChange={ this.handleAddressChange } hideCompliment />
                    <Form.Group>
                      <Form.Input label='Main Phone' value={ this.state.main_telephone_number } name='main_telephone_number' width={ 4 } onChange={ this.handleChange }/>
                      <Form.Input label='Ext' value={ this.state.main_telephone_ext } name='main_telephone_ext' width={ 2 } onChange={ this.handleChange }/>
                      <Form.Input label='Secondary Phone' value={ this.state.second_telephone_number } name='second_telephone_number' width={ 4 } onChange={ this.handleChange }/>
                      <Form.Input label='Ext' value={ this.state.second_telephone_ext } name='second_telephone_ext' width={ 2 } onChange={ this.handleChange }/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Input readOnly label='Acknowledgement date' value={ formatDateTime(fd.acknowledgementdate, DATE_FULL_MONTH_DAY_YEAR) } name='acknowledgementdate' width={ 4 }/>
                      <Form.Input readOnly label='Instructors in the program' value={ fd.numtrainers } width={ 4 } />
                      <Form.Input readOnly label='Instructors allowed' value={ fd.maxallowedtrainers } width={ 4 } />
                    </Form.Group>
                    <Form.Group>
                      <Form.TextArea readOnly label='Justification' value={ fd.justification } name='justification' width={ 16 }/>
                    </Form.Group>
                    <Form.Group>
                      <Form.TextArea label='Warning Message' value={ this.state.fdWarning } name='fdWarning' width={ 16 } onChange={ this.handleChange }/>
                    </Form.Group>
                    <Message error>
                      { this.state.errors.fdWarning }
                    </Message>
                    <Form.Group>
                      <Form.Field required={ false } width={ 16 }
                        control={ FileChooserSegment } customLabel={' '} label='Supporting Documentation' name='applicationDocs' 
                        ui={this.state.ui} maxFiles={4} readOnly headeless files={ this.state.applicationDocs } multiple
                      />
                    </Form.Group>
                  </Segment>
                  <Segment>
                    <Header content='Department Instructors details' size='tiny' />
                    <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                        { field: 'trainername',           title: 'Name',                   width: '4' },
                        { field: 'displayCertDate',       title: 'Certification date',     width: '4', style: {...center}},
                        { field: 'displayType',           title: 'Status',                 width: '3', style: {...center}},
                        { field: 'totalreimbursements',   title: 'Total of reimbursements',width: '6', style: {...center}},
                        { field: 'buttons', noSort: true, title: '',                       width: '1' },
                    ]}>
                      { trainers.map((trainer, index) => (
                        <SortTable.Row key={ trainer.id + "" + index }>
                          <SortTable.Cell>{ trainer.trainername }</SortTable.Cell>
                          <SortTable.Cell {...center}>{ (!trainer.displayCertDate ? "-" : trainer.displayCertDate)}</SortTable.Cell>
                          <SortTable.Cell {...center}>{ (!trainer.displayType ? "-" : trainer.displayType.replace("( )","")) }</SortTable.Cell>
                          <SortTable.Cell {...center}>{ <CurrencyText value={ this.sumReimbursements(trainer) }/> }</SortTable.Cell>
                          <SortTable.Cell {...center}>
                            <ActionButton content={ trainer.type === 'I' ? 'Activate instructor?' : 'Deactivate instructor?' } onConfirm={ this.changeStatus.bind(this, trainer.trainerId, trainer.type === 'I' ? 'A' : 'I', ' ') }/>
                            <EditButton view={ true } pathname={ `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }/${ fd.id }/${ trainer.trainerId }` }/>
                          </SortTable.Cell>
                        </SortTable.Row>
                      ))}
                    </SortTable>
                  </Segment>
                </Segment.Group>);
              })()}

            { this.state.editing.contact_email &&
              <EmailEditDialog open={ !!this.state.editing.contact_email } contact_email={ this.state.editing.contact_email }
                onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
              />
            }

            </Grid.Column>
          </Grid>
      </Form>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    trainerList: storeState.models.fireDepartmentTrainers,
    fireDepartment: storeState.models.fireDepartment,
  };
}

export default connect(mapStoreStateToProps)(AdminReportFireDepartment);
