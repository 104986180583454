import React from "react";

import ViewLoader from '../components/ViewLoader';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <main>
          <div className="pg-content">
            <ViewLoader home loading='Initializing'/>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
 
export default Home;
