import React from 'react';

import { Popup, Button, Icon } from 'semantic-ui-react';

import PropTypes from 'prop-types';

class ConfirmMultiPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.confirmed = this.confirmed.bind(this);
    this.canceled = this.canceled.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  confirmed(reason) {
    this.props.onConfirm(reason);
    this.handleClose();
  }

  canceled() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.handleClose();
  }

  render() {
    const { onConfirm, onCancel, children, selectOptions, ...props } = this.props;

    const header = this.props.header || '';
    const content = (
      <div style={{ marginTop: '8px' }}>
        { this.props.content }
        <div style={{ marginTop: '8px' }}>
          <Button.Group vertical>
            { selectOptions.map((option) => {
                return <Button key={option }primary onClick={ () => this.confirmed(option) }><Icon name='check circle' /> { this.props.oktext || option }</Button>
              })
            }
            <Button onClick={ this.canceled }><Icon name='remove circle' /> { this.props.canceltext || 'Cancel' }</Button>
          </Button.Group>
        </div>
      </div>
    );

    return (
      <Popup id='confirm' onOpen={ this.handleOpen } onClose={ this.handleClose } open={ this.state.open }
        { ...props } header={ header } content={ content } trigger={ this.props.children }
      />
    );
  }
};

ConfirmMultiPopup.propTypes = {
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  canceltext: PropTypes.string,
  oktext: PropTypes.string,
};

export default ConfirmMultiPopup;
