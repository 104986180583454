import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon } from 'semantic-ui-react';

import { putAdminJointTraining, getAdminJointTrainings } from '../../api/administratorAPI';

import { UPDATE_ADMIN_JOINT_TRAINING_UI } from '../../js/actionTypes';
import { PATH_ADMIN, PATH_JOINT_TRAINING, PATH_REIMBURSEMENTS } from '../../js/paths';

import { sort } from '../../utils/utils';

import ApprovalButton from '../../components/ApprovalButton';
import CurrencyText from '../../components/CurrencyText';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import RejectReasonEditDialog from '../../dialogs/RejectReasonEditDialog';

import ListView from '../ListView';

class AdminJointTraining extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      editing: null,

      title: 'Joint Training Reimbursement Requests',
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getAdminJointTrainings('Pending');
    this.setState({ loading: false });
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await putAdminJointTraining(data);
    this.setState({ loading: false });
  }

  handleApprove = async (data) => {
    data.status = 'Approved';
    await this.update(data);
    await this.fetch();
  }

  handleReject = (data) => {
    this.getRejectReason(data);
  }

  closeDialog = () => { this.setState({ editing: null }); }

  saveRejectReason = async (rejectreason) => {
    let data = { ...this.state.editing };
    data.status = 'Rejected';
    data.rejectreason = rejectreason;
    this.closeDialog();
    await this.update(data);
    await this.fetch();
  }

  getRejectReason = (data) => {
    this.setState({ editing: data });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_JOINT_TRAINING_UI, adminJointTraining: this.state.ui });
    });
  }

  render() {
    return (
      <div className='admin-joint-training-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>{ this.state.title }</Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.jointTrainings.length) {
                return <Message info size='large'>
                  You don&apos;t have any joint training reimbursement requests.
                </Message>;
              }

              let jointTrainings = sort(this.props.jointTrainings, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                jointTrainings.reverse();
              }

              const right = { textAlign: 'right' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',              title: 'Request Date',    width: '2'               },
                { field: 'fdName',                title: 'Fire Department', width: '4'               },
                { field: 'sortTrainingDate',      title: 'Training Date',   width: '2'               },
                { field: 'hoursoftraining',       title: 'Training Hours',  width: '2', style: right },
                { field: 'amount',                title: 'Amount',          width: '2', style: right },
                { field: 'status',                title: 'Status',          width: '2'               },
                { field: 'buttons', noSort: true, title: '',                width: '2'               },
              ]}>
                { jointTrainings.map((jointTraining) => (
                  <SortTable.Row key={ jointTraining.key }>
                    <SortTable.Cell>{ jointTraining.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ jointTraining.fdName }</SortTable.Cell>
                    <SortTable.Cell>{ jointTraining.displayTrainingDate }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>{ jointTraining.hoursoftraining }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ jointTraining.amount }/></SortTable.Cell>
                    <SortTable.Cell>{ jointTraining.status }</SortTable.Cell>
                      { jointTraining.status === 'Pending' &&
                        <SortTable.Cell textAlign='right'>
                          <ApprovalButton
                            onApproval={ this.handleApprove.bind(this, jointTraining) }
                            onRejection={ this.handleReject.bind(this, jointTraining) }
                          />
                          <EditButton pathname={ `/${ PATH_ADMIN }/${ PATH_REIMBURSEMENTS }/${ PATH_JOINT_TRAINING }/${ jointTraining.id }/${ jointTraining.timestamp }` }/>
                        </SortTable.Cell>
                      }
                      { jointTraining.status === 'Approved' &&
                        <SortTable.Cell textAlign='right'><Icon name='clipboard check' size='large' color='green' /></SortTable.Cell>
                      }
                      { jointTraining.status === 'Rejected' &&
                        <SortTable.Cell textAlign='right'><Icon name='clipboard' size='large' color='red' /></SortTable.Cell>
                      }
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.state.editing &&
              <RejectReasonEditDialog open={ !!this.state.editing } onSave={ this.saveRejectReason } onClose={ this.closeDialog } />
            }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    jointTrainings: storeState.models.jointTrainings,
    ui: storeState.ui.adminJointTraining,
  };
}

export default connect(mapStoreStateToProps)(AdminJointTraining);
