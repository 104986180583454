import * as Action from '../js/actionTypes';

const DEFAULT_UI = {
  requests: {
    waiting: false,
    error: {},
  },

  showSidebar: false,

  isDirty: false,

  constructionSurveys: {},
  fireDepartmentSurveys: {},
  fireDepartmentReimbursements: {},
  fireDepartmentSpecialPayments: {},
  fireDepartmentTrainers: {},
  fireDepartmentTrainersEquivalency: {},

  fireDepartmentTrainerEquivalencies: {
    equivalencies: {}
  },

  jointTraining: {},

  specialPayment: {
    ballots: {},
    files: {}
  },

  trainingAgencyReimbursements: {},
  trainingAgencyReimbursement: {},

  adminCommittee: {
    members: {}
  },
  adminJointTraining: {},
  adminRegistrationsFD: {},
  adminRegistrationFD: {
    ballots: {}
  },
  adminRegistrationsTA: {},
  adminRegistrationTA: {
    ballots: {}
  },
  adminAnnualSubmissions: {},

  adminPPEs: {},
  adminTRs: {},
  adminTR: {
    ballots: {}
  },
  adminTUs: {},
  adminTU: {},
  costCenters: {},
  adminCBs: {},
  adminCB: {
    ballots: {}
  },

  fireDepartmentReimbursement: {
    backfill: {},
    accommodation: {},
    meal: {},
    airtravel:{},
    mileage: {},
    vehicle: {},
    invoices: {},
  },
};

export default function uiReducer(state = DEFAULT_UI, action) {
  switch(action.type) {
    // Requests

    case Action.REQUESTS_BEGIN:
      return { ...state, requests: {
        waiting: true,
        error: {},
      }};

    case Action.REQUESTS_END:
      return { ...state, requests: { ...state.requests, ...{ waiting: false } } };

    case Action.REQUESTS_ERROR:
      return { ...state, requests: { ...state.requests, ...{ error: action.error } } };

    case Action.REQUESTS_CLEAR:
      return { ...state, requests: { waiting: false, error: {} } };

    // Views

    case Action.VIEW_IS_DIRTY:
      return { ...state, isDirty: true };

    case Action.VIEW_IS_CLEAN:
      return { ...state, isDirty: false };

    // Sidebar

    case Action.UPDATE_SIDEBAR_UI:
      return { ...state, showSidebar: action.showSidebar };

    // Screens

    case Action.UPDATE_CONSTRUCTION_SURVEYS_UI:
      return { ...state, constructionSurveys: action.constructionSurveys };

    case Action.UPDATE_FIRE_DEPARTMENT_SURVEYS_UI:
      return { ...state, fireDepartmentSurveys: action.fireDepartmentSurveys };

    case Action.UPDATE_FIRE_DEPARTMENT_TRAINERS_UI:
      return { ...state, fireDepartmentTrainers: action.fireDepartmentTrainers };

    case Action.UPDATE_FIRE_DEPARTMENT_TRAINERS_EQUIVALENCY_UI:
      return { ...state, fireDepartmentTrainersEquivalency: action.fireDepartmentTrainersEquivalency };

    case Action.UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES_UI:
      return { ...state, fireDepartmentTrainerEquivalencies: action.fireDepartmentTrainerEquivalencies };

    case Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS_UI:
      return { ...state, fireDepartmentReimbursements: action.fireDepartmentReimbursements };

    case Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT_UI:
      return { ...state, fireDepartmentReimbursement: action.fireDepartmentReimbursement };

    case Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS_UI:
      return { ...state, fireDepartmentSpecialPayments: action.fireDepartmentSpecialPayments };

    case Action.UPDATE_SPECIAL_PAYMENT_UI:
      return { ...state, specialPayment: action.specialPayment };

    case Action.UPDATE_JOINT_TRAINING_UI:
      return { ...state, jointTraining: action.jointTraining };

    case Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENTS_UI:
      return { ...state, trainingAgencyReimbursements: action.trainingAgencyReimbursements };

    case Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENT_UI:
      return { ...state, trainingAgencyReimbursement: action.trainingAgencyReimbursement };

    case Action.UPDATE_ADMIN_COMMITTEE_UI:
      return { ...state, adminCommittee: action.adminCommittee };

    case Action.UPDATE_ADMIN_JOINT_TRAINING_UI:
      return { ...state, adminJointTraining: action.adminJointTraining };

    case Action.UPDATE_PPES_UI:
      return { ...state, adminPPEs: action.adminPPEs };

    case Action.UPDATE_TR_UI:
      return { ...state, adminTR: action.adminTR };

    case Action.UPDATE_TRS_UI:
      return { ...state, adminTRs: action.adminTRs };

    case Action.UPDATE_TU_UI:
      return { ...state, adminTU: action.adminTU };

    case Action.UPDATE_TUS_UI:
      return { ...state, adminTUs: action.adminTUs };

    case Action.UPDATE_COST_CENTERS_UI:
      return { ...state, costCenters: action.costCenters };

    case Action.UPDATE_CBS_UI:
      return { ...state, adminCBs: action.adminCBs };

    case Action.UPDATE_CB_UI:
      return { ...state, adminCB: action.adminCB };

    case Action.UPDATE_ADMIN_ANNUAL_SUBMISSIONS_UI:
      return { ...state, adminAnnualSubmissions: action.adminAnnualSubmissions };

    case Action.UPDATE_ADMIN_REGISTRATIONS_FD_UI:
      return { ...state, adminRegistrationsFD: action.adminRegistrationsFD };

    case Action.UPDATE_ADMIN_REGISTRATION_FD_UI:
      return { ...state, adminRegistrationFD: action.adminRegistrationFD };

    case Action.UPDATE_ADMIN_REGISTRATIONS_TA_UI:
      return { ...state, adminRegistrationsTA: action.adminRegistrationsTA };

    case Action.UPDATE_ADMIN_REGISTRATION_TA_UI:
      return { ...state, adminRegistrationTA: action.adminRegistrationTA };

    default:
      return state;
  }
}
