import React from "react";
import { connect } from 'react-redux';

import { Grid, Header, Message } from 'semantic-ui-react';

import { getFireDepartment, getFireDepartmentAnnualSubmission, deleteFireDepartmentAnnualSubmission } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_ANNUAL_SUBMISSIONS_UI } from '../../js/actionTypes';
import { PATH_FIRE_DEPARTMENT, PATH_ANNUAL, PATH_NEW } from '../../js/paths';

import { sort } from '../../utils/utils';

import EditButton from '../../components/EditButton';
import DeleteButton from '../../components/DeleteButton';
import AddButton from '../../components/AddButton';
import CurrencyText from '../../components/CurrencyText';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

class FireDepartmentAnnualSubmissions extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartment(this.props.currentUser.orgId);
    await getFireDepartmentAnnualSubmission(this.props.currentUser.orgId);
    this.setState({ loading: false });
  }

  handleRemove = async (fdid, timestamp) => {
    this.setState({ loading: 'Removing' });
    await deleteFireDepartmentAnnualSubmission({ fdid, timestamp });
    await this.fetch();
    this.setState({ loading: false });
  }

  handleNew() {
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_ANNUAL }/${ PATH_NEW }`);
  }

  handleEdit(timestamp) {
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_ANNUAL }/${ timestamp }`);
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_ANNUAL_SUBMISSIONS_UI, fireDepartmentAnnualSubmission: this.state.ui });
    });
  }

  render() {
    return (
      <div className='fire-department-annual-submission-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>
              <Grid>
                <Grid.Column width={ 5 }>
                  <Header.Content>Annual Submissions</Header.Content>
                  <AddButton onClick={ this.handleNew.bind(this, new Date().getFullYear().toString()) } style={{ marginLeft: '1.5rem' }}/>
                </Grid.Column>
              </Grid>
            </Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.submissions.length) {
                return <Message info compact size='large'>
                  You don&apos;t have any annual submissions.
                </Message>;
              }

              let submissions = sort(this.props.submissions, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                submissions.reverse();
              }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                  { field: 'sortDate',                       title: 'Submission date',       width: '3' },
                  { field: 'year',                           title: 'Year',                  width: '1' },
                  { field: 'firedeptstipendamount',          title: 'FD Stipend',            width: '2' },
                  { field: 'trainersstipendamount',          title: 'Instructor Stipend',    width: '2' },
                  { field: 'sitesurveyreimbamount',          title: 'Crane Surveys',         width: '2' },
                  { field: 'trainerskillstrainingamount',    title: 'Training Skills',       width: '2' },
                  { field: 'status',                         title: 'Status',                width: '2' },
                  { field: 'buttons', noSort: true,          title: '',                      width: '2' },
              ]}>
                { submissions.map((submission, index) => (
                  <SortTable.Row key={ submission.id + "" + index }>
                    <SortTable.Cell>{ submission.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ submission.timestamp.substr(0, 4) }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ submission.firedeptstipendamount }/></SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ submission.trainersstipendamount }/></SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ submission.sitesurveyreimbamount }/></SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ submission.trainerskillstrainingamount }/></SortTable.Cell>
                    <SortTable.Cell>{ submission.status }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      <EditButton view={ submission.status !== 'Draft' && submission.status !== 'Rejected' } onClick={ this.handleEdit.bind(this, submission.timestamp) }/>
                      <DeleteButton disabled={ submission.status !== 'Draft' } content='Confirm the removal of your draft submission?' onConfirm={ this.handleRemove.bind(this, submission.id, submission.timestamp) }/>
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    submissions: storeState.models.fireDepartmentAnnualSubmission,
    fireDepartment: storeState.models.fireDepartment,
    ui: storeState.ui.fireDepartmentTrainers,
  };
}
export default connect(mapStoreStateToProps)(FireDepartmentAnnualSubmissions);
