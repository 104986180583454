import React from 'react';
import { Auth } from 'aws-amplify';

import { Grid, Segment, Header, Form, Message } from 'semantic-ui-react';

import { handleAPIError } from '../../api/api';

import { AUTH_SIGN_IN, AUTH_CONFIRM_SIGN_UP } from '../../js/constants';

const INITIAL_STATE = {
  code: '',
  resent: false,
  error: {}
};

class ConfirmSignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  changeAuthState(type, event) {
    this.setState({ error: {} });
    this.props.onStateChange(type, event);
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  getUsername = () => this.props.authData && this.props.authData.username ? this.props.authData.username : ''

  resendCode() {
    let email = this.getUsername().replace(' ','').toLowerCase().trim();

    this.setState({ resent: false, error: {} });

    Auth.resendSignUp(email).then(() => {
      this.setState({ resent: true });
    }).catch(error => {
      this.setState({ error: handleAPIError(error) });
    });
  }

  handleSubmit = event => {
    let email = this.getUsername();

    this.setState({ error: {} });

    Auth.confirmSignUp(email, this.state.code)
      .then(result => {
        this.changeAuthState(AUTH_SIGN_IN, { username: email });
      }).catch(error => {
        this.setState({ error: handleAPIError(error) });
      });

    event.preventDefault();
  };

  render() {
    if (this.props.authState !== AUTH_CONFIRM_SIGN_UP) return null;

    let email = this.getUsername();
    return (
      <div className='auth-container confirm-signup'>
        <Grid centered className='auth-grid'>
          <Grid.Column className='auth-column'>
            <Header size='medium'>Confirm Account Registration
              <Header.Subheader>
                A verification code has been sent to your email address. Please enter it below to complete your registration.
              </Header.Subheader>
              <Header.Subheader style={{ fontSize: '0.9rem', marginTop: '1rem' }}>
                Already confirmed?&nbsp;&nbsp;<a href='#signin' onClick={() => this.changeAuthState(AUTH_SIGN_IN)}>Sign In</a>
              </Header.Subheader>
              <Header.Subheader style={{ fontSize: '0.9rem' }}>
                Lost your code?&nbsp;&nbsp;<a href='#resend' onClick={() => this.resendCode()}>Resend Code</a>
              </Header.Subheader>
            </Header>
            <Form onSubmit={ this.handleSubmit } error={ this.state.error.message }>
              <Segment size='large' secondary>
                <Form.Input label='Email Address' value={ email } disabled />
                <Form.Input label='Code' value={ this.state.code } name='code' required onChange={ this.handleChange } />

                <Form.Button positive className='centered'>Confirm</Form.Button>

                <Message error className='centered' negative size='small'>
                  { this.state.error.message }
                </Message>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default ConfirmSignUp;
