import React from 'react';
import { connect } from 'react-redux';

import { Grid, Segment, Header, Form, Message } from 'semantic-ui-react';

import { getSettings } from '../../api/administratorAPI';
import { getFireDepartmentTrainers, getFireDepartmentReimbursement, postFireDepartmentReimbursement, updateFireDepartmentReimbursement } from '../../api/fireDepartmentAPI';

import { UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT, UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT_UI } from '../../js/actionTypes';
import { DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { REIMBURSEMENT_COURSES } from '../../js/lists';
import { PATH_ADMIN, PATH_FIRE_DEPARTMENT, PATH_REIMBURSEMENTS, PATH_TRAINING_EXPENSES, PATH_NEW, PATH_DUPLICATE, PATH_ID, PATH_TIMESTAMP, EXTERNAL_PATH_FIN2, EXTERNAL_PATH_FIN3 } from '../../js/paths';

import { today, sortableDateTime, yearsAgo } from '../../utils/date';
import ChosenFile from '../../utils/file';

import CurrencyInput from '../../components/CurrencyInput';
import CurrencyText from '../../components/CurrencyText';
import DateControl from '../../components/DateControl';
import FileChooserSegment from '../../components/FileChooserSegment';

import AccommodationSegment from '../../components/AccommodationSegment';
import AirTravelSegment from '../../components/AirTravelSegment';
import BackfillSegment from '../../components/BackfillSegment';
import MealSegment from '../../components/MealSegment';
import MileageSegment from '../../components/MileageSegment';
import TaxCalculator from '../../components/TaxCalculator';
import VehicleSegment from '../../components/VehicleSegment';
import ViewLoader from '../../components/ViewLoader';
import ExternalButton from '../../components/ExternalButton';

import AccommodationEditDialog from '../../dialogs/AccommodationEditDialog';
import AirTravelEditDialog from '../../dialogs/AirTravelEditDialog';
import BackfillEditDialog from '../../dialogs/BackfillEditDialog';
import MealEditDialog from '../../dialogs/MealEditDialog';
import MileageEditDialog from '../../dialogs/MileageEditDialog';
import VehicleEditDialog from '../../dialogs/VehicleEditDialog';

import EditView from '../EditView';

const INITIAL_STATE = {
  status: 'Pending',
  requestNumber: null,

  instructor_id: '',
  instructor_name: '',

  coursename: 'awareness',
  dateattended: today(),
  tuitionamount: 0,
  tuitiongst: 0,

  invoices: [],
  payableto: '',

  // sub components

  backfill:[],
  totbackfillamount: 0,

  accommodation: [],
  totalbeforetax: 0,
  gst: 0,
  hoteltax: 0,
  totalaftertax: 0,

  meal: [],
  totalmealamount: 0,

  airtravel:[],
  totalairtravelamount: 0,
  totalairtravelgst: 0,
  totalairtravelaftertax: 0,

  mileage: [],
  totalmileageamount: 0,

  vehicle: [],
  vehiclepst: 0,
  vehiclegst: 0,
  totalvehicleaftertax: 0,

  totalamountclaimed: 0,
  totalpst: 0,
  totalgst: 0,
  totalamountaftertax: 0,

  // UI

  ui: null,

  loading: false,
  isNew: false,
  isReadOnly: false,

  progressPercent: 0,
  progressText: '',

  editing: {},
  errors: {
    error: false
  }
};

// TODO: THIS NEEDS TO SELECT FDS

class FireDepartmentReimbursement extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.state.files = [];

    this.state.isNew = (props.match.params[PATH_TIMESTAMP] === PATH_NEW);

    this.isDuplication = (props.match.params[PATH_ID] === PATH_DUPLICATE);

    if (this.state.isNew) {
      let payableto;
      if (this.props.currentUser.fd) {
        payableto = this.props.currentUser.fd.payable_to;
      }
      this.state.payableto = payableto || '';
    }

    // this.state.isReadOnly = this.props.currentUser.role === "Admin";

    this.state.ui = {
      backfill: {
        sortField: this.props.ui.backfill.sortField || 'name',
        sortDescending: this.props.ui.backfill.sortDescending === true,
      },
      accommodation: {
        sortField: this.props.ui.accommodation.sortField || 'displayType',
        sortDescending: this.props.ui.accommodation.sortDescending === true,
      },
      meal: {
        sortField: this.props.ui.meal.sortField || 'sortableDate',
        sortDescending: this.props.ui.meal.sortDescending === true,
      },
      airtravel: {
        sortField: this.props.ui.airtravel.sortField || 'sortableDepartureDate',
        sortDescending: this.props.ui.airtravel.sortDescending === true,
      },
      mileage: {
        sortField: this.props.ui.mileage.sortField || 'displayType',
        sortDescending: this.props.ui.mileage.sortDescending === true,
      },
      vehicle: {
        sortField: this.props.ui.vehicle.sortField || 'displayType',
        sortDescending: this.props.ui.vehicle.sortDescending === true,
      },
      invoices: {
        sortField: this.props.ui.invoices.sortField || 'type',
        sortDescending: this.props.ui.invoices.sortDescending === true,
      },
    }
  }

  componentDidMount = () => {
    this.initialize();
    this.isClean();
  }

  initialize = async () => {
    this.setState({ loading: 'Loading' });

    await getFireDepartmentTrainers(this.props.match.params[PATH_ID]);
    await getSettings();
    if (this.state.isNew) {
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT, fireDepartmentReimbursement: INITIAL_STATE });
    } else {
      await getFireDepartmentReimbursement(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
      this.setState(this.props.reimbursement);
      this.state.files.length = 0;
      this.state.invoices.forEach((invoice) => {
        let file = new ChosenFile();
        //
        if (this.isDuplication) file.duplicate(invoice);
        else file.parse(invoice)
        //
        if (file.id) this.state.files.push(file);
      });
      //Check for duplication clean up
      if (this.isDuplication) {
        this.state.status = "Pending";
        this.state.instructor_id = ""; //clean instructor
        this.state.invoices = Object.assign(this.state.files).map((file) => { return file.stringify(); }); //new
      }
      //images should be pending
      this.processComponentReceipts(this.state.accommodation);
      this.processComponentReceipts(this.state.vehicle);
      this.processComponentReceipts(this.state.airtravel);
    }
    this.updateTotals(); //update dynamically generated values on initialization
    this.setState({ loading: false });
  }

  processComponentReceipts(element) {
    element.forEach((exp)=>{
      let tmp = [], tmpS = [];
      if (exp.receipts) {
        exp.receipts.forEach((receipt)=>{
          let file = new ChosenFile();
          if (this.isDuplication) file.duplicate(receipt);
          else file.parse(receipt)
          if (file.id) {
            tmp.push(file);
            tmpS.push(file.stringify());
          }
        });
      }
      exp.receipts = tmpS;
      exp.files = tmp;
    });
  }

  onChange = () => {
    this.isDirty();
    this.validate();
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value }, () => { this.onChange(); });
  }

  handleInstructorChange = (event, { name, value }) => {
    let stateUpdate = { [name]: value };
    const instructor = this.props.trainers.find((trainer) => { return trainer.trainerId === value });
    stateUpdate.instructor_name = instructor ? instructor.trainername : '';

    this.setState(stateUpdate, () => { this.onChange(); });
  }

  handleInvoicesChange = (state) => {
    let newState = { ...state };

    if (newState.files) {
      newState.invoices = newState.files.map((file) => { return file.stringify(); });
    }

    this.setState(newState, () => { this.onChange(); });
  }

  handleDateChange = (name, value) => {
    this.setState({ [name]: value }, () => { this.onChange(); });
  }

  handleCurrencyChange = (name, value) => {
    const stateUpdate = { [name]: value };
    this.setState(stateUpdate, () => {
      this.updateTotals();
      this.onChange();
    });
  }

  handleTaxChange = (name, value) => {
    const stateUpdate = { [name]: value };
    this.setState(stateUpdate, () => {
      this.updateTotals();
      this.onChange();
    });
  }

  updateTotals = () => {
    // Calculate all the totals
    const totbackfillamount = this.state.backfill.reduce((sum, b) => { return sum + b.amount }, 0);

    const totalbeforetax = this.state.accommodation.reduce((sum, a) => { return sum + a.amount }, 0);

    const totalaftertax = totalbeforetax + this.state.gst + this.state.hoteltax;

    const totalmealamount = this.state.meal.reduce((sum, m) => { return sum + m.rate }, 0);

    const totalairtravelamount = this.state.airtravel.reduce((sum, at) => { return sum + at.fare }, 0);
    const totalairtravelgst = this.state.airtravel.reduce((sum, at) => { return sum + at.gst }, 0);
    const totalairtravelaftertax = totalairtravelamount + totalairtravelgst;

    const totalmileageamount = this.state.mileage.reduce((sum, m) => {
      let amount = m.kms * this.props.settings.mileageamount;
      return sum + parseFloat(amount.toFixed(2));
    }, 0);

    const totalvehiclebeforetax = this.state.vehicle.reduce((sum, v) => { return sum + v.amountbeforetaxes }, 0);
    const totalvehicleaftertax = totalvehiclebeforetax + this.state.vehiclegst + this.state.vehiclepst;

    const totalamountclaimed = this.state.tuitionamount + totbackfillamount + totalbeforetax + totalmealamount + totalairtravelamount + totalmileageamount + totalvehiclebeforetax;
    const totalgst = this.state.tuitiongst + this.state.gst + totalairtravelgst + this.state.vehiclegst;
    const totalpst = this.state.vehiclepst;
    const totalamountaftertax = totalamountclaimed + this.state.hoteltax + totalgst + totalpst;

    this.setState({
      totbackfillamount: totbackfillamount,
      totalbeforetax: totalbeforetax,
      totalaftertax: totalaftertax,
      totalmealamount: totalmealamount,
      totalairtravelamount: totalairtravelamount,
      totalairtravelgst: totalairtravelgst,
      totalairtravelaftertax: totalairtravelaftertax,
      totalmileageamount: totalmileageamount,
      totalvehicleaftertax: totalvehicleaftertax,
      totalamountclaimed: totalamountclaimed,
      totalgst: totalgst,
      totalpst: totalpst,
      totalamountaftertax: totalamountaftertax,
    });
  }

  startEditing = (componentName, entry) => {
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing });
  }

  stopEditing = () => {
    this.setState({ editing: {}});
  }

  handleComponentEdit = (componentName, entry) => {
    this.stopEditing();

    let componentState = [ ...this.state[componentName] ];

    if (entry.timestamp) {
      // Edit: find the entry in the list and replace its contents
      const index = componentState.findIndex((element) => {
        return element.timestamp === entry.timestamp;
      });
      componentState[index] = entry;
    } else {
      // New: create a timestamp and add it to the array
      entry.timestamp = sortableDateTime(new Date());
      componentState.push(entry);
    }

    let stateUpdate = {};
    stateUpdate[componentName] = componentState;
    this.setState(stateUpdate, () => {
      this.updateTotals();
      // document.getElementById(`${ componentName }_${ entry.timestamp }`).scrollIntoView();
    });
  }

  handleComponentRemove = (componentName, entry) => {
    const componentState = this.state[componentName].filter((component) => {
      return component.timestamp !== entry.timestamp;
    });

    const stateUpdate = {};

    stateUpdate[componentName] = componentState;

    if (componentState.length === 0) {
      if (componentName === 'vehicle') {
        stateUpdate.vehiclegst = 0;
        stateUpdate.vehiclepst = 0;
      }

      if (componentName === 'accommodation') {
        stateUpdate.gst = 0;
      }
    }

    this.setState(stateUpdate, () => {
      this.updateTotals();
    });
  }

  handleComponentUIStateChange = (componentUI) => {
    this.setState({ ui: { ...this.state.ui, ...componentUI }}, () => {
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT_UI, fireDepartmentReimbursement: this.state.ui });
    });
  }

  handleInvoicesUIStateChange = (uiState) => {
    const uiStateUpdate = {
      invoices: uiState
    };

    this.setState({ ui: { ...this.state.ui, ...uiStateUpdate }}, () => {
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT_UI, fireDepartmentReimbursement: this.state.ui });
    });
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.instructor_id) {
      errors.error = true;
      errors.instructor_id = 'Please select an instructor. ';
    }

    if (!this.state.coursename) {
      errors.error = true;
      errors.coursename = 'Please select a course name. ';
    } else if (this.state.coursename === 'update' && this.state.instructor_id) {
      // Instructor must have at least 3 years of experience
      const instructor = this.props.trainers.find((trainer) => { return trainer.trainerId === this.state.instructor_id});
      if (!instructor.fullcertdate || yearsAgo(instructor.fullcertdate) < 3 ) {
        errors.error = true;
        errors.coursename = 'Instructor must have at least 3 years in the program to claim update. ';
      }
    }

    if (!this.state.dateattended) {
      errors.error = true;
      errors.dateattended = 'Please choose a completion date. ';
    }

    if (!this.state.tuitionamount) {
      errors.error = true;
      errors.tuitionamount = 'Please enter a tuition amount.';
    } else if (this.state.coursename === 'update' && this.state.tuitionamount > this.props.settings.maxupgradetuitionpertrainer) {
      errors.error = true;
      errors.tuitionamount = `Course Fees amount cannot exceed $${ this.props.settings.maxupgradetuitionpertrainer }.`;
    } else if (this.state.coursename !== 'update' && this.state.tuitionamount > this.props.settings.maxtuitionamountpertrainer) {
      errors.error = true;
      errors.tuitionamount = `Course Fees amount cannot exceed $${ this.props.settings.maxtuitionamountpertrainer }.`;
    }

    if (this.state.invoices.length === 0) {
      errors.error = true;
      errors.invoices = 'Please attach a receipt.';
    }

    if (!this.state.payableto) {
      errors.error = true;
      errors.payableto = 'Please enter a payable to email address.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.isValid()) {
      const { loading, isNew, isReadOnly, ui, editing, errors, ...data } = this.state;

      if (this.state.isNew || this.isDuplication) {
        this.create(data);
      } else {
        this.update(data);
      }
    }
  }

  goToList = () => {
    this.isClean();
    if (this.props.currentUser.role === "Admin") {
      this.props.history.push(`/${ PATH_ADMIN }/${ PATH_REIMBURSEMENTS }`);
    } else {
      this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_REIMBURSEMENTS }/${ PATH_TRAINING_EXPENSES }`);
    }
  }

  deleteFiles = async (toDelete, componentName) => {
    let success = true;

    for (const deleteFile of toDelete) {
      if (!success) return;

      this.setState({
        progressPercent: 50,
        progressText: deleteFile.name
      });

      success = await deleteFile.delete();

      if (!success) {
        let errors = { error: true };
        errors[componentName] = `Failed to remove ${ deleteFile.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      } else {
        this.setState({
          progressPercent: 100,
        });
      }
    }

    return success;
  }

  uploadFiles = async (toUpload, componentName) => {
    let success = true;

    for (const uploadFile of toUpload) {
      if (!success) return;

      success = await uploadFile.upload({
        onUploadProgress: (percent) => {
          this.setState({
            progressPercent: percent,
            progressText: uploadFile.name
          });
        }
      });

      if (!success) {
        let errors = { error: true };
        errors[componentName] = `Failed to upload ${ uploadFile.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      }
    }

    return success;
  }

  updateComponentFiles = async (data, componentName) => {
    console.log(componentName, data)
    let receipts = [];
    if (this.props.reimbursement[componentName]) {
      this.props.reimbursement[componentName].forEach((expense) => {
        if (expense.receipts) { receipts = [ ...receipts, ...expense.receipts ]; }
      });
    }

    let files = [];
    if (data[componentName]) {
      data[componentName].forEach((expense) => {
        if (expense.files) { files = [ ...files, ...expense.files ]; }
      });
    }

    let toDelete = [];
    let toUpload = [];
    ChosenFile.syncFiles((this.isDuplication ? [] : receipts), files, toDelete, toUpload);
    let success = await this.deleteFiles(toDelete, componentName);

    if (success) {
      success = await this.uploadFiles(toUpload, componentName);

      if (toUpload.length > 0) {
        // Update the uploaded state
        data[componentName].forEach((expense) => {
          console.log(expense)
          expense.receipts = (expense.files ? expense.files.map((file) => { return file.stringify(); }) : []);
          let newState = {};
          newState[componentName] = data[componentName];
          this.setState(newState);
        });
      }
    }

    return success;
  }

  updateFiles = async (data) => {
    let toDelete = [];
    let toUpload = [];

    ChosenFile.syncFiles((!this.props.reimbursement.invoices || this.isDuplication ? [] : this.props.reimbursement.invoices), data.files, toDelete, toUpload);

    let success = await this.deleteFiles(toDelete, 'invoices');

    if (success) {
      success = await this.uploadFiles(toUpload, 'invoices');

      // Update the uploaded state
      data.invoices = data.files.map((file) => { return file.stringify(); });
      this.setState({
        invoices: data.invoices
      });
    }

    if (success) {
      success = await this.updateComponentFiles(data, 'accommodation');
    }

    if (success) {
      success = await this.updateComponentFiles(data, 'airtravel');
    }

    if (success) {
      success = await this.updateComponentFiles(data, 'vehicle');
    }

    return success;
  }

  create = async (data) => {
    this.setState({ loading: 'Syncing Files' });

    const success = await this.updateFiles(data);

    if (success) {
      this.setState({ loading: 'Creating' });
      try {
        await postFireDepartmentReimbursement(data);
        if (this.props.reimbursement.id) {
          this.goToList();
        }
      } catch (apiError) {
        let errors = { error: true };
        if (apiError.message.includes('already exists')) {
          errors.coursename = apiError.message;
        }
        this.setState({ errors: errors });
      }
    }

    this.setState({ loading: false });
  }

  update = async (data) => {
    this.setState({ loading: 'Syncing Files' });

    const success = await this.updateFiles(data);

    if (success) {
      this.setState({ loading: 'Updating' });
      try {
        await updateFireDepartmentReimbursement(data);
        if (this.props.reimbursement.id) {
          this.goToList();
        }
      } catch (apiError) {
        let errors = { error: true };
        if (apiError.message.includes('already exists')) {
          errors.coursename = apiError.message;
        }
        this.setState({ errors: errors });
      }
    }

    this.setState({ loading: false });
  }

  cancel = event => {
    event.preventDefault();

    if (this.props.currentUser.role === 'Admin') {
      this.isClean();
      this.props.history.goBack();
    } else {
      // Back to the list
      this.goToList();
    }
  }

  render = () => {
    // Return only active trainers.
    let trainers = this.props.trainers.filter(({ type }) => {
      return type === 'A';
    });
    trainers = trainers.map(({ trainerId, trainername }) => {
      return { key: trainerId, value: trainerId, text: trainername };
    });
    const trainer = trainers.find((t) => { return t.key === this.state.instructor_id });
    const courses = Object.keys(REIMBURSEMENT_COURSES).map((key) => { return { key: key, value: key, text: REIMBURSEMENT_COURSES[key] }});

    return (
      <div className='fire-department-reimbursement'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading } percent={ this.state.progressPercent } text={ this.state.progressText }/>;
              }

              return (
                <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
                <Header size='medium'>
                  <Grid columns='equal' verticalAlign='bottom'>
                    <Grid.Column>
                      <Header.Content>Training Reimbursement Request {this.state.requestNumber ? 'ID: ' + this.state.requestNumber : ''}<br /></Header.Content>
                      <Header.Subheader>
                        Review the Standard Operating Procedure(s) Related to this page
                        <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ EXTERNAL_PATH_FIN2 } label="FIN2" />
                        <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ EXTERNAL_PATH_FIN3 } label="FIN3" />
                      </Header.Subheader>
                    </Grid.Column>
                    <Grid.Column>
                      { this.state.status === 'Pending' &&
                        <Form.Button floated='right' className='form-top-button' positive>{ (this.state.isNew || this.isDuplication ? 'Submit' : 'Update') } Request</Form.Button>
                      }
                      <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
                    </Grid.Column>
                  </Grid>
                </Header>
                  <Segment.Group size='large' className='view-segment-group'>
                    <Segment disabled={ this.state.isReadOnly }>
                      <Header content='Course' size='tiny' />
                      <Form.Group>
                        { this.state.isReadOnly &&
                          <Form.Input readOnly label='Instructor' value={ trainer ? trainer.text : '' } width={ 5 }/>
                        }
                        { !this.state.isReadOnly &&
                          <Form.Select required label='Instructor' name='instructor_id' value={ this.state.instructor_id } options={ trainers } width={ 5 }
                            error={ !!this.state.errors.instructor_id }
                            onChange={ this.handleInstructorChange }
                          />
                        }
                        { this.state.isReadOnly &&
                          <Form.Input readOnly label='Type' value={ this.state.coursename || '' } width={ 4 }/>
                        }
                        { !this.state.isReadOnly &&
                          <Form.Select required label='Type' name='coursename' value={ this.state.coursename } options={ courses } width={ 4 }
                            error={ !!this.state.errors.coursename }
                            onChange={ this.handleChange }
                          />
                        }
                        <Form.Field readOnly={ this.state.isReadOnly } required label='Completion Date' name='dateattended' width={ 3 }
                          control={ DateControl } date={ this.state.dateattended } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                          error={ !!this.state.errors.dateattended }
                          onChange={ this.handleDateChange }
                        />
                        <Form.Field readOnly={ this.state.isReadOnly } required label='Course Fees' value={ this.state.tuitionamount } name='tuitionamount' width={ 2 }
                          error={ !!this.state.errors.tuitionamount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                        <Form.Field readOnly={ this.state.isReadOnly } required value={ this.state.tuitiongst } name='tuitiongst' width={ 2 }
                          label={ <TaxCalculator disabled={ this.state.isReadOnly } label='Course Fees GST' type='gst' name='tuitiongst' amount={ this.state.tuitionamount } onCalculate={ this.handleTaxChange.bind(this) }/> }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.instructor_id }{ this.state.errors.coursename }{ this.state.errors.dateattended }{ this.state.errors.tuitionamount }
                      </Message>
                    </Segment>

                    <Segment disabled={ this.state.isReadOnly }>
                      <Form.Group>
                        <Form.Field required disallowDeletion={ this.state.isReadOnly } width={ 16 }
                          control={ FileChooserSegment } label='Receipts' name='files' files={ this.state.files } multiple
                          ui={ this.state.ui.invoices } onUIStateChange={ this.handleInvoicesUIStateChange }
                          onChange={ this.handleInvoicesChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.invoices }
                      </Message>
                    </Segment>

                    <Segment disabled={ this.state.isReadOnly && this.state.status !== 'Pending' }>
                      <Header content='Expenses' size='tiny' />
                      <Grid columns={ 2 }>
                        <Grid.Row stretched>
                          <Grid.Column>
                            <BackfillSegment disabled={ this.state.isReadOnly } backfill={ this.state.backfill } totbackfillamount={ this.state.totbackfillamount}
                              ui={ this.state.ui.backfill } onUIStateChange={ this.handleComponentUIStateChange }
                              edit={ this.startEditing } remove={ this.handleComponentRemove }
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <AccommodationSegment disabled={ this.state.isReadOnly } accommodation={ this.state.accommodation } totalbeforetax={ this.state.totalbeforetax }
                              gst={ this.state.gst } hoteltax={ this.state.hoteltax } totalaftertax={ this.state.totalaftertax }
                              ui={ this.state.ui.accommodation } onUIStateChange={ this.handleComponentUIStateChange }
                              onCurrencyStateChange={ this.handleCurrencyChange } onTaxCalculate={ this.handleTaxChange }
                              edit={ this.startEditing } remove={ this.handleComponentRemove }
                            />
                            <Message error>
                              { this.state.errors.accommodation }
                            </Message>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row stretched>
                          <Grid.Column>
                            <MealSegment disabled={ this.state.isReadOnly } meal={ this.state.meal } totalmealamount={ this.state.totalmealamount }
                              ui={ this.state.ui.meal } onUIStateChange={ this.handleComponentUIStateChange }
                              edit={ this.startEditing } remove={ this.handleComponentRemove }
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <AirTravelSegment disabled={ this.state.isReadOnly } airtravel={ this.state.airtravel }
                              totalairtravelamount={ this.state.totalairtravelamount } totalairtravelgst={ this.state.totalairtravelgst }
                              totalairtravelaftertax={ this.state.totalairtravelaftertax }
                              ui={ this.state.ui.airtravel } onUIStateChange={ this.handleComponentUIStateChange }
                              edit={ this.startEditing } remove={ this.handleComponentRemove }
                            />
                            <Message error>
                              { this.state.errors.airtravel }
                            </Message>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row stretched>
                          <Grid.Column>
                            <MileageSegment disabled={ this.state.isReadOnly } mileage={ this.state.mileage } totalmileageamount={ this.state.totalmileageamount }
                              ui={ this.state.ui.mileage } onUIStateChange={ this.handleComponentUIStateChange }
                              edit={ this.startEditing } remove={ this.handleComponentRemove }
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <VehicleSegment disabled={ this.state.isReadOnly } vehicle={ this.state.vehicle } totalvehicleaftertax={ this.state.totalvehicleaftertax }
                              vehiclegst={ this.state.vehiclegst } vehiclepst={ this.state.vehiclepst }
                              ui={ this.state.ui.vehicle } onUIStateChange={ this.handleComponentUIStateChange }
                              onCurrencyStateChange={ this.handleCurrencyChange } onTaxCalculate={ this.handleTaxChange }
                              edit={ this.startEditing } remove={ this.handleComponentRemove }
                            />
                            <Message error>
                              { this.state.errors.vehicle }
                            </Message>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={ 16 }>
                            <Segment disabled={ this.state.isReadOnly && this.state.status !== 'Pending' }>
                              <Form.Group>
                                <Form.Input readOnly={ this.state.isReadOnly && this.state.status !== 'Pending' } required
                                  label='Payable To' value={ this.state.payableto } name='payableto' width={ 16 }
                                  error={ !!this.state.errors.payableto }
                                  onChange={ this.handleChange }
                                />
                              </Form.Group>
                              <Message error>
                                { this.state.errors.payableto }
                              </Message>
                            </Segment>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={ 16 }>
                            <Segment disabled={ this.state.isReadOnly } textAlign='right'>
                              <Form.Group>
                                <Form.Field label='Total Amount Claimed' value={ this.state.totalamountclaimed } width={ 3 }
                                  control={ CurrencyText }
                                />
                                <Form.Field label='Hotel Tax' value={ this.state.hoteltax } width={ 3 }
                                  control={ CurrencyText }
                                />
                                <Form.Field label='GST' value={ this.state.totalgst } width={ 3 }
                                  control={ CurrencyText }
                                />
                                <Form.Field label='PST' value={ this.state.totalpst } width={ 3 }
                                  control={ CurrencyText }
                                />
                                <Form.Field label='Total After Taxes' value={ this.state.totalamountaftertax } width={ 4 }
                                  control={ CurrencyText }
                                />
                              </Form.Group>
                            </Segment>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Segment.Group>
                </Form>
              );
            })()}

            { this.state.editing.backfill &&
              <BackfillEditDialog open={ !!this.state.editing.backfill } backfill={ this.state.editing.backfill }
                disabled={ this.state.isReadOnly } onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
              />
            }

            { this.state.editing.accommodation &&
              <AccommodationEditDialog open={ !!this.state.editing.accommodation } accommodation={ this.state.editing.accommodation }
                disabled={ this.state.isReadOnly } onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
              />
            }

            { this.state.editing.meal &&
              <MealEditDialog open={ !!this.state.editing.meal } meal={ this.state.editing.meal }
                disabled={ this.state.isReadOnly } onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
              />
            }

            { this.state.editing.airtravel &&
              <AirTravelEditDialog open={ !!this.state.editing.airtravel } airtravel={ this.state.editing.airtravel }
                disabled={ this.state.isReadOnly } onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
              />
            }

            { this.state.editing.mileage &&
              <MileageEditDialog open={ !!this.state.editing.mileage } mileage={ this.state.editing.mileage }
                disabled={ this.state.isReadOnly } onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
              />
            }

            { this.state.editing.vehicle &&
              <VehicleEditDialog open={ !!this.state.editing.vehicle } vehicle={ this.state.editing.vehicle }
                disabled={ this.state.isReadOnly } onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
              />
            }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    reimbursement: storeState.models.fireDepartmentReimbursement,
    trainers: storeState.models.fireDepartmentTrainers,
    ui: storeState.ui.fireDepartmentReimbursement,
    fireDepartments: storeState.lookups.fireDepartments,
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentReimbursement);
