import React from "react";
import { Form, Transition, Message, Table, Header, Input, Divider, Button, Modal } from 'semantic-ui-react';
import { validateWorksafeID, searchOrganizations } from '../api/bridgeAPI';
//
import ViewLoader from './ViewLoader';
// import { Modal, Row, Col, Typography, Input, Button, Divider, Table, Tag } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
//
// import Utils from '../../../components/Utils';
// import Globals from '../../../config/Globals';
//
// import CustomComponent from '../../../components/CustomComponent';
//
// import CommonOrganizationSelectionConfirmationDrawer from './OrganizationSelectionConfirmationDrawer';
//
const NEW_STATE = { 
  isLoading: false, isVisible: false, 
  searchTerm: '', worksafeID: '', 
  data: null, validationFailed: false,
  selectedOrg: null, validationVisible: false
};
//props are: app, requiresWorksafeValidation, allowOrgsListing, onSelection
export default class CommonOrganizationSelectionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = NEW_STATE;
  }
  //Public
  show() { this.setState({ ...NEW_STATE, isVisible: true }, () => (this.props.allowOrgsListing ? this._search() : null)); }
  //Actions
  handleClose() { this.setState({ isVisible: false }); }
    //Search actions
  handleSearchChange(e) { this.setState({ searchTerm: e.target.value }, this.handleSearch); }
  handleSearch() { 
    if (this.state.searchTerm && this.state.searchTerm.length > 0) this._scheduleSearch(); 
    else if (this.props.allowOrgsListing) this._scheduleSearch(); 
    else this.setState({ data: null });
  }
    //Table actions
  handleSelectOrg(item) {
    this.setState({ selectedOrg: item }, () => {
      if (this.props.requiresWorksafeValidation) this.setState({ validationVisible: true });
      else this.handleModalValidationCompleted();
    });
  }
    //Validation actions
  handleWSIDChange(e, { name, value }) { this.setState({ [name]: value }); }
  handleValidateWorksafeID() { this._validateWorksafeID(); }
    //Shortcuts
  handleModalValidationCompleted() { 
    if (this.props.onSelection) this.props.onSelection(this.state.selectedOrg); 
    this.handleClose();
  }

  //UI
  render() {
    return (
      <Modal title="Company selection" open={this.state.isVisible} size='small'>
        <Header content='Company Selection'/>
        <Modal.Content> 
          <Transition.Group animation={'fade'} duration={250}>
            {this.state.validationVisible && this._renderValidation()}
            {!this.state.validationVisible && this._renderOrgSelection()}
          </Transition.Group>
        </Modal.Content>
        <Modal.Actions>
          <div style={{marginBottom: 4}}>
            <Button type='secondary' style={{ marginRight: 10 }} color='red' content='Cancel' onClick={this.handleClose.bind(this)}/>
            {this.state.validationVisible && 
              <Button style={{ marginLeft: 10 }} type='primary' color='green' content='Confirm' onClick={this.handleValidateWorksafeID.bind(this)}
                disabled={this.state.worksafeID.length <= 0 || this.state.isLoading}/>
            }
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
  /* private UI */
  _renderValidation() {
    const { selectedOrg, worksafeID, validationFailed } = this.state;
    if (!selectedOrg) return (<></>);
    return (
      <div key='validationView'>
        <Form onSubmit={this.handleSubmit} error={this.state.validationFailed}>
          <Form.Group>
            <Form.Input readOnly label='Company Name' name='name' value={selectedOrg.name} width={ 8 }/>
            <Form.Input readOnly label='Company City' name='city' width={ 4 } value={(selectedOrg.address ? selectedOrg.address.city : null) || 'N/A'}/>
          </Form.Group>
          <Divider/>
          <h4>Please, confirm the company <strong>"{selectedOrg.name}"</strong> worksafe number in order to confirm you relation with it.</h4>
          <Form.Input required error={this.state.validationFailed} label='Worksafe ID' placeholder='worksafe ID #' name='worksafeID' value={worksafeID} onChange={this.handleWSIDChange.bind(this)}/>
          {validationFailed && <Message error>Unable to verify your association. Please, verify the worksafe ID you entered matches with the selected company.</Message>}
        </Form>
        {this.state.isLoading && this._renderLoadingView()}
      </div>
    );
  }
  _renderOrgSelection() {
    return (
      <div key='orgSelectionView'>
        {/* Search bar */}
        <Input fluid icon='search' placeholder='Search...' onChange={this.handleSearchChange.bind(this)} />
        {/* Table section */}
        <Divider/>
        {(!this.state.data || this.state.data.length === 0 || this.state.isLoading) ?
          (this.state.isLoading ? this._renderLoadingView() : 
            <Message info size='large'>{this.state.searchTerm.length > 0 ? 'No companies found!' : 'Please, search for your company above'}</Message> ) :
          <Table compact='very'>
            <Table.Body>
              {(this.state.data || []).map((d) => (
                <Table.Row key={d.id}>
                  <Table.Cell>{d.name}</Table.Cell>
                  <Table.Cell textAlign='right'>
                    <Button size='tiny' type='primary' color='green' onClick={this.handleSelectOrg.bind(this, d)}> Select </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        }
      </div>
    );
  }
  _renderLoadingView() {
    return (
      <div style={{ backgroundColor: 'black', zIndex: 99999, width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, opacity: .5 }}> 
        <ViewLoader home loading={ this.state.isLoading }/> 
      </div> 
    );
  }

    /* private - debounces */
  _scheduleSearch() {
    if (this.state.isLoading) return;
    if (this.debounce) clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this._search();
      this.debounce = null;
    }, 500);
  }
  /* private - API */
  async _search() {
    this.setState({ data: [], isLoading: true });
    //request
    const resp = await searchOrganizations(this.state.searchTerm || '', { from: 0 });
    console.log(resp);
    if (resp.bridgeBody && resp.bridgeBody.orgs) {
      const orgs = resp.bridgeBody.orgs.filter((o) => !o.deletionDate);
      this.setState({ data: orgs, isLoading: false });
    } else {
      this.setState({ data: null, isLoading: false });
    }
  }
  async _validateWorksafeID() {
    this.setState({ isLoading: true, validationFailed: false });
    //request
    const resp = await validateWorksafeID(this.state.selectedOrg.id, this.state.worksafeID);
    console.log(resp);
    if (resp.bridgeBody && resp.bridgeBody.transactionID) this.handleModalValidationCompleted();
    else this.setState({ isLoading: false, validationFailed: true });
  }
}
