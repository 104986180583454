import React from 'react';

import PropTypes from 'prop-types';

import ConfirmMultiPopup from './ConfirmMultiPopup';
import IconButton from './IconButton';

class ActionMultiButton extends React.Component {
  render() {
    const { onConfirm, onCancel, hide, header, content, canceltext, oktext, selectOptions, ...props } = this.props;

    const button = (
      <IconButton name='archive' color='orange' className={ 'action-button' + (this.props.hide ? ' hidden' : '') } { ...props }/>
    );

    const confirm = (
      <ConfirmMultiPopup onConfirm={ onConfirm || (()=>{}) }
                    selectOptions={ selectOptions }
                    onCancel={ onCancel }
                    header={ header }
                    content={ content }
                    canceltext={ canceltext }
                    oktext={ oktext }
                    on='click'>
        { button }
      </ConfirmMultiPopup>
    );

    return onConfirm ? confirm : (this.props.onClick ? button : null);
  }
};

ActionMultiButton.propTypes = {
  onConfirm: PropTypes.func,
  onClick: PropTypes.func,
  hide: PropTypes.bool,
};

export default ActionMultiButton;
