import React, { Component } from "react";

class AdminTools extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="pg-content__title">
          <button>
            <i className="bars icon"></i>
          </button>
          <h1>Emails</h1>
        </div>
        <div className="pg-content__body">
          Page Content Here
        </div>
      </React.Fragment>
    );
  }
}

export default AdminTools;