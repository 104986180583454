import { get, post, del, put } from './api';

import { UPDATE_USER, UPDATE_USER_TA, UPDATE_USERS } from '../js/actionTypes';
import { API_USER, DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { PATH_USER, PATH_USER_RESET, PATH_FILE } from '../js/paths';

import store from '../js/store';

import { formatDateTime, sortableDateTime } from '../utils/date';

function parseUser(user) {
  if (user.id) {
    user.timestamp = user.registration_date;
    user.key = user.id + '+' + user.timestamp;
    user.displayDate = formatDateTime(user.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    user.sortDate = sortableDateTime(user.timestamp);

    user.address = {
      street: user.address_street || '',
      number: user.address_number || '',
      compliment: user.address_compliment || '',
      city: user.address_city || '',
      province: user.address_province || '',
      postalcode: user.address_postalcode || ''
    };
  }

  return user;
}

// UPLOAD

export async function obtainGetURL(fileId) {
  const response = await get(API_USER, `/${ PATH_FILE }/${ fileId }`, {});
  return response.url || '';
}

export async function obtainPutURL(fileId, noAuth) {
  const response = await put(API_USER, `/${ PATH_FILE }/${ fileId }`, {}, { noAuthRequired: noAuth });
  return response.url || '';
}

export async function obtainDelURL(fileId) {
  const response = await del(API_USER, `/${ PATH_FILE }/${ fileId }`, {});
  return response.url || '';
}

// Auth

export async function signUpUser(body) {
  return await post(API_USER, `/${ PATH_USER }`, body, { noAuthRequired: true });
}

export async function adminResetUser(body) {
  return await post(API_USER, `/${ PATH_USER_RESET }`, body, { });
}


// Users

export async function getUsers(type) {
  // Will return an array of users when called by an admin
  const response = await get(API_USER, `/${ PATH_USER }?type=${type}`);

  store.dispatch({ type: UPDATE_USERS, users: response });
}

export async function getUser(id) {
  // Will return the data of the current registered user when called by CC
  // Will return registered user associated with <id> when called by others
  const ccId = id ? `/${ id }` : '';

  const response = await get(API_USER, `/${ PATH_USER }${ ccId }`);
  console.log("resp:", response)

  const user = response.length === 1 ? parseUser(response[0]) : {};

  store.dispatch({ type: UPDATE_USER, user: user });
}

export async function getUserTA(id) {
  // Will return the data of the current registered user when called by CC
  // Will return registered user associated with <id> when called by others
  const ccId = id ? `/${ id }?type=taid` : '';

  const response = await get(API_USER, `/${ PATH_USER }${ ccId }`);

  const user = response.length === 1 ? parseUser(response[0]) : {};

  store.dispatch({ type: UPDATE_USER_TA, ta: user });
}

export async function updateUser(body) {
  const response = await put(API_USER, `/${ PATH_USER }`, body);

  const user = parseUser(response);

  store.dispatch({ type: UPDATE_USER, user: user });
}
