import React from 'react';

import { Modal, Form } from 'semantic-ui-react';

import PropTypes from 'prop-types';

class EditDialog extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.props.isValid()) {
      if (this.props.didChange()) {
        this.props.onSave();
      } else {
        this.props.onClose();
      }
    }
  }

  render = () => {
    const { isValid, didChange, onSave, saveText, closeText, className, readOnly, header, children, error, isNotSubmit, ...props } = this.props;

    return <Modal className={ `edit-dialog ${ className || '' }` } closeOnDimmerClick={ false } { ...props }>
      <Modal.Header>{ header }</Modal.Header>
      <Modal.Content>
        <Form onSubmit={ this.handleSubmit } error={ error === true }>
          { children }
          <Form.Group className='actions'>
            <Form.Button type='button' onClick={ this.props.onClose }>{ closeText || 'Close' }</Form.Button>
            { !readOnly &&
              <Form.Button primary type={ (isNotSubmit ? 'button' : 'submit') } { ...isNotSubmit ? { onClick: this.handleSubmit } : {} }>{ saveText || 'Save' }</Form.Button>
            }
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>;
  }
}

EditDialog.propTypes = {
  isValid: PropTypes.func.isRequired,
  didChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,

  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,

  error: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  saveText: PropTypes.string,
  closeText: PropTypes.string,
  isNotSubmit: PropTypes.bool,
};

export default EditDialog;
