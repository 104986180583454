import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon } from 'semantic-ui-react';

import { changeAdminPPE, getAdminPPEs } from '../../api/administratorAPI';

import { UPDATE_PPES_UI } from '../../js/actionTypes';
import { PATH_PPE, PATH_ADMIN } from '../../js/paths';

import { sort } from '../../utils/utils';

import ApprovalButton from '../../components/ApprovalButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';
import SearchableFilter from '../../components/SearchableFilter';

import RejectReasonEditDialog from '../../dialogs/RejectReasonEditDialog';

import ListView from '../ListView';

class AdminPPE extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      editing: null
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    if (this.filterObjFds !== undefined) this.filterObjFds.setFDs(this.props.fireDepartments, true);
    if (this.filterObj !== undefined) {
      this.filterObj.setFilterItems(this.getAvailableStates(), ()=> {
        this.fetch();
        this.isClean();
      });
    }
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    let selectedStatus = this.filterObj.getSelectedText();
    await getAdminPPEs(selectedStatus);
    this.setState({ loading: false });
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await changeAdminPPE(data);
    this.setState({ loading: false });
  }

  handleApprove = async (id, timestamp, amount) => {
    await this.update({
      id: id,
      timestamp: timestamp,
      status: 'Approved',
      amount: amount,
    });
    await this.fetch();
  }

  handleReject = (id, timestamp) => {
    this.getRejectReason({
      id: id,
      timestamp: timestamp,
      status: 'Rejected',
    });
  }

  closeDialog = () => { this.setState({ editing: null }); }

  saveRejectReason = async (rejectreason) => {
    let data = { ...this.state.editing };
    data.rejectreason = rejectreason;
    this.closeDialog();
    await this.update(data);
    await this.fetch();
  }

  getRejectReason = (data) => {
    this.setState({ editing: data });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_PPES_UI, adminPPEs: this.state.ui });
    });
  }

  //Filter support
  handleFilterChange(filter,value) {
    if (filter !== this.filterObjFds) { this.fetch(); }
    else { this.forceUpdate(); }
  }
  getAvailableStates() { return ["Pending","Approved","Rejected"]; }

  render() {
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={6} floated='left'>
            <Header size='medium'>Personal Protection Equipment Requests</Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingBottom: 0}} width={8} floated='right'>
            <SearchableFilter ref={(r)=>this.filterObjFds=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={"All"}/>
            <SearchableFilter ref={(r)=>this.filterObj=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={"Pending"}/>
          </Grid.Column>
          <Grid.Column width={15}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              console.log("adminPPEs:", this.props.adminPPEs)

              if (this.props.adminPPEs && !this.props.adminPPEs.length) {
                return <Message info size='large'>
                  You don&apos;t have any PPEs.
                </Message>;
              }

              let ppes = sort(this.props.adminPPEs, this.state.ui.sortField);

              //filter with status
              if (this.filterObjFds && this.filterObjFds.getSelectedText() !== "") {
                let nPpes = [];
                let selectedFD = this.filterObjFds.getSelectedText();
                for (var i = 0; i < ppes.length; i++) {
                  if (ppes[i].fdName === selectedFD || selectedFD === 'All') nPpes.push(ppes[i]);
                }
                ppes = nPpes;
              }

              if (this.state.ui.sortDescending) {
                ppes.reverse();
              }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',              title: 'Date',            width: '3' },
                { field: 'fdName',                title: 'Fire Department', width: '5' },
                { field: 'status',                title: 'Status',          width: '3' },
                { field: 'amount',                title: 'Total Amount',    width: '3' },
                { field: 'buttons', noSort: true, title: '',                width: '2' },
              ]}>
                { ppes.map(({ key, status, displayDate, fdName, id, timestamp, amount }) => (
                  //this.props.fireDepartments.filter(item => { return item.id === id ? item : null ; });
                  <SortTable.Row key={ key }>
                    <SortTable.Cell>{ displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ fdName }</SortTable.Cell>
                    <SortTable.Cell>{ status }</SortTable.Cell>
                    <SortTable.Cell>${ amount }</SortTable.Cell>
                    { status === 'Pending' &&
                      <SortTable.Cell textAlign='right'>
                        <ApprovalButton
                          onApproval={ this.handleApprove.bind(this, id, timestamp, amount) }
                          onRejection={ this.handleReject.bind(this, id, timestamp) }
                        />
                        <EditButton pathname={ `/${ PATH_ADMIN }/${ PATH_PPE }/${ id }/${ timestamp }` }/>
                      </SortTable.Cell>
                    }
                    { status === 'Approved' &&
                      <SortTable.Cell textAlign='right'><Icon name='clipboard check' size='large' color='green' /></SortTable.Cell>
                    }
                    { status === 'Rejected' &&
                      <SortTable.Cell textAlign='right'><Icon name='clipboard' size='large' color='red' /></SortTable.Cell>
                    }
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.state.editing &&
              <RejectReasonEditDialog open={ !!this.state.editing } onSave={ this.saveRejectReason } onClose={ this.closeDialog } />
            }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    adminPPEs: storeState.models.adminPPEs,
    ui: storeState.ui.adminPPEs,
    fireDepartments: storeState.lookups.fireDepartments,
  };
}

export default connect(mapStoreStateToProps)(AdminPPE);
