import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon } from 'semantic-ui-react';

import { getAdminRegistrations, updateAdminRegistration } from '../../api/administratorAPI';

import { UPDATE_ADMIN_REGISTRATIONS_FD_UI } from '../../js/actionTypes';
import { PATH_ADMIN, PATH_REGISTRATIONS, PATH_FIRE_DEPARTMENT } from '../../js/paths';

import { sort } from '../../utils/utils';

import ActionMultiButton from '../../components/ActionMultiButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import RejectReasonEditDialog from '../../dialogs/RejectReasonEditDialog';

import ListView from '../ListView';

class AdminRegistrationsFD extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      title: 'Fire Department Applications',

      editing: null
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getAdminRegistrations('Pending|Voting', 'FD');
    this.setState({ loading: false });
  }

  closeDialog = () => { this.setState({ editing: null }); }

  saveRejectReason = async (rejectreason) => {
    let data = { ...this.state.editing };
    data.rejectreason = rejectreason;
    data.status = 'Rejected';
    this.closeDialog();
    await this.update(data);
  }

  getRejectReason = (data) => {
    this.setState({ editing: data });
  }

  changeStatus = async (registration, status) => {
    if (status === 'Rejected') {
      this.getRejectReason(registration);
    } else {
      registration.status = status;
      await this.update(registration);
    }
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await updateAdminRegistration(data);
    await this.fetch();
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REGISTRATIONS_FD_UI, adminRegistrationsFD: this.state.ui });
    });
  }

  render() {
    return (
      <div className='admin-registrations-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>{ this.state.title }</Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (this.props.registrations && !this.props.registrations.length) {
                return <Message info size='large'>
                  You don&apos;t have any new fire department registrations.
                </Message>;
              }

              let registrations = sort(this.props.registrations, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                registrations.reverse();
              }

              const right = { textAlign: 'right' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',              title: 'Submitted',       width: '3'               },
                { field: 'entity_name',           title: 'Fire Department', width: '5'               },
                { field: 'address_city',          title: 'City',            width: '3'               },
                { field: 'status',                title: 'Status',          width: '2'               },
                { field: 'maxallowedtrainers',    title: 'Instructors',     width: '2', style: right },
                { field: 'buttons', noSort: true, title: '',                width: '1'               },
              ]}>
                { registrations.map((registration) => (
                  <SortTable.Row key={ registration.key }>
                    <SortTable.Cell>{ registration.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ registration.entity_name }</SortTable.Cell>
                    <SortTable.Cell>{ registration.address_city }</SortTable.Cell>
                    <SortTable.Cell>{ registration.status }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>{ registration.maxallowedtrainers }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      { (registration.status === 'Pending' || registration.status === 'Voting') &&
                        <ActionMultiButton header='Change Status'
                          selectOptions={ registration.status === 'Pending' ? [ 'Voting', 'Approved', 'Rejected' ] : [ 'Approved', 'Rejected' ] }
                          onConfirm={ status => this.changeStatus(registration, status) }
                        />
                      }
                      { registration.status === 'Approved' &&
                        <Icon name='clipboard check' size='large' color='green' />
                      }
                      { registration.status === 'Rejected' &&
                        <Icon name='clipboard' size='large' color='red' />
                      }
                      <EditButton view={ registration.status !== 'Voting' } pathname={ `/${ PATH_ADMIN }/${ PATH_REGISTRATIONS }/${ PATH_FIRE_DEPARTMENT }/${ registration.id }` }/>
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.state.editing &&
              <RejectReasonEditDialog open={ !!this.state.editing } onSave={ this.saveRejectReason } onClose={ this.closeDialog } />
            }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    registrations: storeState.models.adminRegistrations,
    ui: storeState.ui.adminRegistrationsFD,
  };
}

export default connect(mapStoreStateToProps)(AdminRegistrationsFD);
