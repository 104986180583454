import React from 'react';

import PropTypes from 'prop-types';

import ConfirmPopup from './ConfirmPopup';
import IconButton from './IconButton';

class ApprovalButton extends React.Component {
  render() {
    const { onApproval, onRejection, hide, header, ...props } = this.props;

    return (
      <ConfirmPopup onConfirm={ onApproval } onCancel={ onRejection } header={ header || 'Request Approval' } oktext='Approve' canceltext='Reject' on='click'>
        <IconButton name='clipboard list' color='blue' className={ 'approval-button' + (hide ? ' hidden' : '') } { ...props }/>
      </ConfirmPopup>
    );
  }
};

ApprovalButton.propTypes = {
  onApproval: PropTypes.func.isRequired,
  onRejection: PropTypes.func,
  onClick: PropTypes.func,
  header: PropTypes.string,
  hide: PropTypes.bool,
};

export default ApprovalButton;
