import React from 'react';

import { Segment, Header, Message, Icon, Popup, Input, Progress } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import DeleteButton from './DeleteButton';
import FileChooserButton from './FileChooserButton';
import IconButton from './IconButton';
import SortTable from './SortTable';

import { sort } from '../utils/utils';

class FileChooserSegment extends React.Component {
  handleIconClick = (entry) => {
    if (entry.uploaded) {
      this.download(entry);
    } else if (this.props.isStandAlone) {
      this.upload(entry);
    }
  }

  test = async (entry) => {
    const uploadUrl = await entry.uploadUrl();
    const downloadUrl = await entry.downloadUrl();
    console.log('uploadUrl', uploadUrl);
    console.log('downloadUrl', downloadUrl);
  }

  download = async (entry) => {
    const downloadUrl = await entry.downloadUrl();

    if (downloadUrl) {
      window.open(downloadUrl);
    }
  }

  upload = async (entry) => {
    const uploaded = await entry.upload({
      onUploadProgress: (percent) => {
        this.forceUpdate();
      }
    });

    if (uploaded) {
      this.forceUpdate();
    }
  }

  handleRemove = (entry) => {
    if (entry.uploaded && this.props.isStandAlone) {
      this.delete(entry);
    } else {
      this.remove(entry);
    }
  }

  delete = async (entry) => {
    const deleted = await entry.delete();

    if (deleted) {
      this.remove(entry);
    }
  }

  remove = (entry) => {
    const filesState = this.props.files.filter((file) => {
      if (file.id !== entry.id) return true;

      // Flag uploaded files for deletion later
      // Keep them in the list, but don't display
      // if (entry.uploaded) {
      //   entry.deleted = true;
      //   return true;
      // }

      return false;
    });

    let stateUpdate = {};
    stateUpdate[this.props.name] = filesState;
    this.props.onChange(stateUpdate);
  }

  updateUIState = (uiState) => {
    this.props.onUIStateChange(uiState);
  }

  render = () => {
    const { label, ui, onUIStateChange, ...inputProps } = this.props;

    if (this.props.withInput) {
      let files = this.props.files.filter(f => { return !f.deleted });

      let message;
      if (files.length === 0) {
        message = this.props.multiple ? 'No files' : 'No file';
      } else {
        message = files.map(f => { return f.name }).join(', ');
      }

      let file;
      if (!this.props.multiple && files.length === 1) {
        file = files[0];
      }

      let downloadStyle = {
        marginLeft: this.props.readOnly ? '0.2em' : '-0.2em',
        marginTop: '0.3em',
        marginRight: '0.3em'
      };

      let deleteStyle = {
        marginLeft: '-0.2em',
        marginTop: '0.3em'
      };

      return (
        <div style={{ display: 'flex'}}>
          <Input readOnly value={ message }/>
          { !this.props.readOnly &&
            <FileChooserButton { ...inputProps }/>
          }
          { file && file.uploaded && !this.props.hideDownload &&
            <IconButton name='cloud download' color='green' style={ downloadStyle } onClick={ this.handleIconClick.bind(this, file) }/>
          }
          { file && !this.props.readOnly && !this.props.disallowDeletion &&
            <DeleteButton content='Remove the file?' style={ deleteStyle } onConfirm={ this.handleRemove.bind(this, file) }/>
          }
        </div>
      );
    }
    return (
      <Segment disabled={ this.props.readOnly }>
        {!this.props.headeless && <Header size='small'>
          <Header.Content>{ this.props.label || this.props.customLabel || 'Files' }</Header.Content>
          { !this.props.readOnly && !(this.props.maxFiles > 0 && this.props.files.length >= this.props.maxFiles) &&
            <FileChooserButton { ...inputProps }/>
          }
        </Header>}

        {(() => {
          let files = this.props.files.filter(f => { return !f.deleted });

          if (files.length === 0) {
            return <Message info compact size='tiny'>{ this.props.multiple ? 'No files' : 'No file' }</Message>;
          }

          files = files.map(f => {
            let file = { ...f };

            let name;
            switch (file.type) {
              case 'application/pdf':
                name = 'file pdf';
                break;
              case 'application/msword':
                name = 'file word';
                break;
              case 'application/zip':
                name = 'file archive';
                break;
              case 'image/jpeg':
              case 'image/png':
              case 'image/gif':
                name = 'file image';
                break;
              default:
                name = 'file alternate';
            }

            file.typeIcon = <Popup trigger={ <Icon name={ name } size='large' color='blue' /> } content={ file.type } />

            let size = file.size;
            let unit = 'B';
            if (size > 1024) {
              size /= 1024;
              unit = 'KB';
            }
            if (size > 1024) {
              size /= 1024;
              unit = 'MB';
            }
            if (size > 1024) {
              size /= 1024;
              unit = 'GB';
            }

            file.displaySize = parseFloat(size.toFixed(1));
            file.unit = unit;

            const status = file.uploaded ? 'Uploaded' : 'Pending';
            file.statusIcon = <Popup content={ status } trigger={
              <Icon name={ file.uploaded ? 'cloud' : 'upload' } size='large' color={ file.uploaded ? 'green' : 'orange' } />
            }/>;

            if (!file.uploaded && file.progress > 0 && file.progress < 100) {
              file.displayName = <Progress percent={ file.progress } progress color='green' style={{ margin: 0 }}>{ file.name }</Progress>;
            } else if (file.uploaded) {
              // Will want to make this a URL to display asset?
              file.displayName = file.name;
            } else {
              file.displayName = file.name;
            }

            return file;
          });

          files = sort(files, this.props.ui.sortField);
          if (this.props.ui.sortDescending) {
            files.reverse();
          }

          const center = { textAlign: 'center' };
          const right = { textAlign: 'right' };

          return (
            <SortTable collapsing sortField={ this.props.ui.sortField } sortDescending={ this.props.ui.sortDescending }
              onSort={ this.updateUIState } size='small'
              headers={[
                { field: 'type',                  title: 'Type',   width: '1',  style: center },
                { field: 'status',                title: 'Status', width: '1',  style: center },
                { field: 'name',                  title: 'Name',   width: '10',               },
                { field: 'size',                  title: 'Size',   width: '2',  style: right  },
                { field: 'buttons', noSort: true, title: '',       width: '2'                 },
              ]}
            >
              { files.map((file, index) => (
                <SortTable.Row key={ index } id={ `file_${ file.timestamp }`}>
                  <SortTable.Cell textAlign='center'>{ file.typeIcon }</SortTable.Cell>
                  <SortTable.Cell textAlign='center' onClick={ this.handleIconClick.bind(this, file) }>{ file.statusIcon }</SortTable.Cell>
                  <SortTable.Cell>{ file.displayName }</SortTable.Cell>
                  <SortTable.Cell textAlign='right'>{ file.displaySize } { file.unit }</SortTable.Cell>
                  <SortTable.Cell textAlign='right'>
                    { file.uploaded && !this.props.hideDownload &&
                      <IconButton name='cloud download' color='green' onClick={ this.handleIconClick.bind(this, file) }/>
                    }
                    {!this.props.readOnly && !this.props.disallowDeletion &&
                      <DeleteButton content='Remove the file?' onConfirm={ this.handleRemove.bind(this, file) }/>
                    }
                  </SortTable.Cell>
                </SortTable.Row>
              ))}
            </SortTable>
          );
        })()}
      </Segment>
    );
  }
};

FileChooserSegment.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  withInput: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  files: PropTypes.array,
  ui: PropTypes.object,
  onUIStateChange: PropTypes.func,
  onChange: PropTypes.func,
  isStandAlone: PropTypes.bool,
  readOnly: PropTypes.bool,
  disallowDeletion: PropTypes.bool,
  hideDownload: PropTypes.bool,
};

export default FileChooserSegment;
