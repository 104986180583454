import React from 'react';
import { Auth } from 'aws-amplify';

import { Grid, Segment, Header, Form, Message } from 'semantic-ui-react';

import { handleAPIError } from '../../api/api';

import store from '../../js/store';

import * as Constant from '../../js/constants';

import { REQUESTS_CLEAR } from '../../js/actionTypes';

const INITIAL_STATE = {
  code: '',
  newPassword: '',
  newPasswordVerifier: '',
  error: {},
  isLoading: false
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  changeAuthState = (type, event) => {
    this.setState({ error: {} });
    this.props.onStateChange(type, event);
  };

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  getUsername = () => this.props.authData && this.props.authData.username ? this.props.authData.username : ''

  handleSubmit = event => {
    event.preventDefault();
    //Check for requirements
    if (this.state.newPassword !== this.state.newPasswordVerifier) {
      this.setState({error: { message: 'Passwords dont match!' }});
      return;
    }
    //Start loading
    store.dispatch({ type: REQUESTS_CLEAR });
    this.setState({ error: {}, isLoading: true });
    //Make request
    Auth.forgotPasswordSubmit(this.getUsername(), this.state.code, this.state.newPassword)
      .then(resp => {
        this.setState({ error: {}, isLoading: false });
        if (!resp) { this.changeAuthState(Constant.AUTH_SIGN_IN); }
        alert('Success! \nPassword reset with success!');
      })
      .catch(error => {
        if (error.code === 'UserNotConfirmedException') {
          this.changeAuthState(Constant.AUTH_CONFIRM_SIGN_UP, { username: this.getUsername() });
        } else if (error.code === 'PasswordResetRequiredException') {
          this.changeAuthState(Constant.AUTH_REQUIRE_NEW_PASSWORD, { username: this.getUsername() });
        }

        this.setState({ error: handleAPIError(error), isLoading: false });
      });
  }

  render() {
    if (this.props.authState !== Constant.AUTH_CHANGE_PASS) return null;

    return (
      <div className='auth-container signin'>
        <Grid centered className='auth-grid'>
          <Grid.Column className='auth-column'>
            <Header size='medium'>
              Reset your password
              <Header.Subheader>
                A verification code has been sent to your email address. Please enter it below to complete your password reset.
              </Header.Subheader>
              <Header.Subheader style={{ fontSize: '0.9rem', marginTop: '1rem' }}>
                Already confirmed?&nbsp;&nbsp;<a href='#signin' onClick={() => this.changeAuthState(Constant.AUTH_SIGN_IN)}>Sign In</a>
              </Header.Subheader>
              <Header.Subheader style={{ fontSize: '0.9rem' }}>
                Lost your code?&nbsp;&nbsp;<a href='#resend' onClick={() => this.changeAuthState(Constant.AUTH_FORGOT_PASS)}>Request new Code</a>
              </Header.Subheader>
            </Header>
            <Form onSubmit={ this.handleSubmit } error={ (this.state.error.message ? true : false) }>
              <Segment size='large' secondary>
                <Form.Input label='Email Address' value={ this.getUsername() } name='email' placeholder='Email Address'
                  required autoComplete='email' onChange={ this.handleChange } disabled={ true }/>
                <Form.Input label='Code' value={ this.state.code } name='code' placeholder='Email code'
                  required onChange={ this.handleChange }/>
                <Form.Input label='New Password' value={ this.state.password } name='newPassword' placeholder='New Password' type='password'
                  data-dd-privacy="mask" required onChange={ this.handleChange }/>
                <Form.Input label='New Password Verifier' value={ this.state.password } name='newPasswordVerifier' placeholder='New Password Confirmation'
                  type='password' data-dd-privacy="mask" required onChange={ this.handleChange }/>
                <Form.Button positive className='centered' loading={ this.state.isLoading }>Reset Password</Form.Button>
                <Message error className='centered' negative size='small'>
                  { this.state.error.message }
                </Message>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default ChangePassword;
