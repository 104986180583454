import React from 'react';

import NumberFormat from 'react-number-format';

import PropTypes from 'prop-types';

class NumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleValueChange = (values) => {
    this.props.onChange(this.props.name, values.floatValue || 0);
  }

  handleOnClick = (event) => {
    if (this.props.onClick) {
      this.props.onClick();
    }

    if (event && event.target) {
      event.target.focus();
      event.target.select();
    }
  }

  render = () => {
    const { onChange, name, ...props } = this.props;

    return (
      <NumberFormat className='number-input' thousandSeparator={ true } decimalScale={ 0 } fixedDecimalScale={ false }
        allowNegative={ false } onValueChange={ this.handleValueChange }
        { ...props } onClick={ this.handleOnClick }
      />
    );
  }
};

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default NumberInput;
