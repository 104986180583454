import React from 'react';

import { Modal, Segment, Header, Button } from 'semantic-ui-react';

import MonthlyTable from '../components/MonthlyTable';

import PropTypes from 'prop-types';

class MonthlyTableDialog extends React.Component {
  render = () => {
    const { onClose, header, label, data, ...props } = this.props;

    return <Modal className='monthly-table-dialog' size='large' { ...props }>
      <Modal.Header>{ header }</Modal.Header>
      <Modal.Content>
        <Segment.Group size='large'>
          <Segment>
            <Header content={ label } size='tiny' />
            <MonthlyTable data={ data }/>
          </Segment>
        </Segment.Group>
        <Button.Group className='actions'>
          <Button type='button' onClick={ onClose }>Close</Button>
        </Button.Group>
      </Modal.Content>
    </Modal>;
  }
}

MonthlyTableDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default MonthlyTableDialog;
