import React from "react";
import { connect } from 'react-redux';

import { Grid, Header, Message, Statistic, Segment } from 'semantic-ui-react';

import { getAdminSpecialPayments, getAdminPPEs, getAdminTRs } from '../../api/administratorAPI';
import { getAllFireDepartmentReimbursements } from '../../api/fireDepartmentAPI';

import { PATH_ADMIN, PATH_REIMBURSEMENTS, PATH_SPECIAL_PAYMENTS, PATH_PPE, PATH_TR } from '../../js/paths';

import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

class AdminHome extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    console.log("FDS:", this.props.fireDepartments);
    await getAllFireDepartmentReimbursements(this.props.fireDepartments, 'Pending');
    await getAdminSpecialPayments('Pending');
    await getAdminPPEs('Pending');
    await getAdminTRs('Pending');
    this.setState({ loading: false });
  }

  goToItem = (loc) => {
    this.props.history.push(`/${ PATH_ADMIN }/${ loc }`);
  }

  render() {
  return (
    <div className='fire-department-special-payment-list'>
      <Grid centered className='view-grid'>
        <Grid.Column className='view-column'>
          <Header size='medium'>
            <Header.Content>Dashboard</Header.Content>
          </Header>
          <div className="pg-content__body">

          {(() => {
            if (this.state.loading) {
              return <ViewLoader loading={ this.state.loading }/>;
            }

            if (!this.props.reimbursementList.length
                && !this.props.specialPaymentList.length
                && !this.props.adminPPEs.length
                && !this.props.adminTRs.length) {
              return <Message info compact size='large'>
                You don&apos;t have any content yet.
              </Message>;
            }
            return (
              <Grid stackable textAlign='center' columns={2}>
                <Grid.Column>
                  <Segment className="clickable" onClick={ this.goToItem.bind(this, PATH_REIMBURSEMENTS) }>
                    <Statistic>
                      <Statistic.Value>{this.props.reimbursementList.length}</Statistic.Value>
                      <Statistic.Label>FD Reimbursements</Statistic.Label>
                    </Statistic>
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment className="clickable" onClick={ this.goToItem.bind(this, PATH_SPECIAL_PAYMENTS) }>
                    <Statistic>
                      <Statistic.Value>{this.props.specialPaymentList.length}</Statistic.Value>
                      <Statistic.Label>Special Payments</Statistic.Label>
                    </Statistic>
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment className="clickable" onClick={ this.goToItem.bind(this, PATH_PPE) }>
                    <Statistic>
                      <Statistic.Value>{this.props.adminPPEs.length}</Statistic.Value>
                      <Statistic.Label>Personal Protection Equipment Requests</Statistic.Label>
                    </Statistic>
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment className="clickable" onClick={ this.goToItem.bind(this, PATH_TR) }>
                    <Statistic>
                      <Statistic.Value>{this.props.adminTRs.length}</Statistic.Value>
                      <Statistic.Label>Trainer Increase Requests</Statistic.Label>
                    </Statistic>
                  </Segment>
                </Grid.Column>
              </Grid>
            )
          })()}
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )}
}
 
function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    specialPaymentList: storeState.models.fireDepartmentSpecialPayments,
    adminPPEs: storeState.models.adminPPEs,
    adminTRs: storeState.models.adminTRs,
    reimbursementList: storeState.models.fireDepartmentReimbursements,
    fireDepartments: storeState.lookups.fireDepartments,
  };
}

export default connect(mapStoreStateToProps)(AdminHome);
