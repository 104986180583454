import React from 'react';

import { Segment, Form, Message } from 'semantic-ui-react';

import EditDialog from '../components/EditDialog';
import NumberInput from '../components/NumberInput';

import { MILEAGE_TYPES } from '../js/lists';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  timestamp: '',
  type: '',
  kms: 0,

  errors: {
    error: false
  }
};

class MileageEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, ...this.props.mileage };

    this.didChange = this.didChange.bind(this);
    this.isValid = this.isValid.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleTypeChange = (event, { value }) => {
    this.setState({
      type: value
    }, () => { this.validate(); });
  }

  handleDistanceChange = (name, value) => {
    this.setState({
      kms: value,
    }, () => { this.validate(); });
  }

  didChange = () => {
    // Always true for new entriesl
    if (!this.props.mileage.timestamp) return true;

    if (this.state.type !== this.props.mileage.type) { return true; }
    if (this.state.kms !== this.props.mileage.kms) { return true; }

    return false;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.type) {
      errors.error = true;
      errors.type = 'Please select a type. ';
    }

    if (!this.state.kms) {
      errors.error = true;
      errors.kms = 'Please enter the number of kms.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    const { errors, ...state } = this.state;
    this.props.onSave('mileage', { ...state });
  }

  render = () => {
    const { onSave, onClose, mileage, ...props } = this.props;

    const types = Object.keys(MILEAGE_TYPES).map((key) => { return { key: key, value: key, text: MILEAGE_TYPES[key] }});

    return (
      <EditDialog size='small' header='Add Mileage Expense' onSave={ this.onSave } onClose={ onClose } readOnly={ this.props.disabled }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Segment.Group size='large'>
          <Segment disabled={ this.props.disabled }>
            <Form.Group>
              { this.props.disabled &&
                <Form.Input readOnly label='Type' value={ MILEAGE_TYPES[this.state.type] } width={ 6 }/>
              }
              { !this.props.disabled &&
                <Form.Select required label='Type' value={ this.state.type } name='type' options={ types } width={ 6 }
                  error={ !!this.state.errors.type }
                  onChange={ this.handleTypeChange }
                />
              }
              <Form.Field readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 6 }
                label='Total of Kms' value={ this.state.kms } name='kms'
                control={ NumberInput } onChange={ this.handleDistanceChange }
                error={ !!this.state.errors.kms }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.type }{ this.state.errors.kms }
            </Message>
          </Segment>
        </Segment.Group>
      </EditDialog>
    );
  }
}

MileageEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  mileage: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default MileageEditDialog;
