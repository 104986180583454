import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Segment, Button, Table } from 'semantic-ui-react';

import { getSettings } from '../../api/administratorAPI';
import { getFireDepartmentReimbursement, getFireDepartmentTrainer } from '../../api/fireDepartmentAPI';

import { PATH_ID, PATH_ADMIN, PATH_REIMBURSEMENTS, PATH_TIMESTAMP } from '../../js/paths';
import { UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT_UI } from '../../js/actionTypes';

import ChosenFile from '../../utils/file';

import ViewLoader from '../../components/ViewLoader';
import CurrencyText from '../../components/CurrencyText';

import FileSummarySegment from '../../components/FileSummarySegment';
import AccomodationSummarySegment from '../../components/AccomodationSummarySegment';
import AirTravelSummarySegment from '../../components/AirTravelSummarySegment';
import BackfillSummarySegment from '../../components/BackfillSummarySegment';
import MealSummarySegment from '../../components/MealSummarySegment';
import MileageSummarySegment from '../../components/MileageSummarySegment';
import VehicleSummarySegment from '../../components/VehicleSummarySegment';

import ListView from '../ListView';

class AdminReimbursementSummary extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      status: 'Pending',
      requestNumber: null,
    
      instructor_id: '',
      instructor_name: '',
    
      coursename: '',
      dateattended: '',
      tuitionamount: 0,
      tuitiongst: 0,
    
      invoices: [],
      payableto: '',
    
      // sub components
    
      backfill:[],
      totbackfillamount: 0,
    
      accommodation: [],
      totalbeforetax: 0,
      gst: 0,
      hoteltax: 0,
      totalaftertax: 0,
    
      meal: [],
      totalmealamount: 0,
    
      airtravel:[],
      totalairtravelamount: 0,
      totalairtravelgst: 0,
      totalairtravelaftertax: 0,
    
      mileage: [],
      totalmileageamount: 0,
    
      vehicle: [],
      vehiclepst: 0,
      vehiclegst: 0,
      totalvehicleaftertax: 0,
    
      totalamountclaimed: 0,
      totalpst: 0,
      totalgst: 0,
      totalamountaftertax: 0,

      files: [],

      ui: {
        backfill: {
          sortField: this.props.ui.backfill.sortField || 'name',
          sortDescending: this.props.ui.backfill.sortDescending === true,
        },
        accommodation: {
          sortField: this.props.ui.accommodation.sortField || 'displayType',
          sortDescending: this.props.ui.accommodation.sortDescending === true,
        },
        meal: {
          sortField: this.props.ui.meal.sortField || 'sortableDate',
          sortDescending: this.props.ui.meal.sortDescending === true,
        },
        airtravel: {
          sortField: this.props.ui.airtravel.sortField || 'sortableDepartureDate',
          sortDescending: this.props.ui.airtravel.sortDescending === true,
        },
        mileage: {
          sortField: this.props.ui.mileage.sortField || 'displayType',
          sortDescending: this.props.ui.mileage.sortDescending === true,
        },
        vehicle: {
          sortField: this.props.ui.vehicle.sortField || 'displayType',
          sortDescending: this.props.ui.vehicle.sortDescending === true,
        },
        invoices: {
          sortField: this.props.ui.invoices.sortField || 'type',
          sortDescending: this.props.ui.invoices.sortDescending === true,
        },
      }
    };
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartmentReimbursement(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
    await getFireDepartmentTrainer(this.props.reimbursement.instructor_id, null, this.props.match.params[PATH_ID]);
    await getSettings();

    this.setState(this.props.reimbursement);
    this.state.files.length = 0;
    this.state.invoices.forEach((invoice) => {
      let file = new ChosenFile();
      //
      if (this.isDuplication) file.duplicate(invoice);
      else file.parse(invoice)
      //
      if (file.id) this.state.files.push(file);
    });
    //Check for duplication clean up
    if (this.isDuplication) {
      this.state.status = "Pending";
      this.state.instructor_id = ""; //clean instructor
      this.state.invoices = Object.assign(this.state.files).map((file) => { return file.stringify(); }); //new
    }
    //images should be pending
    this.processComponentReceipts(this.state.accommodation);
    this.processComponentReceipts(this.state.vehicle);
    this.processComponentReceipts(this.state.airtravel);
    this.updateTotals(); //update dynamically generated values on initialization

    this.setState({ loading: false });
  }

  processComponentReceipts(element) {
    element.forEach((exp)=>{
      let tmp = [], tmpS = [];
      if (exp.receipts) {
        exp.receipts.forEach((receipt)=>{
          let file = new ChosenFile();
          if (this.isDuplication) file.duplicate(receipt);
          else file.parse(receipt)
          if (file.id) {
            tmp.push(file);
            tmpS.push(file.stringify());
          }
        });
      }
      exp.receipts = tmpS;
      exp.files = tmp;
    });
  }

  updateTotals = () => {
    // Calculate all the totals
    const totbackfillamount = this.state.backfill.reduce((sum, b) => { return sum + b.amount }, 0);

    const totalbeforetax = this.state.accommodation.reduce((sum, a) => { return sum + a.amount }, 0);

    const totalaftertax = totalbeforetax + this.state.gst + this.state.hoteltax;

    const totalmealamount = this.state.meal.reduce((sum, m) => { return sum + m.rate }, 0);

    const totalairtravelamount = this.state.airtravel.reduce((sum, at) => { return sum + at.fare }, 0);
    const totalairtravelgst = this.state.airtravel.reduce((sum, at) => { return sum + at.gst }, 0);
    const totalairtravelaftertax = totalairtravelamount + totalairtravelgst;

    const totalmileageamount = this.state.mileage.reduce((sum, m) => {
      let amount = m.kms * this.props.settings.mileageamount;
      return sum + parseFloat(amount.toFixed(2));
    }, 0);

    const totalvehiclebeforetax = this.state.vehicle.reduce((sum, v) => { return sum + v.amountbeforetaxes }, 0);
    const totalvehicleaftertax = totalvehiclebeforetax + this.state.vehiclegst + this.state.vehiclepst;

    const totalamountclaimed = this.state.tuitionamount + totbackfillamount + totalbeforetax + totalmealamount + totalairtravelamount + totalmileageamount + totalvehiclebeforetax;
    const totalgst = this.state.tuitiongst + this.state.gst + totalairtravelgst + this.state.vehiclegst;
    const totalpst = this.state.vehiclepst;
    const totalamountaftertax = totalamountclaimed + this.state.hoteltax + totalgst + totalpst;

    this.setState({
      totbackfillamount: totbackfillamount,
      totalbeforetax: totalbeforetax,
      totalaftertax: totalaftertax,
      totalmealamount: totalmealamount,
      totalairtravelamount: totalairtravelamount,
      totalairtravelgst: totalairtravelgst,
      totalairtravelaftertax: totalairtravelaftertax,
      totalmileageamount: totalmileageamount,
      totalvehicleaftertax: totalvehicleaftertax,
      totalamountclaimed: totalamountclaimed,
      totalgst: totalgst,
      totalpst: totalpst,
      totalamountaftertax: totalamountaftertax,
    });
  }

  handleComponentUIStateChange = (componentUI) => {
    this.setState({ ui: { ...this.state.ui, ...componentUI }}, () => {
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT_UI, fireDepartmentReimbursement: this.state.ui });
    });
  }

  handleInvoicesUIStateChange = (uiState) => {
    const uiStateUpdate = {
      invoices: uiState
    };

    this.setState({ ui: { ...this.state.ui, ...uiStateUpdate }}, () => {
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT_UI, fireDepartmentReimbursement: this.state.ui });
    });
  }

  //back action
  goToList = () => {
    this.isClean();
    this.props.history.push(`/${ PATH_ADMIN }/${ PATH_REIMBURSEMENTS }`);
  }
  cancel = event => {
    event.preventDefault();

    // Back to the list
    this.goToList();
  }

  render() {
    return (
      <div className='admin-reimbursement-summary'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={15} floated='left'>
            <Header size='medium'>
              <Header.Content>Training Reimbursement Request {this.state.requestNumber ? 'ID: ' + this.state.requestNumber : ''}</Header.Content>
              <Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Button>
            </Header>

          </Grid.Column>
          <Grid.Column width={15}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              return (
              <Segment.Group size='large' className='view-segment-group'>
                <Segment>
                <Table basic='very'>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Instructor</strong></Table.Cell>
                      <Table.Cell>{ this.props.trainer.name }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Type</strong></Table.Cell>
                      <Table.Cell>{ this.state.displayCourse }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Completion Date</strong></Table.Cell>
                      <Table.Cell>{ this.state.displayDate }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Course Fees</strong></Table.Cell>
                      <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.state.tuitionamount } /></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Course Fees GST</strong></Table.Cell>
                      <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.state.tuitiongst } /></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Receipts</strong></Table.Cell>
                      <Table.Cell>
                        <FileSummarySegment files={ this.state.files } multiple />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Backfill Expenses</strong></Table.Cell>
                      <Table.Cell>
                        <BackfillSummarySegment backfill={ this.state.backfill } totbackfillamount={ this.state.totbackfillamount} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Accomodation Expenses</strong></Table.Cell>
                      <Table.Cell>
                        <AccomodationSummarySegment 
                          accommodation={ this.state.accommodation } gst={ this.state.gst } totalaftertax={ this.state.totalaftertax }
                          hoteltax={ this.state.hoteltax } settings={ this.props.settings }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Meal Expenses</strong></Table.Cell>
                      <Table.Cell> 
                        <MealSummarySegment meal={ this.state.meal } totalmealamount={ this.state.totalmealamount } />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Air Travel Expenses</strong></Table.Cell>
                      <Table.Cell> 
                        <AirTravelSummarySegment
                          airtravel={ this.state.airtravel } totalairtravelamount={ this.state.totalairtravelamount } 
                          totalairtravelgst={ this.state.totalairtravelgst }
                          totalairtravelaftertax={ this.state.totalairtravelaftertax } settings={ this.props.settings }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Mileage Expenses</strong></Table.Cell>
                      <Table.Cell> 
                        <MileageSummarySegment mileage={ this.state.mileage } totalmileageamount={ this.state.totalmileageamount } />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Vehicle Expenses</strong></Table.Cell>
                      <Table.Cell> 
                        <VehicleSummarySegment
                          vehicle={ this.state.vehicle } totalvehicleaftertax={ this.state.totalvehicleaftertax }
                          vehiclegst={ this.state.vehiclegst } vehiclepst={ this.state.vehiclepst } settings={ this.props.settings }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Payable To</strong></Table.Cell>
                      <Table.Cell>{ this.state.payableto }</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Total Claimed</strong></Table.Cell>
                      <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.state.totalamountclaimed } /></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Hotel Tax</strong></Table.Cell>
                      <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.state.hoteltax } /></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>GST</strong></Table.Cell>
                      <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.state.totalgst } /></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>PST</strong></Table.Cell>
                      <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.state.totalpst } /></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='2'><strong>Total after taxes</strong></Table.Cell>
                      <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.state.totalamountaftertax } /></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                </Segment>
              </Segment.Group>);
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    reimbursement: storeState.models.fireDepartmentReimbursement,
    trainer: storeState.models.fireDepartmentTrainer,
    ui: storeState.ui.fireDepartmentReimbursement,
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(AdminReimbursementSummary);
