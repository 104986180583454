import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form, Message, List, Button, Grid } from 'semantic-ui-react';

import { getSettings } from '../../api/administratorAPI';
import { getFireDepartment, getFireDepartmentTrainers, getFireDepartmentSurveys, getFireDepartmentWorkSiteRescues, getFireDepartmentAnnualSubmission, postFireDepartmentAnnualSubmission, putFireDepartmentAnnualSubmission } from '../../api/fireDepartmentAPI';
import { EXTERNAL_PATH_FIN4, PATH_FIRE_DEPARTMENT, PATH_ANNUAL, PATH_TIMESTAMP, PATH_NEW } from '../../js/paths';

import { DATE_YEAR, DATE_SHORT_MONTH } from '../../js/constants';
import { SHORT_MONTHS } from '../../js/lists';

import { today, formatDateTime } from '../../utils/date';

import CurrencyText from '../../components/CurrencyText';
import MonthlyTable from '../../components/MonthlyTable';

import ConfirmPopup from '../../components/ConfirmPopup';
import ExternalButton from '../../components/ExternalButton';

import EditView from '../EditView';

const INITIAL_STATE = {
  status: 'Draft',
  trainersstipendamount: 0,
  sitesurveyreimbamount: 0,
  trainerskillstrainingamount: 0,
  firedeptstipendamount: 0,
  acknowledgementdate: today(),
  acknowledgement: true,
  traininghours: {},

  // From FD

  fdid: '',
  payableto: '',
  fdstipendamount: 0,

  // Other

  year: today(DATE_YEAR),

  traininghourstotal: 0,

  surveys: {},
  rescues: {},

  numcranesurveys: 0,
  numworkrescues: 0,

  // UI

  className: 'fire-department-annual-submission',
};

// TODO: THIS NEEDS TO SELECT FDS

class FireDepartmentAnnualSubmission extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    if (this.props.currentUser.fd) {
      this.state.fdid = this.props.currentUser.fd.id;
      this.state.payableto = this.props.currentUser.fd.payable_to;
      this.state.fdstipendamount = this.props.currentUser.fd.fdstipendamount || 0;
    }
    if (props.match.params[PATH_TIMESTAMP] === PATH_NEW) {
      this.state.isNew = true;
    } else {
      this.state.year = props.match.params[PATH_TIMESTAMP].substr(0, 4);
    }

    this.state.title = `Request for Calendar Year of ${ this.state.year }`;
    this.state.exitPage = this.props.currentUser.homepage;

    SHORT_MONTHS.forEach(mon => {
      this.state.traininghours[mon] = 0;
      this.state.surveys[mon] = 0;
      this.state.rescues[mon] = 0;
    });

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
  }

  async componentDidMount() { await this.fetch(); }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getSettings();
    await getFireDepartment(this.props.currentUser.orgId);
    await getFireDepartmentTrainers(this.props.currentUser.orgId, 'A');
    await getFireDepartmentSurveys({ status: 'Completed', fdid: this.props.currentUser.orgId, year: this.state.year, shift: true });
    await getFireDepartmentWorkSiteRescues(null, this.state.year, true);
    await getFireDepartmentAnnualSubmission(this.state.fdid, null, this.state.year);
    this.setState({ loading: false, status: this.props.annualSubmission.status || 'Draft'});
    this.onLoad();
  }

  onLoad = ()=> {
    SHORT_MONTHS.forEach(mon => {
      this.state.traininghours[mon] = 0;
      this.state.surveys[mon] = 0;
      this.state.rescues[mon] = 0;
    });
    let surveys = { ...this.state.surveys };
    let numcranesurveys = 0;
    let numcranesurveysMutualAID = 0;
    const numtrainers = this.props.trainers.length;

    this.props.surveys.forEach(survey => {
      // Increment the count for the survey's month
      surveys[formatDateTime(survey.timestamp, DATE_SHORT_MONTH)]++;
      if (survey.surveyCategory === 'MUTUAL_AID') numcranesurveysMutualAID++;
      else numcranesurveys++;
    });

    let rescues = { ...this.state.rescues };
    let numworkrescues = 0;

    this.props.rescues.forEach(rescue => {
      // Increment the count for the rescue's month
      rescues[formatDateTime(rescue.timestamp, DATE_SHORT_MONTH)]++;
      numworkrescues++;
    });

    let trainingHours = { ...this.props.annualSubmission.traininghours };
    let traininghourstotal = 0;

    for (const key in trainingHours) {
      traininghourstotal = traininghourstotal + trainingHours[key];
    }

    let trainersstipendamount = 0, trainerskillstrainingamount = 0, sitesurveyreimbamount = 0, firedeptstipendamount = 0;
    if (this.state.status !== 'Draft' && this.state.status !== 'Rejected') {
      trainersstipendamount = this.props.annualSubmission.trainersstipendamount;
      trainerskillstrainingamount = this.props.annualSubmission.trainerskillstrainingamount;
      sitesurveyreimbamount = this.props.annualSubmission.sitesurveyreimbamount;
      firedeptstipendamount = this.props.annualSubmission.firedeptstipendamount;
    } else {
      trainersstipendamount = numtrainers * this.props.settings.stipendpertraineramount;
      trainerskillstrainingamount = numtrainers * this.props.settings.trainerskillstrainingamount;
      sitesurveyreimbamount = numcranesurveys * this.props.settings.sitesurveyamount + (numcranesurveysMutualAID * (this.props.settings.sitesurveyamountMutualAid || 0));
      firedeptstipendamount = trainersstipendamount + trainerskillstrainingamount + sitesurveyreimbamount + this.state.fdstipendamount;
    }

    const payableto = this.props.annualSubmission.payableto || this.props.fireDepartment.payable_to || '';

    this.setState({
      surveys: surveys,
      rescues: rescues,
      traininghours: trainingHours,
      numcranesurveys: numcranesurveys,
      numworkrescues: numworkrescues,
      traininghourstotal: traininghourstotal,
      trainersstipendamount: trainersstipendamount,
      trainerskillstrainingamount: trainerskillstrainingamount,
      sitesurveyreimbamount: sitesurveyreimbamount,
      firedeptstipendamount: firedeptstipendamount,
      payableto: payableto,
    });
  }

  handleTrainingHoursChange = (mon, value) => {
    let traininghours = { ...this.state.traininghours };
    traininghours[mon] = value;

    this.setState({
      traininghours: traininghours,
      traininghourstotal: this.state.traininghourstotal + value - this.state.traininghours[mon]
    }, () => { this.onChange() });
  }

  handleYearChange = async (e, { value }) => {
    SHORT_MONTHS.forEach(mon => {
      this.state.traininghours[mon] = 0;
      this.state.surveys[mon] = 0;
      this.state.rescues[mon] = 0;
    });
    this.setState({ year: value, title: `Request for Calendar Year of ${value}` });
    await this.fetch();
  }

  handleCheckboxChange = () => {
    this.setState({ acknowledgement: !this.state.acknowledgement });
  }

  handleSubmit = (status) => {
    if (this.isValid()) {
      this.state.status = status;
      const data = this.getData();
      if (!this.props.annualSubmission.id) {
        this.create(data);
      } else if (this.didChange()) {
        this.update(data);
      }
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.payableto) {
      errors.error = true;
      errors.payableto = 'Please enter a payable to email address.';
    }

    if (!this.state.acknowledgement) {
      errors.error = true;
      errors.acknowledgement = 'Please check off the acknowledgement.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  // Always update
  didChange = () => { return true; }

  create = async (data) => {
    this.setState({ loading: 'Submitting' });

    await postFireDepartmentAnnualSubmission(data);
    if (this.props.annualSubmission.id) {
      this.setState({ successfulRequest: true });
      this.goToList();
    }

    this.setState({ loading: false });
  }

  update = async (data) => {
    this.setState({ loading: 'Submitting' });

    await putFireDepartmentAnnualSubmission(data);
    if (this.props.annualSubmission.id) {
      this.setState({ successfulRequest: true });
      this.goToList();
    }

    this.setState({ loading: false });
  }

  goToList = () => {
    this.isClean();
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_ANNUAL }`);
  }

  cancel = event => {
    event.preventDefault();
    // Back to the list
    this.goToList();
  }

  renderView = () => {
    const alignLeft = {style:{textAlign: 'left'}};
    const d = new Date();
    const yearOpts = [{ key: d.getFullYear()+'', value: d.getFullYear()+'', text: d.getFullYear()+'' },
                      { key: (d.getFullYear()-1)+'', value: (d.getFullYear()-1)+'', text: (d.getFullYear()-1)+'' },
                      { key: (d.getFullYear()-2)+'', value: (d.getFullYear()-2)+'', text: (d.getFullYear()-2)+'' }];
    return (
      <Form error={ this.state.errors.error }>
        <Header size='medium'>

          <Grid columns='equal' verticalAlign='bottom'>
            <Grid.Column>
            <Header.Content>{ this.state.title }</Header.Content>
              <Header.Subheader>
                Review the Standard Operating Procedure(s) Related to this page
                <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ EXTERNAL_PATH_FIN4 } label="FIN4" />
              </Header.Subheader>
            </Grid.Column>
            <Grid.Column>
              <ConfirmPopup onConfirm={ this.handleSubmit.bind(this, 'Pending') } oktext='Submit' canceltext='Cancel' on='click' header='Are you sure? Submissions are final and cannot be edited once submitted.'>
                <Button floated='right' className='form-top-button' positive disabled={ this.state.status !== 'Draft' && this.state.status !== 'Rejected' }>Submit</Button>
              </ConfirmPopup>
              <ConfirmPopup onConfirm={ this.handleSubmit.bind(this, 'Draft') } oktext='Save' canceltext='Cancel' on='click'>
                <Button floated='right' className='form-top-button' color='blue' disabled={ this.state.status !== 'Draft' && this.state.status !== 'Rejected' }>Save</Button>
              </ConfirmPopup>
              <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
            </Grid.Column>
          </Grid>
        </Header>
        { this.state.isNew && 
          <Form.Select floated='right' required label='Declaration Year' value={ this.state.year } name='declarationYear' options={ yearOpts } width={ 1 } onChange={ this.handleYearChange }/>
        }
        <Segment.Group size='large' className='view-segment-group'>
          <Segment>
            <Header content='Reporting' size='small' />
            <Form.Group>
              <Segment compact>
                <Header content='Crane Surveys' size='tiny' />
                <MonthlyTable data={ this.state.surveys }/>
              </Segment>
            </Form.Group>
            <Form.Group>
              <Segment>
                <Header content='Work Rescues' size='tiny' />
                <MonthlyTable data={ this.state.rescues }/>
              </Segment>
            </Form.Group>
            <Form.Group>
              <Segment>
                <Header size='tiny'>
                  <Header.Content>{ 'Training Conducted' }</Header.Content>
                  <Header.Content style={{color: "#db2828", margin:"-.2em 0 0 .2em"}}>{ '*' }</Header.Content>
                </Header>
                <MonthlyTable data={ this.state.traininghours } onEdit={ this.state.status !== 'Draft' && this.state.status !== 'Rejected' ? null : this.handleTrainingHoursChange }/>
              </Segment>
            </Form.Group>
          </Segment>

          <Segment>
            <Header content='Stipend' size='small' />
            <Segment>
              <Form.Group>
                <Form.Field label='Total Instructors Stipend' {...alignLeft} value={ this.state.trainersstipendamount } width={ 4 } control={ CurrencyText }/>
                <Form.Field label='Total Skills Training Amount' {...alignLeft} value={ this.state.trainerskillstrainingamount } width={ 4 } control={ CurrencyText }/>
                <Form.Field label='Total Crane Survey Amount' {...alignLeft} value={ this.state.sitesurveyreimbamount } width={ 4 } control={ CurrencyText }/>
                <Form.Field label='Total Annual Amount' {...alignLeft} value={ this.state.firedeptstipendamount } width={ 4 } control={ CurrencyText }/>
              </Form.Group>
              <Form.Group>
                <Form.Input required
                  label='Payable To' value={ this.state.payableto } name='payableto' width={ 16 }
                  readOnly={ this.state.status !== 'Draft' && this.state.status !== 'Rejected' } error={ !!this.state.errors.payableto }
                  onChange={ this.handleChange }
                />
              </Form.Group>
              <Message error>
                { this.state.errors.payableto }
              </Message>
            </Segment>
          </Segment>

          <Segment hidden={ this.state.status !== 'Draft' && this.state.status !== 'Rejected' }>
            <Header content='Acknowledgement of Training' size='small' />
            <Segment>
              <Form.Checkbox style={{ fontSize: '1em' }} checked={ this.state.acknowledgement } onChange={ this.handleCheckboxChange } width={ 16 }
                label='I verify that this fire department will:'
              />
              <Message error>
                { this.state.errors.acknowledgement }
              </Message>
              <List bulleted>
                <List.Item>provide THARRP service in the coming year;</List.Item>
                <List.Item>ensure that THARRP funded instructors are trained through recognized agencies providing THARRP training to current and applicable NFPA Standards;</List.Item>
                <List.Item>ensure that THARRP funded instructors will conduct in-house training to current and applicable NFPA Standards; technician level.</List.Item>
              </List>
            </Segment>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    fireDepartment: storeState.models.fireDepartment,
    annualSubmission: storeState.models.fireDepartmentAnnualSubmission,
    trainers: storeState.models.fireDepartmentTrainers,
    surveys: storeState.models.fireDepartmentSurveys,
    rescues: storeState.models.fireDepartmentWorkSiteRescues,
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentAnnualSubmission);
