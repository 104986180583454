import React from 'react';
import { connect } from 'react-redux';

import { Grid, Segment, Header, Form, Message } from 'semantic-ui-react';

import { getCostCenter, postCostCenter, updateCostCenter } from '../../api/administratorAPI';

import { UPDATE_COST_CENTER } from '../../js/actionTypes';
import { PATH_ADMIN, PATH_COST_CENTERS, PATH_NEW, PATH_ID, PATH_TIMESTAMP } from '../../js/paths';

import ViewLoader from '../../components/ViewLoader';

import EditView from '../EditView';

const INITIAL_STATE = {
  type: '',
  description: '',
  glaccount: '',

  // UI
  ui: null,

  loading: false,
  isNew: false,

  editing: {},
  errors: {
    error: false
  }
};

class AdminCostCenter extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.state.isNew = (props.match.params[PATH_ID] === PATH_NEW);
    console.log(props.match.params)
  }

  componentDidMount = () => {
    this.initialize();
    this.isClean();
  }

  initialize = async () => {
    this.setState({ loading: 'Loading' });
    if (this.state.isNew) {
      this.dispatch({ type: UPDATE_COST_CENTER, costCenter: INITIAL_STATE });
    } else {
      await getCostCenter(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
      this.setState(this.props.costCenter);
    }
    console.log(this.state)
    this.setState({ loading: false });
  }

  onChange = () => {
    this.isDirty();
    this.validate();
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value }, () => { this.onChange(); });
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.glaccount) {
      errors.error = true;
      errors.glaccount = 'Please enter a glaccount.';
    }

    if (!this.state.description) {
      errors.error = true;
      errors.description = 'Please enter a description.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.isValid()) {
      const { loading, isNew, ui, editing, errors, ...data } = this.state;

      if (this.state.isNew) {
        this.create(data);
      } else {
        this.update(data);
      }
    }
  }

  goToList = () => {
    this.isClean();
    this.props.history.push(`/${ PATH_ADMIN }/${ PATH_COST_CENTERS }`);
  }

  create = async (data) => {
    this.setState({ loading: 'Submitting' });
    await postCostCenter(data);
    if (this.props.costCenter.id) {
      this.goToList();
    }
    this.setState({ loading: false });
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await updateCostCenter(data);
    if (this.props.costCenter.id) {
      this.goToList();
    }
    this.setState({ loading: false });
  }

  cancel = event => {
    event.preventDefault();

    // Back to the list
    this.goToList();
  }

  render = () => {
    return (
      <div className='fire-department-reimbursement'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              return (
                <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
                  <Header size='medium'>
                    <Header.Content>Cost Center Details</Header.Content>
                    <Form.Button floated='right' className='form-top-button' positive>{ this.state.isNew ? 'Submit' : 'Update' }</Form.Button>
                    <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
                  </Header>
                  <Segment.Group size='large' className='view-segment-group'>
                    <Segment>
                      <Header content='Cost Center' size='tiny' />
                        <Form.Input readOnly={!this.state.isNew} label='Type' value={ this.state.type } name='type' width={ 12 }
                          error={ !!this.state.errors.type }
                          onChange={ this.handleChange }
                        />
                        <Form.Input required label='GL Account' value={ this.state.glaccount } name='glaccount' width={ 12 }
                          error={ !!this.state.errors.glaccount }
                          onChange={ this.handleChange }
                        />
                        <Form.Input required label='Description' value={ this.state.description } name='description' width={ 12 }
                          error={ !!this.state.errors.description }
                          onChange={ this.handleChange }
                        />
                      <Message error>
                        { this.state.errors.description } { this.state.errors.glaccount } { this.state.errors.type }
                      </Message>
                    </Segment>
                  </Segment.Group>
                </Form>
              );
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    costCenter: storeState.models.costCenter,
    trainers: storeState.models.fireDepartmentTrainers,
    ui: storeState.ui.fireDepartmentReimbursement,
    fireDepartments: storeState.lookups.fireDepartments,
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(AdminCostCenter);
