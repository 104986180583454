import React from 'react';

import { Segment, Header, Form, Grid } from 'semantic-ui-react';

import AddButton from './AddButton';
import DeleteButton from './DeleteButton';
import AsyncImage from './AsyncImage';

class CraneSegment extends React.Component {
  //download stuff
  handleIconClick = (file) => {
    if (file.uploaded) {
      this.download(file);
    }
  }
  download = async (file) => {
    const downloadUrl = await file.downloadUrl();

    if (downloadUrl) {
      window.open(downloadUrl);
    }
  }

  render = () => {
    const crane = this.props.dataSource.state.cranes[this.props.index];
    const index = this.props.index;
    let images = (crane.images ? crane.images : []);
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Crane {this.props.index + 1}</Header.Content>
        </Header>
        <Form.Group className="crane-line">
          <Form.Input required={ !this.props.disabled } readOnly={ this.props.disabled }
            label={ `Manufacturer/ID` } key={ `input${ index}` } index={ index } width={ 8 }
            value={ crane.name } name={ `crane_name_${ index }` } id={ `crane_name_${ index }` }
            onChange={ (this.props.dataSource.handleCraneNameChange ? this.props.dataSource.handleCraneNameChange.bind(this.props.dataSource) : ()=>{}) }/>
          {!this.props.disabled && this.props.dataSource.addCrane &&
            <AddButton key={ `addbutton${ index }` } size='big'
                     onClick={ (this.props.dataSource.addCrane ? this.props.dataSource.addCrane.bind(this.props.dataSource, index) : ()=>{}) }/>
          }

          { !this.props.disabled && this.props.dataSource.state.cranes.length > 1 && this.props.dataSource.removeCrane &&
            <DeleteButton key={ `deletebutton${ index }` } size='big'
                          onClick={ (this.props.dataSource.removeCrane ? this.props.dataSource.removeCrane.bind(this.props.dataSource, index) : ()=>{}) }/>
          }
        </Form.Group>

        {images.length > 0 && <b>Pictures:</b>}
        <Grid columns={8} celled='internally' verticalAlign='middle'>
          <Grid.Row stretched>
            {
              images.map((image, index) => {
                 return (
                   <Grid.Column key={index}>
                     <AsyncImage fileName={image} size='medium'/>
                   </Grid.Column>
                 )
              })
            }
          </Grid.Row>
        </Grid>

      </Segment>
    );
  }
};

export default CraneSegment;
