import React from 'react';
import { connect } from 'react-redux';

import * as ExcelJS from 'exceljs/dist/exceljs';

import { Grid, Select, Button, Header } from 'semantic-ui-react';

import { USERS_LIST_TYPES } from '../../js/lists';
import { sort, downloadArrayBuffer, xlsxAutoWidth } from '../../utils/utils';
import { DATE_TIME_READABLE } from '../../js/constants';
import { formatDateTime } from '../../utils/date';
import { getUsers } from '../../api/userAPI';
import { PATH_ADMIN, PATH_CONSTRUCTION, PATH_USER } from '../../js/paths';

import ListView from '../ListView';
import IconButton from '../../components/IconButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import ChangePasswordModal from '../Auth/ChangePasswordModal';

const INITIAL_STATE = {
  loading: false,
  userType: Object.keys(USERS_LIST_TYPES)[0],
  ui : {
    sortField: 'entity_name',
    sortDescending: false,
  },
};

class AdminUsers extends ListView {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.handleExport = this.handleExport.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: true });
    await getUsers(this.state.userType);
    this.setState({ loading: false });
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value }, this.fetch);
  }
  handleExport() {
    this._exportToXLSX();
  }

  handleChangeConfirmUser(user) {
    this.setState({ currentUser: user }, () => {
      if (this.changePassModal) this.changePassModal.handleShow();
    });
  }

  handleEditUser(user) {
    this.props.history.push(`/${ PATH_ADMIN }/${ PATH_CONSTRUCTION }/${ PATH_USER }/${user.id}`);
  }

  updateUIState = (uiState) => {
    this.setState({ ui: { ...this.state.ui, ...uiState }});
  }

  render() {
    //
    if (this.state.loading) {
      return <ViewLoader loading={ this.state.loading }/>;
    }
    //
    const types = Object.keys(USERS_LIST_TYPES).map((key) => { return { key: key, value: key, text: USERS_LIST_TYPES[key] }});
    let users = sort(this.props.users, this.state.ui.sortField, this.state.ui.sortField == 'entity_name' ? 'gsi3sk' : null);
    if (this.state.ui.sortDescending) users.reverse();
    
    return (
      <React.Fragment>
        <div className='pg-content__body'>
        <ChangePasswordModal adminFlow ref={(ref) => this.changePassModal = ref} currentUser={this.state.currentUser}/>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{ marginLeft: 40, paddingTop: 30 }} width={ 6 } floated='left'>
            <Header size='medium'> <Header.Content style={{ marginLeft: 20 }}>Users Management</Header.Content> </Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{ marginRight: 40, textAlign:'right', paddingBottom: 0 }} width={ 8 } floated='right'>
            <Select name='userType' value={ this.state.userType } options={ types } onChange={this.handleChange} style={{ marginRight: 10 }}/>
            <Button primary onClick={this.handleExport} style={{ marginRight: 20 }}>Export</Button>
          </Grid.Column>
          <Grid.Column width={ 15 }>
            
            <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending }
                       onSort={ this.updateUIState } size='small'
                       headers={[
                          { field: 'entity_name',           title: 'Name',     width: '3' },
                          { field: 'contact_email',          title: 'Email',     width: '4' },
                          { field: 'registration_date',          title: 'Registered At',     width: '4' },
                          { field: 'buttons', noSort: true, title: 'Actions', style: { textAlign: 'center'}, width: '1' },
                       ]}>
              { users.map((user) => {
                const { id, entity_name, registration_date, gsi3sk, contact_email } = user;
                return (
                  <SortTable.Row key={ id + Date.now() }>
                    <SortTable.Cell>{ entity_name || gsi3sk }</SortTable.Cell>
                    <SortTable.Cell>{ contact_email }</SortTable.Cell>
                    <SortTable.Cell>{ formatDateTime(registration_date, DATE_TIME_READABLE) }</SortTable.Cell>
                    <SortTable.Cell textAlign='center'>
                      <IconButton popup='Reset Password & Confirm' name={'unlock'} style={{ marginRight: '0.3em' }} 
                      onClick={ this.handleChangeConfirmUser.bind(this, user)}/>
                      {this.state.userType === Object.keys(USERS_LIST_TYPES)[0] &&
                        <IconButton popup='Edit' name={'edit'} style={{ marginRight: '0.3em' }} onClick={ this.handleEditUser.bind(this, user)}/>}
                    </SortTable.Cell>
                  </SortTable.Row>
                );
              })}
            </SortTable>
          </Grid.Column>
        </Grid>
        </div>
      </React.Fragment>
    );
  }


  /* Private XLSX */
  async _exportToXLSX() {
    let users = sort(this.props.users, this.state.ui.sortField);
    if (this.state.ui.sortDescending) users.reverse();
    if (!users || users.length <= 0) return;
    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');
    // Generate XLSX header
    ws.addRow(['Name', 'Email', 'Registered At']);
    // Generate XLSX rows
    users.forEach(({ id, entity_name, registration_date, gsi3sk, contact_email }) => {
      ws.addRow([
         entity_name || gsi3sk, contact_email, formatDateTime(registration_date, DATE_TIME_READABLE)
      ]);
    });
    // Auto width
    xlsxAutoWidth(ws, 75);
    // Generate buffer and save it
    const buffer = await wb.xlsx.writeBuffer();
    downloadArrayBuffer(buffer, `users-export-${this.state.userType}`, 'xlsx');
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    users: storeState.models.users,
  };
}

export default connect(mapStoreStateToProps)(AdminUsers);
