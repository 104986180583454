import React from 'react';

import { Dropdown } from 'semantic-ui-react';

export const MONTHNAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

class SearchableFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filters: [], selectedValue: -1 };
  }
  //utils
  setFDs(fds, addAll, callback) {
    let values = [];
    if (addAll !== undefined && addAll === true) values.push("All");
    for (var i = 0; i < fds.length; i++) {
      let name = fds[i].name;
      //if (values.indexOf(name) <= -1)
      values.push(name);
    }
    this.setFilterItems(values, callback);
  }
  setYears(callback) {
    let date = new Date();
    let years = [];
    for (var i = -3; i < 3; i++) { years.push(date.getFullYear() + i); }
    this.setFilterItems(years, callback);
  }
  setManyYears(callback) {
    let date = new Date();
    let years = [];
    for (var i = 2; i > -50; i--) { years.push(date.getFullYear() + i); }
    this.setFilterItems(years, callback);
  }
  setMonths(callback) {
    this.setFilterItems(MONTHNAMES, callback);
  }

  //
  getSelectedIndex() { return this.state.selectedValue; }
  getSelectedText() { return (this.state.filters !== undefined && this.state.filters.length > 0 && this.state.selectedValue !== -1 ? this.state.filters[this.state.selectedValue].text : ""); }
  setFilterItems(items, callback) {
    let filters = [];
    for (var i = 0; i < items.length; i++) {
      filters.push({key: i, value: i, text: items[i] + ""});
    }
    //check for default value if found
    let idx = items.indexOf(this.props.defaultValue);
    this.setState({
      selectedValue: (idx !== -1 ? idx : 0),
      filters: filters,
    }, (callback !== undefined ? callback : () => {}));
  }
  handleChange(e, { value }) {
    let previousState = this.state.selectedValue;
    this.setState({ selectedValue: value }, () => {
      if (this.props.handleFilterChange !== undefined && previousState !== value) this.props.handleFilterChange(this,this.state.selectedValue);
    });
  }
  render() {
    let text = this.getSelectedText();
    return(
      <Dropdown
        button className='icon' onChange={this.handleChange.bind(this)} style={{textAlign: "left"}}
        floating labeled value={this.state.selectedValue} search selection
        icon='filter' options={this.state.filters} text={text}
      />
    )
  }
}

export default SearchableFilter;
