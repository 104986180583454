import { startRequest, endRequest } from '../api/api';

import { REQUESTS_ERROR } from '../js/actionTypes';

import store from '../js/store';

export function request(path, options) {
  options = options || {};

  let xhr = new XMLHttpRequest();

  if (!options.headers) { options.headers = {}; }
  if (!options.file) {
    options.headers = { 'Content-Type': 'application/x-www-form-urlencoded', ...options.headers };
  } else {
    xhr.responseType = 'blob';
  }

  const method = (options.method || 'GET').toUpperCase();

  if (options.onUploadProgress) {
    xhr.upload.addEventListener('progress', function(e) {
      if (e.lengthComputable) {
        options.onUploadProgress(Math.floor(e.loaded / e.total * 100));
      } else {
        options.onUploadProgress(null);
      }
    });

    xhr.upload.addEventListener('load', function() {
      options.onUploadProgress(100);
    });
  }

/*  return new Promise((resolve, reject, onCancel) => {
    onCancel(function() {
      if (!options.silent) { endRequest(); }
      xhr.abort();
    });
*/

    return new Promise((resolve, reject) => {
      xhr.addEventListener('load', function() {
        if (xhr.status >= 400) {
          reject(handleHTMLError(method, path, xhr.status, (options.file ? null : xhr.responseText)));
        } else {
          resolve(xhr);
        }
      });

      xhr.addEventListener('error', function() {
        reject(handleHTMLError(method, path));
      });

      let qs = '';
      if (options.querystring) {
        qs = Object.keys(options.querystring).map((key) => { return `${ encodeURIComponent(key) }=${ encodeURIComponent(options.querystring[key]) }` }).join('&');
      }
      xhr.open(method, `${path}${qs ? '?' : ''}${qs}`, true);

      Object.keys(options.headers).forEach(key => {
        xhr.setRequestHeader(key, options.headers[key]);
      });

      if (!options.silent) {
        startRequest();
      }

      let payload = null;

      if (options.file) {
        payload = options.file;
      } else if (typeof options.body === 'object') {
        payload = new FormData();
        Object.keys(options.body).forEach(key => {
          payload.append(key, options.body[key]);
        });
      }

      xhr.send(payload);
    }).finally(() => {
      if (!options.silent) { endRequest(); }
    }
  );
}

export function handleHTMLError(method, path, status, responseText) {
  let message = `Request failed`;

  if (responseText) {
    message += ` "${ responseText }"`;
  }

  const htmlError = {
    message: message,
    method: method,
    path: path,
    status: status || 'Unknown',
  };

  store.dispatch({ type: REQUESTS_ERROR, error: htmlError });

  return htmlError;
}
