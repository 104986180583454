import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form } from 'semantic-ui-react';

import { getAdminWB, updateAdminWB } from '../../api/administratorAPI';
import { getFireDepartment, updateFireDepartment } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_REGISTRATION_FD_UI } from '../../js/actionTypes';
import { ROLE_ADMIN } from '../../js/constants';
import { PATH_ID, PATH_ADMIN, PATH_REGISTRATIONS, PATH_FIRE_DEPARTMENT } from '../../js/paths';

import AddressInput from '../../components/AddressInput';
import BallotSegment from '../../components/BallotSegment';
import FileChooserSegment from '../../components/FileChooserSegment';
import EditButton from '../../components/EditButton';

import ChosenFile from '../../utils/file';

import BallotEditDialog from '../../dialogs/BallotEditDialog';
import EmailEditDialog from '../../dialogs/EmailEditDialog';

import EditView from '../EditView';

const INITIAL_STATE = {
  entity_name: '',
  contact_name: '',
  contact_email: '',
  other_emails: '',

  chief_name: '',
  payable_to: '',
  maxallowedtrainers: 0,

  address: {
    street: '',
    number: '',
    city: '',
    province: '',
    postalcode: ''
  },

  main_telephone_number: '',
  main_telephone_ext: '',
  second_telephone_number: '',
  second_telephone_ext: '',

  applicationDocs: [],

  justification: '',

  // UI

  ballots: [],

  editing: {},

  className: 'fire-department-profile',
  title: 'Department Profile',
};

class FireDepartmentProfile extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.isAdmin = this.props.currentUser.role === ROLE_ADMIN;
    this.state.exitPage = this.state.isAdmin ? `/${ PATH_ADMIN }/${ PATH_REGISTRATIONS }/${ PATH_FIRE_DEPARTMENT }` : this.props.currentUser.homepage;

    this.state.ui = {
      ballots: {
        sortField: this.props.ui.ballots.sortField || 'voteoption',
        sortDescending: this.props.ui.ballots.sortDescending === true,
      },
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUIStateChange = this.handleUIStateChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
  }

  fetch = async () => {
    await getFireDepartment(this.state.isAdmin ? this.props.match.params[PATH_ID] : this.props.currentUser.orgId);
    if (this.state.isAdmin && this.props.fireDepartment.status !== 'Pending') {
      await getAdminWB('FD', this.props.match.params[PATH_ID]);
    }
  }

  onLoad = () => {
    const address = {
      street: this.props.fireDepartment.address_street || '',
      number: this.props.fireDepartment.address_number || '',
      city: this.props.fireDepartment.address_city || '',
      province: this.props.fireDepartment.address_province || '',
      postalcode: this.props.fireDepartment.address_postalcode || '',
    };

    const applicationDocs = [];
    (this.props.fireDepartment.applicationDocs || []).forEach((_file) => {
      let file = new ChosenFile();
      file.parse(_file);
      if (file.id) applicationDocs.push(file);
    });

    this.setState({
      ...this.props.fireDepartment,
      address: address, 
      applicationDocs
    });

    if (this.state.isAdmin && this.props.fireDepartment.status !== 'Pending') {
      this.setState({ ballots: this.props.adminWB.ballots || [] });
    }
  }

  handleAddressChange = (event, { name, value }) => {
    this.setState({ address: { ...this.state.address, [name]: value }}, () => { this.onChange(); });
  }

  startEditing = (componentName, entry) => {
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing });
  }

  stopEditing = () => {
    this.setState({ editing: {}});
  }

  handleComponentRemove = (componentName, entry) => {
    const ballots = this.state.ballots.filter(item => {
      return entry.memberemail !== item.memberemail
    });

    this.setState({ ballots: ballots }, () => { this.onChange() });
  }

  handleComponentEdit = (componentName, entry) => {
    this.stopEditing();

    let ballots = this.state.ballots.filter(item => {
      return entry.memberemail !== item.memberemail
    });
    ballots.push(entry);

    this.setState({ ballots: ballots }, () => { this.onChange() });
  }

  isValid = () => {
    let errors = { error: false };

    this.setState({ errors: errors });

    return !errors.error;
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });

    if (this.state.isAdmin) {
      const body = { ...this.props.adminWB, ballots: data.ballots };
      await updateAdminWB(body);
      this.exit();
    } else {
      data.contact_email = data.contact_email.replace(' ','').toLowerCase().trim();
      const { editing, address, ...body } = data;

      body.address_street = address.street;
      body.address_number = address.number;
      body.address_city = address.city;
      body.address_province = address.province;
      body.address_postalcode = address.postalcode;

      await updateFireDepartment(body);
      if (this.props.fireDepartment.id) {
        this.props.currentUser.fd = this.props.fireDepartment;
        this.exit();
      }
    }

    this.setState({ loading: false });
  }

  handleUIStateChange(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REGISTRATION_FD_UI, adminRegistrationFD: this.state.ui });
    });
  }

  startEditing = (componentName, entry) => {
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing });
  }

  handleLogonEmailEdit = (componentName, entry) => {
    this.stopEditing();
    this.setState({ contact_email: entry }, () => { this.onChange(); });
  }

  renderView = () => {
    const canEdit = !this.state.isAdmin || this.state.status === 'Voting';
    return (
      <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
          { canEdit &&
            <Form.Button floated='right' className='form-top-button' positive>Update</Form.Button>
          }
          <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>{ canEdit ? 'Cancel' : 'Close' }</Form.Button>
        </Header>
        <Segment.Group size='large' className='view-segment-group'>
          { this.state.isAdmin && this.state.status !== 'Pending' &&
            <BallotSegment disabled={ !canEdit } ballots={ this.state.ballots }
              ui={ this.state.ui.ballots } onUIStateChange={ this.handleUIStateChange }
              edit={ this.startEditing } remove={ this.handleComponentRemove }
            />
          }
          <Segment disabled={ this.state.isAdmin }>
            <Form.Group>
              <Form.Input readOnly label='Department Name' value={ this.state.entity_name } width={ 16 } />
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly={ this.state.isAdmin } required={ !this.state.isAdmin }
                label='Contact Name(s)' value={ this.state.contact_name } name='contact_name' width={ 16 }
                placeholder='Tom Jones, Bob Smith'
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly required={ !this.state.isAdmin }
                label='FD Logon ID' value={ this.state.contact_email } name='contact_email' width={ 6 }
                placeholder='tom@abc.com; bob@abc.com'
                onChange={ this.handleChange }
              />
              <EditButton onClick={ this.startEditing.bind(this, 'contact_email', this.state.contact_email) } style={{ position: 'relative', bottom: -32 }}/>
            </Form.Group>
            <Form.Group>
              <Form.Input label='Other Email(s)' value={ this.state.other_emails } name='other_emails' width={ 16 }
                placeholder='tom@abc.com; bob@abc.com' onChange={ this.handleChange }/>
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly={ this.state.isAdmin } required={ !this.state.isAdmin }
                label='Fire Chief' value={ this.state.chief_name } name='chief_name' width={ 6 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isAdmin } required={ !this.state.isAdmin }
                label='Payable To' value={ this.state.payable_to } name='payable_to' width={ 6 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly label='Number Of Trainers' value={ this.state.maxallowedtrainers } width={ 4 } />
            </Form.Group>

            <AddressInput readOnly={ this.state.isAdmin } address={ this.state.address } onChange={ this.handleAddressChange } hideCompliment />

            <Form.Group>
              <Form.Input readOnly={ this.state.isAdmin } required={ !this.state.isAdmin }
                label='Main Phone' value={ this.state.main_telephone_number } name='main_telephone_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isAdmin }
                label='Ext' value={ this.state.main_telephone_ext } name='main_telephone_ext' width={ 2 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isAdmin }
                label='Secondary Phone' value={ this.state.second_telephone_number } name='second_telephone_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isAdmin }
                label='Ext' value={ this.state.second_telephone_ext } name='second_telephone_ext' width={ 2 }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.TextArea readOnly={ this.state.isAdmin }
                label='Justification' value={ this.state.justification } name='justification'
                onChange={ this.handleChange } width={ 16 }
              />
            </Form.Group>
            <Form.Group>
              <Form.Field required={ false } width={ 16 }
                control={ FileChooserSegment } customLabel={' '} label='Supporting Documentation' name='applicationDocs' 
                error={ !!this.state.errors.applicationDocs } ui={this.state.ui} maxFiles={4} readOnly
                files={ this.state.applicationDocs } multiple headeless
              />
            </Form.Group>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }

  renderAfterView = () => {
    if (this.state.editing.ballots) {
      return (
        <BallotEditDialog open={ !!this.state.editing.ballots } ballot={ this.state.editing.ballots }
          onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
        />
      );
    }
    if (this.state.editing.contact_email) {
      return (
        <EmailEditDialog open={ !!this.state.editing.contact_email } contact_email={ this.state.editing.contact_email }
        onSave={ this.handleLogonEmailEdit } onClose={ this.stopEditing }
      />
      );
    }

    return null;
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    fireDepartment: storeState.models.fireDepartment,
    adminWB: storeState.models.adminWB,
    ui: storeState.ui.adminRegistrationFD
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentProfile);
