import React from 'react';

import { Message, Table } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import CurrencyText from './CurrencyText';

import { MILEAGE_TYPES } from '../js/lists';

class MileageSummarySegment extends React.Component {

  render = () => {
    return (
      (() => {
        if (this.props.mileage.length === 0) {
          return <Message info compact size='tiny'>No mileage expenses</Message>;
        }

        let mileages = this.props.mileage.map(m => {
          let mileage = { ...m };
          mileage.displayType = MILEAGE_TYPES[mileage.type];

          return mileage;
        });

        return <Table collapsing basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Distance</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { mileages.map((mileage, index) => (
              <Table.Row key={ index } id={ `mileage_${ mileage.timestamp }`}>
                <Table.Cell>{ mileage.displayType }</Table.Cell>
                <Table.Cell>{ mileage.kms + ' km' }</Table.Cell>
                <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.props.totalmileageamount }/></Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>;
      })()
    );
  }
};

MileageSummarySegment.propTypes = {
  mileage: PropTypes.array.isRequired,
  totalmileageamount: PropTypes.number.isRequired,
};

export default MileageSummarySegment;
