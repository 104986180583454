import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, HeaderContent, Message } from 'semantic-ui-react';

import { getFireDepartmentSurveys, deleteFireDepartmentSurvey } from '../../api/fireDepartmentAPI';

import { UPDATE_FIRE_DEPARTMENT_SURVEYS_UI } from '../../js/actionTypes';
import { PATH_FIRE_DEPARTMENT, PATH_SITE_SURVEY, PATH_NEW } from '../../js/paths';

import { sort } from '../../utils/utils';

import AddButton from '../../components/AddButton';
import DeleteButton from '../../components/DeleteButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';
import SearchableFilter from '../../components/SearchableFilter';

import ListView from '../ListView';

class FireDepartmentSiteSurveyList extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartmentSurveys();
    if (this.filterObj !== undefined) {
      this.filterObj.setFilterItems(this.getAvailableStates(), ()=>this.setState({ loading: false }));
    }else this.setState({ loading: false });
  }

  remove = async (id, timestamp) => {
    this.setState({ loading: 'Removing' });
    await deleteFireDepartmentSurvey(id, timestamp);
    this.setState({ loading: false });
  }

  handleRemove = async (id, timestamp) => {
    await this.remove(id, timestamp);
    await this.fetch();
  }

  handleNew() {
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_SITE_SURVEY }/${ PATH_NEW }`);
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_SURVEYS_UI, fireDepartmentSurveys: this.state.ui });
    });
  }
  processAddresses(surveys) {
    for (const s of surveys) {
      const number = s.project_street_number ? s.project_street_number : '';
      const street = s.project_street_name ? s.project_street_name : '';
      const city = s.project_city ? s.project_city : '';
      s.craneLocation = number ? number : '';
      s.craneLocation = s.craneLocation ? s.craneLocation + ' ' + (street ? street : '') : (street ? street : '');
      s.craneLocation = s.craneLocation ? s.craneLocation + ' - ' + (city ? city : '') : (city ? city : '');
    }
    return surveys;
  }
  //Filter support
  handleFilterChange(filter,value) {
    this.forceUpdate();
  }
  getAvailableStates() {
    let states = ["All"];
    for (var i = 0; i < this.props.surveyList.length; i++) {
      let status = this.props.surveyList[i].status;
      if (states.indexOf(status) <= -1) states.push(status);
    } return states;
  }

  render() {
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={10} floated='left'>
            <Header size='medium'>
              <HeaderContent>Site Surveys</HeaderContent>
              <AddButton onClick={ this.handleNew.bind(this) }/>
            </Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingBottom: 0}} width={4} floated='right'>
            <SearchableFilter ref={(r)=>this.filterObj=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={"Pending"}/>
          </Grid.Column>
          <Grid.Column width={15}>
            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.surveyList.length) {
                return <Message info compact size='large'>
                  You don&apos;t have any surveys.
                </Message>;
              }

              let surveys = sort(this.props.surveyList, this.state.ui.sortField);
              surveys = this.processAddresses(surveys);

              //filter with status
              if (this.filterObj && this.filterObj.getSelectedText() !== "") {
                let nSurveys = [];
                let filterStatus = this.filterObj.getSelectedText();
                for (var i = 0; i < surveys.length; i++) {
                  if (surveys[i].status === filterStatus || filterStatus === "All") nSurveys.push(surveys[i]);
                }
                surveys = nSurveys;
              }

              if (this.state.ui.sortDescending) {
                surveys.reverse();
              }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                  { field: 'project_name',          title: 'Project',        width: '4' },
                  { field: 'craneLocation',         title: 'Crane Location', width: '4' },
                  { field: 'ccName',                title: 'Company',        width: '4' },
                  { field: 'status',                title: 'Status',         width: '2' },
                  { field: 'displayType',           title: 'Type',           width: '2' },
                  { field: 'sortDate',              title: 'Date',           width: '2' },
                  { field: 'buttons', noSort: true, title: '',               width: '2' },
              ]}>
                { surveys.map((survey) => (
                  <SortTable.Row key={ survey.key }>
                    <SortTable.Cell>{ survey.project_name }</SortTable.Cell>
                    <SortTable.Cell>{ survey.craneLocation }</SortTable.Cell>
                    <SortTable.Cell>{ survey.ccName ? survey.ccName : '' }</SortTable.Cell>
                    <SortTable.Cell>{ survey.status }</SortTable.Cell>
                    <SortTable.Cell>{ survey.displayType }</SortTable.Cell>
                    <SortTable.Cell>{ survey.displayDate }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      <EditButton pathname={ `/${ PATH_FIRE_DEPARTMENT }/${ PATH_SITE_SURVEY }/${ survey.timestamp }` } view={ survey.status === 'Completed' }/>
                      <DeleteButton disabled={ !survey.fdInitiated } content='Delete the survey request?' onConfirm={ survey.fdInitiated ? this.handleRemove.bind(this, survey.id, survey.timestamp) : () => {} }/>
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    surveyList: storeState.models.fireDepartmentSurveys,
    ui: storeState.ui.fireDepartmentSurveys,
    constructionCompanies: storeState.lookups.constructionCompanies
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentSiteSurveyList);
