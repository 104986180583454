import Amplify from 'aws-amplify';
// import { Auth } from 'aws-amplify';

import { API_ADMIN, API_CONSTRUCTION, API_FIRE_DEPARTMENT, API_TRAINING_AGENCY, API_USER, API_BRIDGE } from './constants';

var endpoints, auth, logrocket, organizationModuleCustomerID = null;

//ATTENTION - IF YOU CHANGE ENDPOINT HERE, PLEASE CHANGE ON SSM PARAMETER STORE
switch (window.location.hostname) {
  case 'tharrp.bccsa-services.ca':
    endpoints = [
      {
        name: API_FIRE_DEPARTMENT,
        endpoint: "https://api-tharrp.bccsa-services.ca/fireDepartment",
      },
      {
        name: API_CONSTRUCTION,
        endpoint: "https://api-tharrp.bccsa-services.ca/construction",
      },
      {
        name: API_ADMIN,
        endpoint: "https://api-tharrp.bccsa-services.ca/admin",
      },
      {
        name: API_TRAINING_AGENCY,
        endpoint: "https://api-tharrp.bccsa-services.ca/trainingAgency",
      },
      {
        name: API_USER,
        endpoint: "https://api-tharrp.bccsa-services.ca/user",
      },
      {
        name: API_BRIDGE,
        endpoint: "https://ij0oio1vih.execute-api.ca-central-1.amazonaws.com/prod",
      }
    ];
    auth = {
      userPoolId: 'ca-central-1_abyO9AzrK',
      region: 'ca-central-1',
      userPoolWebClientId: '6o04bci56kl0jn073vr42ep9af',
    };
    logrocket = {
      applicationId:  "ue6tsn/tharrp-o3aka",
    };
    organizationModuleCustomerID = 'BCCSA-ST-Prod';
    break;
  case 'tharrp-stage.bccsa-services.ca':
    endpoints = [
      {
        name: API_FIRE_DEPARTMENT,
        endpoint: "https://api-tharrp-stage.bccsa-services.ca/fireDepartment",
      },
      {
        name: API_CONSTRUCTION,
        endpoint: "https://api-tharrp-stage.bccsa-services.ca/construction",
      },
      {
        name: API_ADMIN,
        endpoint: "https://api-tharrp-stage.bccsa-services.ca/admin",
      },
      {
        name: API_TRAINING_AGENCY,
        endpoint: "https://api-tharrp-stage.bccsa-services.ca/trainingAgency",
      },
      {
        name: API_USER,
        endpoint: "https://api-tharrp-stage.bccsa-services.ca/user",
      },
      {
        name: API_BRIDGE,
        endpoint: "https://ajdjnbm1si.execute-api.ca-central-1.amazonaws.com/stage",
      }
    ];
    auth = {
      userPoolId: 'ca-central-1_g1BGhdjMy',
      region: 'ca-central-1',
      userPoolWebClientId: '2gssv936ldmicqhu2f1mbn89lh',
    };
    logrocket = {
      applicationId:  "ikon-dev/bccsa",
    };
    organizationModuleCustomerID = 'BCCSA-ST-Stage';
    break;
  default:
    endpoints = [
      {
        name: API_FIRE_DEPARTMENT,
        endpoint: "https://api-tharrp-dev.bccsa-services.ca/fireDepartment",
      },
      {
        name: API_CONSTRUCTION,
        endpoint: "https://api-tharrp-dev.bccsa-services.ca/construction",
      },
      {
        name: API_ADMIN,
        endpoint: "https://api-tharrp-dev.bccsa-services.ca/admin",
      },
      {
        name: API_TRAINING_AGENCY,
        endpoint: "https://api-tharrp-dev.bccsa-services.ca/trainingAgency",
      },
      {
        name: API_USER,
        endpoint: "https://api-tharrp-dev.bccsa-services.ca/user",
      },
      {
        name: API_BRIDGE,
        endpoint: "https://qh5h2zidyl.execute-api.ca-central-1.amazonaws.com/dev",
      }
    ];
    auth = {
      userPoolId: 'ca-central-1_h0JhKh0XZ',
      region: 'ca-central-1',
      userPoolWebClientId: 'bhv9l2l05m4qd237ha40bm966',
    };
    logrocket = {
      applicationId:  "ikon-dev/bccsa",
    };
    organizationModuleCustomerID = 'BCCSA-ST-Dev';
}

Amplify.configure({
  Auth: auth,
  API: {
    endpoints: endpoints
  }
});

export const logrocketOptions = logrocket;
export const OrganizationModuleCustomerID = organizationModuleCustomerID;
