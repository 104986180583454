import React from 'react';

import { Table, Form } from 'semantic-ui-react';

import { SHORT_MONTHS } from '../js/lists';

import PropTypes from 'prop-types';

import NumberInput from './NumberInput';

const ENABLED_HEADER_COLOR = "black";
const DISABLED_HEADER_COLOR = "#7E7F80";
const ENABLED_VALUE_COLOR = "black";
const DISABLED_VALUE_COLOR = "#A1AAAD";

class MonthlyTable extends React.Component {
  render() {
    const { data, onEdit, ...props } = this.props;

    const total = Object.keys(data).reduce((sum, key) => { return sum + data[key] }, 0);

    return (
      <Table celled fixed compact='very' { ...props }>
        <Table.Header>
          <Table.Row>
            {
              SHORT_MONTHS.map(mon => {
                return (
                  <Table.HeaderCell key={ mon } style={{color:(onEdit ? ENABLED_HEADER_COLOR : DISABLED_HEADER_COLOR)}} textAlign='center' content={ mon }/>
                );
              })
            }
            <Table.HeaderCell textAlign='center' style={{color:DISABLED_HEADER_COLOR}} content={ 'Total' }/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {
              SHORT_MONTHS.map(mon => {
                if (onEdit) {
                  return (
                    <Table.Cell key={ mon }>
                      <Form.Field control={ NumberInput } name={mon} style={{color:ENABLED_VALUE_COLOR}} value={ data[mon] } onChange={ (name, value) => { onEdit(mon, value) }}/>
                    </Table.Cell>
                  );
                }
                return (
                  <Table.Cell key={ mon } style={{color:DISABLED_VALUE_COLOR}} textAlign='center'>{ data[mon] || 0 }</Table.Cell>
                );
              })
            }
            <Table.Cell style={{color:DISABLED_VALUE_COLOR}} textAlign='center'>{ total }</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

MonthlyTable.Row = Table.Row;
MonthlyTable.Cell = Table.Cell;

MonthlyTable.propTypes = {
  data: PropTypes.object.isRequired,
  // Fields are editable
  onEdit: PropTypes.func
};

export default MonthlyTable;
