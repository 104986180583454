import React from 'react';
import { connect } from 'react-redux';

import { Segment, Form, Message } from 'semantic-ui-react';

import CurrencyText from '../components/CurrencyText';
import DateControl from '../components/DateControl';
import EditDialog from '../components/EditDialog';

import { DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { MEAL_TYPES } from '../js/lists';

import { today } from '../utils/date';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  timestamp: '',
  type: '',
  date: today(),
  rate: '',

  errors: {
    error: false
  }
};

class MealEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, ...this.props.meal };

    this.rates = {
      B: this.props.settings.dailybreakfastamount,
      L: this.props.settings.dailylunchamount,
      D: this.props.settings.dailydinneramount,
    };

    this.didChange = this.didChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleTypeChange = (event, { value }) => {
    this.setState({
      type: value,
      rate: value ? this.rates[value] : 0
    }, () => { this.validate(); });
  }

  handleDateChange = (name, value) => {
    this.setState({ [name]: value }, () => { this.validate(); });
  }

  didChange = () => {
    // Always true for new entriesl
    if (!this.props.meal.timestamp) return true;

    if (this.state.type !== this.props.meal.type) { return true; }
    if (this.state.date !== this.props.meal.date) { return true; }
    if (this.state.rate !== this.props.meal.rate) { return true; }

    return false;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.date) {
      errors.error = true;
      errors.date = 'Please enter a date.';
    }

    if (!this.state.type) {
      errors.error = true;
      errors.type = 'Please select a type.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    const { errors, ...state } = this.state;
    this.props.onSave('meal', { ...state });
  }

  render = () => {
    const { onSave, onClose, meal, settings, dispatch, ...props } = this.props;

    const types = Object.keys(MEAL_TYPES).map((key) => { return { key: key, value: key, text: MEAL_TYPES[key] }});

    return (
      <EditDialog size='small' header='Add Meal Expense' onSave={ this.onSave } onClose={ onClose } readOnly={ this.props.disabled }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Segment.Group size='large'>
          <Segment disabled={ this.props.disabled }>
            <Form.Group>
              <Form.Field readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 6 }
                label='Date' name='date'
                control={ DateControl } date={ this.state.date } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                error={ !!this.state.errors.date }
                onChange={ this.handleDateChange }
              />
              { this.props.disabled &&
                <Form.Input readOnly label='Type' value={ MEAL_TYPES[this.state.type] } width={ 6 }/>
              }
              { !this.props.disabled &&
                <Form.Select required label='Type' value={ this.state.type } name='type' options={ types } width={ 6 }
                  error={ !!this.state.errors.type }
                  onChange={ this.handleTypeChange }
                />
              }
              <Form.Field label='Rate' value={ this.state.rate } width={ 4 }
                control={ CurrencyText }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.date } { this.state.errors.type }
            </Message>
          </Segment>
        </Segment.Group>
      </EditDialog>
    );
  }
}

MealEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  meal: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

function mapStoreStateToProps(storeState) {
  return {
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(MealEditDialog);
