import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon, Popup } from 'semantic-ui-react';

import { getAdminAnnualSubmissions, updateAdminAnnualSubmission, getWorkSiteRescues } from '../../api/administratorAPI';
import { getFireDepartments, getFireDepartmentSurveys } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_ANNUAL_SUBMISSIONS_UI } from '../../js/actionTypes';
import { DATE_YEAR, DATE_SHORT_MONTH } from '../../js/constants';
import { SHORT_MONTHS } from '../../js/lists';
import { PATH_ADMIN, PATH_ANNUAL } from '../../js/paths';

import { formatDateTime } from '../../utils/date';
import { sort } from '../../utils/utils';

import ApprovalButton from '../../components/ApprovalButton';
import CurrencyText from '../../components/CurrencyText';
import IconButton from '../../components/IconButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import MonthlyTableDialog from '../../dialogs/MonthlyTableDialog';
import RejectReasonEditDialog from '../../dialogs/RejectReasonEditDialog';
import AnnualSubmissionEditDialog from '../../dialogs/AnnualSubmissionEditDialog';


import ListView from '../ListView';

class AdminAnnualSubmissions extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      title: 'Annual Submissions',

      editing: {}
    };

    this.updateUIState = this.updateUIState.bind(this);
    this.saveSubmission = this.saveSubmission.bind(this);
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    if (!this.props.fireDepartments || this.props.fireDepartments.length === 0) {
      await getFireDepartments();
    }
    await getAdminAnnualSubmissions('Pending');
    this.setState({ loading: false });
  }

  handleTrainingClick = (submission) => {
    this.setState({ editing: { training: submission } });
  }

  handleRescuesClick = async (submission) => {
    this.setState({ loading: 'Loading' });

    await getWorkSiteRescues(submission.id, submission.timestamp.substr(0, 4), true);

    submission.rescues = {};

    SHORT_MONTHS.forEach(mon => {
      submission.rescues[mon] = 0;
    });

    this.props.rescues.forEach(rescue => {
      // Increment the count for the survey's month
      submission.rescues[formatDateTime(rescue.timestamp, DATE_SHORT_MONTH)]++;
    });

    this.setState({
      loading: false,
      editing: { rescues: submission }
    });
  }

  handleSurveysClick = async (submission) => {
    this.setState({ loading: 'Loading' });

    await getFireDepartmentSurveys({ status: 'Completed', fdid: submission.id, year: submission.timestamp.substr(0, 4), shift: true });

    submission.surveys = {};

    SHORT_MONTHS.forEach(mon => {
      submission.surveys[mon] = 0;
    });

    this.props.surveys.forEach(survey => {
      // Increment the count for the survey's month
      submission.surveys[formatDateTime(survey.timestamp, DATE_SHORT_MONTH)]++;
    });

    this.setState({
      loading: false,
      editing: { surveys: submission }
    });
  }

  handleEditSubmissionClick = (submission) => {
    this.setState({ editing: { submission: submission } });
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await updateAdminAnnualSubmission(data);
    this.setState({ loading: false });
  }

  handleApprove = async (id, timestamp) => {
    await this.update({
      id: id,
      timestamp: timestamp,
      status: 'Approved'
    });
    await this.fetch();
  }

  handleReject = (id, timestamp) => {
    this.getRejectReason({
      id: id,
      timestamp: timestamp,
      status: 'Rejected'
    });
  }

  closeDialog = () => { this.setState({ editing: {} }); }

  saveRejectReason = async (rejectreason) => {
    let data = { ...this.state.editing.reason };
    data.rejectreason = rejectreason;
    this.closeDialog();
    await this.update(data);
    await this.fetch();
  }

  saveSubmission = async (submission) => {
    console.log('sss', submission);
    this.closeDialog();
    await this.update(submission);
    await this.fetch();
  }

  getRejectReason = (data) => {
    this.setState({ editing: { reason: data } });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_ANNUAL_SUBMISSIONS_UI, adminAnnualSubmissions: this.state.ui });
    });
  }

  render() {
    return (
      <div className='admin-annual-submissions'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>{ this.state.title }</Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.annualSubmissions.length) {
                return <Message info size='large'>
                  You don&apos;t have any annual submissions.
                </Message>;
              }

              let annualSubmissions = sort(this.props.annualSubmissions, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                annualSubmissions.reverse();
              }

              const right = { textAlign: 'right' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',                    title: 'Submission Date',     width: '2'               },
                { field: 'year',                        title: 'Year',                width: '1' },
                { field: 'fdName',                      title: 'Fire Department',     width: '4'               },
                { field: 'firedeptstipendamount',       title: 'FD Stipend',          width: '2', style: right },
                { field: 'trainersstipendamount',       title: 'Instructors Stipend', width: '2', style: right },
                { field: 'sitesurveyreimbamount',       title: 'Crane Surveys',       width: '2', style: right },
                { field: 'trainerskillstrainingamount', title: 'Training Skills',     width: '2', style: right },
                { field: 'buttons', noSort: true,       title: '',                    width: '2'               },
              ]}>
                { annualSubmissions.map((submission) => (
                  <SortTable.Row key={ submission.key }>
                    <SortTable.Cell>{ submission.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ submission.timestamp.substr(0, 4) }</SortTable.Cell>
                    <SortTable.Cell>{ submission.fdName }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ submission.firedeptstipendamount }/></SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ submission.trainersstipendamount }/></SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ submission.sitesurveyreimbamount }/></SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ submission.trainerskillstrainingamount }/></SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      <IconButton name='eye' color='orange' style={{ marginRight: '0.3em' }}
                        popup='Edit submission'
                        onClick={ this.handleEditSubmissionClick.bind(this, submission) }
                      />
                      <IconButton name='life ring' color='green' style={{ marginRight: '0.3em' }}
                        popup='View work rescues per month'
                        onClick={ this.handleRescuesClick.bind(this, submission) }
                      />
                      <IconButton name='clock' color='green' style={{ marginRight: '0.3em' }}
                        popup='View training hours per month'
                        onClick={ this.handleTrainingClick.bind(this, submission) }
                      />
                      <IconButton name='file text' color='green' style={{ marginRight: '0.3em' }}
                        popup='View crane surveys per month'
                        onClick={ this.handleSurveysClick.bind(this, submission) }
                      />
                      <EditButton popup='Edit annual submission'
                        pathname={ `/${ PATH_ADMIN }/${ PATH_ANNUAL }/${ submission.id }/${ submission.timestamp }` }
                      />
                      { submission.status === 'Pending' &&
                        <ApprovalButton popup='Approve or reject the submission'
                          onApproval={ this.handleApprove.bind(this, submission.id, submission.timestamp) }
                          onRejection={ this.handleReject.bind(this, submission.id, submission.timestamp) }
                        />
                      }
                      { submission.status === 'Approved' &&
                        <Popup content='Approved' trigger={
                          <Icon name='clipboard check' size='large' color='green' />
                        }/>
                      }
                      { submission.status === 'Rejected' &&
                        <Popup content='Rejected' trigger={
                          <Icon name='clipboard' size='large' color='red' />
                        }/>
                      }
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.state.editing.reason &&
              <RejectReasonEditDialog open={ !!this.state.editing.reason }
                onSave={ this.saveRejectReason } onClose={ this.closeDialog }
              />
            }
            { this.state.editing.training &&
              <MonthlyTableDialog open={ !!this.state.editing.training } label='Training Conducted' header={ this.state.editing.training.fdName }
                onClose={ this.closeDialog } data={ this.state.editing.training.traininghours }
              />
            }
            { this.state.editing.rescues &&
              <MonthlyTableDialog open={ !!this.state.editing.rescues } label='Work Rescues' header={ this.state.editing.rescues.fdName }
                onClose={ this.closeDialog } data={ this.state.editing.rescues.rescues }
              />
            }
            { this.state.editing.surveys &&
              <MonthlyTableDialog open={ !!this.state.editing.surveys } label='Crane Surveys' header={ this.state.editing.surveys.fdName }
                onClose={ this.closeDialog } data={ this.state.editing.surveys.surveys }
              />
            }
            { this.state.editing.submission &&
              <AnnualSubmissionEditDialog open={ !!this.state.editing.submission } label='Crane Surveys'
                onClose={ this.closeDialog } data={ this.state.editing.submission } onSave={ this.saveSubmission }
              />
            }

            
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    annualSubmissions: storeState.models.fireDepartmentAnnualSubmissions,
    surveys: storeState.models.fireDepartmentSurveys,
    rescues: storeState.models.workSiteRescues,
    ui: storeState.ui.adminAnnualSubmissions,
  };
}

export default connect(mapStoreStateToProps)(AdminAnnualSubmissions);
