import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form, Message } from 'semantic-ui-react';

import { postFireDepartmentWorkSiteRescue } from '../../api/fireDepartmentAPI';

import { DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { PATH_FIRE_DEPARTMENT, PATH_RESCUE } from '../../js/paths';

import { today } from '../../utils/date';
import { sort } from '../../utils/utils';

import DateControl from '../../components/DateControl';

import EditView from '../EditView';

const INITIAL_STATE = {
  ccid: '',
  ccname: '',
  dateofservice: today(),
  comments: '',

  // UI
  isNew: true,
  ccs: [],

  title: 'Work Site Rescues',
  className: 'fire-department-rescue-service'
};

class FireDepartmentRescueService extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.exitPage = `/${ PATH_FIRE_DEPARTMENT }/${ PATH_RESCUE }`;

    this.state.ccs = this.props.constructionCompanies.map(({ id, entity_name }) => {
      return { key: id, value: id, text: entity_name };
    });
    this.state.ccs = sort(this.state.ccs, 'text');

    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleCcChange = (event, { name, value }) => {
    if (name === 'ccid') {
      const cc = this.props.constructionCompanies.find((cc) => { return cc.id === value; });
      this.setState({
        ccid: value,
        ccname: cc ? cc.entity_name : ''
      }, () => { this.onChange(); });
    } else if (name === 'ccname') {
      const cc = this.props.constructionCompanies.find((cc) => { return cc.entity_name === value; });
      this.setState({
        ccid: cc ? cc.id : '',
        ccname: value
      }, () => { this.onChange(); });
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.ccid) {
      errors.error = true;
      errors.ccid = 'Please select a construction company.'
    }

    if (!this.state.dateofservice) {
      errors.error = true;
      errors.dateofservice = 'Please select a date of service.'
    }

    if (!this.state.comments) {
      errors.error = true;
      errors.comments = 'Please enter some comments.'
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  create = async (data) => {
    this.setState({ loading: 'Submitting' });
    const { ccs, ...body } = data;
    await postFireDepartmentWorkSiteRescue(body);
    if (this.props.workSiteRescue.id) {
      this.exit();
    }
    this.setState({ loading: false });
  }

  renderView = () => {
    return (
      <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
          <Form.Button floated='right' className='form-top-button' positive>Submit</Form.Button>
          <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
        </Header>
        <Segment.Group size='large' className='view-segment-group'>
          <Segment>
            <Form.Group>
              <Form.Dropdown search selection label='Construction Company' name='ccid' value={ this.state.ccid } options={ this.state.ccs } width={ 6 }
                onChange={ this.handleCcChange } required={true}
              />
              <Form.Field required label='Date of Service' name='dateofservice' width={ 4 }
                control={ DateControl } date={ this.state.dateofservice } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                onChange={ this.handleDateChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.dateofservice || this.state.errors.ccid }
            </Message>
            <Form.Group>
              <Form.TextArea required label='Comments' value={ this.state.comments } name='comments' width={ 16 } onChange={ this.handleChange } />
            </Form.Group>
            <Message error>
              { this.state.errors.comments }
            </Message>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }
  //Old cc field
  // <Form.Input label='Construction Company Name' value={ this.state.ccname } name='ccname' width={ 6 }
  //   onChange={ this.handleCcChange }
  // />
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    workSiteRescue: storeState.models.fireDepartmentWorkSiteRescue,
    constructionCompanies: storeState.lookups.constructionCompanies,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentRescueService);
