import React from 'react';

import { Segment, Header, Message } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import AddButton from './AddButton';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import SortTable from './SortTable';

import { sort } from '../utils/utils';

class MemberSegment extends React.Component {
  updateUIState = (uiState) => {
    this.props.onUIStateChange({ members: uiState });
  }

  render = () => {
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Members</Header.Content>
          <AddButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'members', {}) }/>
        </Header>

        {(() => {
          if (this.props.members.length === 0) {
            return <Message info compact size='tiny'>No members</Message>;
          }

          let members = this.props.members.map(b => {
            let member = { ...b };
            return member;
          });

          members = sort(members, this.props.ui.sortField);
          if (this.props.ui.sortDescending) {
            members.reverse();
          }

          const right = { textAlign: 'right' };

          return <SortTable sortField={ this.props.ui.sortField } sortDescending={ this.props.ui.sortDescending }
            onSort={ this.updateUIState } size='small'
            headers={[
              { field: 'name',                  title: 'Name',  width: '7'               },
              { field: 'memberemail',           title: 'Email', width: '6'               },
              { field: 'vote',                  title: 'Vote',  width: '1'               },
              { field: 'buttons', noSort: true, title: '',      width: '2', style: right },
            ]}
          >
            { members.map((member, index) => (
              <SortTable.Row key={ index } id={ `member_${ member.memberemail }`}>
                <SortTable.Cell>{ member.name }</SortTable.Cell>
                <SortTable.Cell>{ member.memberemail }</SortTable.Cell>
                <SortTable.Cell>{ member.vote ? 'Yes' : 'No' }</SortTable.Cell>
                <SortTable.Cell textAlign='right'>
                  <EditButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'members', member) }/>
                  <DeleteButton hide={ this.props.disabled } content='Delete the member?' onConfirm={ this.props.remove.bind(this, 'members', member) }/>
                </SortTable.Cell>
              </SortTable.Row>
            ))}
          </SortTable>;
        })()}
      </Segment>
    );
  }
};

MemberSegment.propTypes = {
  members: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onUIStateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MemberSegment;
