import React from 'react';

import { Segment, Form, Message } from 'semantic-ui-react';

import EditDialog from '../components/EditDialog';

import PropTypes from 'prop-types';

const INITIAL_STATE = {
  voteoption: '',
  memberemail: '',

  errors: {
    error: false
  }
};

class BallotEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, ...this.props.ballot };

    this.didChange = this.didChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleVoteChange = (event, { name, value }) => {
    let stateUpdate = { [name]: value };

    this.setState(stateUpdate, () => { this.validate(); });
  }

  didChange = () => {
    return true;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.memberemail) {
      errors.error = true;
      errors.memberemail = 'Please enter a valid email address. ';
    }

    if (this.state.voteoption === '') {
      errors.error = true;
      errors.voteoption = 'Please vote Yes or No.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    const { errors, ...state } = this.state;
    this.props.onSave('ballots', { ...state });
  }

  render = () => {
    const { onSave, onClose, ballot, ...props } = this.props;

    const types = [{ key: 'T', value: 'true', text: 'Yes' }, { key: 'F', value: 'false', text: 'No' }];

    return (
      <EditDialog size='small' header='Edit Vote' onSave={ this.onSave } onClose={ onClose } readOnly={ this.props.disabled }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Segment.Group size='large'>
          <Segment disabled={ this.props.disabled }>
            <Form.Group>
              <Form.Input readOnly={ this.props.disabled } required={ !this.props.disabled } width={ 8 }
                label='Member Email' value={ this.state.memberemail } name='memberemail'
                onChange={ this.handleChange }
              />
              { this.props.disabled &&
                <Form.Input readOnly label='Type' value={ this.state.voteoption === 'true' ? 'Yes' : 'No' } width={ 6 }/>
              }
              { !this.props.disabled &&
                <Form.Select required label='Vote Option' name='voteoption' value={ this.state.voteoption } options={ types } width={ 6 }
                  error={ !!this.state.errors.voteoption }
                  onChange={ this.handleVoteChange }
                />
              }
            </Form.Group>
            <Message error>
              { this.state.errors.memberemail }{ this.state.errors.voteoption }
            </Message>
          </Segment>
        </Segment.Group>
      </EditDialog>
    );
  }
}

BallotEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  ballot: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default BallotEditDialog;
