import React from 'react';
import { connect } from 'react-redux';

import { Grid, Segment, Header, Form, Message } from 'semantic-ui-react';

import { UPDATE_FIRE_DEPARTMENT_TRAINER } from '../../js/actionTypes';

import { PATH_FIRE_DEPARTMENT, PATH_TRAINER, PATH_NEW, PATH_ID, PATH_TIMESTAMP, EXTERNAL_PATH_FIN3 } from '../../js/paths';

import { getFireDepartmentTrainer, updateFireDepartmentTrainer, postFireDepartmentTrainer } from '../../api/fireDepartmentAPI';

import EditView from '../EditView';

import ViewLoader from '../../components/ViewLoader';
import CourseSegment from '../../components/CourseSegment';
import CurrencyInput from '../../components/CurrencyInput';
import ExternalButton from '../../components/ExternalButton';

const INITIAL_STATE = {
  trainername: '',

  loading: false,
  isNew: false,

  errors: {
    error: false
  }
};

class FireDepartmentTrainer extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

    this.state.isNew = (props.match.params[PATH_ID] === PATH_NEW);

    this.onDidSave = this.onDidSave.bind(this);
  }

  componentDidMount = () => {
    this.initialize();
    this.isClean();
  }

  initialize = async () => {
    if (this.state.isNew) {
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_TRAINER, fireDepartmentTrainer: INITIAL_STATE });
    } else {
      this.setState({ loading: 'Loading' });
      await getFireDepartmentTrainer(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
      this.setState({ ...this.props.trainer, loading: false });
    }
  }

  onChange = () => {
    this.isDirty();
    this.validate();
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value }, () => { this.onChange(); });
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    this.setState({ errors: errors });

    return !errors.error;
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.isValid()) {
      const { loading, isNew, errors, ...data } = this.state;

      if (this.state.isNew) {
        this.create(data);
      } else {
        this.update(data);
      }
    }
  };

  onDidSave = async () => {
    this.setState({ loading: 'Updating' });
    this.initialize();
    this.setState({ loading: false });
  }

  goToList = () => {
    this.isClean();
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_TRAINER }`);
  }

  create = async (data) => {
    this.setState({ loading: 'Adding' });
    await postFireDepartmentTrainer(data);
    if (this.props.trainer.id) {
      this.goToList();
    }
    this.setState({ loading: false });
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await updateFireDepartmentTrainer(data);
    if (this.props.trainer.id) {
      this.goToList();
    }
    this.setState({ loading: false });
  }

  cancel = event => {
    event.preventDefault();

    // Back to the list
    this.goToList();
  }

  calculateTotalReimbursement() {
    if (this.state.totalreimbursements !== undefined && this.state.totalreimbursements.length > 0) {
      let val = 0;
      for (var i = 0; i < this.state.totalreimbursements.length; i++) { val += parseFloat(this.state.totalreimbursements[i].totalamount); }
      return val;
    } return 0;
  }

  render() {
    const totalReimbursement = this.calculateTotalReimbursement();
    return (
      <div className='fire-department-trainer'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              return (
                <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
                  
                  <Header size='medium'>
                    <Grid columns='equal' verticalAlign='bottom'>
                      <Grid.Column>
                        <Header.Content>{this.state.isNew ? "New Instructor" : "Edit Instructor details"}</Header.Content>
                        <Header.Subheader>
                          Review the Standard Operating Procedure(s) Related to this page
                          <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ EXTERNAL_PATH_FIN3 } label="FIN3" />
                        </Header.Subheader>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Button floated='right' className='form-top-button' positive>{ this.state.isNew ? 'Add' : 'Update' } Instructor</Form.Button>
                        <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
                      </Grid.Column>
                    </Grid>
                  </Header>
                  <Segment.Group size='large' className='view-segment-group'>
                    <Segment>
                      <Form.Group>
                        <Form.Input required label='Instructor Name' value={ this.state.trainername } name='trainername' width={ 8 }
                          error={ !!this.state.errors.trainername }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                      { !this.state.isNew &&
                        <>
                          <CourseSegment disabled={ this.state.isReadOnly } onDidSave={ this.onDidSave } courses={ this.state.courses } trainer={ this.props.trainer }/>
                          <br></br>
                          <Form.Group>
                            <Form.Input label='Total Reimbursement' name='totalreimbursement' control={ CurrencyInput } readOnly={ true }  value={ totalReimbursement } width={ 3 } onChange={()=>{}}/>
                          </Form.Group>
                        </>
                      }
                      <Message error>
                        { this.state.errors.trainername }
                      </Message>
                    </Segment>
                  </Segment.Group>
                </Form>
              );
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    trainer: storeState.models.fireDepartmentTrainer,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentTrainer);
