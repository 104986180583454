import { post } from './api';

import { ORG_MODULE_ORGS_SEARCH, ORG_MODULE_WORKSAFEID_VALIDATION } from '../js/actionTypes';
import { API_BRIDGE } from '../js/constants';
import { OrganizationModuleCustomerID } from '../js/auth';

import store from '../js/store';

import sha1 from 'sha1';

// validation
export async function validateWorksafeID(orgID, worksafeID) {
  // Will return an array of users when called by an admin
  const response = await post(API_BRIDGE, '/bridge/open', {
    method: 'POST',
    path: `/organization/${orgID}/employee/validation`,
    body: { hashedWorksafeID : sha1(worksafeID) },
    "service": "module-org-api",
    "customerID": OrganizationModuleCustomerID,
    headers: { "customerID": OrganizationModuleCustomerID },
  }, { noAuthRequired: true });
  //leave error handling if reducer
  store.dispatch({ type: ORG_MODULE_WORKSAFEID_VALIDATION, validation: response });
  //
  return response;
}

// search
export async function searchOrganizations(searchTerm, filter) {
  // Will return an array of users when called by an admin
  const response = await post(API_BRIDGE, '/bridge/open', {
    method: 'POST',
    path: '/organizations/search',
    body: { searchTerm, filter },
    "service": "module-org-api",
    "customerID": OrganizationModuleCustomerID,
    headers: { "customerID": OrganizationModuleCustomerID },
  }, { noAuthRequired: true });
  //leave error handling if reducer
  store.dispatch({ type: ORG_MODULE_ORGS_SEARCH, orgs: response });
  //
  return response;
}
