import React from "react";
import { connect } from 'react-redux';

import { Grid, Header, Message, Segment, Statistic } from 'semantic-ui-react';

import { getFireDepartmentFinancialSummary, getFireDepartmentSurveys } from '../../api/fireDepartmentAPI';

import { PATH_FIRE_DEPARTMENT, PATH_SITE_SURVEY } from "../../js/paths";

import CurrencyText from '../../components/CurrencyText';
import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

class FireDepartmentHome extends ListView {
  constructor(props) {
    super(props);
    this.currentYear = new Date().getFullYear().toString();
    this.state = {
      loading: false,
    };
  }

  fetch = async () => {
    await getFireDepartmentFinancialSummary(this.props.currentUser.orgId, this.currentYear);
    await getFireDepartmentSurveys({ status: 'Pending'});
  }

  goToItem = (loc) => {
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ loc }`);
  }

  //Sum support
  sumTotalValues(values) {
    if (values !== undefined && values.length > 0) {
      let sum = 0;
      values.forEach(obj => {
        sum += (obj.total !== undefined ? obj.total : 0);
      }); return sum;
    } else return 0;
  }

  render() {
  return (
    <div className='fire-department-special-payment-list'>
      <Grid centered className='view-grid'>
        <Grid.Column className='view-column' width={ 15 } style={{marginLeft: 40, paddingTop: 30}} >
          <Header size='medium'>
            <Header.Content>{this.currentYear} THARRP Overview</Header.Content>
          </Header>
          <div className="pg-content__body">

          {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

            if (!this.props.fireDepartmentFinancialSummaries.length) {
              return <Message info compact size='large'>
                You don&apos;t have a Financial Summary yet.
              </Message>;
            }
            let summary = this.props.fireDepartmentFinancialSummaries[0];
            const alignCenter = {alignSelf:"center"};
            const center = {textAlign:"center"};
            return <Grid key={ summary.key} style={{paddingTop: 0}}>
              <Grid.Row>
                <Grid.Column width={ 16 }>
                  <Segment color='red'>
                    <Statistic.Group widths='5' size='small'>
                      <Statistic style={alignCenter}>
                        <Statistic.Value><CurrencyText style={center} value={ (summary.trainingreimbursement ? summary.trainingreimbursement : 0) }/></Statistic.Value>
                        <Statistic.Label><br/>Course Reimbursements</Statistic.Label>
                      </Statistic>
                      <Statistic style={alignCenter}>
                        <Statistic.Value>
                          <CurrencyText style={center} value={ (summary.updatetrainingpaid ? summary.updatetrainingpaid : 0) }/>
                        </Statistic.Value>
                        <Statistic.Label><br/>Update Training <br/> Reimbursements</Statistic.Label>
                      </Statistic>
                      <Statistic style={alignCenter}>
                        <Statistic.Value>
                          { this.sumTotalValues(summary.numcranesurveys) + this.sumTotalValues(summary.numcranesurveysMutualAid) }
                        </Statistic.Value>
                        <Statistic.Label><br/>Crane Surveys </Statistic.Label>
                      </Statistic>
                      <Statistic style={alignCenter}>
                        <Statistic.Value>
                          <CurrencyText style={center} value={ (summary.ppepaid ? summary.ppepaid : 0) }/>
                        </Statistic.Value>
                        <Statistic.Label><br/>PPE</Statistic.Label>
                      </Statistic>
                      <Statistic style={alignCenter}>
                        <Statistic.Value>
                          <CurrencyText style={center} value={ (summary.totalspecialpayments ? summary.totalspecialpayments : 0) }/>
                        </Statistic.Value>
                        <Statistic.Label><br/>Special Payments</Statistic.Label>
                      </Statistic>
                    </Statistic.Group>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Segment className="clickable" onClick={ this.goToItem.bind(this, PATH_SITE_SURVEY) }>
                    <Statistic.Group widths='1' size='small'>
                      <Statistic style={alignCenter}>
                        <Statistic.Value>{this.props.surveyList.length}</Statistic.Value>
                        <Statistic.Label>Crane Survey(s)</Statistic.Label>
                      </Statistic>
                    </Statistic.Group>
                  </Segment>
                </Grid.Column>
              </Grid.Row>

            </Grid>
          })()}
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )}
}
 
function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    fireDepartmentFinancialSummaries: storeState.models.fireDepartmentFinancialSummaries,
    surveyList: storeState.models.fireDepartmentSurveys,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentHome);
