import React from 'react';
import { connect } from 'react-redux';

import { Grid, Segment, Header, Form, Message } from 'semantic-ui-react';

import CurrencyInput from '../../components/CurrencyInput';
import NumberInput from '../../components/NumberInput';
import MultiSelectInput from '../../components/MultiSelectInput';
import ViewLoader from '../../components/ViewLoader';

import { getSettings, updateSettings } from '../../api/administratorAPI';

const INITIAL_STATE = {
  ap: '',
  dailybreakfastamount: 13.5,
  dailydinneramount: 25.5,
  dailylunchamount: 15.5,
  emailpayments: '',
  gst: 0.05,
  initialequipamount: 11567.07,
  maxamoutoperatorreimbursement: 1088.02,
  maxdaysofoperatorreimbursement: 2,
  maxtuitionamountpertrainer: 5395,
  maxupgradetuitionpertrainer: 865,
  mileageamount: 0.54,
  nighthotelaccommodamount: 130,
  nightprivateaccommodamount: 35,
  ppeamount: 1500,
  province: "BC",
  pst: 0.07,
  sitesurveyamount: 258.82,
  stipendpertraineramount: 1445.88,
  trainerskillstrainingamount: 650.2,
  maritimeCUs: [],

  loading: false,
  errors: {
    error: false
  }
};

class AdminSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount = () => {
    this.initialize();
  }

  initialize = async () => {
    this.setState({ loading: 'Loading' });
    await getSettings();
    this.setState({ ...this.props.settings, loading: false });
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value }, () => { this.validate(); });
  }

  handleCurrencyChange = (name, value) => {
    const stateUpdate = { [name]: value };
    this.setState(stateUpdate, () => { this.validate(); });
  }

  handleValueChange = (name, value) => {
    const stateUpdate = { [name]: value };
    this.setState(stateUpdate, () => { this.validate(); });
  }

  handleCUsChange = (event, { name, value }) => {
    const stateUpdate = { [name]: value.map(v => v) };
    this.setState(stateUpdate, () => { this.validate(); });
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.ap) {
      errors.error = true;
      errors.ap = 'Please enter the Accounts Payable email address.';
    }

    if (!this.state.dailybreakfastamount) {
      errors.error = true;
      errors.dailybreakfastamount = 'Please enter the daily breakfast flat rate.';
    }

    if (!this.state.dailydinneramount) {
      errors.error = true;
      errors.dailydinneramount = 'Please enter the daily dinner flat rate.';
    }

    if (!this.state.dailylunchamount) {
      errors.error = true;
      errors.dailylunchamount = 'Please enter the daily lunch flat rate.';
    }

    if (!this.state.emailpayments) {
      errors.error = true;
      errors.emailpayments = 'Please enter the email payments address.';
    }

    if (!this.state.gst) {
      errors.error = true;
      errors.gst = 'Please enter the GST amount.';
    }

    if (!this.state.initialequipamount) {
      errors.error = true;
      errors.initialequipamount = 'Please enter the initial equipment amount.';
    }

    if (!this.state.maxamoutoperatorreimbursement) {
      errors.error = true;
      errors.maxamoutoperatorreimbursement = 'Please enter the tower crane operator maximum daily reimbursement amount.';
    }

    if (!this.state.maxdaysofoperatorreimbursement) {
      errors.error = true;
      errors.maxdaysofoperatorreimbursement = 'Please enter the tower crane operator maximum number of reimbursement days.';
    }

    if (!this.state.maxtuitionamountpertrainer) {
      errors.error = true;
      errors.maxtuitionamountpertrainer = 'Please enter the maximum course tuition amount.';
    }

    if (!this.state.maxupgradetuitionpertrainer) {
      errors.error = true;
      errors.maxupgradetuitionpertrainer = 'Please enter the maximum upgrade tuition amount.';
    }

    if (!this.state.mileageamount) {
      errors.error = true;
      errors.mileageamount = 'Please enter the daily mileage rate per Km.';
    }

    if (!this.state.nighthotelaccommodamount) {
      errors.error = true;
      errors.nighthotelaccommodamount = 'Please enter the maximum daily hotel rate.';
    }

    if (!this.state.nightprivateaccommodamount) {
      errors.error = true;
      errors.nightprivateaccommodamount = 'Please enter the daily private accommodation flat rate.';
    }

    if (!this.state.ppeamount) {
      errors.error = true;
      errors.ppeamount = 'Please enter the personal protection equipment amount.';
    }

    if (!this.state.province) {
      errors.error = true;
      errors.province = 'Please enter the province.';
    }

    if (!this.state.pst) {
      errors.error = true;
      errors.pst = 'Please enter the PST amount.';
    }

    if (!this.state.defaultcc) {
      errors.error = true;
      errors.pst = 'Please enter the Built-in CC.';
    }

    if (!this.state.sitesurveyamount) {
      errors.error = true;
      errors.sitesurveyamount = 'Please enter the site survey amount.';
    }

    if (!this.state.sitesurveyamountMutualAid) {
      errors.error = true;
      errors.sitesurveyamountMutualAid = 'Please enter the site survey amount - mutual aid.';
    }

    if (!this.state.stipendpertraineramount) {
      errors.error = true;
      errors.stipendpertraineramount = 'Please enter the annual stipend (per trainer) amount.';
    }

    if (!this.state.trainerskillstrainingamount) {
      errors.error = true;
      errors.trainerskillstrainingamount = 'Please enter the annual skills training (per active trainer) amount.';
    }

    if (!this.state.maritimeCUs || !Array.isArray(this.state.maritimeCUs) || this.state.maritimeCUs.length === 0) {
      errors.error = true;
      errors.maritimeCUs = 'Please enter at least one maritime CU.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  didChange = () => {
    return true;
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.isValid()) {
      if (this.didChange()) {
        const { loading, errors, ...data } = this.state;

        this.update(data);
      }
    }
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await updateSettings(data);
    this.setState({ loading: false });
  }

  reset = event => {
    event.preventDefault();

    this.setState({ ...INITIAL_STATE });
    this.initialize();
  }

  render = () => {
    return (
      <div className='admin-settings'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              return (
                <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
                  <Header size='medium'>
                    <Header.Content>Settings</Header.Content>
                    <Form.Button floated='right' className='form-top-button' positive>Update Settings</Form.Button>
                    <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.reset }>Reset</Form.Button>
                  </Header>
                  <Segment.Group size='large' className='view-segment-group'>
                    <Segment>
                      <Header content='General' size='tiny' />
                      <Form.Group>
                        <Form.Input required label='Accounts Payable Email' value={ this.state.ap } name='ap' width={ 6 }
                          error={ !!this.state.errors.ap }
                          onChange={ this.handleChange }
                        />
                        <Form.Input required label='Email Payments' value={ this.state.emailpayments } name='emailpayments' width={ 6 }
                          error={ !!this.state.errors.emailpayments }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.ap }{ this.state.errors.emailpayments }
                      </Message>
                      <Form.Group>
                        <Form.Input required label='Province' value={ this.state.province } name='province' width={ 4 }
                          error={ !!this.state.errors.province }
                          onChange={ this.handleChange }
                        />
                        <Form.Field required label='GST' value={ this.state.gst } name='gst' width={ 2 }
                          error={ !!this.state.errors.gst }
                          control={ NumberInput } decimalScale={ 2 } fixedDecimalScale={ true }
                          onChange={ this.handleValueChange }
                        />
                        <Form.Field required label='PST' value={ this.state.pst } name='pst' width={ 2 }
                          error={ !!this.state.errors.pst }
                          control={ NumberInput } decimalScale={ 2 } fixedDecimalScale={ true }
                          onChange={ this.handleValueChange }
                        />
                        <Form.Input required label='Built-In CC' value={ this.state.defaultcc } name='defaultcc' width={ 4 }
                          error={ !!this.state.errors.defaultcc }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.province }{ this.state.errors.gst }{ this.state.errors.pst }{ this.state.errors.defaultcc }
                      </Message>
                    </Segment>
                    <Segment>
                      <Header content='Fire Department' size='tiny' />
                      <Form.Group>
                        <Form.Field required label='Initial Equipment Amount' value={ this.state.initialequipamount } name='initialequipamount' width={ 4 }
                          error={ !!this.state.errors.initialequipamount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                        {/*
                        <Form.Field required label='PPE Amount' value={ this.state.ppeamount } name='ppeamount' width={ 4 }
                          error={ !!this.state.errors.ppeamount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                        */}
                        <Form.Field required label='Site Survey Amount' value={ this.state.sitesurveyamount } name='sitesurveyamount' width={ 4 }
                          error={ !!this.state.errors.sitesurveyamount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.initialequipamount }{ this.state.errors.ppeamount }{ this.state.errors.sitesurveyamount }
                      </Message>
                      <Form.Group>
                      <Form.Field required label={ <label>Site Survey Amount<br />Mutual Aid</label> }
                          value={ this.state.sitesurveyamountMutualAid } name='sitesurveyamountMutualAid' width={ 4 }
                          error={ !!this.state.errors.sitesurveyamountMutualAid }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                        <Form.Field required label={ <label>Annual Stipend Amount<br />(per Trainer)</label> }
                          value={ this.state.stipendpertraineramount } name='stipendpertraineramount' width={ 4 }
                          error={ !!this.state.errors.stipendpertraineramount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                        <Form.Field required label={ <label>Annual Skills Training Amount<br />(per Active Trainer)</label> }
                          value={ this.state.trainerskillstrainingamount } name='trainerskillstrainingamount' width={ 4 }
                          error={ !!this.state.errors.trainerskillstrainingamount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                      </Form.Group>
                      <Message error>
                      { this.state.errors.sitesurveyamountMutualAid }{ this.state.errors.trainerskillstrainingamount }{ this.state.errors.stipendpertraineramount }
                      </Message>
                    </Segment>
                    <Segment>
                      <Header content='Tower Crane Operator Reimbursements' size='tiny' />
                      <Form.Group>
                        <Form.Input required type='number' min='0' label='Maximum Number of Days' value={ this.state.maxdaysofoperatorreimbursement } name='maxdaysofoperatorreimbursement' width={ 4 }
                          error={ !!this.state.errors.maxdaysofoperatorreimbursement }
                          onChange={ this.handleChange }
                        />
                        <Form.Field required label='Maximum Daily Amount' value={ this.state.maxamoutoperatorreimbursement } name='maxamoutoperatorreimbursement' width={ 4 }
                          error={ !!this.state.errors.maxamoutoperatorreimbursement }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.maxupgradetuitionpertrainer }{ this.state.errors.maxamoutoperatorreimbursement }
                      </Message>
                    </Segment>
                    <Segment>
                      <Header content='Training Tuitions' size='tiny' />
                      <Form.Group>
                        <Form.Field required label='Maximum Course Tuition' value={ this.state.maxtuitionamountpertrainer } name='maxtuitionamountpertrainer' width={ 4 }
                          error={ !!this.state.errors.maxtuitionamountpertrainer }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                        <Form.Field required label='Upgrade Tower Crane Rescue Training' value={ this.state.maxupgradetuitionpertrainer } name='maxupgradetuitionpertrainer' width={ 4 }
                          error={ !!this.state.errors.maxupgradetuitionpertrainer }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.maxupgradetuitionpertrainer }{ this.state.errors.maxtuitionamountpertrainer }
                      </Message>
                    </Segment>
                    <Segment>
                      <Header content='Daily Trainer Allowances' size='tiny' />
                      <Form.Group>
                        <Form.Field required label='Breakfast Flat Rate' value={ this.state.dailybreakfastamount } name='dailybreakfastamount' width={ 4 }
                          error={ !!this.state.errors.dailybreakfastamount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                        <Form.Field required label='Lunch Flat Rate' value={ this.state.dailylunchamount } name='dailylunchamount' width={ 4 }
                          error={ !!this.state.errors.dailylunchamount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                        <Form.Field required label='Dinner Flat Rate' value={ this.state.dailydinneramount } name='dailydinneramount' width={ 4 }
                          error={ !!this.state.errors.dailydinneramount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.dailybreakfastamount }{ this.state.errors.dailylunchamount }{ this.state.errors.dailydinneramount }
                      </Message>
                      <Form.Group>
                        <Form.Field required label='Maximum Hotel Rate' value={ this.state.nighthotelaccommodamount } name='nighthotelaccommodamount' width={ 4 }
                          error={ !!this.state.errors.nighthotelaccommodamount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                        <Form.Field required label='Private Stay Flat Rate' value={ this.state.nightprivateaccommodamount } name='nightprivateaccommodamount' width={ 4 }
                          error={ !!this.state.errors.nightprivateaccommodamount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                        <Form.Field required label='Mileage Rate (per Km)' value={ this.state.mileageamount } name='mileageamount' width={ 4 }
                          error={ !!this.state.errors.mileageamount }
                          control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.nighthotelaccommodamount }{ this.state.errors.nightprivateaccommodamount }{ this.state.errors.mileageamount }
                      </Message>
                    </Segment>
                    <Segment>
                      <Header content='Other' size='tiny' />
                      <Form.Group>
                        <Form.Field required label='Maritime CUs' value={ this.state.maritimeCUs } name='maritimeCUs' width={ 12 }
                          error={ !!this.state.errors.maritimeCUs }
                          control={ MultiSelectInput } onChange={ this.handleCUsChange }
                          optionsdata={ this.state.maritimeCUs.map(v => { return { key: v, text: v, value: v } }) }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.maritimeCUs }
                      </Message>
                    </Segment>
                  </Segment.Group>
                </Form>
              );
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    settings: storeState.lookups.settings
  };
}

export default connect(mapStoreStateToProps)(AdminSettings);
