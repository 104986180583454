import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class InfoButton extends React.Component {
  render() {
    const { style, size, compact, basic, label, pathname } = this.props;
    return <Button 
      style={style}
      size={size}
      compact={compact}
      basic={basic}
      onClick={() => window.open(pathname, '_blank')}
    >
      { label }
    </Button>;
  }
};

InfoButton.propTypes = {
  style: PropTypes.object,
  size: PropTypes.string,
  compact: PropTypes.bool,
  basic: PropTypes.bool,
  label: PropTypes.string,
  pathname: PropTypes.string,
};

export default InfoButton;
