import React from 'react';

import { Dropdown } from 'semantic-ui-react';

import PropTypes from 'prop-types';

class MultiSelectInput extends React.Component {
  render = () => {
    const optionsData = this.props.optionsdata;
    return (
      <Dropdown
        placeholder='CUs'
        fluid
        multiple
        search
        selection
        allowAdditions
        options={optionsData}
        { ...this.props }
      />
    );
  }
};

//optionsdata: [{key: value, text: value, value: value}]
MultiSelectInput.propTypes = {
  optionsdata: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiSelectInput;
