import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form, Button } from 'semantic-ui-react';

import { getSettings } from '../../api/administratorAPI';
import { getFireDepartmentTrainers, getFireDepartmentSurveys } from '../../api/fireDepartmentAPI';
import { getWorkSiteRescues, getAdminAnnualSubmissions, updateAdminAnnualSubmission } from '../../api/administratorAPI';

import { DATE_YEAR, DATE_SHORT_MONTH } from '../../js/constants';
import { SHORT_MONTHS } from '../../js/lists';
import { PATH_ID, PATH_TIMESTAMP, PATH_ADMIN, PATH_ANNUAL } from '../../js/paths';

import { today, formatDateTime } from '../../utils/date';

import CurrencyInput from '../../components/CurrencyInput';
import MonthlyTable from '../../components/MonthlyTable';
import ConfirmPopup from '../../components/ConfirmPopup';

import EditView from '../EditView';

const INITIAL_STATE = {
  status: 'Pending',
  trainersstipendamount: 0,
  sitesurveyreimbamount: 0,
  trainerskillstrainingamount: 0,
  firedeptstipendamount: 0,
  traininghours: {},

  // From FD

  fdid: '',
  payableto: '',
  fdstipendamount: 0,

  // Other

  year: today(DATE_YEAR),
  
  traininghourstotal: 0,
  
  annualSubmission: {},
  surveys: {},
  rescues: {},

  numcranesurveys: 0,
  numworkrescues: 0,

  // UI

  className: 'admin-annual-submission',
};

// TODO: THIS NEEDS TO SELECT FDS

class AdminAnnualSubmission extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.title = '';
    this.state.exitPage = `/${ PATH_ADMIN }/${ PATH_ANNUAL }`;

    SHORT_MONTHS.forEach(mon => {
      this.state.traininghours[mon] = 0;
      this.state.surveys[mon] = 0;
      this.state.rescues[mon] = 0;
    });

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
    console.log(this.props);
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getSettings();
    await getFireDepartmentTrainers(this.props.match.params[PATH_ID], 'A');
    await getFireDepartmentSurveys({ status: 'Completed', fdid: this.props.match.params[PATH_ID], year: this.props.match.params[PATH_TIMESTAMP].substr(0,4) });
    await getWorkSiteRescues(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP].substr(0,4));
    await getAdminAnnualSubmissions(null, null, this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
    this.setState({ loading: false, annualSubmission: this.props.annualSubmissions[0], title: `Annual Submission: ${this.props.annualSubmissions[0].fdName} - ${this.props.annualSubmissions[0].timestamp.substr(0,4)}`, year: this.props.annualSubmissions[0].timestamp.substr(0,4) });
  }

  onLoad = ()=> {
    SHORT_MONTHS.forEach(mon => {
      this.state.traininghours[mon] = 0;
      this.state.surveys[mon] = 0;
      this.state.rescues[mon] = 0;
    });
    let surveys = { ...this.state.surveys };
    let numcranesurveys = 0;
    let numcranesurveysMutualAID = 0;

    this.props.surveys.forEach(survey => {
      // Filter results for current year
      if (formatDateTime(survey.timestamp, DATE_YEAR) === this.state.year) {
        // Increment the count for the survey's month
        surveys[formatDateTime(survey.timestamp, DATE_SHORT_MONTH)]++;
        if (survey.surveyCategory === 'MUTUAL_AID') numcranesurveysMutualAID++;
        else numcranesurveys++;
      }
    });

    let rescues = { ...this.state.rescues };
    let numworkrescues = 0;

    this.props.rescues.forEach(rescue => {
      // Filter results for current year
      if (formatDateTime(rescue.timestamp, DATE_YEAR) === this.state.year) {
        // Increment the count for the rescue's month
        rescues[formatDateTime(rescue.timestamp, DATE_SHORT_MONTH)]++;
        numworkrescues++;
      }
    });

    let trainingHours = { ...this.state.annualSubmission.traininghours };
    let traininghourstotal = 0;

    for (const key in trainingHours) {
      traininghourstotal = traininghourstotal + trainingHours[key];
    }

    let trainersstipendamount = 0, trainerskillstrainingamount = 0, sitesurveyreimbamount = 0, firedeptstipendamount = 0;
    trainersstipendamount = this.state.annualSubmission.trainersstipendamount;
    trainerskillstrainingamount = this.state.annualSubmission.trainerskillstrainingamount;
    sitesurveyreimbamount = this.state.annualSubmission.sitesurveyreimbamount;
    firedeptstipendamount = this.state.annualSubmission.firedeptstipendamount;

    const payableto = this.state.annualSubmission.payableto || '';

    this.setState({
      surveys: surveys,
      rescues: rescues,
      traininghours: trainingHours,
      numcranesurveys: numcranesurveys,
      numworkrescues: numworkrescues,
      traininghourstotal: traininghourstotal,
      trainersstipendamount: trainersstipendamount,
      trainerskillstrainingamount: trainerskillstrainingamount,
      sitesurveyreimbamount: sitesurveyreimbamount,
      firedeptstipendamount: firedeptstipendamount,
      payableto: payableto,
    });
  }

  handleSubmit = () => {
    if (this.isValid()) {
      const data = this.getData();
      if (this.didChange()) {
        this.update(data);
      }
    }
  }

  handleCurrencyChange = (name, value) => {
    const stateUpdate = { [name]: value };
    this.setState(stateUpdate, () => {
      this.onChange();
    });
  }

  isValid = () => {
    let errors = { error: false };

    this.setState({ errors: errors });

    return !errors.error;
  }

  // Always update
  didChange = () => { return true; }

  update = async (data) => {
    this.setState({ loading: 'Submitting' });

    data.id = this.props.match.params[PATH_ID];
    data.timestamp = this.props.match.params[PATH_TIMESTAMP];
    await updateAdminAnnualSubmission(data);
    if (this.state.annualSubmission.id) {
      this.exit();
    }

    this.setState({ loading: false });
  }

  renderView = () => {
    console.log(this.state);
    return (
      <Form error={ this.state.errors.error }>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
          <ConfirmPopup onConfirm={ this.handleSubmit } oktext='Submit' canceltext='Cancel' on='click'>
            <Button floated='right' className='form-top-button' positive disabled={ this.state.annualSubmission.status === 'Approved' }>{'Update'}</Button>
          </ConfirmPopup>
          <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
        </Header>

          <Segment>
            <Header content='Payments' size='small' />
            <Segment>
              <Form.Group>
                <Form.Field required label='FD Stipend' value={ this.state.firedeptstipendamount } name='firedeptstipendamount'
                  width={ 4 } error={ !!this.state.errors.tuitionamount }
                  control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                />
                <Form.Field required label='Instructors Stipend' value={ this.state.trainersstipendamount } name='trainersstipendamount'
                  width={ 4 } error={ !!this.state.errors.tuitionamount }
                  control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                />
                <Form.Field required label='Crane Surveys' value={ this.state.sitesurveyreimbamount } name='sitesurveyreimbamount'
                  width={ 4 } error={ !!this.state.errors.tuitionamount }
                  control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                />
                <Form.Field required label='Training Skills' value={ this.state.trainerskillstrainingamount } name='trainerskillstrainingamount'
                  width={ 4 } error={ !!this.state.errors.tuitionamount }
                  control={ CurrencyInput } onChange={ this.handleCurrencyChange }
                />
              </Form.Group>
              <Form.Group>
                <Form.Input disabled label='Payable To' value={ this.state.payableto } name='payableto' width={ 16 } />
              </Form.Group>
            </Segment>
          </Segment>

        <Segment.Group size='large' className='view-segment-group'>
          <Segment>
            <Header content='Reporting' size='small' />
            <Form.Group>
              <Segment compact>
                <Header content='Crane Surveys' size='tiny' />
                <MonthlyTable data={ this.state.surveys }/>
              </Segment>
            </Form.Group>
            <Form.Group>
              <Segment>
                <Header content='Work Rescues' size='tiny' />
                <MonthlyTable data={ this.state.rescues }/>
              </Segment>
            </Form.Group>
            <Form.Group>
              <Segment>
                <Header content='Training Conducted' size='tiny' />
                <MonthlyTable data={ this.state.traininghours }/>
              </Segment>
            </Form.Group>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    annualSubmissions: storeState.models.fireDepartmentAnnualSubmissions,
    trainers: storeState.models.fireDepartmentTrainers,
    surveys: storeState.models.fireDepartmentSurveys,
    rescues: storeState.models.workSiteRescues,
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(AdminAnnualSubmission);
