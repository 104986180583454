import React from 'react';

import { Segment, Header, Message } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import AddButton from './AddButton';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import SortTable from './SortTable';

import { sort } from '../utils/utils';

class BallotSegment extends React.Component {
  updateUIState = (uiState) => {
    this.props.onUIStateChange({ ballots: uiState });
  }

  render = () => {
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Ballots</Header.Content>
          <AddButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'ballots', {}) }/>
        </Header>

        {(() => {
          if (this.props.ballots.length === 0) {
            return <Message info compact size='tiny'>No returned ballots</Message>;
          }

          let ballots = this.props.ballots.map(b => {
            let ballot = { ...b };
            return ballot;
          });

          ballots = sort(ballots, this.props.ui.sortField);
          if (this.props.ui.sortDescending) {
            ballots.reverse();
          }

          const right = { textAlign: 'right' };

          return <SortTable collapsing sortField={ this.props.ui.sortField } sortDescending={ this.props.ui.sortDescending }
            onSort={ this.updateUIState } size='small'
            headers={[
              { field: 'memberemail',           title: 'Member Email', width: '11'              },
              { field: 'voteoption',            title: 'Vote',         width: '3'               },
              { field: 'buttons', noSort: true, title: '',             width: '2', style: right },
            ]}
          >
            { ballots.map((ballot, index) => (
              <SortTable.Row key={ index } id={ `ballot_${ ballot.memberemail }`}>
                <SortTable.Cell>{ ballot.memberemail }</SortTable.Cell>
                <SortTable.Cell>{ ballot.voteoption === 'true' ? 'Yes' : 'No' }</SortTable.Cell>
                <SortTable.Cell textAlign='right'>
                  <EditButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'ballots', ballot) }/>
                  <DeleteButton hide={ this.props.disabled } content='Delete the vote?' onConfirm={ this.props.remove.bind(this, 'ballots', ballot) }/>
                </SortTable.Cell>
              </SortTable.Row>
            ))}
          </SortTable>;
        })()}
      </Segment>
    );
  }
};

BallotSegment.propTypes = {
  ballots: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onUIStateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default BallotSegment;
