import { get, post, put, del, buildQuerystring } from './api';
import { parseSurvey } from './constructionAPI';

import { API_FIRE_DEPARTMENT, DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { TRAINER_TYPES, REIMBURSEMENT_COURSES } from '../js/lists';
import { PATH_FIRE_DEPARTMENT, PATH_REIMBURSEMENTS, PATH_FINANCIAL_SUMMARY, PATH_SITE_SURVEY } from '../js/paths';
import { PATH_SPECIAL_PAYMENTS, PATH_TRAINER, PATH_FILE, PATH_TRAINER_EQUIVALENCY, PATH_RESCUE, PATH_ANNUAL, PATH_JOINT_TRAINING } from '../js/paths';

import * as Action from '../js/actionTypes';

import store from '../js/store';

import { formatDateTime, sortableDateTime, yearsAgo } from '../utils/date';

// UPLOAD

export async function obtainGetURL(fileId) {
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_FILE }/${ fileId }`, {});
  return response.url || '';
}

export async function obtainPutURL(fileId) {
  const response = await put(API_FIRE_DEPARTMENT, `/${ PATH_FILE }/${ fileId }`, {});
  return response.url || '';
}

export async function obtainDelURL(fileId) {
  const response = await del(API_FIRE_DEPARTMENT, `/${ PATH_FILE }/${ fileId }`, {});
  return response.url || '';
}

// FIRE DEPARTMENTS

export function parseFireDepartment(fd) {
  return fd;
}

export async function getFireDepartment(fdid) {
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_FIRE_DEPARTMENT }/${ fdid }`);

  const fireDepartment = response.length === 1 ? parseFireDepartment(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT, fireDepartment: fireDepartment });
}

export async function updateFireDepartment(body) {
  const response = await put(API_FIRE_DEPARTMENT, `/${ PATH_FIRE_DEPARTMENT }`, body);

  const fireDepartment = parseFireDepartment(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT, fireDepartment: fireDepartment });
}

// SITE SURVEYS

export async function getFireDepartmentSurveys(filters) {
  if (!filters) filters = {};
  for (const key in filters) {
    if (!filters[key]) delete filters[key];
  }

  const querystring = buildQuerystring(filters);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_SITE_SURVEY }${ querystring }`);

  const surveys = response.map(survey => { return parseSurvey(survey); });

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SURVEYS, fireDepartmentSurveys: surveys });
}

export async function getFireDepartmentSurvey(fdid, timestamp) {
  let params = {};
  if (fdid) params.fdid = fdid;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_SITE_SURVEY }${ querystring }`);

  const survey = response.length === 1 ? parseSurvey(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SURVEY, fireDepartmentSurvey: survey });
}

export async function postFireDepartmentSurvey(body) {
  const response = await post(API_FIRE_DEPARTMENT, `/${ PATH_SITE_SURVEY }`, body);

  const survey = parseSurvey(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SURVEY, fireDepartmentSurvey: survey });
}

export async function updateFireDepartmentSurvey(body) {
  const response = await put(API_FIRE_DEPARTMENT, `/${ PATH_SITE_SURVEY }`, body);

  const survey = parseSurvey(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SURVEY, fireDepartmentSurvey: survey });
}

export async function deleteFireDepartmentSurvey(fdid, timestamp) {
  let body = {};
  if (fdid) body.fdid = fdid;
  if (timestamp) body.timestamp = timestamp;

  const response = await del(API_FIRE_DEPARTMENT, `/${ PATH_SITE_SURVEY }`, body);

  const survey = parseSurvey(response);

  store.dispatch({ type: Action.DELETE_FIRE_DEPARTMENT_SURVEY, fireDepartmentSurvey: survey });
}

// TRAINERS
///////////
export function parseTrainer(trainer) {
  if (trainer.id) {
    const storeState = store.getState();
    const fireDepartment = storeState.lookups.fireDepartments.find((fd) => { return fd.id === trainer.id; });

    trainer.key = trainer.id + '+' + trainer.currenttypedate;
    if (trainer.reason && TRAINER_TYPES[trainer.type] === TRAINER_TYPES['I']) {
      trainer.displayType = `${ TRAINER_TYPES[trainer.type] } (${ trainer.reason })`;
    } else {
      trainer.displayType = TRAINER_TYPES[trainer.type] || trainer.type;
    }
    trainer.displayDate = formatDateTime(trainer.currenttypedate, DATE_FULL_MONTH_DAY_YEAR);
    trainer.sortDate = sortableDateTime(trainer.currenttypedate);
    trainer.displayCertDate = formatDateTime(trainer.fullcertdate, DATE_FULL_MONTH_DAY_YEAR);
    trainer.sortCertDate = sortableDateTime(trainer.fullcertdate);
    trainer.isTenured = yearsAgo(trainer.fullcertdate) >= 3;
    trainer.fdName = fireDepartment ? fireDepartment.name : '';
    trainer.name = trainer.trainername;

    if (!trainer.courses) trainer.courses = [];
    trainer.courselist = trainer.courses.map(course => {
      course.displayName = REIMBURSEMENT_COURSES[course.coursename];
      return REIMBURSEMENT_COURSES[course.coursename];
    }).join(', ');
  }

  return trainer;
}

export async function getFireDepartmentTrainers(fdid, type) {
  let params = {};
  if (type) params.type = type;
  if (fdid) params.fdid = fdid;

  const querystring = buildQuerystring(params);

  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_TRAINER }${ querystring }`);

  const trainers = response.map(trainer => { return parseTrainer(trainer); });

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINERS, fireDepartmentTrainers: trainers });
}

export async function getFireDepartmentTrainer(id, timestamp, fdid) {
  let params = {};
  if (id) params.id = id;
  if (timestamp) params.timestamp = timestamp;
  if (fdid) params.fdid = fdid;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_TRAINER }${ querystring }`);

  console.log(response)

  const trainer = response.length === 1 ? parseTrainer(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER, fireDepartmentTrainer: trainer });
}

export async function postFireDepartmentTrainer(body) {
  const response = await post(API_FIRE_DEPARTMENT, `/${ PATH_TRAINER }`, body);

  const trainer = parseTrainer(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER, fireDepartmentTrainer: trainer });
}

export async function updateFireDepartmentTrainer(body) {
  const response = await put(API_FIRE_DEPARTMENT, `/${ PATH_TRAINER }`, body);

  const trainer = parseTrainer(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER, fireDepartmentTrainer: trainer });
}

export async function deleteFireDepartmentTrainer(id) {
  let body = {};
  if (id) body.trainerID = id;

  const response = await del(API_FIRE_DEPARTMENT, `/${ PATH_TRAINER }`, body);

  const trainer = parseTrainer(response);

  store.dispatch({ type: Action.DELETE_FIRE_DEPARTMENT_TRAINER, fireDepartmentTrainer: trainer });
}

export async function updateFireDepartmentTrainerStatus(body) {
  const response = await put(API_FIRE_DEPARTMENT, `/${ PATH_TRAINER }/status`, body);

  const trainer = parseTrainer(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER, fireDepartmentTrainer: trainer });
}

export async function requestFireDepartmentTrainerIncrease(body) {
  const response = await put(API_FIRE_DEPARTMENT, `/${ PATH_TRAINER }/request`, body);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER_INCREASE, fireDepartmentTrainerIncrease: response });
}

export function parseEquivalency(equivalency) {
  if (!equivalency.courses) equivalency.courses = [];

  return equivalency;
}

export async function getFireDepartmentTrainerEquivalencies(trid, timestamp, subAction) {
  let params = {};
  if (trid) params.trid = trid;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_TRAINER_EQUIVALENCY }${ querystring }`);

  const trainerEquivalencies = response.map(equivalency => { return parseEquivalency(equivalency); });

  if(subAction) {
    store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES_2, fireDepartmentTrainerEquivalencies2: trainerEquivalencies });
  } else {
    store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES, fireDepartmentTrainerEquivalencies: trainerEquivalencies });
  }
}

export async function postFireDepartmentTrainerEquivalency(body) {
  const response = await post(API_FIRE_DEPARTMENT, `/${ PATH_TRAINER_EQUIVALENCY }`, body);

  const trainerEquivalency = parseEquivalency(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCY, fireDepartmentTrainerEquivalency: trainerEquivalency });
}

// REIMBURSEMENTS
///////////
export function parseReimbursement(reimbursement) {
  if (reimbursement && reimbursement.id) {
    const storeState = store.getState();
    const fireDepartment = storeState.lookups.fireDepartments.find((fd) => { return fd.id === reimbursement.id; });
    const trainer = storeState.models.fireDepartmentTrainers.find((trainer) => { return trainer.trainerId === reimbursement.instructor_id; });

    reimbursement.key = reimbursement.id + '+' + reimbursement.timestamp;
    reimbursement.displayCourse = REIMBURSEMENT_COURSES[reimbursement.coursename] || reimbursement.coursename;
    reimbursement.displayDate = formatDateTime(reimbursement.dateattended, DATE_FULL_MONTH_DAY_YEAR);
    reimbursement.sortDate = sortableDateTime(reimbursement.dateattended);
    reimbursement.displaySubmissionDate = formatDateTime(reimbursement.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    reimbursement.sortSubmissionDate = sortableDateTime(reimbursement.timestamp);

    reimbursement.fdName = fireDepartment ? fireDepartment.name : '';
    reimbursement.trainerId = trainer ? trainer.trainerId : '';
    reimbursement.trainerName = trainer ? trainer.trainername : '';
  }

  return reimbursement;
}

export async function getAllFireDepartmentReimbursements(fds, type, year) {
  let allReimbursements = [];

  await Promise.all(fds.map(async fd => {
      let params = {
        fdid: fd.id
      };
      if (type) params.status = type;
      const trainerParams = { ...params }; //trainer request does not accept year
      if (year) params.year = year;

      const querystring = buildQuerystring(params);
      const trainerQuerystring = buildQuerystring(trainerParams);

      const reimbursements = await get(API_FIRE_DEPARTMENT, `/${ PATH_REIMBURSEMENTS }${ querystring }`);

      if (reimbursements.length > 0) {
        const trainers = await get(API_FIRE_DEPARTMENT, `/${ PATH_TRAINER }${ trainerQuerystring }`);

        reimbursements.forEach(reimbursement => {
          parseReimbursement(reimbursement);

          const trainer = trainers.find((trainer) => { return trainer.trainerId === reimbursement.instructor_id; });
          reimbursement.trainerId = trainer ? trainer.trainerId : '';
          reimbursement.trainerName = trainer ? trainer.trainername : '';

          allReimbursements.push(reimbursement);
        });
      }
  }));

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS, fireDepartmentReimbursements: allReimbursements });
}

export async function getFireDepartmentReimbursements(fdid, type) {
  let params = {};
  if (type) params.type = type;
  if (fdid) params.fdid = fdid;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_REIMBURSEMENTS }${ querystring }`);

  if (response.length > 0 && fdid) {
    await getFireDepartmentTrainers({ fdid: fdid });
  }

  const reimbursements = response.map(reimbursement => { return parseReimbursement(reimbursement); });

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS, fireDepartmentReimbursements: reimbursements });
}

export async function getFireDepartmentReimbursement(fdid, timestamp) {
  let params = {};
  if (fdid) params.fdid = fdid;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_REIMBURSEMENTS }${ querystring }`);

  const reimbursement = response.length === 1 ? parseReimbursement(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT, fireDepartmentReimbursement: reimbursement });
}

export async function postFireDepartmentReimbursement(body) {
  const response = await post(API_FIRE_DEPARTMENT, `/${ PATH_REIMBURSEMENTS }`, body);

  const reimbursement = parseReimbursement(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT, fireDepartmentReimbursement: reimbursement });
}

export async function updateFireDepartmentReimbursement(body) {
  const response = await put(API_FIRE_DEPARTMENT, `/${ PATH_REIMBURSEMENTS }`, body);

  const reimbursement = parseReimbursement(response);

  if (reimbursement) store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT, fireDepartmentReimbursement: reimbursement });
}

export async function deleteFireDepartmentReimbursement(fdid, timestamp) {
  let body = {};
  if (fdid) body.fdid = fdid;
  if (timestamp) body.timestamp = timestamp;

  const response = await del(API_FIRE_DEPARTMENT, `/${ PATH_REIMBURSEMENTS }`, body);

  const reimbursement = parseReimbursement(response);

  store.dispatch({ type: Action.DELETE_FIRE_DEPARTMENT_REIMBURSEMENT, fireDepartmentReimbursement: reimbursement });
}

export function parseFinancialSummary(item) {
  if (item.id) {
    const storeState = store.getState();
    const fireDepartment = storeState.lookups.fireDepartments.find((fd) => { return fd.id === item.id; });
    //
    item.key = item.id + '+' + item.timestamp;
    item.totalspecialpayments = item.totalspecialpayments ? item.totalspecialpayments : 0.00;
    item.totalspecialpaymentsaftertax = item.totalspecialpaymentsaftertax ? item.totalspecialpaymentsaftertax : 0.00;
    item.trainingreimbursement = item.trainingreimbursement ? item.trainingreimbursement : 0.00;
    item.trainingreimbursementaftertax = item.trainingreimbursementaftertax ? item.trainingreimbursementaftertax : 0.00;
    item.totalstipend = item.totalstipend ? item.totalstipend : 0.00;
    item.numtrainersadded = item.numtrainersadded ? item.numtrainersadded : 0;
    item.numtrainersreplaced = item.numtrainersreplaced ? item.numtrainersreplaced : 0;
    item.initialequippaid = item.initialequippaid ? item.initialequippaid : 0;
    item.numtrainersupdatedtrainer = item.numtrainersupdatedtrainer ? item.numtrainersupdatedtrainer : 0;
    item.skillstrainingpaid = item.skillstrainingpaid ? item.skillstrainingpaid : 0.00;
    item.updatetrainingpaid = item.updatetrainingpaid ? item.updatetrainingpaid : 0.00;
    item.numconstructionrescues = item.numconstructionrescues ? item.numconstructionrescues : [];
    item.fdName = fireDepartment ? fireDepartment.name : '';
  }

  return item;
}

export async function getFireDepartmentFinancialSummary(fdid, year) {
  let params = {};
  if (fdid) params.fdid = fdid;
  if (year) params.timestamp = year;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_FINANCIAL_SUMMARY }${ querystring }`);

  const fireDepartmentFinancialSummary = response.map(item => { return parseFinancialSummary(item); });

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_FINANCIAL_SUMMARIES, fireDepartmentFinancialSummaries: fireDepartmentFinancialSummary });
}

// SPECIAL PAYMENTS
///////////
export function parseSpecialPayment(specialPayment) {
  if (specialPayment.timestamp) {
    const storeState = store.getState();
    const fireDepartment = storeState.lookups.fireDepartments.find((fd) => { return fd.id === specialPayment.id; });

    specialPayment.key = specialPayment.id + '+' + specialPayment.timestamp;
    specialPayment.displayDate = formatDateTime(specialPayment.date, DATE_FULL_MONTH_DAY_YEAR);
    specialPayment.sortDate = sortableDateTime(specialPayment.date);
    specialPayment.displaySubmissionDate = formatDateTime(specialPayment.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    specialPayment.sortSubmissionDate = sortableDateTime(specialPayment.timestamp);
    specialPayment.fdName = fireDepartment ? fireDepartment.name : '';
  }

  return specialPayment;
}

export async function getFireDepartmentSpecialPayments(fdid, status) {
  let params = {};
  if (status) params.status = status;
  if (fdid) params.fdid = fdid;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_SPECIAL_PAYMENTS }${ querystring }`);
  const specialPayments = response.map(specialPayment => { return parseSpecialPayment(specialPayment); });

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS, fireDepartmentSpecialPayments: specialPayments });
}

export async function getFireDepartmentSpecialPayment(fdid, timestamp) {
  let params = {};
  if (fdid) params.fdid = fdid;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_SPECIAL_PAYMENTS }${ querystring }`);

  const specialPayment = response.length === 1 ? parseSpecialPayment(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT, fireDepartmentSpecialPayment: specialPayment });
}

export async function postFireDepartmentSpecialPayment(body) {
  const response = await post(API_FIRE_DEPARTMENT, `/${ PATH_SPECIAL_PAYMENTS }`, body);

  const specialPayment = parseSpecialPayment(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT, fireDepartmentSpecialPayment: specialPayment });
}

export async function updateFireDepartmentSpecialPayment(body) {
  const response = await put(API_FIRE_DEPARTMENT, `/${ PATH_SPECIAL_PAYMENTS }`, body);

  const specialPayment = parseSpecialPayment(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT, fireDepartmentSpecialPayment: specialPayment });
}

export async function deleteFireDepartmentSpecialPayment(fdid, timestamp) {
  let body = {};
  if (fdid) body.fdid = fdid;
  if (timestamp) body.timestamp = timestamp;

  const response = await del(API_FIRE_DEPARTMENT, `/${ PATH_SPECIAL_PAYMENTS }`, body);

  const specialPayment = parseSpecialPayment(response);

  store.dispatch({ type: Action.DELETE_FIRE_DEPARTMENT_SPECIAL_PAYMENT, fireDepartmentSpecialPayment: specialPayment });
}
// TODO: Implement this
export async function approveFireDepartmentSpecialPayment(id) {
  // let querystring = buildQuerystring(params);
  // let reimbursement = await put(API_FIRE_DEPARTMENT, `/${ PATH_REIMBURSEMENTS }${ querystring }`, {
  //   id: id
  // });

  // store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT, fireDepartmentReimbursement: reimbursement });
}

export function parsePPE(ppe) {
  if (ppe.timestamp) {
    const storeState = store.getState();
    const fireDepartment = storeState.lookups.fireDepartments.find((fd) => { return fd.id === ppe.id; });

    ppe.key = ppe.id + '+' + ppe.timestamp;
    ppe.displayDate = formatDateTime(ppe.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    ppe.sortDate = sortableDateTime(ppe.timestamp);
    ppe.fdName = fireDepartment ? fireDepartment.name : '';
  }

  return ppe;
}

// Work Site Rescues

export function parseWorkSiteRescue(rescue) {
  if (rescue.timestamp) {
    const storeState = store.getState();
    const cc = rescue.ccid ? storeState.lookups.constructionCompanies.find((cc) => { return cc.id === rescue.ccid; }) : null;

    rescue.key = rescue.id + '+' + rescue.timestamp;
    rescue.displayDate = formatDateTime(rescue.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    rescue.sortDate = sortableDateTime(rescue.timestamp);

    if (cc) rescue.ccname = cc.entity_name;
  }

  return rescue;
}

export async function getFireDepartmentWorkSiteRescues(fdid, timestamp, shift) {
  let params = {};
  if (fdid) params.fdid = fdid;
  if (timestamp) params.timestamp = timestamp;
  if (shift) params.shift = shift;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_RESCUE }${ querystring }`);

  const workSiteRescues = response.map(rescue => { return parseWorkSiteRescue(rescue); });

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_WORK_SITE_RESCUES, fireDepartmentWorkSiteRescues: workSiteRescues });
}

export async function postFireDepartmentWorkSiteRescue(body) {
  const response = await post(API_FIRE_DEPARTMENT, `/${ PATH_RESCUE }`, body);

  const workSiteRescue = parseWorkSiteRescue(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_WORK_SITE_RESCUE, fireDepartmentWorkSiteRescue: workSiteRescue });
}

// Annual Submission

export function parseAnnualSubmission(annual) {
  if (annual.id) {
    annual.key = annual.id + '+' + annual.timestamp;
    if (annual.submittedOn) {
      annual.displayDate = formatDateTime(annual.submittedOn, DATE_FULL_MONTH_DAY_YEAR);
      annual.sortDate = sortableDateTime(annual.submittedOn);
    } else {
      annual.displayDate = formatDateTime(annual.timestamp, DATE_FULL_MONTH_DAY_YEAR);
      annual.sortDate = sortableDateTime(annual.timestamp);
    }
  }

  return annual;
}

export async function getFireDepartmentAnnualSubmission(fdid, timestamp, year) {
  let params = {};
  if (fdid) params.fdid = fdid;
  if (timestamp) params.timestamp = timestamp;
  if (year) params.year = year;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_ANNUAL }${ querystring }`);

  let annualSubmission = {};
  if (fdid && !timestamp && !year) {
    annualSubmission = response.length > 0 ? response.map(r => parseAnnualSubmission(r)) : {};
  } else {
    annualSubmission = response.length === 1 ? parseAnnualSubmission(response[0]) : {};
  }

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSION, fireDepartmentAnnualSubmission: annualSubmission });
}

export async function postFireDepartmentAnnualSubmission(body) {
  const response = await post(API_FIRE_DEPARTMENT, `/${ PATH_ANNUAL }`, body);

  const annualSubmission = parseAnnualSubmission(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSION, fireDepartmentAnnualSubmission: annualSubmission });
}

export async function putFireDepartmentAnnualSubmission(body) {
  const response = await put(API_FIRE_DEPARTMENT, `/${ PATH_ANNUAL }`, body);

  const annualSubmission = parseAnnualSubmission(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSION, fireDepartmentAnnualSubmission: annualSubmission });
}

export async function deleteFireDepartmentAnnualSubmission(body) {
  return await del(API_FIRE_DEPARTMENT, `/${ PATH_ANNUAL }`, body);
}

// Joint Training

export function parseJointTraining(jt) {
  if (jt.id) {
    jt.key = jt.id + '+' + jt.timestamp;
  }

  return jt;
}

export async function getFireDepartmentJointTrainings(id, status) {
  let params = {};
  if (id) params.id = id;
  if (status) params.status = status;

  const querystring = buildQuerystring(params);
  const response = await get(API_FIRE_DEPARTMENT, `/${ PATH_JOINT_TRAINING }${ querystring }`);

  const jointTrainings = response.map(jt => { return parseJointTraining(jt); });

  store.dispatch({ type: Action.UPDATE_JOINT_TRAININGS, jointTrainings: jointTrainings });
}

export async function postFireDepartmentJointTraining(body) {
  const response = await post(API_FIRE_DEPARTMENT, `/${ PATH_JOINT_TRAINING }`, body);

  const jointTraining = parseJointTraining(response);

  store.dispatch({ type: Action.UPDATE_JOINT_TRAINING, jointTraining: jointTraining });
}

// Lookups

export async function getFireDepartments() {
  let fireDepartments = await get(API_FIRE_DEPARTMENT, `/${ PATH_FIRE_DEPARTMENT }`);
  //sort FDs by name on lookup
  fireDepartments.sort((a,b)=> a.name.localeCompare(b.name))
  //
  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENTS_LOOKUP, fireDepartments: fireDepartments });
}
