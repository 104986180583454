import React from 'react';

import { Message, Table } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import CurrencyText from './CurrencyText';
import IconButton from './IconButton';

import { DATE_SHORT_MONTH_DAY_YEAR } from '../js/constants';

import { formatDateTime } from '../utils/date';

class AirTravelSummarySegment extends React.Component {

  handleIconClick = (entry) => {
    this.download(entry);
  }

  download = async (entry) => {
    const downloadUrl = await entry.downloadUrl();

    if (downloadUrl) {
      window.open(downloadUrl);
    }
  }

  render = () => {
    return (
      (() => {
        if (this.props.airtravel.length === 0) {
          return <Message info compact size='tiny'>No air travel expenses</Message>;
        }

        let total = 0, totalFare = 0, totalGst = 0;
        let airtravels = this.props.airtravel.map(at => {
          let airtravel = { ...at };
          airtravel.displayDepartureDate = formatDateTime(airtravel.departuredate, DATE_SHORT_MONTH_DAY_YEAR);
          airtravel.displayReturnDate = formatDateTime(airtravel.returndate, DATE_SHORT_MONTH_DAY_YEAR);
          airtravel.amount = airtravel.fare + airtravel.gst;
          totalFare = totalFare+airtravel.fare;
          totalGst = totalGst+airtravel.gst;
          total = total+airtravel.amount;

          airtravel.files = airtravel.files.map(f => {
            let file = { ...f };
  
            let size = file.size;
            let unit = 'B';
            if (size > 1024) {
              size /= 1024;
              unit = 'KB';
            }
            if (size > 1024) {
              size /= 1024;
              unit = 'MB';
            }
            if (size > 1024) {
              size /= 1024;
              unit = 'GB';
            }
  
            file.displaySize = parseFloat(size.toFixed(1));
            file.unit = unit;
            file.displayName = file.name;
  
            return file;
          });

          return airtravel;
        });

        const position = { 'vertical-align': 'top', 'text-align': 'left' };

        return <Table collapsing basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Departure</Table.HeaderCell>
              <Table.HeaderCell>Return</Table.HeaderCell>
              <Table.HeaderCell>Carrier</Table.HeaderCell>
              <Table.HeaderCell>Fare</Table.HeaderCell>
              <Table.HeaderCell>GST/Taxes</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Files</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body style={ position }>
            { airtravels.map((airtravel, index) => (
              <Table.Row key={ index } id={ `airtravel_${ airtravel.timestamp }`}>
                <Table.Cell>{ airtravel.displayDepartureDate }</Table.Cell>
                <Table.Cell>{ airtravel.displayReturnDate }</Table.Cell>
                <Table.Cell>{ airtravel.carrier }</Table.Cell>
                <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ airtravel.fare }/></Table.Cell>
                <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ airtravel.gst }/></Table.Cell>
                <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ airtravel.amount }/></Table.Cell>
                <Table.Cell>
                  { airtravel.files.map((file, index) => (
                    <span>{ file.displayName+'\t'+file.displaySize+' '+file.unit+'\t' }<IconButton name='arrow down' color='black' size='small' bordered onClick={ this.handleIconClick.bind(this, file) }/><br/></span>
                  ))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell>Total:</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ totalFare }/></Table.Cell>
              <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ totalGst }/></Table.Cell>
              <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ total }/></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>;
      })()
    );
  }
};

AirTravelSummarySegment.propTypes = {
  airtravel: PropTypes.array.isRequired,
  totalairtravelamount: PropTypes.number.isRequired,
  totalairtravelgst: PropTypes.number.isRequired,
  totalairtravelaftertax: PropTypes.number.isRequired,
};

export default AirTravelSummarySegment;
