import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form, Message } from 'semantic-ui-react';

import { getAdminJointTraining, putAdminJointTraining } from '../../api/administratorAPI';
import { getFireDepartmentJointTrainings, postFireDepartmentJointTraining } from '../../api/fireDepartmentAPI';

import { UPDATE_JOINT_TRAINING_UI } from '../../js/actionTypes';
import { DATE_YEAR, DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { PATH_ADMIN, PATH_JOINT_TRAINING, PATH_ID, PATH_TIMESTAMP } from '../../js/paths';

import { today, formatDateTime } from '../../utils/date';
import ChosenFile from '../../utils/file';

import CurrencyInput from '../../components/CurrencyInput';
import DateControl from '../../components/DateControl';
import FileChooserSegment from '../../components/FileChooserSegment';
import NumberInput from '../../components/NumberInput';

import EditView from '../EditView';

const INITIAL_STATE = {
  status: 'Pending',

  trainingdate: today(),
  hoursoftraining: 0,
  amount: 0,
  documents: [],

  // From FD

  fdName: '',
  payableto: '',

  // UI

  files: [],
  maxAmount: 0,

  title: 'Joint Training Request',
  className: 'fire-department-joint-training',
};

// TODO: THIS NEEDS TO SELECT FDS

class FireDepartmentJointTraining extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    if (this.props.currentUser.fd) {
      this.state.fdName = this.props.currentUser.fd.name;
      this.state.payableto = this.props.currentUser.fd.payable_to;
      this.state.isAdmin = false;
    } else {
      // Admin
      this.state.isAdmin = true;
    }

    this.state.ui = {
      sortField: this.props.ui.sortField || 'type',
      sortDescending: this.props.ui.sortDescending === true,
    }

    this.state.exitPage = this.state.isAdmin ? `/${ PATH_ADMIN }/${ PATH_JOINT_TRAINING }` : this.props.currentUser.homepage;

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFilesChange = this.handleFilesChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleFilesUIStateChange = this.handleFilesUIStateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
  }

  fetch = async () => {
    if (this.state.isAdmin) {
      await getAdminJointTraining(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
    } else {
      await getFireDepartmentJointTrainings();
    }
  }

  onLoad = () => {
    if (this.state.isAdmin) {
      this.setState({ ...this.props.jointTraining });
    } else {
      // Get the amount already requested for this year
      let requestedTotal = 0;

      const year = today(DATE_YEAR);

      this.props.jointTrainings.forEach(jt => {
        // Filter results for current year
        if (formatDateTime(jt.timestamp, DATE_YEAR) === year && jt.status != 'Rejected') {
          // Add to the total
          requestedTotal += jt.amount;
        }
      });

      // Get the remaining allowable amount
      const maxAmount = this.props.settings.maxjointtrainingamount - requestedTotal;

      this.setState({ maxAmount: maxAmount > 0 ? maxAmount : 0 });
    }

    this.state.files.length = 0;
    this.state.documents.forEach((doc) => {
      let file = new ChosenFile();
      file.parse(doc);
      if (file.id) {
        this.state.files.push(file);
      }
    });
  }

  handleFilesChange = (state) => {
    let newState = { ...state };

    if (newState.files) {
      newState.documents = newState.files.map((file) => { return file.stringify(); });
    }

    this.setState(newState, () => { this.onChange() });
  }

  handleFilesUIStateChange(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_JOINT_TRAINING_UI, jointTraining: this.state.ui });
    });
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.trainingdate) {
      errors.error = true;
      errors.trainingdate = 'Please choose a training date. ';
    }

    if (!this.state.hoursoftraining) {
      errors.error = true;
      errors.hoursoftraining = 'Please enter training hours. ';
    }

    if (!this.state.amount) {
      errors.error = true;
      errors.amount = 'Please enter an amount.';
    }

    if (!this.state.isAdmin && this.state.amount > this.state.maxAmount) {
      errors.error = true;
      const maxAmount = this.state.maxAmount.toFixed(2);
      errors.amount = `The amount being request exceeds the maximum allowed yearly reimbursements for Joint Training. The maximum amount that can be requested is $${ maxAmount }.`;
    }

    if (!this.state.payableto) {
      errors.error = true;
      errors.payableto = 'Please enter a payable to email address.';
    }

    if (this.state.files.length === 0) {
      errors.error = true;
      errors.files = 'Please attach supporting documents.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  uploadFiles = async (toUpload) => {
    let success = true;

    for (const uploadFile of toUpload) {
      if (!success) return;

      success = await uploadFile.upload({
        onUploadProgress: (percent) => {
          this.setState({
            progressPercent: percent,
            progressText: uploadFile.name
          });
        }
      });

      if (!success) {
        let errors = { error: true };
        errors.files = `Failed to upload ${ uploadFile.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      }
    }

    return success;
  }

  updateFiles = async (data) => {
    const success = await this.uploadFiles(data.files);

    // Update the uploaded state
    data.documents = data.files.map((file) => { return file.stringify(); });
    this.setState({
      documents: data.documents
    });

    return success;
  }

  update = async (data) => {
    if (this.state.isAdmin) {
      this.setState({ loading: 'Updating' });

      await putAdminJointTraining(data);
      if (this.props.jointTraining.timestamp) {
        this.exit();
      }
    } else {
      this.setState({ loading: 'Uploading Files' });

      const success = await this.updateFiles(data);

      if (success) {
        this.setState({ loading: 'Submitting' });

        await postFireDepartmentJointTraining(data);
        if (this.props.jointTraining.id) {
          this.exit();
        }
      }
    }

    this.setState({ loading: false });
  }

  renderView = () => {
    return (
      <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
            <Form.Button floated='right' className='form-top-button' positive>Submit</Form.Button>
            <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
        </Header>
        <Segment.Group size='large' className='view-segment-group'>
          <Segment>
            <Form.Group>
              <Form.Input readOnly label='Department' value={ this.state.fdName } width={ 8 }/>
              <Form.Field readOnly={ this.state.isAdmin } required={ !this.state.isAdmin } width={ 3 }
                label='Training Date' name='trainingdate'
                control={ DateControl } date={ this.state.trainingdate } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                error={ !!this.state.errors.trainingdate }
                onChange={ this.handleDateChange }
              />
              <Form.Field readOnly={ this.state.isAdmin } required={ !this.state.isAdmin } width={ 2 }
                label='Hours' value={ this.state.hoursoftraining } name='hoursoftraining'
                control={ NumberInput } onChange={ this.handleNumberChange }
                error={ !!this.state.errors.hoursoftraining }
              />
              <Form.Field readOnly={ this.state.isAdmin } required={ !this.state.isAdmin } width={ 3 }
                label='Amount' value={ this.state.amount } name='amount'
                error={ !!this.state.errors.amount }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.trainingdate }{ this.state.errors.hoursoftraining }{ this.state.errors.amount }
            </Message>
            <Form.Group>
              <Form.Input required width={ 16 }
                label='Payable To' value={ this.state.payableto } name='payableto'
                error={ !!this.state.errors.payableto }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.payableto }
            </Message>
            <Form.Group>
              <Form.Field readOnly={ this.state.isAdmin } required={ !this.state.isAdmin } width={ 16 }
                control={ FileChooserSegment } label='Documents' name='files' files={ this.state.files } multiple
                ui={ this.state.ui } onUIStateChange={ this.handleFilesUIStateChange }
                error={ !!this.state.errors.files }
                onChange={ this.handleFilesChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.files }
            </Message>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    jointTrainings: storeState.models.jointTrainings,
    jointTraining: storeState.models.jointTraining,
    ui: storeState.ui.jointTraining,
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentJointTraining);
