import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form } from 'semantic-ui-react';

import { UPDATE_TU_UI } from '../../js/actionTypes';

import { PATH_ADMIN, PATH_EQUIVALENCY, PATH_ID, PATH_TIMESTAMP } from '../../js/paths';

import { getAdminEquivalency, updateAdminEquivalency } from '../../api/administratorAPI';

import EditView from '../EditView';

import EquivalencySegment from '../../components/EquivalencySegment';

import RejectReasonEditDialog from '../../dialogs/RejectReasonEditDialog';

const INITIAL_STATE = {
  trid: '',
  trainername: '',
  status: '',
  courses: [],

  ui: null,
  editing: false,

  courselist: [],

  title: 'Prior Training Approval',
  className: 'admin-tu',
  exitPage: `/${ PATH_ADMIN }/${ PATH_EQUIVALENCY }`,
};

class AdminTU extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.ui = {
      equivalencies: {
        sortField: this.props.ui.sortField || 'displayCourse',
        sortDescending: this.props.ui.sortDescending === true,
      }
    }

    this.handleApprove = this.handleApprove.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  fetch = async () => {
    await getAdminEquivalency(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
  }

  onLoad = () => {
    this.setState({ ...this.state, ...this.props.equivalency });
  }

  handleComponentUIStateChange = (componentUI) => {
    this.setState({ ui: { ...this.state.ui, ...componentUI }}, () => {
      this.dispatch({ type: UPDATE_TU_UI, adminTU: this.state.ui });
    });
  }

  closeDialog = () => { this.setState({ editing: false }); }

  saveRejectReason = (rejectreason) => {
    this.closeDialog();
    this.setState({
      rejectreason: rejectreason,
      status: 'Rejected',
    }, async () => {
      await this.update();
    });
  }

  getRejectReason = () => {
    this.setState({ editing: true });
  }

  handleApprove = () => {
    this.setState({ status: 'Approved' }, async () => {
      await this.update();
    });
  }

  handleReject = () => {
    this.getRejectReason();
  }

  update = async () => {
    this.setState({ loading: 'Updating' });
    await updateAdminEquivalency(this.state);
    if (this.props.equivalency.status === this.state.status) {
      this.exit();
    }
    this.setState({ loading: false });
  }

  renderView = () => {
    return (
      <Form onSubmit={ this.handleApprove } error={ this.state.errors.error }>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
          <Form.Button floated='right' className='form-top-button' positive>Approve</Form.Button>
          <Form.Button floated='right' className='form-top-button' color='orange' type='button' onClick={ this.handleReject }>Reject</Form.Button>
          <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
        </Header>
        <Segment.Group size='large' className='view-segment-group'>
          <Segment>
            <Form.Group>
              <Form.Input readOnly label='Trainer Name' value={ this.state.trainername } width={ 8 } />
            </Form.Group>
          </Segment>
          <EquivalencySegment courses={ this.state.courses } disabled
            ui={ this.state.ui.equivalencies } onUIStateChange={ this.handleComponentUIStateChange }
            edit={ () => {} } remove={ () => {} }
          />
        </Segment.Group>
      </Form>
    );
  }

  renderAfterView = () => {
    if (this.state.editing) {
      return (
        <RejectReasonEditDialog open={ !!this.state.editing } onSave={ this.saveRejectReason } onClose={ this.closeDialog } />
      );
    }

    return null;
  }
}

function mapStoreStateToProps(storeState) {
  return {
    equivalency: storeState.models.fireDepartmentTrainerEquivalency,
    ui: storeState.ui.adminTU
  };
}

export default connect(mapStoreStateToProps)(AdminTU);
