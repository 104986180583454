import { get, post, put, del, buildQuerystring } from './api';

import { API_CONSTRUCTION, DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { SURVEY_TYPES } from '../js/lists';
import { PATH_SURVEY, PATH_CONSTRUCTION } from '../js/paths';

import * as Action from '../js/actionTypes';

import store from '../js/store';

import { formatDateTime, sortableDateTime } from '../utils/date';

// Construction Site Surveys

export function parseSurvey(survey) {

  if (survey.id) {
    const storeState = store.getState();
    const fireDepartment = storeState.lookups.fireDepartments.find((fd) => { return fd.id === survey.id; });
    const cc = storeState.lookups.constructionCompanies.find((item) => { return item.id === survey.gsi1pk; });

    survey.displayType = SURVEY_TYPES[survey.request_type] || survey.request_type;
    survey.displayDate = formatDateTime(survey.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    survey.sortDate = sortableDateTime(survey.timestamp);
    survey.displayVisitDate = formatDateTime(survey.visitdate, DATE_FULL_MONTH_DAY_YEAR);
    survey.sortVisitDate = sortableDateTime(survey.visitdate);
    survey.fdName = fireDepartment ? fireDepartment.name : '';
    survey.ccName = cc ? cc.entity_name : '';
    survey.fdid = survey.id;
    survey.ccId = survey.gsi1pk;
    survey.key = survey.id + survey.timestamp;
  }

  return survey;
}

export async function getConstructionSurveys() {
  const response = await get(API_CONSTRUCTION, `/${ PATH_SURVEY }`);

  const surveys = response.map(survey => { return parseSurvey(survey); });

  store.dispatch({ type: Action.UPDATE_CONSTRUCTION_SURVEYS, constructionSurveys: surveys });
}

export async function getConstructionSurvey(id, timestamp) {
  let params = {};
  if (id) params.id = id;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_CONSTRUCTION, `/${ PATH_SURVEY }${ querystring }`);

  const survey = response.length === 1 ? parseSurvey(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_CONSTRUCTION_SURVEY, constructionSurvey: survey });
}

export async function postConstructionSurvey(body) {
  const survey = await post(API_CONSTRUCTION, `/${ PATH_SURVEY }`, body);

  store.dispatch({ type: Action.ADD_CONSTRUCTION_SURVEY, constructionSurvey: survey });
}

export async function updateConstructionSurvey(body) {
  const response = await put(API_CONSTRUCTION, `/${ PATH_SURVEY }`, body);

  const survey = parseSurvey(response);

  store.dispatch({ type: Action.UPDATE_CONSTRUCTION_SURVEY, constructionSurvey: survey });
}

export async function deleteConstructionSurvey(id, timestamp) {
  const survey = await del(API_CONSTRUCTION, `/${ PATH_SURVEY }`, {
    id: id,
    timestamp: timestamp
  });

  store.dispatch({ type: Action.DELETE_CONSTRUCTION_SURVEY, constructionSurvey: survey });
}

export async function getConstructionCompanies() {
  const constructionCompanies = await get(API_CONSTRUCTION, `/${ PATH_CONSTRUCTION }`);

  store.dispatch({ type: Action.UPDATE_CONSTRUCTION_COMPANIES_LOOKUP, constructionCompanies: constructionCompanies });
}
