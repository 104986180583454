import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message } from 'semantic-ui-react';

import { getConstructionSurveys, deleteConstructionSurvey } from '../../api/constructionAPI';

import { UPDATE_CONSTRUCTION_SURVEYS_UI } from '../../js/actionTypes';
import { PATH_CONSTRUCTION, PATH_SURVEY, PATH_NEW } from '../../js/paths';

import { sort } from '../../utils/utils';

import ListView from '../ListView';

import AddButton from '../../components/AddButton';
import DeleteButton from '../../components/DeleteButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

class ConstructionCraneSurveyList extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getConstructionSurveys();
    this.setState({ loading: false });
  }

  remove = async (id, timestamp) => {
    this.setState({ loading: 'Removing' });
    await deleteConstructionSurvey(id, timestamp);
    this.setState({ loading: false });
  }
  handleRemove = async (id, timestamp) => {
    await this.remove(id, timestamp);
    await this.fetch();
  }


  handleNew() {
    this.props.history.push(`/${ PATH_CONSTRUCTION }/${ PATH_SURVEY }/${ PATH_NEW }`);
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_CONSTRUCTION_SURVEYS_UI, constructionSurveys: this.state.ui });
    });
  }

  render() {
    return (
      <div className='construction-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>
              <Header.Content>Crane Surveys</Header.Content>
              <AddButton onClick={ this.handleNew.bind(this) }/>
            </Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.surveyList.length) {
                return <Message info compact size='large'>
                  You don&apos;t have any pending survey requests.
                </Message>;
              }

              let surveys = sort(this.props.surveyList, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                surveys.reverse();
              }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                  { field: 'project_name',          title: 'Project',         width: '4' },
                  { field: 'fdName',                title: 'Fire Department', width: '4' },
                  { field: 'status',                title: 'Status',          width: '2' },
                  { field: 'displayType',           title: 'Type',            width: '2' },
                  { field: 'sortDate',              title: 'Date',            width: '2' },
                  { field: 'buttons', noSort: true, title: '',                width: '2' },
              ]}>
                { surveys.map((survey) => (
                  <SortTable.Row key={ survey.key }>
                    <SortTable.Cell>{ survey.project_name }</SortTable.Cell>
                    <SortTable.Cell>{ survey.fdName }</SortTable.Cell>
                    <SortTable.Cell>{ survey.status }</SortTable.Cell>
                    <SortTable.Cell>{ survey.displayType }</SortTable.Cell>
                    <SortTable.Cell>{ survey.displayDate }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      <EditButton view={ survey.status !== 'Pending' } pathname={ `/${ PATH_CONSTRUCTION }/${ PATH_SURVEY }/${ survey.timestamp }/${ survey.id }` }/>
                      <DeleteButton content='Delete the survey request?' onConfirm={ this.handleRemove.bind(this, survey.id, survey.timestamp) }/>
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    surveyList: storeState.models.constructionSurveys,
    ui: storeState.ui.constructionSurveys,
  };
}

export default connect(mapStoreStateToProps)(ConstructionCraneSurveyList);
