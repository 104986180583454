import { saveAs } from 'file-saver';
import sortBy from 'lodash/sortBy';

export function sort(array, field, fallbackField) {
  return sortBy(array, (element) => {
    const sortItem = element[field] || element[fallbackField];
    if (typeof sortItem === 'string') {
      // Case insensitive sort
      return sortItem.toLowerCase();
    }
    return sortItem;
  });
}

export function downloadArrayBuffer(data, fileName, fileType) {
  var byteArray = new Uint8Array(data);
  const blob = new Blob([byteArray]);
  saveAs(blob, `${fileName}.${fileType}`);
}

export function xlsxAutoWidth(sheet, maxWidth) {
  if (!sheet || !sheet.columns) return;
  for (let i = 0; i < sheet.columns.length; i += 1) {
    let dataMax = 0;
    const column = sheet.columns[i];
    for (let j = 1; j < column.values.length; j += 1) {
      const columnLength = (column.values[j] ? column.values[j].length : 0);
      if (columnLength > dataMax) {
        dataMax = columnLength;
      }
    } column.width = Math.min(maxWidth, dataMax + (dataMax * .3));
  }
}