import * as Action from '../js/actionTypes';

const DEFAULT_MODELS = {
  users: [],
  user: {},
  tas: [],
  ta: {},

  constructionCompany: {},

  constructionSurveys: [],
  constructionSurvey: {},

  costCenters: [],
  costCenter: {},

  trainingAgency: {},

  fireDepartment: {},

  fireDepartmentSurveys: [],
  fireDepartmentSurvey: {},

  fireDepartmentTrainerEquivalencies: [],
  fireDepartmentTrainerEquivalencies2: [],
  fireDepartmentTrainerEquivalency: {},

  fireDepartmentTrainers: [],
  fireDepartmentTrainer: {},

  fireDepartmentTrainerIncrease: {},

  fireDepartmentFinancialSummaries: [],
  fireDepartmentFinancialSummary: {},

  fireDepartmentReimbursements: [],
  fireDepartmentReimbursement: {},

  fireDepartmentSpecialPayments: [],
  fireDepartmentSpecialPayment: {},

  fireDepartmentWorkSiteRescues: [],
  fireDepartmentWorkSiteRescue: {},

  workSiteRescues: [],

  fireDepartmentAnnualSubmissions: [],
  fireDepartmentAnnualSubmission: {},

  jointTrainings: [],
  jointTraining: {},

  trainingAgencyReimbursements: [],
  trainingAgencyReimbursement: {},

  adminCommittee: {},

  adminRegistrations: [],
  adminRegistration: {},

  adminPPEs: [],
  adminPPE: {},

  adminTRs: [],
  adminTR: {},

  adminCBs: [],
  adminCB: {},

  adminWBs: [],
  adminWB: {},

  validation: null,
  orgs: [],
};

export default function modelsReducer(state = DEFAULT_MODELS, action) {
  switch(action.type) {
    // Users
    case Action.UPDATE_USER_TA:
      return { ...state, ta: action.ta };

    case Action.UPDATE_USERS_TA:
      return { ...state, tas: action.tas };

    case Action.UPDATE_USERS:
      return { ...state, users: action.users };

    case Action.UPDATE_USER:
      return { ...state, user: action.user };

    case Action.ADD_USER:
      return { ...state, user: action.user };

    case Action.DELETE_USER:
      return { ...state, user: action.user };


    // Construction Company
    case Action.UPDATE_CONSTRUCTION_COMPANY:
      return { ...state, constructionCompany: action.constructionCompany };

    // Cost Centers
    case Action.UPDATE_COST_CENTERS:
      return { ...state, costCenters: action.costCenters };
    case Action.UPDATE_COST_CENTER:
      return { ...state, costCenter: action.costCenter };
    case Action.ADD_COST_CENTER:
      return { ...state, costCenter: action.costCenter };
    case Action.DELETE_COST_CENTER:
      return { ...state, costCenter: action.costCenter };


    // Construction Surveys
    case Action.UPDATE_CONSTRUCTION_SURVEYS:
      return { ...state, constructionSurveys: action.constructionSurveys };

    case Action.UPDATE_CONSTRUCTION_SURVEY:
      return { ...state, constructionSurvey: action.constructionSurvey };

    case Action.ADD_CONSTRUCTION_SURVEY:
      return { ...state, constructionSurvey: action.constructionSurvey };

    case Action.DELETE_CONSTRUCTION_SURVEY:
      return { ...state, constructionSurvey: action.constructionSurvey };

    // Fire Department Summaries
    case Action.UPDATE_FIRE_DEPARTMENT_FINANCIAL_SUMMARIES:
      return { ...state, fireDepartmentFinancialSummaries: action.fireDepartmentFinancialSummaries };

    case Action.UPDATE_FIRE_DEPARTMENTS_FINANCIAL_SUMMARIES:
      return { ...state, fireDepartmentsFinancialSummaries: action.fireDepartmentsFinancialSummaries };

    // Training Agency
    case Action.UPDATE_TRAINING_AGENCY:
      return { ...state, trainingAgency: action.trainingAgency };


    // Fire Department
    case Action.UPDATE_FIRE_DEPARTMENT:
      return { ...state, fireDepartment: action.fireDepartment };

    // Fire Department Surveys
    case Action.UPDATE_FIRE_DEPARTMENT_SURVEYS:
      return { ...state, fireDepartmentSurveys: action.fireDepartmentSurveys };

    case Action.UPDATE_FIRE_DEPARTMENT_SURVEY:
      return { ...state, fireDepartmentSurvey: action.fireDepartmentSurvey };

    case Action.ADD_FIRE_DEPARTMENT_SURVEY:
      return { ...state, fireDepartmentSurvey: action.fireDepartmentSurvey };

    case Action.DELETE_FIRE_DEPARTMENT_SURVEY:
      return { ...state, fireDepartmentSurvey: action.fireDepartmentSurvey };


    // Trainers
    case Action.UPDATE_FIRE_DEPARTMENT_TRAINERS:
      return { ...state, fireDepartmentTrainers: action.fireDepartmentTrainers };

    case Action.UPDATE_FIRE_DEPARTMENT_TRAINER:
      return { ...state, fireDepartmentTrainer: action.fireDepartmentTrainer };

    case Action.ADD_FIRE_DEPARTMENT_TRAINER:
      return { ...state, fireDepartmentTrainer: action.fireDepartmentTrainer };

    case Action.DELETE_FIRE_DEPARTMENT_TRAINER:
      return { ...state, fireDepartmentTrainer: action.fireDepartmentTrainer };

    case Action.UPDATE_FIRE_DEPARTMENT_TRAINER_INCREASE:
      return { ...state, fireDepartmentTrainerIncrease: action.fireDepartmentTrainerIncrease };

    case Action.UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES:
      return { ...state, fireDepartmentTrainerEquivalencies: action.fireDepartmentTrainerEquivalencies };

    case Action.UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES_2:
      return { ...state, fireDepartmentTrainerEquivalencies2: action.fireDepartmentTrainerEquivalencies2 };

    case Action.UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCY:
      return { ...state, fireDepartmentTrainerEquivalency: action.fireDepartmentTrainerEquivalency };


    // Reimbursements
    case Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS:
      return { ...state, fireDepartmentReimbursements: action.fireDepartmentReimbursements };

    case Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT:
      return { ...state, fireDepartmentReimbursement: action.fireDepartmentReimbursement };

    case Action.ADD_FIRE_DEPARTMENT_REIMBURSEMENT:
      return { ...state, fireDepartmentReimbursement: action.fireDepartmentReimbursement };

    case Action.DELETE_FIRE_DEPARTMENT_REIMBURSEMENT:
      return { ...state, fireDepartmentReimbursement: action.fireDepartmentReimbursement };


    // Special Payments
    case Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS:
      return { ...state, fireDepartmentSpecialPayments: action.fireDepartmentSpecialPayments };

    case Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT:
      return { ...state, fireDepartmentSpecialPayment: action.fireDepartmentSpecialPayment };

    case Action.ADD_FIRE_DEPARTMENT_SPECIAL_PAYMENT:
      return { ...state, fireDepartmentSpecialPayment: action.fireDepartmentSpecialPayment };

    case Action.DELETE_FIRE_DEPARTMENT_SPECIAL_PAYMENT:
      return { ...state, fireDepartmentSpecialPayment: action.fireDepartmentSpecialPayment };

    // Work Site Rescues
    case Action.UPDATE_FIRE_DEPARTMENT_WORK_SITE_RESCUE:
      return { ...state, fireDepartmentWorkSiteRescue: action.fireDepartmentWorkSiteRescue };

    case Action.UPDATE_FIRE_DEPARTMENT_WORK_SITE_RESCUES:
      return { ...state, fireDepartmentWorkSiteRescues: action.fireDepartmentWorkSiteRescues };

    case Action.UPDATE_ADMIN_FIRE_DEPARTMENT_WORK_SITE_RESCUES:
      return { ...state, workSiteRescues: action.workSiteRescues };

    // Annual Submission
    case Action.UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSIONS:
      return { ...state, fireDepartmentAnnualSubmissions: action.fireDepartmentAnnualSubmissions };

    case Action.UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSION:
      return { ...state, fireDepartmentAnnualSubmission: action.fireDepartmentAnnualSubmission };

    case Action.DELETE_FIRE_DEPARTMENT_ANNUAL_SUBMISSION:
      return { ...state, fireDepartmentAnnualSubmission: action.fireDepartmentAnnualSubmission };

    // Joint Training
    case Action.UPDATE_JOINT_TRAININGS:
      return { ...state, jointTrainings: action.jointTrainings };

    case Action.UPDATE_JOINT_TRAINING:
      return { ...state, jointTraining: action.jointTraining };

    // TA Reimbursements
    case Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENTS:
      return { ...state, trainingAgencyReimbursements: action.trainingAgencyReimbursements };

    case Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENT:
      return { ...state, trainingAgencyReimbursement: action.trainingAgencyReimbursement };

    case Action.ADD_TRAINING_AGENCY_REIMBURSEMENT:
      return { ...state, trainingAgencyReimbursement: action.trainingAgencyReimbursement };

    case Action.DELETE_TRAINING_AGENCY_REIMBURSEMENT:
      return { ...state, trainingAgencyReimbursement: action.trainingAgencyReimbursement };

    // TA Declaration
    case Action.TRAINING_AGENCIES_DECLARATION:
      return { ...state, trainingAgencyDeclaration: action.trainingAgencyDeclaration };

    // Admin Registrations
    case Action.UPDATE_ADMIN_REGISTRATIONS:
      return { ...state, adminRegistrations: action.adminRegistrations };

    case Action.UPDATE_ADMIN_REGISTRATION:
      return { ...state, adminRegistration: action.adminRegistration };


    // Admin Committee
    case Action.UPDATE_ADMIN_COMMITTEE:
      return { ...state, adminCommittee: action.adminCommittee };

    // PPEs
    case Action.UPDATE_PPES:
      return { ...state, adminPPEs: action.adminPPEs };

    case Action.UPDATE_PPE:
      return { ...state, adminPPE: action.adminPPE };

    // TRs
    case Action.UPDATE_TRS:
      return { ...state, adminTRs: action.adminTRs };

    case Action.UPDATE_TR:
      return { ...state, adminTR: action.adminTR };

    // CBs
    case Action.UPDATE_CBS:
      return { ...state, adminCBs: action.adminCBs };

    case Action.UPDATE_CB:
      return { ...state, adminCB: action.adminCB };

    // WBs
    case Action.UPDATE_WBS:
      return { ...state, adminWBs: action.adminWBs };

    case Action.UPDATE_WB:
      return { ...state, adminWB: action.adminWB };

    // Org module
    case Action.ORG_MODULE_WORKSAFEID_VALIDATION:
      return { ...state, validation: action.validation };
    case Action.ORG_MODULE_ORGS_SEARCH:
      return { ...state, orgs: action.orgs };

    default:
      return state;
  }
}
