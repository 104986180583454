import React from 'react';

import { Message, Table } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import CurrencyText from './CurrencyText';
import IconButton from './IconButton';

import { DATE_SHORT_MONTH_DAY_YEAR } from '../js/constants';
import { VEHICLE_TYPES } from '../js/lists';

import { formatDateTime } from '../utils/date';

class VehicleSummarySegment extends React.Component {

  handleIconClick = (entry) => {
    this.download(entry);
  }

  download = async (entry) => {
    const downloadUrl = await entry.downloadUrl();

    if (downloadUrl) {
      window.open(downloadUrl);
    }
  }

  render = () => {
    return (
      (() => {
        if (this.props.vehicle.length === 0) {
          return <Message info compact size='tiny'>No vehicle expenses</Message>;
        }

        let totalBeforeTaxes = 0;
        let vehicles = this.props.vehicle.map(at => {
          let vehicle = { ...at };
          vehicle.displayType = VEHICLE_TYPES[vehicle.type];
          vehicle.displayDate = formatDateTime(vehicle.date, DATE_SHORT_MONTH_DAY_YEAR);
          totalBeforeTaxes = totalBeforeTaxes+vehicle.amountbeforetaxes;

          vehicle.files = vehicle.files.map(f => {
            let file = { ...f };
  
            let size = file.size;
            let unit = 'B';
            if (size > 1024) {
              size /= 1024;
              unit = 'KB';
            }
            if (size > 1024) {
              size /= 1024;
              unit = 'MB';
            }
            if (size > 1024) {
              size /= 1024;
              unit = 'GB';
            }
  
            file.displaySize = parseFloat(size.toFixed(1));
            file.unit = unit;
            file.displayName = file.name;
  
            return file;
          });

          return vehicle;
        });

        const position = { 'vertical-align': 'top', 'text-align': 'left' };

        return <Table collapsing basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>GST</Table.HeaderCell>
              <Table.HeaderCell>PST</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Files</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body style={ position }>
            { vehicles.map((vehicle, index) => (
              <Table.Row key={ index } id={ `vehicle_${ vehicle.timestamp }`}>
                <Table.Cell>{ vehicle.displayType }</Table.Cell>
                <Table.Cell>{ vehicle.displayDate }</Table.Cell>
                <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ vehicle.amountbeforetaxes }/></Table.Cell>
                <Table.Cell>{ '-' }</Table.Cell>
                <Table.Cell>{ '-' }</Table.Cell>
                <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ vehicle.amountbeforetaxes }/></Table.Cell>
                <Table.Cell>
                  { vehicle.files.map((file, index) => (
                    <span>{ file.displayName+'\t'+file.displaySize+' '+file.unit+'\t' }<IconButton name='arrow down' color='black' size='small' bordered onClick={ this.handleIconClick.bind(this, file) }/><br/></span>
                  ))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell>Total:</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ totalBeforeTaxes }/></Table.Cell>
              <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.props.vehiclegst }/></Table.Cell>
              <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.props.vehiclepst }/></Table.Cell>
              <Table.Cell><CurrencyText style={ {'text-align': 'left'} } value={ this.props.totalvehicleaftertax }/></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>;
      })()
    );
  }
};

VehicleSummarySegment.propTypes = {
  vehicle: PropTypes.array.isRequired,
  vehiclegst: PropTypes.number,
  vehiclepst: PropTypes.number,
  totalvehicleaftertax: PropTypes.number.isRequired,
};

export default VehicleSummarySegment;
