import React from 'react';
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

import PropTypes from 'prop-types';

class TaxCalculator extends React.Component {
  constructor(props) {
    super(props);

    let label, tax;
    switch (this.props.type) {
      case 'gst':
        label = this.props.label || 'GST';
        tax = this.props.settings.gst;
        break;
      case 'pst':
        label = this.props.label || 'PST';
        tax = this.props.settings.pst;
        break;
      default:
        label = this.props.label || 'Tax';
        tax = this.props.tax || 0;
    }

    this.state = {
      label: label,
      tax: tax,
    }
  }

  calculateTax = () => {
    if (this.props.disabled) return;

    let value = this.props.amount * this.state.tax;
    value = parseFloat(value.toFixed(2));
    this.props.onCalculate(this.props.name, value);
  }

  render() {
    return (
      <label>{ this.state.label } <Icon disabled={ this.props.disabled } link name='calculator' onClick={ this.calculateTax.bind(this) }/></label>
    );
  }
};

TaxCalculator.propTypes = {
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  onCalculate: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  tax: PropTypes.number,
  disabled: PropTypes.bool,
};

function mapStoreStateToProps(storeState) {
  return {
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(TaxCalculator);
