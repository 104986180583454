import React from 'react';
import { withRouter } from 'react-router'
import { Route, Prompt } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import Home from './Home'
import Admin from './Admin/Admin'
import Construction from './Construction/Construction'
import FireDepartment from './FireDepartment/FireDepartment'
import TrainingAgency from './TrainingAgency/TrainingAgency'

import { handleAPIError } from '../api/api';
import { getSettings } from '../api/administratorAPI';
import { getFireDepartments } from '../api/fireDepartmentAPI';
import { getTrainingAgencies } from '../api/trainingAgencyAPI';
import { getUser, getUserTA } from '../api/userAPI';
import { getConstructionCompanies } from '../api/constructionAPI';

import { REQUESTS_CLEAR, UPDATE_CURRENT_USER } from '../js/actionTypes';
import { AUTH_SIGNED_IN, AUTH_SIGN_IN, ROLE_TRAINING_AGENCY, ROLE_FIRE_DEPARTMENT, ROLE_CONSTRUCTION } from '../js/constants';
import { PATH_HOME, PATH_ADMIN, PATH_CONSTRUCTION, PATH_FIRE_DEPARTMENT, PATH_TRAINING_AGENCY } from '../js/paths';

import store from '../js/store';

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.handleStoreStateChange = this.handleStoreStateChange.bind(this);

    this.state = {
      cognitoUser: null,
      loading: false,
    };
  }

  componentDidMount() {
    // Listen for changes in the store
    store.subscribe(this.handleStoreStateChange);
  }

  initialize = async (currentUser) => {
    this.setState({ loading: true });
    // Load lookups
    await getFireDepartments();
    await getTrainingAgencies();
    await getConstructionCompanies();
    await getSettings();

      // Load user info
    if (currentUser.orgId) {
      if (currentUser.role === ROLE_FIRE_DEPARTMENT) {
        currentUser.fd = store.getState().lookups.fireDepartments.find((fd) => { return fd.id === currentUser.orgId; });
      } else if (currentUser.role === ROLE_CONSTRUCTION) {
        await getUser(currentUser.orgId);
        currentUser.cc = store.getState().models.user;
      } else if (currentUser.role === ROLE_TRAINING_AGENCY) {
        await getUserTA(currentUser.orgId);
        currentUser.ta = store.getState().models.ta;
      }
    }
    this.setState({ loading: false });

    if ((currentUser.role === ROLE_FIRE_DEPARTMENT && (!currentUser.fd || currentUser.fd.status !=='Approved'))
        || (currentUser.role === ROLE_TRAINING_AGENCY && (!currentUser.ta || currentUser.ta.status !=='Approved'))) {
      // Only approved fire departments/training agencies can log in
      store.dispatch({ type: UPDATE_CURRENT_USER, user: { cognitoUser: null }});
      window.alert('You have not been approved to use this system!')
    } else if (this.props.history.location.pathname === PATH_HOME) {
      // Load home page if we've hit the home page
      // Note: New roles must have homepage set
      this.props.history.push(currentUser.homepage);
    }
  }

  handleStoreStateChange() {
    if (this.props.authState === AUTH_SIGNED_IN) {
      const storeState = store.getState();
      // Check for a change in the cognito user
      if (storeState.auth.currentUser.cognitoUser !== this.state.cognitoUser) {
        // Was the cognito user cleared by the sign out button?
        if (!storeState.auth.currentUser.cognitoUser) {
          // Clear errors
          if (storeState.ui.requests.error.message) {
            store.dispatch({ type: REQUESTS_CLEAR });
          }
          // Sign out of cognito
          const props = this.props;
          Auth.signOut().then(() => {
            props.onStateChange(AUTH_SIGN_IN, {});
            props.history.push(PATH_HOME);
          }).catch(error => {
            handleAPIError(error);
          });
          this.setState({ cognitoUser: null });
        } else {
          // Signing in
          this.setState({ cognitoUser: storeState.auth.currentUser.cognitoUser });
          // Initialize authenticated app
          this.initialize(storeState.auth.currentUser);
        }
      }
    }
  }

  handlePageNavigationPrompt = (location, action) => {
    // Allow pushes and replaces, just catch back/forward
    if (action === 'POP') {
      const storeState = store.getState();

      if (storeState.ui.isDirty) {
        return 'Changes will be lost! Are you sure?';
      }
    }

    return true;
  }

  render() {
    if (this.props.authState !== AUTH_SIGNED_IN) return null;

    return (
      <div className='main-body'>
        <Prompt message={ this.handlePageNavigationPrompt }/>
        <Route exact path={ `${ PATH_HOME }` } component={ Home }/>
        <Route path={ `/${ PATH_ADMIN }` } component={ Admin }/>
        <Route path={ `/${ PATH_CONSTRUCTION }` } component={ Construction }/>
        <Route path={ `/${ PATH_FIRE_DEPARTMENT }` } component={ FireDepartment }/>
        <Route path={ `/${ PATH_TRAINING_AGENCY }` } component={ TrainingAgency }/>
      </div>
    );
  }
}

export default withRouter(Main);
