import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon } from 'semantic-ui-react';

import { changeAdminTR, getAdminTRs } from '../../api/administratorAPI';

import { UPDATE_TRS_UI } from '../../js/actionTypes';
import { PATH_TR, PATH_ADMIN } from '../../js/paths';

import { sort } from '../../utils/utils';

import ActionMultiButton from '../../components/ActionMultiButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';
import SearchableFilter from '../../components/SearchableFilter';

import RejectReasonEditDialog from '../../dialogs/RejectReasonEditDialog';

import ListView from '../ListView';

class AdminTRs extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      title: 'Trainer Increase Requests',

      editing: null
    };

    this.setTitle = this.setTitle.bind(this);
    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    if (this.filterObj !== undefined) this.filterObj.setFilterItems(this.getAvailableStates());
    this.fetch();
    this.setTitle();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getAdminTRs();
    this.setState({ loading: false });
  }

  closeDialog = () => { this.setState({ editing: null }); }

  saveRejectReason = async (rejectreason) => {
    let data = { ...this.state.editing };
    data.rejectreason = rejectreason;
    data.status = 'Rejected';
    this.closeDialog();
    await this.updateStatus(data);
  }

  getRejectReason = (data) => {
    this.setState({ editing: data });
  }

  changeStatus = async (tr, status) => {
    if (status === 'Rejected') {
      this.getRejectReason(tr);
    } else {
      tr.status = status;
      await this.updateStatus(tr);
    }
  }

  updateStatus = async (data) => {
    this.setState({ loading: 'Updating' });
    await changeAdminTR(data);
    await this.fetch();
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_TRS_UI, adminTRs: this.state.ui });
    });
  }

  //Filter support
  handleFilterChange(filter,value) { this.forceUpdate(); }
  getAvailableStates() {
    let states = ["All","Pending","Approved","Rejected","Voting"];
    return states;
  }

  render() {
    return (
      <div className='admin-trainer-increase'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={5} floated='left'>
            <Header size='medium'>{ this.state.title }</Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingBottom: 0}} width={9} floated='right'>
            <SearchableFilter ref={(r)=>this.filterObj=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={"Pending"}/>
          </Grid.Column>
          <Grid.Column className='view-column' width={ 15 }>
            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (this.props.adminTRs && !this.props.adminTRs.length) {
                return <Message info size='large'>
                  You don&apos;t have any Trainer Increase Requests.
                </Message>;
              }

              let trs = sort(this.props.adminTRs, this.state.ui.sortField);

              //filter with status
              if (this.filterObj && this.filterObj.getSelectedText() !== "") {
                let nTrs = [];
                let filterStatus = this.filterObj.getSelectedText();
                for (var i = 0; i < trs.length; i++) {
                  if (trs[i].status === filterStatus || filterStatus === "All") nTrs.push(trs[i]);
                }
                trs = nTrs;
              }

              if (this.state.ui.sortDescending) {
                trs.reverse();
              }

              const right = { textAlign: 'right' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',              title: 'Date',            width: '3'               },
                { field: 'fdName',                title: 'Fire Department', width: '5'               },
                { field: 'status',                title: 'Status',          width: '3'               },
                { field: 'addnumberoftrainers',   title: 'Request Amount',  width: '3', style: right },
                { field: 'buttons', noSort: true, title: '',                width: '2'               },
              ]}>
                { trs.map(tr => (
                  <SortTable.Row key={ tr.key }>
                    <SortTable.Cell>{ tr.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ tr.fdName }</SortTable.Cell>
                    <SortTable.Cell>{ tr.status }</SortTable.Cell>
                    <SortTable.Cell { ...right }>{ tr.addnumberoftrainers }</SortTable.Cell>
                    <SortTable.Cell { ...right }>
                      { (tr.status === 'Pending' || tr.status === 'Voting') &&
                        <ActionMultiButton header='Change Status'
                        selectOptions={ tr.status === 'Pending' ? [ 'Voting', 'Approved', 'Rejected' ] : [ 'Approved', 'Rejected' ] }
                          onConfirm={ status => this.changeStatus(tr, status) }
                        />
                      }
                      { tr.status === 'Approved' &&
                        <Icon name='clipboard check' size='large' color='green' />
                      }
                      { tr.status === 'Rejected' &&
                        <Icon name='clipboard' size='large' color='red' />
                      }
                      <EditButton view={ tr.status === 'Approved' || tr.status === 'Rejected' }
                        pathname={ `/${ PATH_ADMIN }/${ PATH_TR }/${ tr.id }/${ tr.timestamp }` }
                      />
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.state.editing &&
              <RejectReasonEditDialog open={ !!this.state.editing } onSave={ this.saveRejectReason } onClose={ this.closeDialog } />
            }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    adminTRs: storeState.models.adminTRs,
    ui: storeState.ui.adminTRs,
  };
}

export default connect(mapStoreStateToProps)(AdminTRs);
