import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message } from 'semantic-ui-react';

import { getFireDepartmentSpecialPayments, deleteFireDepartmentSpecialPayment } from '../../api/fireDepartmentAPI';

import { UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS_UI } from '../../js/actionTypes';
import { PATH_FIRE_DEPARTMENT, PATH_REIMBURSEMENTS, PATH_SPECIAL_PAYMENTS, PATH_NEW } from '../../js/paths';

import { sort } from '../../utils/utils';

import AddButton from '../../components/AddButton';
import CurrencyText from '../../components/CurrencyText';
import DeleteButton from '../../components/DeleteButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

class FireDepartmentSpecialPayments extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      title: 'Special Equipment Requests'
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartmentSpecialPayments(this.props.currentUser.orgId, 'Pending');
    this.setState({ loading: false });
  }

  remove = async (id, timestamp) => {
    this.setState({ loading: 'Removing' });
    await deleteFireDepartmentSpecialPayment(id, timestamp);
    this.setState({ loading: false });
  }

  handleRemove = async (id, timestamp) => {
    await this.remove(id, timestamp);
    await this.fetch();
  }

  handleNew() {
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_REIMBURSEMENTS }/${ PATH_SPECIAL_PAYMENTS }/${ PATH_NEW }`);
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS_UI, fireDepartmentSpecialPayments: this.state.ui });
    });
  }

  render() {
    return (
      <div className='fire-department-special-payment-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>
              <Header.Content>{ this.state.title }</Header.Content>
              <AddButton onClick={ this.handleNew.bind(this) }/>
            </Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.specialPaymentList.length) {
                return <Message info compact size='large'>
                  You don&apos;t have any equipment payment requests.
                </Message>;
              }

              let specialPayments = sort(this.props.specialPaymentList, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                specialPayments.reverse();
              }

              const right = { textAlign: 'right' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',              title: 'Date',        width: '3'               },
                { field: 'description',           title: 'Description', width: '5'               },
                { field: 'status',                title: 'Status',      width: '3'               },
                { field: 'amountaftertax',        title: 'Amount',      width: '3', style: right },
                { field: 'buttons', noSort: true, title: '',            width: '2'               },
              ]}>
                { specialPayments.map((specialPayment) => (
                  <SortTable.Row key={ specialPayment.key }>
                    <SortTable.Cell>{ specialPayment.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ specialPayment.description }</SortTable.Cell>
                    <SortTable.Cell>{ specialPayment.status }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ specialPayment.amountaftertax }/></SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      <EditButton pathname={ `/${ PATH_FIRE_DEPARTMENT }/${ PATH_REIMBURSEMENTS }/${ PATH_SPECIAL_PAYMENTS }/${ specialPayment.timestamp }` }/>
                      <DeleteButton content='Delete the special payment request?' onConfirm={ this.handleRemove.bind(this, specialPayment.id, specialPayment.timestamp) }/>
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    specialPaymentList: storeState.models.fireDepartmentSpecialPayments,
    ui: storeState.ui.fireDepartmentSpecialPayments,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentSpecialPayments);
