import React from 'react';

import { Segment, Header, Message } from 'semantic-ui-react';

import SortTable from './SortTable';
import IconButton from "./IconButton";

import { DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';

import ChosenFile from '../utils/file';
import { formatDateTime } from '../utils/date';

import CertificateEditDialog from '../dialogs/CertificateEditDialog';

class CourseSegment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editing: false };

    this.onDidSave = this.onDidSave.bind(this);
  }

  startEditing = (componentName) => {
    console.log(componentName);
    this.setState({ editing: true, course: componentName });
  }

  stopEditing = () => {
    this.setState({ editing: false } );
  }

  onDidSave = () => {
    this.setState({ editing: false } );
    this.props.onDidSave();
  }

  //download stuff
  handleIconClick = (file) => {
    if (file.uploaded) {
      this.download(file);
    }
  }
  download = async (file) => {
    const downloadUrl = await file.downloadUrl();

    if (downloadUrl) {
      window.open(downloadUrl);
    }
  }

  render = () => {
    let courses = (this.props.courses !== undefined ? this.props.courses : []);
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Courses</Header.Content>
        </Header>

        {(() => {
          if (courses.length === 0) {
            return <Message info compact size='tiny'>No courses</Message>;
          }

          const right = { textAlign: 'right' };

          return <SortTable collapsing size='small' onSort={()=>{}}
            sortField='coursename' sortDescending={false}
            headers={[
              { field: 'coursename',     title: 'Course name',   width: '8' },
              { field: 'dateattended',   title: 'Date attended',   width: '8' },
              { field: 'certificate', noSort: true, title: 'Certificate/Transcript',   width: '8', style:{ ...right } }
            ]}
          >
            { courses.map((course, index) => {
              let cert = new ChosenFile();
              if (course.certificate && course.certificate[0]) { cert.parse(course.certificate[0]); }
              return(
                <SortTable.Row key={ course.coursename + "" + index } id={course.coursename + "" + index }>
                  <SortTable.Cell>{ course.displayName }</SortTable.Cell>
                  <SortTable.Cell>{ formatDateTime(course.dateattended, DATE_FULL_MONTH_DAY_YEAR) }</SortTable.Cell>
                  <SortTable.Cell {...right}>
                    <IconButton name={'add square'} color={('blue')} popup={(!cert.uploaded ? 'Upload certificate' : 'Replace certificate')}  onClick={ this.startEditing.bind(this, course) }/>
                    <IconButton name={(!cert.uploaded ? 'ban' : 'eye')} popup={(!cert.uploaded ? 'Certificate unavailable' : 'View certificate')} color={(!cert.uploaded ? 'red' : 'green')} onClick={ this.handleIconClick.bind(this, cert) }/>
                  </SortTable.Cell>
                </SortTable.Row>
              )})}
            { this.state.editing &&
              <CertificateEditDialog open={ this.state.editing }
                course={ this.state.course } trainer= { this.props.trainer }
                onDidSave={ this.onDidSave } onClose={ this.stopEditing }
              />
            }
          </SortTable>;
        })()}
      </Segment>
    );
  }
};

export default CourseSegment;
