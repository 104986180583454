import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message } from 'semantic-ui-react';

import { getFireDepartmentReimbursements, deleteFireDepartmentReimbursement } from '../../api/fireDepartmentAPI';

import { UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS_UI } from '../../js/actionTypes';
import { PATH_FIRE_DEPARTMENT, PATH_REIMBURSEMENTS, PATH_TRAINING_EXPENSES, PATH_NEW, PATH_DUPLICATE } from '../../js/paths';

import { sort } from '../../utils/utils';

import AddButton from '../../components/AddButton';
import CurrencyText from '../../components/CurrencyText';
import DuplicateButton from '../../components/DuplicateButton';
import DeleteButton from '../../components/DeleteButton';
import EditButton from '../../components/EditButton';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

class FireDepartmentReimbursementList extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
      },
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartmentReimbursements(this.props.currentUser.orgId);
    this.setState({ loading: false });
  }

  remove = async (id, timestamp) => {
    this.setState({ loading: 'Removing' });
    await deleteFireDepartmentReimbursement(id, timestamp);
    this.setState({ loading: false });
  }

  handleRemove = async (id, timestamp) => {
    await this.remove(id, timestamp);
    await this.fetch();
  }

  handleDuplicate = async (reimbursement) => {
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_REIMBURSEMENTS }/${ PATH_TRAINING_EXPENSES }/${ reimbursement.timestamp }/${ PATH_DUPLICATE }`);
  }

  handleNew() {
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_REIMBURSEMENTS }/${ PATH_TRAINING_EXPENSES }/${ PATH_NEW }`);
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS_UI, fireDepartmentReimbursements: this.state.ui });
    });
  }

  render() {
    return (
      <div className='fire-department-reimbursement-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            <Header size='medium'>
              <Header.Content>Training Expenses</Header.Content>
              <AddButton onClick={ this.handleNew.bind(this) }/>
            </Header>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.reimbursementList.length) {
                return <Message info compact size='large'>
                  You don&apos;t have any training expenses.
                </Message>;
              }

              let reimbursements = sort(this.props.reimbursementList, this.state.ui.sortField);
              if (this.state.ui.sortDescending) {
                reimbursements.reverse();
              }

              const right = { textAlign: 'right' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',              title: 'Completion Date', width: '2'               },
                { field: 'trainerName',           title: 'Trainer',         width: '3'               },
                { field: 'displayCourse',         title: 'Course Name',     width: '3'               },
                { field: 'status',                title: 'Status',          width: '2'               },
                { field: 'totalamountaftertax',   title: 'Amount',          width: '2', style: right },
                { field: 'buttons', noSort: true, title: '',                width: '2'               },
              ]}>
                { reimbursements.map((reimbursement) => (
                  <SortTable.Row key={ reimbursement.key }>
                    <SortTable.Cell>{ reimbursement.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.trainerName }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.displayCourse }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.status }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'><CurrencyText value={ (reimbursement.totalamountaftertax || 0) }/></SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      <EditButton pathname={ `/${ PATH_FIRE_DEPARTMENT }/${ PATH_REIMBURSEMENTS }/${ PATH_TRAINING_EXPENSES }/${ reimbursement.timestamp }` }/>
                      <DuplicateButton onClick={ this.handleDuplicate.bind(this, reimbursement) }/>
                      <DeleteButton content='Delete the training expense request?' hide={ reimbursement.status !== 'Pending' }
                        onConfirm={ this.handleRemove.bind(this, reimbursement.id, reimbursement.timestamp) }
                      />
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    reimbursementList: storeState.models.fireDepartmentReimbursements,
    ui: storeState.ui.fireDepartmentReimbursements,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentReimbursementList);
