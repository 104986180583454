export const STATUS_LIST = [
  'Pending',
  'Approved',
  'Rejected',
  'Cancelled',
];

export const SURVEY_STATUS_LIST = [
  'Pending',
  'Scheduled',
  'Completed',
  'Rejected',
];

export const SURVEY_STATUS = {
  P : 'Pending',
  S : 'Scheduled',
  C : 'Completed',
  R : 'Rejected',
};

export const SURVEY_TYPES = {
  first_survey : 'First Survey',
  second_survey : 'Second Survey',
  multiple_cranes : 'Multiple Cranes',
  annual_survey : 'Annual Survey'
};

export const REIMBURSEMENT_COURSES = {
  awareness: 'Awareness',
  operations: 'Operations',
  technician: 'Technician',
  tower_crane: 'Tower Crane',
  instructors_training: 'Fire Service Instructor',
  update: 'Update Tower Crane Rescue',
};

export const ACCOMMODATION_TYPES = {
  H: 'Hotel',
  P: 'Private'
};

export const AIR_TRAVEL_TYPES = {
  R: 'Return',
  O: 'One-Way'
};

export const MEAL_TYPES = {
  B: 'Breakfast',
  L: 'Lunch',
  D: 'Dinner'
};

export const MILEAGE_TYPES = {
  H: 'Home',
  A: 'Away'
};

export const VEHICLE_TYPES = {
  T: 'Taxi',
  F: 'Ferry',
  R: 'Rental',
  O: 'Other'
};

export const TRAINER_TYPES = {
  A: 'Active',
  I: 'Inactive',
  D: 'Deleted'
};

export const USERS_LIST_TYPES = {
  cc: 'Construction Company',
  fd: 'Fire Department',
  ta: 'Training Agency'
};

export const SURVEY_CATEGORIES = {
  STANDARD: 'Standard',
  MUTUAL_AID: 'Mutual Aid',
};

export const SHORT_MONTHS = [ 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov' ];

export const CC_CATEGORIES = { MARITIME: 'Maritime', CONSTRUCTION: 'Construction' };