import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Sidebar, Menu } from 'semantic-ui-react';

import { UPDATE_SIDEBAR_UI } from '../js/actionTypes';

import PropTypes from 'prop-types';

class SidebarMenu extends React.Component {
  handleItemClick = () => {
    this.props.dispatch({ type: UPDATE_SIDEBAR_UI, showSidebar: false });
  }

  handleSidebarHide = (event) => {
    // Ignore clicks on hamburger icon!
    if (event && event.target && event.target.className && event.target.className.indexOf('burger') !== -1) return;

    this.props.dispatch({ type: UPDATE_SIDEBAR_UI, showSidebar: false });
  }

  render() {
    const activeClass = (route) => {
      return this.props.location.pathname === route ? 'active' : null;
    }

    return (
      <Sidebar.Pushable>
        <Sidebar animation='overlay' visible={ this.props.showSidebar } onHide={ this.handleSidebarHide }>
          <aside className='aside-primary'>
            <ul className='primary-nav'>
              <Menu vertical>
                {
                  this.props.menus.map(menu => {
                    return (
                      <Menu.Item key={ menu.header }>
                        <Menu.Header>{ menu.header }</Menu.Header>
                        <Menu.Menu>
                          {
                            menu.items.map(item => {
                              return (
                                <li className={ activeClass(item.path) } key={ item.name } onClick={ this.handleItemClick }>
                                  <NavLink to={ item.path }>{ item.name }</NavLink>
                                </li>
                              );
                            })
                          }
                        </Menu.Menu>
                      </Menu.Item>
                    );
                  })
                }
              </Menu>
            </ul>
          </aside>
        </Sidebar>
        <Sidebar.Pusher>
          { this.props.children }
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
 
SidebarMenu.propTypes = {
  menus: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

function mapStoreStateToProps(storeState) {
  return {
    showSidebar: storeState.ui.showSidebar,
  };
}

export default connect(mapStoreStateToProps)(SidebarMenu);
