import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Form, Segment, Button } from 'semantic-ui-react';

import { getFireDepartmentFinancialSummary, getFireDepartment, getFireDepartmentTrainers } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_REPORT_FDFINANCIALDETAILS_UI } from '../../js/actionTypes';
import { PATH_TIMESTAMP, PATH_ID, PATH_ADMIN, PATH_REPORTS, PATH_FIRE_DEPARTMENT, PATH_FINANCIAL_SUMMARY } from '../../js/paths';

import ViewLoader from '../../components/ViewLoader';
import SearchableFilter from '../../components/SearchableFilter';
import CurrencyInput from '../../components/CurrencyInput';

import EditView from '../EditView';

class AdminReportFDFinancialDetails extends EditView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    if (this.filterObjYrs !== undefined) {
      this.filterObjYrs.setYears(()=> {
        this.fetch();
        this.isClean();
      });
    }
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartmentTrainers(this.props.match.params[PATH_ID]); //get FD trainers count
    await getFireDepartment(this.props.match.params[PATH_ID]); //get FD details
    await getFireDepartmentFinancialSummary(this.props.match.params[PATH_ID], this.filterObjYrs.getSelectedText()); //get FD financial summary
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REPORT_FDFINANCIALDETAILS_UI });
    });
  }


  //back action
  goToList = () => {
    this.isClean();
    this.props.history.push(`/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }/${ PATH_FINANCIAL_SUMMARY }/${ this.filterObjYrs.getSelectedText() }`);
  }
  cancel = event => {
    event.preventDefault();
    // Back to the list
    this.goToList();
  }

  //Filter support
  handleFilterChange(filter,value) { this.fetch(); }

  //Sum support
  sumTotalValues(values) {
    let sum = 0;
    for (let key in values) { sum += (values[key] !== undefined ? values[key] : 0); }
    return sum;
  }
  safeNum(value) { return (value !== undefined && !isNaN(parseFloat(value)) ? parseFloat(value) : 0); }
  sumTotal(summary) {
    let total = 0;
    total += this.safeNum(summary.initialequippaid);
    total += this.safeNum(summary.trainingreimbursementaftertax);
    total += this.safeNum(summary.updatetrainingpaidaftertax);
    total += this.safeNum(summary.totalspecialpaymentsaftertax);
    total += this.safeNum(summary.totalfiredepartmentstipend);
    total += this.safeNum(summary.ppepaid);
    return total;
  }

  render() {
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 20, paddingTop: 30}} width={ 15 }>
            <Header size='medium'>Reports - Fire Department Financial Summary - { this.props.fireDepartment.entity_name }</Header>
            <Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Button>
            <SearchableFilter ref={(r)=>this.filterObjYrs=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={parseInt(this.props.match.params[PATH_TIMESTAMP])}/>
          </Grid.Column>
          <Grid.Column width={ 15 } style={{paddingTop: 0}}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              console.log("FD:", this.props.fireDepartment)
              console.log("summary:", this.props.fireDepartmentFinancialSummaries)

              if (this.props.fireDepartmentFinancialSummaries && !this.props.fireDepartmentFinancialSummaries.length) {
                return <Message info size='large'>
                  No Financial summary for the selected department and year.
                </Message>;
              }

              let summary = this.props.fireDepartmentFinancialSummaries[0];
              let selectedYear = (this.filterObjYrs !== undefined ? this.filterObjYrs.getSelectedText() : "Loading..");

              return <Form>
                      <Segment.Group size='large' className='view-segment-group'>
                        <Segment>
                          <Form.Group>
                            <Form.Input readOnly label='Department Name' value={ this.props.fireDepartment.entity_name } width={ 12 } />
                            <Form.Input readOnly label='Report Year' value={ selectedYear } width={ 4 } />
                          </Form.Group>
                          <Form.Group>
                            <Form.Input readOnly label='Number of Instructors' value={ this.props.trainerList.length } width={ 3 } />
                            <Form.Input readOnly label='Instructors Added' value={ summary.numtrainersadded } width={ 3 } />
                            <Form.Input readOnly label='Instructors Replaced' value={ summary.numtrainersreplaced } width={ 3 } />
                            <Form.Input readOnly label='Instructors With Update Training' value={ summary.numtrainersupdatedtrainer } width={ 4 } />
                          </Form.Group>
                          <Form.Group>
                            <Form.Input readOnly label='Rescues' value={ this.sumTotalValues(summary.numconstructionrescues) } width={ 3 } />
                            <Form.Input readOnly label='Training Hours' value={ this.sumTotalValues(summary.traininghours) } width={ 3 } />
                            <Form.Input control={ CurrencyInput } readOnly name='initialequippaid' label='Initial Equipment Purchase' value={ summary.initialequippaid } width={ 3 } onChange={()=>{}} />
                          </Form.Group>
                          <Form.Group>
                            <Form.Input control={ CurrencyInput } readOnly name='trainingreimbursementaftertax' label='Training Reimbursements' value={ summary.trainingreimbursementaftertax } width={ 3 } onChange={()=>{}} />
                            <Form.Input control={ CurrencyInput } readOnly name='updatetrainingpaidaftertax' label='Update Training' value={ summary.updatetrainingpaidaftertax } width={ 3 } onChange={()=>{}} />
                            <Form.Input control={ CurrencyInput } readOnly name='totalspecialpaymentsaftertax' label='Special Payments' value={ summary.totalspecialpaymentsaftertax } width={ 3 } onChange={()=>{}} />
                            <Form.Input control={ CurrencyInput } readOnly name='totalfiredepartmentstipend' label='Stipend' value={ this.safeNum(summary.totalfiredepartmentstipend) } width={ 3 } onChange={()=>{}} />
                            <Form.Input control={ CurrencyInput } readOnly name='ppepaid' label='PPE' value={ summary.ppepaid } width={ 3 } onChange={()=>{}} />
                          </Form.Group>
                          <Form.Group>
                            <Form.Input control={ CurrencyInput } readOnly name='totalsurveyamount' label='Crane Survey' value={ this.safeNum(summary.totalsurveyamount) } width={ 3 } onChange={()=>{}} />
                            <Form.Input control={ CurrencyInput } readOnly name='skillstrainingpaid' label='Skills Training' value={ summary.skillstrainingpaid } width={ 3 } onChange={()=>{}} />
                            <Form.Input control={ CurrencyInput } readOnly name='total' label='Total' value={ this.sumTotal(summary) } width={ 3 } onChange={()=>{}} />
                          </Form.Group>
                        </Segment>
                      </Segment.Group>
                    </Form>;
                })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    trainerList: storeState.models.fireDepartmentTrainers,
    fireDepartment: storeState.models.fireDepartment,
    fireDepartmentFinancialSummaries: storeState.models.fireDepartmentFinancialSummaries
  };
}

export default connect(mapStoreStateToProps)(AdminReportFDFinancialDetails);
