import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form, Message } from 'semantic-ui-react';

import { getAdminCB, updateAdminCB, postAdminCB } from '../../api/administratorAPI';

import { UPDATE_CB, UPDATE_CB_UI } from '../../js/actionTypes';
import { DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { PATH_ID, PATH_TIMESTAMP, PATH_ADMIN, PATH_CUSTOM_BALLOTS, PATH_NEW } from '../../js/paths';

import { today } from '../../utils/date';

import BallotSegment from '../../components/BallotSegment';
import DateControl from '../../components/DateControl';

import BallotEditDialog from '../../dialogs/BallotEditDialog';

import EditView from '../EditView';

const INITIAL_STATE = {
  status: 'Pending',
  timestamp: today(),
  dueDate: null,
  description: '',
  comments: '',
  ballots: [],

  // UI
  isReadOnly: false,

  editing: {},

  className: 'admin-custom-ballot',
  title: 'Custom Ballot',
};

class CustomBallot extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.isNew = (props.match.params[PATH_ID] === PATH_NEW);
    this.state.exitPage = `/${ PATH_ADMIN }/${ PATH_CUSTOM_BALLOTS }`;

    this.state.ui = {
      ballots: {
        sortField: this.props.ui.ballots.sortField || 'voteoption',
        sortDescending: this.props.ui.ballots.sortDescending === true,
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleBallotsUIStateChange = this.handleBallotsUIStateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
  }

  fetch = async () => {
    await getAdminCB(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
  }

  onNew = () => {
    this.dispatch({ type: UPDATE_CB, adminCB: INITIAL_STATE });
  }

  onLoad = () => {
    this.setState({
      ...this.props.adminCB,
      isReadOnly: this.props.adminCB.status !== 'Voting'
    });
  }

  handleBallotsUIStateChange(uiComponent) {
    this.setState({ ui: { ...this.state.ui, ...uiComponent }}, () => {
      this.dispatch({ type: UPDATE_CB_UI, adminCB: this.state.ui });
    });
  }

  startEditing = (componentName, entry) => {
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing });
  }

  stopEditing = () => {
    this.setState({ editing: {}});
  }

  handleComponentRemove = (componentName, entry) => {
    const ballots = this.state.ballots.filter(item => {
      return entry.memberemail !== item.memberemail
    });

    this.setState({ ballots: ballots }, () => { this.onChange() });
  }

  handleComponentEdit = (componentName, entry) => {
    this.stopEditing();

    let ballots = this.state.ballots.filter(item => {
      return entry.memberemail !== item.memberemail
    });
    ballots.push(entry);

    this.setState({ ballots: ballots }, () => { this.onChange() });
  }

  handleDateChange = (name, value) => {
    this.setState({ [name]: value }, () => { this.onChange(); });
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.description) {
      errors.error = true;
      errors.description = 'Please enter a description.';
    }

    if (!this.state.comments) {
      errors.error = true;
      errors.comments = 'Please enter comments.';
    }

    if (this.state.dueDate && new Date(this.state.dueDate) < new Date()) {
      errors.error = true;
      errors.dueDate = 'Please enter a Due Date that\'s higher than today.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  create = async (data) => {
    this.setState({ loading: 'Submitting' });
    const { editing, ui, isReadOnly, displayDate, sortDate, key, ...body } = data;
    await postAdminCB(body);
    if (this.props.adminCB.cbid) {
      this.exit();
    }
    this.setState({ loading: false });
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    const { editing, ui, isReadOnly, displayDate, sortDate, key, ...body } = data;
    await updateAdminCB(body);
    if (this.props.adminCB.cbid) {
      this.exit();
    }
    this.setState({ loading: false });
  }

  cancel = event => {
    event.preventDefault();
    this.isClean();
    this.props.history.goBack();
  }

  renderView = () => {
    const canEdit = this.state.status === 'Pending' || this.state.status === 'Voting';

    return (
      <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
          { canEdit &&
            <Form.Button floated='right' className='form-top-button' positive>{ this.state.isNew ? 'Submit' : 'Update' } Ballot</Form.Button>
          }
          <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>{ canEdit ? 'Cancel' : 'Close' }</Form.Button>
        </Header>
        <Segment.Group size='large' className='view-segment-group'>
          { this.state.status !== 'Pending' &&
            <BallotSegment disabled={ !canEdit } ballots={ this.state.ballots }
              ui={ this.state.ui.ballots } onUIStateChange={ this.handleBallotsUIStateChange }
              edit={ this.startEditing } remove={ this.handleComponentRemove }
            />
          }
          <Segment disabled={ this.state.isReadOnly }>
            <Form.Group>
              <Form.Field readOnly label='Date' name='date' width={ 3 }
                control={ DateControl } date={ this.state.timestamp } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
              />
              <Form.Field readOnly={ this.state.isReadOnly } label='Due Date' name='dueDate' width={ 3 }
                control={ DateControl } date={ this.state.dueDate } dateFormat={ DATE_FULL_MONTH_DAY_YEAR } onChange={ this.handleDateChange }
              />
              <Form.Input readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 8 }
                label='Description' value={ this.state.description } name='description'
                error={ !!this.state.errors.description }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.dueDate }
            </Message>
            <Message error>
              { this.state.errors.description }
            </Message>
            <Form.Group>
              <Form.TextArea readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 16 }
                label='Comments' value={ this.state.comments } name='comments'
                error={ !!this.state.errors.comments }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.comments }
            </Message>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }

  renderAfterView = () => {
    if (this.state.editing.ballots) {
      return (
        <BallotEditDialog open={ !!this.state.editing.ballots } ballot={ this.state.editing.ballots }
          onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
        />
      );
    }

    return null;
  }
}

function mapStoreStateToProps(storeState) {
  return {
    adminCB: storeState.models.adminCB,
    ui: storeState.ui.adminCB
  };
}

export default connect(mapStoreStateToProps)(CustomBallot);
