import React from 'react';

import PropTypes from 'prop-types';

import AddButton from './AddButton';

import ChosenFile from '../utils/file';

class FileChooserButton extends React.Component {
  add = (event) => {
    if (event.target.files.length === 0) return;

    let filesState = this.props.multiple ? [ ...this.props.files ] : [];

    const files = [ ...event.target.files ];
    files.forEach((file, index) => {
      const exists = filesState.find((f) => { return f.isEqual(file); });
      if (!exists) {
        filesState.push(new ChosenFile(file));
      }
    });

    // Clear the input so any file can be chosen again
    event.target.value = '';

    let stateUpdate = {};
    stateUpdate[this.props.name] = filesState;
    this.props.onChange(stateUpdate);
  }

  render = () => {
    let inputProps = {};
    if (this.props.multiple) inputProps.multiple = this.props.multiple;
    if (this.props.accept) inputProps.accept = this.props.accept;

    let style = { marginLeft: '0.4em' };
    if (this.props.withInput) {
      style.marginTop = '0.5em';
    }
    return (
      <label style={ style }>
        <AddButton />
        <input style={{ display: 'none' }} type="file" onChange={ this.add.bind(this) } { ...inputProps }/>
      </label>
    );
  }
};

FileChooserButton.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  files: PropTypes.array,
  withInput: PropTypes.bool,
};

export default FileChooserButton;
