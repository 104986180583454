// Log rocket
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
//
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import './scss/App.scss';

import { logrocketOptions } from './js/auth';
import './js/polyfills';

import store from './js/store';
import * as serviceWorker from './js/serviceWorker';

import App from './views/App';

if (!process.env.REACT_APP_OFFLINE) {
  //Log rocket
  LogRocket.init(logrocketOptions.applicationId);
  setupLogRocketReact(LogRocket);
}

const WrappedApp =
  <Provider store={ store }>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>;

ReactDOM.render(WrappedApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
