import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon } from 'semantic-ui-react';

import { getAdminOperatorReimbursements, putAdminOperatorReimbursement } from '../../api/administratorAPI';

import { UPDATE_TRAINING_AGENCY_REIMBURSEMENTS_UI } from '../../js/actionTypes';
import { PATH_ADMIN, PATH_OPERATOR_REIMBURSEMENT } from '../../js/paths';

import { sort } from '../../utils/utils';

import ApprovalButton from '../../components/ApprovalButton';
import SortTable from '../../components/SortTable';
import EditButton from '../../components/EditButton';
import ViewLoader from '../../components/ViewLoader';
import CurrencyText from '../../components/CurrencyText';
import SearchableFilter from '../../components/SearchableFilter';

import RejectReasonEditDialog from '../../dialogs/RejectReasonEditDialog';

import ListView from '../ListView';

class AdminReimbursementsTA extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
        filterValue: 'Pending'
      },

      filterValues: ['All', 'Pending', 'Approved', 'Rejected'],

      editing: null,

      title: 'Tower Crane Operator Reimbursements'
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  fetch = async () => {
    await getAdminOperatorReimbursements();
  }

  onLoad = () => {
    if (this.filterObj) {
      this.filterObj.setFilterItems(this.state.filterValues);
    }
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await putAdminOperatorReimbursement(data);
    await this.fetch();
    this.setState({ loading: false });
  }

  handleApprove = async (reimbursement) => {
    reimbursement.status = 'Approved';
    await this.update(reimbursement);
  }

  handleReject = (reimbursement) => {
    this.getRejectReason(reimbursement);
  }

  closeDialog = () => { this.setState({ editing: null }); }

  saveRejectReason = async (rejectreason) => {
    let data = { ...this.state.editing };
    data.status = 'Rejected';
    data.rejectreason = rejectreason;
    this.closeDialog();
    await this.update(data);
  }

  getRejectReason = (data) => {
    this.setState({ editing: data });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_TRAINING_AGENCY_REIMBURSEMENTS_UI, trainingAgencyReimbursements: this.state.ui });
    });
  }

  handleFilterChange(filter, value) {
    this.updateUIState({ filterValue: value < 0 ? 'Pending' : this.state.filterValues[value] });
  }

  render() {
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{ marginLeft: 40, paddingTop: 30 }} width={ 6 } floated='left'>
            <Header size='medium'>{ this.state.title }</Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{ marginRight: 40, textAlign:'right', paddingBottom: 0 }} width={ 8 } floated='right'>
            <SearchableFilter ref={ r => this.filterObj = r } defaultValue={ this.props.ui.filterValue }
              handleFilterChange={ this.handleFilterChange.bind(this) }
            />
          </Grid.Column>
          <Grid.Column width={ 15 }>
            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (!this.props.reimbursements.length) {
                return <Message info size='large'>
                  You don&apos;t have any reimbursements.
                </Message>;
              }

              let reimbursements = sort(this.props.reimbursements, this.state.ui.sortField);

              if (this.filterObj) {
                const filterStatus = this.filterObj.getSelectedText();
                if (filterStatus && filterStatus !== 'All') {
                  reimbursements = reimbursements.filter(reimbursement => reimbursement.status === filterStatus);
                }
              }

              if (this.state.ui.sortDescending) {
                reimbursements.reverse();
              }

              const right = { textAlign: 'right' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',              title: 'Submission Date', width: '2'               },
                { field: 'taName',                title: 'Training Agency', width: '3'               },
                { field: 'operatorName',          title: 'Operator',        width: '3'               },
                { field: 'status',                title: 'Status',          width: '2'               },
                { field: 'numdays',               title: 'Days',            width: '2', style: right },
                { field: 'totalamount',           title: 'Amount',          width: '2', style: right },
                { field: 'buttons', noSort: true, title: '',                width: '2'               },
              ]}>
                { reimbursements.map((reimbursement) => (
                  <SortTable.Row key={ reimbursement.key }>
                    <SortTable.Cell>{ reimbursement.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.taName }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.operatorname }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.status }</SortTable.Cell>
                    <SortTable.Cell { ...right }>{ reimbursement.numdays }</SortTable.Cell>
                    <SortTable.Cell { ...right }><CurrencyText value={ reimbursement.totalamount }/></SortTable.Cell>
                    <SortTable.Cell { ...right }>
                    { reimbursement.status === 'Pending' &&
                      <ApprovalButton
                        onApproval={ this.handleApprove.bind(this, reimbursement) }
                        onRejection={ this.handleReject.bind(this, reimbursement) }
                      />
                    }
                    { reimbursement.status === 'Approved' &&
                      <Icon name='clipboard check' size='large' color='green' />
                    }
                    { reimbursement.status === 'Rejected' &&
                      <Icon name='clipboard' size='large' color='red' />
                    }
                      <EditButton view={ reimbursement.status !== 'Pending' }
                        pathname={ `/${ PATH_ADMIN }/${ PATH_OPERATOR_REIMBURSEMENT }/${ reimbursement.id }/${ reimbursement.timestamp }` }
                      />
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.state.editing &&
              <RejectReasonEditDialog open={ !!this.state.editing } onSave={ this.saveRejectReason } onClose={ this.closeDialog } />
            }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    reimbursements: storeState.models.trainingAgencyReimbursements,
    ui: storeState.ui.trainingAgencyReimbursements,
  };
}

export default connect(mapStoreStateToProps)(AdminReimbursementsTA);
