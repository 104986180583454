import { get, post, put, del, buildQuerystring } from './api';
import { parseSpecialPayment, parseReimbursement, parsePPE, getFireDepartmentTrainers, parseWorkSiteRescue, parseFinancialSummary } from './fireDepartmentAPI';

import * as Action from '../js/actionTypes';
import { API_ADMIN, API_FIRE_DEPARTMENT, DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { PATH_VOTE, PATH_SPECIAL_PAYMENTS, PATH_SETTINGS, PATH_REIMBURSEMENTS, PATH_COST_CENTERS, PATH_CUSTOM_BALLOTS, PATH_COMMITTEE } from '../js/paths';
import { PATH_PPE, PATH_TR, PATH_EQUIVALENCY, PATH_REGISTRATIONS, PATH_RESCUE, PATH_ANNUAL, PATH_JOINT_TRAINING, PATH_OPERATOR_REIMBURSEMENT, PATH_FINANCIAL } from '../js/paths';

import store from '../js/store';
import { formatDateTime, sortableDateTime } from '../utils/date';

export function parseWBs(wb) {
  if (wb.guid) {
    wb.key = wb.guid + '+' + wb.wbid;
  }

  return wb;
}

export async function getAdminWBs(type) {
  let params = {};
  if (type) params.type = type;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_VOTE }${ querystring }`);

  const wbs = response.map(wb => { return parseWBs(wb); });

  store.dispatch({ type: Action.UPDATE_WBS, adminWBs: wbs });
}

export async function getAdminWB(type, fdid, timestamp) {
  let params = {};
  if (type) params.type = type;
  if (fdid) params.fdid = fdid;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_VOTE }${ querystring }`);

  const wb = response.length === 1 ? parseWBs(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_WB, adminWB: wb });
}

export async function updateAdminWB(body) {
  const response = await put(API_ADMIN, `/${ PATH_VOTE }`, body);

  const wb = parseWBs(response);

  store.dispatch({ type: Action.UPDATE_WB, adminWB: wb });
}

export function parseCBs(cb) {
  if (cb.timestamp) {
    cb.key = cb.timestamp;
    cb.displayDate = formatDateTime(cb.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    cb.sortDate = sortableDateTime(cb.timestamp);
    if(cb.dueDate) {
      cb.displayDueDate = formatDateTime(cb.dueDate, DATE_FULL_MONTH_DAY_YEAR);
      cb.sortDueDate = sortableDateTime(cb.dueDate);
    }
  }

  return cb;
}

export async function getAdminCBs(status) {
  let params = {};
  if (status) params.status = status;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_CUSTOM_BALLOTS }${ querystring }`);

  const cbs = response.map(cb => { return parseCBs(cb); });

  store.dispatch({ type: Action.UPDATE_CBS, adminCBs: cbs });
}

export async function getAdminCB(cbid, timestamp) {
  let params = {};
  if (cbid) params.cbid = cbid;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_CUSTOM_BALLOTS }${ querystring }`);

  const cb = response.length === 1 ? parseCBs(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_CB, adminCB: cb });
}

export async function postAdminCB(body) {
  const response = await post(API_ADMIN, `/${ PATH_CUSTOM_BALLOTS }`, body);

  const cb = parseCBs(response);

  store.dispatch({ type: Action.UPDATE_CB, adminCB: cb });
}

export async function updateAdminCB(body) {
  const response = await put(API_ADMIN, `/${ PATH_CUSTOM_BALLOTS }`, body);

  const cb = parseCBs(response);

  store.dispatch({ type: Action.UPDATE_CB, adminCB: cb });
}


export function parseTRS(tr) {
  if (tr.timestamp) {
    const storeState = store.getState();
    const fireDepartment = storeState.lookups.fireDepartments.find((fd) => { return fd.id === tr.id; });

    tr.key = tr.id + '+' + tr.timestamp;
    tr.displayDate = formatDateTime(tr.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    tr.sortDate = sortableDateTime(tr.timestamp);
    tr.displayCertDate = formatDateTime(tr.fullcertdate, DATE_FULL_MONTH_DAY_YEAR);
    tr.fdName = fireDepartment ? fireDepartment.name : '';
  }

  return tr;
}

export async function getAdminTRs(status) {
  let params = {};
  if (status) params.status = status;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_TR }${ querystring }`);

  const trs = response.map(tr => { return parseTRS(tr); });

  store.dispatch({ type: Action.UPDATE_TRS, adminTRs: trs });
}

export async function getAdminTR(id, timestamp) {
  let params = {};
  if (id) params.id = id;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_TR }${ querystring }`);

  const tr = response.length === 1 ? parseTRS(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_TR, adminTR: tr });
}

export async function changeAdminTR(body) {
  const response = await put(API_ADMIN, `/${ PATH_TR }`, body);

  const tr = parseTRS(response);

  store.dispatch({ type: Action.UPDATE_TR, adminTR: tr });
}

// Registrations
export function parseRegistrations(registration) {
  if (registration.id) {
    registration.key = registration.id + '+' + registration.registration_date;
    registration.displayDate = formatDateTime(registration.registration_date, DATE_FULL_MONTH_DAY_YEAR);
    registration.sortDate = sortableDateTime(registration.registration_date);
  }

  return registration;
}

export async function getAdminRegistrations(status, type) {
  let params = {};
  if (status) params.status = status;
  if (type) params.type = type;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_REGISTRATIONS }${ querystring }`);

  const registrations = response.map(registration => { return parseRegistrations(registration); });

  store.dispatch({ type: Action.UPDATE_ADMIN_REGISTRATIONS, adminRegistrations: registrations });
}

export async function updateAdminRegistration(body) {
  const response = await put(API_ADMIN, `/${ PATH_REGISTRATIONS }`, body);

  const registration = parseRegistrations(response);

  store.dispatch({ type: Action.UPDATE_ADMIN_REGISTRATION, adminRegistration: registration });
}

export async function getAdminPPEs(status) {
  let params = {};
  if (status) params.status = status;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_PPE }${ querystring }`);

  const ppes = response.map(ppes => { return parsePPE(ppes); });

  store.dispatch({ type: Action.UPDATE_PPES, adminPPEs: ppes });
}

export async function getAdminPPE(status, timestamp, id) {
  let params = {};
  if (status) params.status = status;
  if (timestamp) params.timestamp = timestamp;
  if (id) params.id = id;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_PPE }${ querystring }`);

  const ppe = response.length === 1 ? parsePPE(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_PPE, adminPPE: ppe });
}

export async function changeAdminPPE(body) {
  const response = await put(API_ADMIN, `/${ PATH_PPE }`, body);

  const ppe = parsePPE(response);

  store.dispatch({ type: Action.UPDATE_PPE, adminPPE: ppe });
}

export async function getAdminSpecialPayments(status) {
  let params = {};
  if (status) params.status = status;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_SPECIAL_PAYMENTS }${ querystring }`);

  const specialPayments = response.map(specialPayment => { return parseSpecialPayment(specialPayment); });

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS, fireDepartmentSpecialPayments: specialPayments });
}

export async function getAdminSpecialPayment(fdid, timestamp) {
  let params = {};
  if (fdid) params.fdid = fdid;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_SPECIAL_PAYMENTS }${ querystring }`);

  const specialPayment = response.length === 1 ? parseSpecialPayment(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT, fireDepartmentSpecialPayment: specialPayment });
}

export async function postAdminSpecialPayment(body) {
  const response = await post(API_ADMIN, `/${ PATH_SPECIAL_PAYMENTS }`, body);

  const specialPayment = parseSpecialPayment(response);

  store.dispatch({ type: Action.ADD_FIRE_DEPARTMENT_SPECIAL_PAYMENT, fireDepartmentSpecialPayment: specialPayment });
}

export async function updateAdminSpecialPayment(body) {
  const response = await put(API_ADMIN, `/${ PATH_SPECIAL_PAYMENTS }`, body);

  const specialPayment = parseSpecialPayment(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT, fireDepartmentSpecialPayment: specialPayment });
}

export async function deleteAdminSpecialPayment(fdid, timestamp) {
  let body = {};
  if (fdid) body.fdid = fdid;
  if (timestamp) body.timestamp = timestamp;

  const response = await del(API_ADMIN, `/${ PATH_SPECIAL_PAYMENTS }`, body);

  const specialPayment = parseSpecialPayment(response);

  store.dispatch({ type: Action.DELETE_FIRE_DEPARTMENT_SPECIAL_PAYMENT, fireDepartmentSpecialPayment: specialPayment });
}

export async function changeAdminSpecialPayment(body) {
  const response = await put(API_ADMIN, `/${ PATH_SPECIAL_PAYMENTS }`, body);

  const specialPayment = parseSpecialPayment(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT, fireDepartmentSpecialPayment: specialPayment });
}

export async function changeFireDepartmentReimbursement(body) {
  const response = await put(API_FIRE_DEPARTMENT, `/${ PATH_REIMBURSEMENTS }`, body);

  const reimbursement = parseReimbursement(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT, fireDepartmentReimbursement: reimbursement });
}

export async function parseEquivalency(equivalency) {
  // Need to grab trainers for this FD to get trainer name ...
  await getFireDepartmentTrainers(equivalency.id);

  const storeState = store.getState();
  const trainer = storeState.models.fireDepartmentTrainers.find((trainer) => { return trainer.trainerId === equivalency.trid; });
  const fireDepartment = storeState.lookups.fireDepartments.find((fd) => { return fd.id === equivalency.id; });

  equivalency.key = equivalency.id + '+' + equivalency.timestamp;
  equivalency.sortDate = sortableDateTime(equivalency.timestamp);
  equivalency.displayDate = formatDateTime(equivalency.timestamp, DATE_FULL_MONTH_DAY_YEAR);
  equivalency.fdName = fireDepartment ? fireDepartment.name : '';

  if (!equivalency.courses) equivalency.courses = [];

  equivalency.trainername = trainer ? trainer.trainername : '';

  return equivalency;
}

export async function getAdminEquivalencies(status) {
  let params = {};
  if (status) params.status = status;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_EQUIVALENCY }${ querystring }`);

  // parseEquivalency is async because we're grabbing trainers, so the map call will return an array of promises!
  const trainerEquivalencies = await Promise.all(response.map(equivalency => parseEquivalency(equivalency)));

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES, fireDepartmentTrainerEquivalencies: trainerEquivalencies });
}

export async function getAdminEquivalency(id, timestamp) {
  let params = {};
  if (id) params.id = id;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_EQUIVALENCY }${ querystring }`);

  const trainerEquivalency = response.length === 1 ? await parseEquivalency(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCY, fireDepartmentTrainerEquivalency: trainerEquivalency });
}

export async function updateAdminEquivalency(body) {
  const response = await put(API_ADMIN, `/${ PATH_EQUIVALENCY }`, body);

  const trainerEquivalency = await parseEquivalency(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCY, fireDepartmentTrainerEquivalency: trainerEquivalency });
}

export async function deleteAdminEquivalency(id, timestamp, courses, trainername) {
  let body = {};
  if (id) body.fdid = id;
  if (timestamp) body.timestamp = timestamp;
  if (courses) body.courses = courses;
  if (trainername) body.instructorName = trainername;

  const response = await del(API_ADMIN, `/${ PATH_EQUIVALENCY }`, body);

  const trainerEquivalency = await parseEquivalency(response);

  store.dispatch({ type: Action.DELETE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCY, fireDepartmentTrainerEquivalency: trainerEquivalency });
}

// Annual Submission

export function parseAnnualSubmission(submission) {
  if (submission.id) {
    const storeState = store.getState();
    const fireDepartment = storeState.lookups.fireDepartments.find((fd) => { return fd.id === submission.id; });

    submission.key = submission.id + '+' + submission.timestamp;
    if (submission.submittedOn) {
      submission.displayDate = formatDateTime(submission.submittedOn, DATE_FULL_MONTH_DAY_YEAR);
      submission.sortDate = sortableDateTime(submission.submittedOn);
    } else {
      submission.displayDate = formatDateTime(submission.timestamp, DATE_FULL_MONTH_DAY_YEAR);
      submission.sortDate = sortableDateTime(submission.timestamp);
    }
    submission.fdName = fireDepartment ? fireDepartment.name : '';
  }

  return submission;
}

export async function getAdminAnnualSubmissions(status, type, fdid, timestamp, year) {
  let params = {};
  if (status) params.status = status;
  if (type) params.type = type;
  if (fdid) params.fdid = fdid;
  if (timestamp) params.timestamp = timestamp;
  if (year) params.year = year;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_ANNUAL }${ querystring }`);

  const annualSubmissions = response.map(submission => parseAnnualSubmission(submission));

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSIONS, fireDepartmentAnnualSubmissions: annualSubmissions });
}

export async function updateAdminAnnualSubmission(body) {
  const response = await put(API_ADMIN, `/${ PATH_ANNUAL }`, body);

  const annualSubmission = parseAnnualSubmission(response);

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSION, fireDepartmentAnnualSubmission: annualSubmission });
}

// Committee

export async function getAdminCommittee() {
  const response = await get(API_ADMIN, `/${ PATH_COMMITTEE }`);

  const committee = response.length === 1 ? response[0] : {};

  store.dispatch({ type: Action.UPDATE_ADMIN_COMMITTEE, adminCommittee: committee });
}

export async function updateAdminCommittee(body) {
  const response = await put(API_ADMIN, `/${ PATH_COMMITTEE }`, body);

  const committee = response;

  store.dispatch({ type: Action.UPDATE_ADMIN_COMMITTEE, adminCommittee: committee });
}


// Joint Training

export function parseJointTraining(jt) {
  if (jt.fdid) {
    const storeState = store.getState();
    const fireDepartment = storeState.lookups.fireDepartments.find((fd) => { return fd.id === jt.fdid; });

    jt.key = jt.fdid + '+' + jt.timestamp;
    jt.displayDate = formatDateTime(jt.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    jt.sortDate = sortableDateTime(jt.timestamp);
    jt.displayTrainingDate = formatDateTime(jt.trainingdate, DATE_FULL_MONTH_DAY_YEAR);
    jt.sortTrainingDate = sortableDateTime(jt.trainingdate);
    jt.fdName = fireDepartment ? fireDepartment.name : '';
  }

  return jt;
}

export async function getAdminJointTrainings(status, fdid, yearmonth, id) {
  let params = {};
  if (status) params.status = status;
  if (fdid) params.fdid = fdid;
  if (yearmonth) params.yearmonth = yearmonth;
  if (id) params.id = id;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_JOINT_TRAINING }${ querystring }`);

  const jointTrainings = response.map(jt => { return parseJointTraining(jt) });

  store.dispatch({ type: Action.UPDATE_JOINT_TRAININGS, jointTrainings: jointTrainings });
}

export async function getAdminJointTraining(id, timestamp) {
  let params = {};
  if (id) params.id = id;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_JOINT_TRAINING }${ querystring }`);

  const jointTraining = response.length === 1 ? await parseJointTraining(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_JOINT_TRAINING, jointTraining: jointTraining });
}

export async function putAdminJointTraining(body) {
  const response = await put(API_ADMIN, `/${ PATH_JOINT_TRAINING }`, body);

  const jointTraining = parseJointTraining(response);

  store.dispatch({ type: Action.UPDATE_JOINT_TRAINING, jointTraining: jointTraining });
}

//////////////////////////////////////////////////////////////////////////////////////////
// COST CENTERS
///////////
export function parseCostCenter(costCenter) {
  if (costCenter.id) {
    costCenter.key = costCenter.id;
    costCenter.sortDate = sortableDateTime(costCenter.type);
  }
  return costCenter;
}

export async function getCostCenters(fdid, type) {
  let params = {};
  if (type) params.type = type;
  if (fdid) params.fdid = fdid;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_COST_CENTERS }${ querystring }`);
  const costCenters = response.map(costCenter => { return parseCostCenter(costCenter); });

  store.dispatch({ type: Action.UPDATE_COST_CENTERS, costCenters: costCenters });
}

export async function getCostCenter(id, timestamp) {
  let params = {};
  if (id) params.ct = id;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_COST_CENTERS }${ querystring }`);

  const costCenter = response.length === 1 ? parseCostCenter(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_COST_CENTER, costCenter: costCenter });
}

export async function postCostCenter(body) {
  const response = await post(API_ADMIN, `/${ PATH_COST_CENTERS }`, body);

  const costCenter = parseCostCenter(response);

  store.dispatch({ type: Action.ADD_COST_CENTER, costCenter: costCenter });
}

export async function updateCostCenter(body) {
  const response = await put(API_ADMIN, `/${ PATH_COST_CENTERS }`, body);

  const costCenter = parseCostCenter(response);

  store.dispatch({ type: Action.UPDATE_COST_CENTER, costCenter: costCenter });
}

export async function deleteCostCenter(id, timestamp) {
  let body = {};
  if (id) body.id = id;
  if (timestamp) body.timestamp = timestamp;

  const response = await del(API_ADMIN, `/${ PATH_COST_CENTERS }`, body);

  const costCenter = parseCostCenter(response);

  store.dispatch({ type: Action.DELETE_COST_CENTER, costCenter: costCenter });
}

// Operator Reimbursements

export function parseOperatorReimbursement(reimbursement) {
  if (reimbursement.id) {
    const storeState = store.getState();
    const trainingAgency = storeState.lookups.trainingAgencies.find((ta) => { return ta.id === reimbursement.id; });

    reimbursement.key = reimbursement.id + '+' + reimbursement.timestamp;
    reimbursement.displayDate = formatDateTime(reimbursement.timestamp, DATE_FULL_MONTH_DAY_YEAR);
    reimbursement.sortDate = sortableDateTime(reimbursement.timestamp);
    reimbursement.taName = trainingAgency ? trainingAgency.name : '';
  }

  return reimbursement;
}

export async function getAdminOperatorReimbursements(status, type, timestamp, taid) {
  let params = {};
  if (status) params.status = status;
  if (taid) params.taid = taid;
  if (timestamp) params.timestamp = timestamp;
  if (type) params.type = type;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_OPERATOR_REIMBURSEMENT }${ querystring }`);

  const trainingAgencyReimbursements = response.map(reimbursement => { return parseOperatorReimbursement(reimbursement) });

  store.dispatch({ type: Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENTS, trainingAgencyReimbursements: trainingAgencyReimbursements });
}

export async function getAdminOperatorReimbursement(taid, timestamp) {
  let params = {};
  if (taid) params.taid = taid;
  if (timestamp) params.timestamp = timestamp;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_OPERATOR_REIMBURSEMENT }${ querystring }`);

  const trainingAgencyReimbursement = response.length === 1 ? await parseOperatorReimbursement(response[0]) : {};

  store.dispatch({ type: Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENT, trainingAgencyReimbursement: trainingAgencyReimbursement });
}

export async function putAdminOperatorReimbursement(body) {
  const response = await put(API_ADMIN, `/${ PATH_OPERATOR_REIMBURSEMENT }`, body);

  const trainingAgencyReimbursement = parseOperatorReimbursement(response);

  store.dispatch({ type: Action.UPDATE_TRAINING_AGENCY_REIMBURSEMENT, trainingAgencyReimbursement: trainingAgencyReimbursement });
}

// Work Site Rescues

export async function getWorkSiteRescues(fdid, year, shift) {
  let params = {};
  if (fdid) params.fdid = fdid;
  if (year) params.year = year;
  if (shift) params.shift = shift;

  const querystring = buildQuerystring(params);
  const response = await get(API_ADMIN, `/${ PATH_RESCUE }${ querystring }`);

  const workSiteRescues = response.map(rescue => { return parseWorkSiteRescue(rescue); });

  store.dispatch({ type: Action.UPDATE_ADMIN_FIRE_DEPARTMENT_WORK_SITE_RESCUES, workSiteRescues: workSiteRescues });
}


//////////////////////////////////////////////////////////////////////////////////////////
// Lookups

export async function getSettings() {
  const response = await get(API_ADMIN, `/${ PATH_SETTINGS }`, { noAuthRequired: true });

  const settings = response.length === 1 ? response[0] : {};

  store.dispatch({ type: Action.UPDATE_SETTINGS_LOOKUP, settings: settings });
}

export async function updateSettings(body) {
  const response = await put(API_ADMIN, `/${ PATH_SETTINGS }`, body);

  const settings = response;

  store.dispatch({ type: Action.UPDATE_SETTINGS_LOOKUP, settings: settings });
}

//
export async function getFireDepartmentsFinancialSummary(year) {
  let params = {};
  if (year) params.year = year;

  const querystring = buildQuerystring(params);
  let response = await get(API_ADMIN, `/${ PATH_FINANCIAL }${ querystring }`);
  //this line should be removed whenever an API fix is implemented to return all entries and not just one.
  response = (Array.isArray(response) ? response : [response]);

  console.log("response", response);
  const fireDepartmentsFinancialSummaries = response.map(item => { return parseFinancialSummary(item); });

  store.dispatch({ type: Action.UPDATE_FIRE_DEPARTMENTS_FINANCIAL_SUMMARIES, fireDepartmentsFinancialSummaries: fireDepartmentsFinancialSummaries });
}
