import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon } from 'semantic-ui-react';

import { getAdminCBs, updateAdminCB } from '../../api/administratorAPI';

import { UPDATE_CBS_UI } from '../../js/actionTypes';
import { PATH_CUSTOM_BALLOTS, PATH_ADMIN, PATH_NEW } from '../../js/paths';

import { sort } from '../../utils/utils';

import ApprovalButton from '../../components/ApprovalButton';
import AddButton from '../../components/AddButton';
import EditButton from '../../components/EditButton';
import SearchableFilter from '../../components/SearchableFilter';
import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';

import RejectReasonEditDialog from '../../dialogs/RejectReasonEditDialog';

import ListView from '../ListView';

class CustomBallots extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortDate',
        sortDescending: this.props.ui.sortDescending === true,
        filterValue: 'All'
      },

      filterValues: ['All', 'Voting', 'Approved', 'Rejected'],

      title: 'Custom Ballots',

      editing: null
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  fetch = async () => {
    await getAdminCBs();
  }

  onLoad = () => {
    if (this.filterObj) {
      this.filterObj.setFilterItems(this.state.filterValues);
    }
  }

  handleNew = () => {
    this.props.history.push(`/${ PATH_ADMIN }/${ PATH_CUSTOM_BALLOTS }/${ PATH_NEW }`);
  }

  closeDialog = () => { this.setState({ editing: null }); }

  saveRejectReason = async (rejectreason) => {
    let data = { ...this.state.editing };
    data.rejectreason = rejectreason;
    data.status = 'Rejected';
    this.closeDialog();
    await this.update(data);
  }

  getRejectReason = (data) => {
    this.setState({ editing: data });
  }

  handleApprove = async (data) => {
    data.status = 'Approved';
    await this.update(data);
  }

  handleReject = (data) => {
    this.getRejectReason(data);
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    const { displayDate, sortDate, key, ...body } = data;
    await updateAdminCB(body);
    await this.fetch();
    this.setState({ loading: false });
  }

  updateUIState = (state) => {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_CBS_UI, adminCBs: this.state.ui });
    });
  }

  handleFilterChange(filter, value) {
    this.updateUIState({ filterValue: value < 0 ? 'All' : this.state.filterValues[value] });
  }

  render = () => {
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{ marginLeft: 40, paddingTop: 30 }} width={ 6 } floated='left'>
            <Header size='medium'>
              <Header.Content>{ this.state.title }</Header.Content>
              <AddButton onClick={ this.handleNew.bind(this) }/>
            </Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{ marginRight: 40, textAlign:'right', paddingBottom: 0 }} width={ 8 } floated='right'>
            <SearchableFilter ref={ r => this.filterObj = r } defaultValue={ this.props.ui.filterValue }
              handleFilterChange={ this.handleFilterChange.bind(this) }
            />
          </Grid.Column>
          <Grid.Column width={ 15 }>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (this.props.adminCBs && !this.props.adminCBs.length) {
                return <Message info size='large'>
                  You don&apos;t have any Custom Ballots.
                </Message>;
              }

              let cbs = sort(this.props.adminCBs, this.state.ui.sortField);

              if (this.filterObj) {
                const filterStatus = this.filterObj.getSelectedText();
                if (filterStatus && filterStatus !== 'All') {
                  cbs = cbs.filter(cb => cb.status === filterStatus);
                }
              }

              if (this.state.ui.sortDescending) {
                cbs.reverse();
              }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortDate',              title: 'Date',       width: '3' },
                { field: 'sortDueDate',           title: 'Due Date',   width: '3' },
                { field: 'description',           title: 'Descripton', width: '9' },
                { field: 'status',                title: 'Status',     width: '2' },
                { field: 'buttons', noSort: true, title: '',           width: '2' },
              ]}>
                { cbs.map(cb => (
                  <SortTable.Row key={ cb.key }>
                    <SortTable.Cell>{ cb.displayDate }</SortTable.Cell>
                    <SortTable.Cell>{ cb.displayDueDate || '' }</SortTable.Cell>
                    <SortTable.Cell>{ cb.description }</SortTable.Cell>
                    <SortTable.Cell>{ cb.status }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                      { cb.status === 'Voting' &&
                        <ApprovalButton
                          onApproval={ this.handleApprove.bind(this, cb) }
                          onRejection={ this.handleReject.bind(this, cb) }
                        />
                      }
                      { cb.status === 'Approved' &&
                        <Icon name='clipboard check' size='large' color='green' />
                      }
                      { cb.status === 'Rejected' &&
                        <Icon name='clipboard' size='large' color='red' />
                      }
                      <EditButton view={ cb.status === 'Approved' || cb.status === 'Rejected' }
                        pathname={ `/${ PATH_ADMIN }/${ PATH_CUSTOM_BALLOTS }/${ cb.cbid }/${ cb.timestamp }` }
                      />
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.state.editing &&
              <RejectReasonEditDialog open={ !!this.state.editing } onSave={ this.saveRejectReason } onClose={ this.closeDialog } />
            }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    adminCBs: storeState.models.adminCBs,
    ui: storeState.ui.adminCBs,
  };
}

export default connect(mapStoreStateToProps)(CustomBallots);
