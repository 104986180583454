import React from 'react';
import { connect } from 'react-redux';

import { Grid, Segment, Header, Form, Message, Checkbox } from 'semantic-ui-react';

import { DATE_YEAR } from '../../js/constants';

import { postTrainingAgencyDeclaration, getTrainingAgency } from '../../api/trainingAgencyAPI';

import EditView from '../EditView';

import ViewLoader from '../../components/ViewLoader';
import DateControl from '../../components/DateControl';
import ExternalButton from '../../components/ExternalButton';
import { EXTERNAL_PATH_TRG1, EXTERNAL_PATH_TRG2 } from '../../js/paths';

const INITIAL_STATE = {
  checked: false,
  lastdeclarationyear: new Date().getFullYear().toString(),

  loading: false,
  dimissAlert: false,
  errors: {
    error: false
  }
};

class TrainingAgencyAnnualDeclaration extends EditView {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount = () => {
    this.initialize();
    this.isClean();
  }

  initialize = async () => {
    this.setState({ loading: 'Loading' });
    await getTrainingAgency(this.props.currentUser.orgId);
    this.setState({ loading: false });
  }

  onChange = () => {
    this.isDirty();
    this.validate();
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value }, () => { this.onChange(); });
  }

  handleCheckboxChange = () => {
    this.setState({ checked: !this.state.checked });
  }

  handleDateChange = (name, value) => {
    this.setState({ [name]: value }, () => { this.onChange(); });
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    this.setState({ errors: errors });

    return !errors.error;
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.isValid()) {
      const { loading, errors, ...data } = this.state;
      this.create(data);
    }
  };

  create = async (data) => {
    this.setState({ loading: 'Adding', dimissAlert: false });
    await postTrainingAgencyDeclaration(data.lastdeclarationyear, this.props.currentUser.orgId);
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className='fire-department-trainer'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              let taName = (this.props.trainingAgency.entity_name ? this.props.trainingAgency.entity_name : "[training agency]");

              return (
                <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
                  <Header size='medium'>
                    <Grid columns='equal' verticalAlign='bottom'>
                      <Grid.Column>
                        <Header.Content>Submit Annual Declaration</Header.Content>
                        <Header.Subheader>
                          Review the Standard Operating Procedures Related to this Page: 
                          <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ EXTERNAL_PATH_TRG1 } label="TRG1" />
                          <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ EXTERNAL_PATH_TRG2 } label="TRG2" />
                        </Header.Subheader>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Button disabled={ !this.state.checked } floated='right' className='form-top-button' positive>Submit</Form.Button>
                      </Grid.Column>
                    </Grid>
                  </Header>
                  { this.props.trainingAgencyDeclaration && !this.state.dimissAlert &&
                    <Message floating positive size='small' onDismiss={()=>{ this.setState({ dimissAlert: true }); }}>
                      <Message.Header textalign='center'>Success</Message.Header>
                      <Message.Content>
                        <p>Declaration submitted with success!</p>
                      </Message.Content>
                    </Message>
                  }
                  <Segment.Group size='large' className='view-segment-group'>
                    <Segment>
                        <Form.Group>
                          <Form.Field style={{textAlign: "justify"}}>
                            <Checkbox checked={ this.state.checked } name='checked' onChange={ this.handleCheckboxChange }
                              label={`${taName} ensures that Technical High Angle Rope and Tower Rescue training meets or exceeds current and applicable National Fire Protection Association (NFPA) Standards for training provided to THARRP funded fire departments. By clicking “Submit”, ${taName} certifies that Technical High Angle Rope and Tower Rescue training meets or exceeds current and applicable National Fire Protection Association (NFPA) Standards.`} />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field required={ true }
                            label='Declaration date' name='lastdeclarationyear' width={ 5 } disabled
                            control={ DateControl } date={ this.state.lastdeclarationyear } dateFormat={ DATE_YEAR }
                            onChange={ this.handleDateChange }
                          />
                        </Form.Group>
                      <Message error>
                        { this.state.errors.error }
                      </Message>
                    </Segment>
                  </Segment.Group>
                </Form>
              );
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    trainingAgency: storeState.models.trainingAgency,
    trainingAgencyDeclaration: storeState.models.trainingAgencyDeclaration
  };
}

export default connect(mapStoreStateToProps)(TrainingAgencyAnnualDeclaration);
