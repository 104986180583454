import React from 'react';

import { Icon, Popup } from 'semantic-ui-react';

import PropTypes from 'prop-types';

class IconButton extends React.Component {
  render() {
    const { className, size, color, name, hide, popup, ...props } = this.props;

    let iconSize = {};
    if (size !== 'medium') {
      // A 'medium' size icon has no size prop (so as to not confuse with medium.com brand icon!)
      iconSize.size = size || 'large';
    }

    const icon = (
      <Icon link name={ name } color={ color } { ...iconSize } className={ 'icon-button ' + (className || '') + (hide ? ' hidden' : '') } { ...props }/>
    );

    return popup ? <Popup content={ popup } trigger={ icon }/> : icon;
  }
};

IconButton.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  popup: PropTypes.string,
  hide: PropTypes.bool,
};

export default IconButton;
