// API Requests
export const REQUESTS_BEGIN = 'REQUESTS_BEGIN';
export const REQUESTS_END = 'REQUESTS_END';
export const REQUESTS_ERROR = 'REQUESTS_ERROR';
export const REQUESTS_CLEAR = 'REQUESTS_CLEAR';

// Views
export const VIEW_IS_DIRTY = 'VIEW_IS_DIRTY';
export const VIEW_IS_CLEAN = 'VIEW_IS_CLEAN';

// Auth
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_AUTH_STATE = 'UPDATE_AUTH_STATE';

// Registrations
export const UPDATE_ADMIN_REGISTRATIONS = 'UPDATE_ADMIN_REGISTRATIONS';
export const UPDATE_ADMIN_REGISTRATION = 'UPDATE_ADMIN_REGISTRATION';

// TR's
export const UPDATE_TRS = 'UPDATE_TRS';
export const UPDATE_TR = 'UPDATE_TR';

// WB's
export const UPDATE_WBS = 'UPDATE_WBS';
export const UPDATE_WB = 'UPDATE_WB';

// CB's
export const UPDATE_CBS = 'UPDATE_CBS';
export const UPDATE_CB = 'UPDATE_CB';

// PPEs
export const UPDATE_PPES = 'UPDATE_PPES';
export const UPDATE_PPE = 'UPDATE_PPE';

// Users
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USERS_TA = 'UPDATE_USERS_TA';
export const UPDATE_USER_TA = 'UPDATE_USER_TA';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';

// Construction Company
export const UPDATE_CONSTRUCTION_COMPANY = 'UPDATE_CONSTRUCTION_COMPANY';

// Construction Surveys
export const UPDATE_CONSTRUCTION_SURVEYS = 'UPDATE_CONSTRUCTION_SURVEYS';
export const ADD_CONSTRUCTION_SURVEY = 'ADD_CONSTRUCTION_SURVEY';
export const UPDATE_CONSTRUCTION_SURVEY = 'UPDATE_CONSTRUCTION_SURVEY';
export const DELETE_CONSTRUCTION_SURVEY = 'DELETE_CONSTRUCTION_SURVEY';

// Cost Centers
export const UPDATE_COST_CENTERS = 'UPDATE_COST_CENTERS';
export const ADD_COST_CENTER = 'ADD_COST_CENTER';
export const UPDATE_COST_CENTER = 'UPDATE_COST_CENTER';
export const DELETE_COST_CENTER = 'DELETE_COST_CENTER';

// Training Agency
export const UPDATE_TRAINING_AGENCY = 'UPDATE_TRAINING_AGENCY';

// Fire Department
export const UPDATE_FIRE_DEPARTMENT = 'UPDATE_FIRE_DEPARTMENT';

// Fire Department Surveys
export const UPDATE_FIRE_DEPARTMENT_SURVEYS = 'UPDATE_FIRE_DEPARTMENT_SURVEYS';
export const ADD_FIRE_DEPARTMENT_SURVEY = 'ADD_FIRE_DEPARTMENT_SURVEY';
export const UPDATE_FIRE_DEPARTMENT_SURVEY = 'UPDATE_FIRE_DEPARTMENT_SURVEY';
export const DELETE_FIRE_DEPARTMENT_SURVEY = 'DELETE_FIRE_DEPARTMENT_SURVEY';

// Fire Department Trainers
export const UPDATE_FIRE_DEPARTMENT_TRAINERS = 'UPDATE_FIRE_DEPARTMENT_TRAINERS';
export const ADD_FIRE_DEPARTMENT_TRAINER = 'ADD_FIRE_DEPARTMENT_TRAINER';
export const UPDATE_FIRE_DEPARTMENT_TRAINER = 'UPDATE_FIRE_DEPARTMENT_TRAINER';
export const DELETE_FIRE_DEPARTMENT_TRAINER = 'DELETE_FIRE_DEPARTMENT_TRAINER';

export const UPDATE_FIRE_DEPARTMENT_TRAINER_INCREASE = 'UPDATE_FIRE_DEPARTMENT_TRAINER_INCREASE';

export const UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES = 'UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES';
export const UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES_2 = 'UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES_2';
export const UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCY = 'UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCY';
export const DELETE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCY = 'DELETE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCY';

// Fire Department Reimbursements
export const UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS = 'UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS';
export const ADD_FIRE_DEPARTMENT_REIMBURSEMENT = 'ADD_FIRE_DEPARTMENT_REIMBURSEMENT';
export const UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT = 'UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT';
export const DELETE_FIRE_DEPARTMENT_REIMBURSEMENT = 'DELETE_FIRE_DEPARTMENT_REIMBURSEMENT';

// Fire Department Financial Summary
export const UPDATE_FIRE_DEPARTMENT_FINANCIAL_SUMMARIES = 'UPDATE_FIRE_DEPARTMENT_FINANCIAL_SUMMARIES';
export const UPDATE_FIRE_DEPARTMENTS_FINANCIAL_SUMMARIES = 'UPDATE_FIRE_DEPARTMENTS_FINANCIAL_SUMMARIES';

// Fire Department Special Payments
export const UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS = 'UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS';
export const ADD_FIRE_DEPARTMENT_SPECIAL_PAYMENT = 'ADD_FIRE_DEPARTMENT_SPECIAL_PAYMENT';
export const UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT = 'UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT';
export const DELETE_FIRE_DEPARTMENT_SPECIAL_PAYMENT = 'DELETE_FIRE_DEPARTMENT_SPECIAL_PAYMENT';

// Fire Department Work Site Rescues
export const UPDATE_FIRE_DEPARTMENT_WORK_SITE_RESCUES = 'UPDATE_FIRE_DEPARTMENT_WORK_SITE_RESCUES';
export const UPDATE_FIRE_DEPARTMENT_WORK_SITE_RESCUE = 'UPDATE_FIRE_DEPARTMENT_WORK_SITE_RESCUE';
export const UPDATE_ADMIN_FIRE_DEPARTMENT_WORK_SITE_RESCUES = 'UPDATE_ADMIN_FIRE_DEPARTMENT_WORK_SITE_RESCUES';

// Fire Department Annual Submission
export const UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSIONS = 'UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSIONS';
export const UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSION = 'UPDATE_FIRE_DEPARTMENT_ANNUAL_SUBMISSION';
export const DELETE_FIRE_DEPARTMENT_ANNUAL_SUBMISSION = 'DELETE_FIRE_DEPARTMENT_ANNUAL_SUBMISSION';

// Joint Training
export const UPDATE_JOINT_TRAININGS = 'UPDATE_JOINT_TRAININGS';
export const UPDATE_JOINT_TRAINING = 'UPDATE_JOINT_TRAINING';

// Committee
export const UPDATE_ADMIN_COMMITTEE = 'UPDATE_ADMIN_COMMITTEE';

// TA Reimbursements
export const UPDATE_TRAINING_AGENCY_REIMBURSEMENTS = 'UPDATE_TRAINING_AGENCY_REIMBURSEMENTS';
export const ADD_TRAINING_AGENCY_REIMBURSEMENT = 'ADD_TRAINING_AGENCY_REIMBURSEMENT';
export const UPDATE_TRAINING_AGENCY_REIMBURSEMENT = 'UPDATE_TRAINING_AGENCY_REIMBURSEMENT';
export const DELETE_TRAINING_AGENCY_REIMBURSEMENT = 'DELETE_TRAINING_AGENCY_REIMBURSEMENT';

// UI
export const UPDATE_SIDEBAR_UI = 'UPDATE_SIDEBAR_UI';

export const UPDATE_COST_CENTERS_UI = 'UPDATE_COST_CENTERS_UI';
export const UPDATE_CONSTRUCTION_SURVEYS_UI = 'UPDATE_CONSTRUCTION_SURVEYS_UI';
export const UPDATE_FIRE_DEPARTMENT_SURVEYS_UI = 'UPDATE_FIRE_DEPARTMENT_SURVEYS_UI';
export const UPDATE_FIRE_DEPARTMENT_TRAINERS_UI = 'UPDATE_FIRE_DEPARTMENT_TRAINERS_UI';
export const UPDATE_FIRE_DEPARTMENT_TRAINERS_EQUIVALENCY_UI = 'UPDATE_FIRE_DEPARTMENT_TRAINERS_EQUIVALENCY_UI';
export const UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES_UI = 'UPDATE_FIRE_DEPARTMENT_TRAINER_EQUIVALENCIES_UI';
export const UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS_UI = 'UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS_UI';
export const UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT_UI = 'UPDATE_FIRE_DEPARTMENT_REIMBURSEMENT_UI';
export const UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS_UI = 'UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENTS_UI';
export const UPDATE_FIRE_DEPARTMENT_RESCUE_SERVICES_UI = 'UPDATE_FIRE_DEPARTMENT_RESCUE_SERVICES_UI';
export const UPDATE_PPES_UI = 'UPDATE_PPES_UI';
export const UPDATE_PPE_UI = 'UPDATE_PPE_UI';
export const UPDATE_TRS_UI = 'UPDATE_TRS_UI';
export const UPDATE_TR_UI = 'UPDATE_TR_UI';
export const UPDATE_TUS_UI = 'UPDATE_TUS_UI';
export const UPDATE_TU_UI = 'UPDATE_TU_UI';
export const UPDATE_CBS_UI = 'UPDATE_CBS_UI';
export const UPDATE_CB_UI = 'UPDATE_CB_UI';
export const UPDATE_ADMIN_ANNUAL_SUBMISSIONS_UI = 'UPDATE_ADMIN_ANNUAL_SUBMISSIONS_UI';
export const UPDATE_SPECIAL_PAYMENT_UI = 'UPDATE_SPECIAL_PAYMENT_UI';
export const UPDATE_JOINT_TRAINING_UI = 'UPDATE_JOINT_TRAINING_UI';
export const UPDATE_ADMIN_COMMITTEE_UI = 'UPDATE_ADMIN_COMMITTEE_UI';
export const UPDATE_TRAINING_AGENCY_REIMBURSEMENTS_UI = 'UPDATE_TRAINING_AGENCY_REIMBURSEMENTS_UI';
export const UPDATE_TRAINING_AGENCY_REIMBURSEMENT_UI = 'UPDATE_TRAINING_AGENCY_REIMBURSEMENT_UI';
export const UPDATE_ADMIN_JOINT_TRAINING_UI = 'UPDATE_ADMIN_JOINT_TRAINING_UI';
export const UPDATE_ADMIN_REGISTRATIONS_FD_UI = 'UPDATE_ADMIN_REGISTRATIONS_FD_UI';
export const UPDATE_ADMIN_REGISTRATIONS_TA_UI = 'UPDATE_ADMIN_REGISTRATIONS_TA_UI';
export const UPDATE_ADMIN_REGISTRATION_FD_UI = 'UPDATE_ADMIN_REGISTRATION_FD_UI';
export const UPDATE_ADMIN_REGISTRATION_TA_UI = 'UPDATE_ADMIN_REGISTRATION_TA_UI';
export const UPDATE_ADMIN_REPORT_CBS_UI = 'UPDATE_ADMIN_REPORT_CBS_UI';
export const UPDATE_ADMIN_REPORT_FDRESCUES_UI = 'UPDATE_ADMIN_REPORT_FDRESCUES_UI';
export const UPDATE_ADMIN_REPORT_FDFINANCIAL_UI = 'UPDATE_ADMIN_REPORT_FDFINANCIAL_UI';
export const UPDATE_ADMIN_REPORT_FDFINANCIALDETAILS_UI = 'UPDATE_ADMIN_REPORT_FDFINANCIALDETAILS_UI';
export const UPDATE_ADMIN_REPORT_TAS_UI = 'UPDATE_ADMIN_REPORT_TAS_UI';
export const UPDATE_ADMIN_REPORT_FDS_UI = 'UPDATE_ADMIN_REPORT_FDS_UI';
export const UPDATE_ADMIN_REPORT_FD_UI = 'UPDATE_ADMIN_REPORT_FD_UI';
export const UPDATE_ADMIN_REPORT_FDTR_UI = 'UPDATE_ADMIN_REPORT_FDTR_UI';

// Lookups
export const UPDATE_FIRE_DEPARTMENTS_LOOKUP = 'UPDATE_FIRE_DEPARTMENTS_LOOKUP';
export const UPDATE_TRAINING_AGENCIES_LOOKUP = 'UPDATE_TRAINING_AGENCIES_LOOKUP';
export const UPDATE_CONSTRUCTION_COMPANIES_LOOKUP = 'UPDATE_CONSTRUCTION_COMPANIES_LOOKUP';
export const UPDATE_SETTINGS_LOOKUP = 'UPDATE_SETTINGS_LOOKUP';
export const TRAINING_AGENCIES_DECLARATION = 'TRAINING_AGENCIES_DECLARATION';

// Organization module integration
export const ORG_MODULE_WORKSAFEID_VALIDATION = 'ORG_MODULE_WORKSAFEID_VALIDATION';
export const ORG_MODULE_ORGS_SEARCH = 'ORG_MODULE_ORGS_SEARCH';