import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Form, Segment, Button } from 'semantic-ui-react';

import { getAdminPPE } from '../../api/administratorAPI';
import { getFireDepartmentTrainer } from '../../api/fireDepartmentAPI';

import { UPDATE_PPE_UI } from '../../js/actionTypes';
import { PATH_TIMESTAMP, PATH_ID, PATH_ADMIN, PATH_PPE } from '../../js/paths';
import { DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';

import { formatDateTime } from '../../utils/date';

import ViewLoader from '../../components/ViewLoader';

import EditView from '../EditView';

class AdminPPEDetails extends EditView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getAdminPPE(null, this.props.match.params[PATH_TIMESTAMP], this.props.match.params[PATH_ID]);
    await getFireDepartmentTrainer(this.props.adminPPE.trainerid, null, this.props.match.params[PATH_ID]);
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_PPE_UI });
    });
  }


  //back action
  goToList = () => {
    this.isClean();
    this.props.history.push(`/${ PATH_ADMIN }/${ PATH_PPE }`);
  }
  cancel = event => {
    event.preventDefault();
    // Back to the list
    this.goToList();
  }

  render() {
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 20, paddingTop: 30}} width={ 15 }>
            <Header size='medium'>
              <Header.Content>Personal Protective Equipment</Header.Content>
              <Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Button>
            </Header>
          </Grid.Column>
          <Grid.Column width={ 15 } style={{paddingTop: 0}}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              console.log("PPE:", this.props.adminPPE)
              console.log("PPE-Trainer:", this.props.trainer);

              if (! this.props.adminPPE) {
                return <Message info size='large'>
                  Personal protection equipment request is not available!
                </Message>;
              }

              let ppe = this.props.adminPPE;
              let trainer = this.props.trainer;

              return <Form>
                      <Segment.Group size='large' className='view-segment-group'>
                        <Segment>
                          <Form.Group>
                            <Form.Input readOnly label='Fire Department Name' value={ ppe.fdName } width={ 12 } />
                            <Form.Input readOnly label='Request Date' value={ ppe.displayDate } width={ 4 } />
                          </Form.Group>
                          <Form.Group>
                            <Form.Input readOnly label='Instructor name' value={ trainer.name } width={ 6 } />
                            <Form.Input readOnly label='Registration date' value={ formatDateTime(trainer.currenttypedate, DATE_FULL_MONTH_DAY_YEAR) } width={ 4 } />
                          </Form.Group>
                        </Segment>
                      </Segment.Group>
                    </Form>;
                })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    adminPPE: storeState.models.adminPPE,
    trainer: storeState.models.fireDepartmentTrainer
  };
}

export default connect(mapStoreStateToProps)(AdminPPEDetails);
