import React from 'react';

import { Segment, Header, Message } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import AddButton from './AddButton';
import CurrencyText from './CurrencyText';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import SortTable from './SortTable';

import { MILEAGE_TYPES } from '../js/lists';

import { sort } from '../utils/utils';

class MileageSegment extends React.Component {
  updateUIState = (uiState) => {
    this.props.onUIStateChange({ mileage: uiState });
  }

  render = () => {
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Mileage Expenses</Header.Content>
          <AddButton hide={ this.props.disabled || this.props.mileage.length > 0 } onClick={ this.props.edit.bind(this, 'mileage', {}) }/>
        </Header>

        {(() => {
          if (this.props.mileage.length === 0) {
            return <Message info compact size='tiny'>No mileage expenses</Message>;
          }

          let mileages = this.props.mileage.map(m => {
            let mileage = { ...m };
            mileage.displayType = MILEAGE_TYPES[mileage.type];
            return mileage;
          });

          mileages = sort(mileages, this.props.ui.sortField);
          if (this.props.ui.sortDescending) {
            mileages.reverse();
          }

          const right = { textAlign: 'right' };

          return <SortTable collapsing sortField={ this.props.ui.sortField } sortDescending={ this.props.ui.sortDescending }
            onSort={ this.updateUIState } size='small'
            headers={[
              { field: 'displayType',           title: 'Type',           width: '4'               },
              { field: 'kms',                   title: 'Distance (Kms)', width: '2', style: right },
              { field: 'buttons', noSort: true, title: '',               width: '2'               },
            ]}
            footers={[
              [ <div style={ right }><strong>Total:</strong></div>, <CurrencyText value={ this.props.totalmileageamount } style={ right }/>, null ]
            ]}
          >
            { mileages.map((mileage, index) => (
              <SortTable.Row key={ index } id={ `mileage_${ mileage.timestamp }`}>
                <SortTable.Cell>{ mileage.displayType }</SortTable.Cell>
                <SortTable.Cell textAlign='right'>{ mileage.kms }</SortTable.Cell>
                <SortTable.Cell textAlign='right'>
                  <EditButton view={ this.props.disabled } onClick={ this.props.edit.bind(this, 'mileage', mileage) }/>
                  <DeleteButton hide={ this.props.disabled } content='Delete the mileage expense?' onConfirm={ this.props.remove.bind(this, 'mileage', mileage) }/>
                </SortTable.Cell>
              </SortTable.Row>
            ))}
          </SortTable>;
        })()}
      </Segment>
    );
  }
};

MileageSegment.propTypes = {
  mileage: PropTypes.array.isRequired,
  totalmileageamount: PropTypes.number.isRequired,
  ui: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onUIStateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MileageSegment;
