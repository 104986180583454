import React from 'react';

import { Form } from 'semantic-ui-react';

import PropTypes from 'prop-types';

class AddressInput extends React.Component {
  render = () => {
    return (
      <React.Fragment>
        <Form.Group>
          <Form.Input readOnly={ this.props.readOnly } required={ !this.props.readOnly }
            label='Address Number' value={ this.props.address.number } name='number' width={ 4 }
            onChange={ this.props.onChange }
          />
          <Form.Input readOnly={ this.props.readOnly } required={ !this.props.readOnly }
            label='Street' value={ this.props.address.street } name='street' width={ 8 }
            autoComplete='street-address'
            onChange={ this.props.onChange }
          />
          { !this.props.hideCompliment &&
            <Form.Input readOnly={ this.props.readOnly }
              label='Suite' value={ this.props.address.compliment } name='compliment' width={ 4 }
              onChange={ this.props.onChange }
            />
          }
        </Form.Group>

        <Form.Group>
          <Form.Input readOnly={ this.props.readOnly } required={ !this.props.readOnly }
            label='City' value={ this.props.address.city } name='city' width={ 8 }
            autoComplete='locality'
            onChange={ this.props.onChange }
          />
          <Form.Input readOnly={ this.props.readOnly } required={ !this.props.readOnly }
            label='Province' value={ this.props.address.province } name='province' width={ 4 }
            autoComplete='region'
            onChange={ this.props.onChange }
          />
          <Form.Input readOnly={ this.props.readOnly } required={ !this.props.readOnly }
            label='Postal Code' value={ this.props.address.postalcode } name='postalcode' width={ 4 }
            autoComplete='postal-code'
            onChange={ this.props.onChange }
          />
        </Form.Group>
      </React.Fragment>
    );
  }
};

AddressInput.propTypes = {
  address: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  hideCompliment: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default AddressInput;
