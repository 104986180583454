import React from 'react';

import PropTypes from 'prop-types';

import NumberInput from './NumberInput';

class CurrencyInput extends React.Component {
  render = () => {
    return (
      <NumberInput className='number-input currency-input' prefix={ '$' } decimalScale={ 2 } fixedDecimalScale={ true } { ...this.props } />
    );
  }
};

CurrencyInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CurrencyInput;
