import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form, Message, Grid } from 'semantic-ui-react';

import { getAdminWB, updateAdminWB, updateAdminSpecialPayment } from '../../api/administratorAPI';
import { getFireDepartmentSpecialPayment, postFireDepartmentSpecialPayment, updateFireDepartmentSpecialPayment } from '../../api/fireDepartmentAPI';

import { UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT, UPDATE_SPECIAL_PAYMENT_UI } from '../../js/actionTypes';
import { ROLE_ADMIN, DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { PATH_FIRE_DEPARTMENT, PATH_ADMIN, PATH_REIMBURSEMENTS, PATH_SPECIAL_PAYMENTS, PATH_NEW, PATH_ID, PATH_TIMESTAMP, EXTERNAL_PATH_FIN1 } from '../../js/paths';

import { today } from '../../utils/date';
import ChosenFile from '../../utils/file';

import BallotSegment from '../../components/BallotSegment';
import CurrencyInput from '../../components/CurrencyInput';
import DateControl from '../../components/DateControl';
import FileChooserSegment from '../../components/FileChooserSegment';
import TaxCalculator from '../../components/TaxCalculator';
import ExternalButton from '../../components/ExternalButton';

import BallotEditDialog from '../../dialogs/BallotEditDialog';

import EditView from '../EditView';

const INITIAL_STATE = {
  status: 'Pending',
  date: today(),
  description: '',
  amount: 0,
  amountaftertax: 0,
  gstamount: 0,
  pstamount: 0,
  payableto: '',
  receipts: [],

  // UI

  files: [],
  isReadOnly: false,

  ballots: [],

  editing: {},

  className: 'fire-department-special-payment',
  title: 'Special Equipment Request',
};

class FireDepartmentSpecialPayment extends EditView {
  constructor(props) {
    super(props);

    this.state = { ...this.state, ...INITIAL_STATE };

    this.state.isNew = (props.match.params[PATH_TIMESTAMP] === PATH_NEW);
    this.state.isAdmin = this.props.currentUser.role === ROLE_ADMIN;

    if (this.state.isNew) {
      let payableto;
      if (this.props.currentUser.fd) {
        payableto = this.props.currentUser.fd.payable_to;
      }
      this.state.payableto = payableto || '';
    }

    this.state.ui = {
      files: {
        sortField: this.props.ui.files.sortField || 'type',
        sortDescending: this.props.ui.files.sortDescending === true,
      },
      ballots: {
        sortField: this.props.ui.ballots.sortField || 'voteoption',
        sortDescending: this.props.ui.ballots.sortDescending === true,
      },
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFilesChange = this.handleFilesChange.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleBallotsUIStateChange = this.handleBallotsUIStateChange.bind(this);
    this.handleFilesUIStateChange = this.handleFilesUIStateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
  }

  fetch = async () => {
    await getFireDepartmentSpecialPayment(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
    if (this.state.isAdmin && this.props.specialPayment.status !== 'Pending') {
      await getAdminWB('FP', this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
    }
  }

  onNew = () => {
    this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_SPECIAL_PAYMENT, fireDepartmentSpecialPayment: INITIAL_STATE });
    this.state.files.length = 0;
  }

  onLoad = () => {
    this.setState({
      ...this.props.specialPayment,
      exitPage: this.state.isAdmin ? `/${ PATH_ADMIN }/${ PATH_SPECIAL_PAYMENTS }` : `/${ PATH_FIRE_DEPARTMENT }/${ PATH_REIMBURSEMENTS }/${ PATH_SPECIAL_PAYMENTS }`,
      isReadOnly: this.state.isAdmin || this.props.specialPayment.status !== 'Pending',
    });

    this.state.files.length = 0;
    this.state.receipts.forEach((receipt) => {
      let file = new ChosenFile();
      file.parse(receipt);
      if (file.id) {
        this.state.files.push(file);
      }
    });

    if (this.state.isAdmin && this.props.specialPayment.status !== 'Pending') {
      this.setState({ ballots: this.props.adminWB.ballots || [] });
    }
  }

  handleCurrencyChange = (name, value) => {
    const stateUpdate = { [name]: value };
    this.setState(stateUpdate, () => {
      this.updateTotals();
      this.onChange();
    });
  }

  handleTaxChange = (name, value) => {
    const stateUpdate = { [name]: value };
    this.setState(stateUpdate, () => {
      this.updateTotals();
      this.onChange();
    });
  }

  updateTotals = () => {
    // Calculate all the totals
    const pstamount = this.state.pstamount;
    const gstamount = this.state.gstamount;

    const amountaftertax = this.state.amount + pstamount + gstamount;

    this.setState({
      amountaftertax: amountaftertax
    });
  }

  handleFilesChange = (state) => {
    let newState = { ...state };

    if (newState.files) {
      newState.receipts = newState.files.map((file) => { return file.stringify(); });
    }

    this.setState(newState, () => { this.onChange() });
  }

  handleFilesUIStateChange(uiState) {
    this.setState({ ui: { ...this.state.ui, files: uiState }}, () => {
      this.dispatch({ type: UPDATE_SPECIAL_PAYMENT_UI, specialPayment: this.state.ui });
    });
  }

  handleBallotsUIStateChange(uiComponent) {
    this.setState({ ui: { ...this.state.ui, ...uiComponent }}, () => {
      this.dispatch({ type: UPDATE_SPECIAL_PAYMENT_UI, specialPayment: this.state.ui });
    });
  }

  startEditing = (componentName, entry) => {
    let editing = {};
    editing[componentName] = entry || {};
    this.setState({ editing: editing });
  }

  stopEditing = () => {
    this.setState({ editing: {}});
  }

  handleComponentRemove = (componentName, entry) => {
    const ballots = this.state.ballots.filter(item => {
      return entry.memberemail !== item.memberemail
    });

    this.setState({ ballots: ballots }, () => { this.onChange() });
  }

  handleComponentEdit = (componentName, entry) => {
    this.stopEditing();

    let ballots = this.state.ballots.filter(item => {
      return entry.memberemail !== item.memberemail
    });
    ballots.push(entry);

    this.setState({ ballots: ballots }, () => { this.onChange() });
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.description) {
      errors.error = true;
      errors.description = 'Please enter a description.';
    }

    if (!this.state.date) {
      errors.error = true;
      errors.date = 'Please choose a date. ';
    }

    if (!this.state.amount) {
      errors.error = true;
      errors.amount = 'Please enter an amount. ';
    }

    if (!this.state.amountaftertax) {
      errors.error = true;
      errors.amountaftertax = 'Please enter an amount after tax. ';
    }

    if (!this.state.payableto) {
      errors.error = true;
      errors.payableto = 'Please enter payable to.';
    }

    if (this.state.files.length === 0) {
      errors.error = true;
      errors.files = 'Please attach supporting receipts.';
    }

    this.setState({ errors: errors });

    return !errors.error;
  }

  deleteFiles = async (toDelete) => {
    let success = true;

    for (const deleteFile of toDelete) {
      if (!success) return;

      this.setState({
        progressPercent: 50,
        progressText: deleteFile.name
      });

      success = await deleteFile.delete();

      if (!success) {
        let errors = { error: true };
        errors.files = `Failed to remove ${ deleteFile.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      } else {
        this.setState({
          progressPercent: 100,
        });
      }
    }

    return success;
  }

  uploadFiles = async (toUpload) => {
    let success = true;

    for (const uploadFile of toUpload) {
      if (!success) return;

      success = await uploadFile.upload({
        onUploadProgress: (percent) => {
          this.setState({
            progressPercent: percent,
            progressText: uploadFile.name
          });
        }
      });

      if (!success) {
        let errors = { error: true };
        errors.files = `Failed to upload ${ uploadFile.name }`;

        this.setState({
          errors: errors,
          progressPercent: 0,
        });
      }
    }

    return success;
  }

  updateFiles = async (data) => {
    let toDelete = [];
    let toUpload = [];

    ChosenFile.syncFiles(this.props.specialPayment.receipts || [], data.files, toDelete, toUpload);

    let success = await this.deleteFiles(toDelete);

    if (success) {
      success = await this.uploadFiles(toUpload);

      // Update the uploaded state
      data.receipts = data.files.map((file) => { return file.stringify(); });
      this.setState({
        receipts: data.receipts
      });
    }

    return success;
  }

  create = async (data) => {
    this.setState({ loading: 'Syncing Files' });

    const success = await this.updateFiles(data);

    if (success) {
      this.setState({ loading: 'Submitting' });
      await postFireDepartmentSpecialPayment(data);
      if (this.props.specialPayment.id) {
        this.exit();
      }
    }

    this.setState({ loading: false });
  }

  update = async (data) => {
    if (this.state.isAdmin) {
      this.setState({ loading: 'Updating' });

      const { ballots, spBody } = data;
      await updateAdminSpecialPayment(spBody);

      const wbBody = { ...this.props.adminWB, ballots: ballots };
      await updateAdminWB(wbBody);

      this.exit();
    } else {
      this.setState({ loading: 'Syncing Files' });

      const success = await this.updateFiles(data);

      if (success) {
        this.setState({ loading: 'Updating' });
        await updateFireDepartmentSpecialPayment(data);
        this.exit();
      }
    }

    this.setState({ loading: false });
  }

  renderView = () => {
    const canEdit = !this.state.isAdmin || this.state.status === 'Pending' || this.state.status === 'Voting';

    return (
      <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
        <Header size='medium'>
          <Grid columns='equal' verticalAlign='bottom'>
            <Grid.Column>
              <Header.Content>{ this.state.title }</Header.Content>
              <Header.Subheader>
                Review the Standard Operating Procedure(s) Related to this page
                <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ EXTERNAL_PATH_FIN1 } label="FIN1" />
              </Header.Subheader>
            </Grid.Column>
            <Grid.Column>
              { canEdit &&
              <Form.Button floated='right' className='form-top-button' positive>{ this.state.isNew ? 'Submit' : 'Update' } Request</Form.Button>
              }
              <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>{ canEdit ? 'Cancel' : 'Close' }</Form.Button>
            </Grid.Column>
          </Grid>
        </Header>
        <Segment.Group size='large' className='view-segment-group'>
          { this.state.isAdmin && this.state.status !== 'Pending' &&
            <BallotSegment disabled={ !canEdit } ballots={ this.state.ballots }
              ui={ this.state.ui.ballots } onUIStateChange={ this.handleBallotsUIStateChange }
              edit={ this.startEditing } remove={ this.handleComponentRemove }
            />
          }
          <Segment disabled={ this.state.isReadOnly }>
            <Form.Group>
              <Form.Field readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 3 }
                label='Date' name='date'
                control={ DateControl } date={ this.state.date } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                error={ !!this.state.errors.date }
                onChange={ this.handleDateChange }
              />
              <Form.Input readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 13 }
                label='Description' value={ this.state.description } name='description'
                error={ !!this.state.errors.description }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.date }{ this.state.errors.description }
            </Message>
            <Form.Group>
              <Form.Field readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 2 }
                label='Amount' value={ this.state.amount } name='amount'
                error={ !!this.state.errors.amount }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 2 }
                value={ this.state.gstamount } name='gstamount'
                label={ <TaxCalculator disabled={ this.state.isReadOnly } label='GST' type='gst' name='gstamount' amount={ this.state.amount } onCalculate={ this.handleTaxChange }/> }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 2 }
                value={ this.state.pstamount } name='pstamount'
                label={ <TaxCalculator disabled={ this.state.isReadOnly } label='PST' type='pst' name='pstamount' amount={ this.state.amount } onCalculate={ this.handleTaxChange }/> }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 2 }
                label='Amount After Tax' value={ this.state.amountaftertax } name='amountaftertax'
                error={ !!this.state.errors.amountaftertax }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Input readOnly={ !canEdit } required={ canEdit } width={ 8 }
                label='Payable To' value={ this.state.payableto } name='payableto'
                error={ !!this.state.errors.payableto }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.date }{ this.state.errors.amount }{ this.state.errors.amountaftertax }{ this.state.errors.payableto }
            </Message>
            <Form.Group>
              <Form.Field readOnly={ this.state.isReadOnly } required={ !this.state.isReadOnly } width={ 16 }
                control={ FileChooserSegment } label='Receipts' name='files' files={ this.state.files } multiple
                ui={ this.state.ui.files } onUIStateChange={ this.handleFilesUIStateChange }
                error={ !!this.state.errors.files }
                onChange={ this.handleFilesChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.files }
            </Message>
          </Segment>
        </Segment.Group>
      </Form>
    );
  }

  renderAfterView = () => {
    if (this.state.editing.ballots) {
      return (
        <BallotEditDialog open={ !!this.state.editing.ballots } ballot={ this.state.editing.ballots }
          onSave={ this.handleComponentEdit } onClose={ this.stopEditing }
        />
      );
    }

    return null;
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    specialPayment: storeState.models.fireDepartmentSpecialPayment,
    adminWB: storeState.models.adminWB,
    ui: storeState.ui.specialPayment,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentSpecialPayment);
