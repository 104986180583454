import React from 'react';
import { Auth } from 'aws-amplify';

import { Grid, Segment, Header, Form, Message } from 'semantic-ui-react';

import { handleAPIError } from '../../api/api';

import store from '../../js/store';

import * as Constant from '../../js/constants';

import { REQUESTS_CLEAR } from '../../js/actionTypes';

const INITIAL_STATE = {
  email: '',
  error: {},
  isLoading: false
};

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  changeAuthState = (type, event) => {
    this.setState({ error: {} });
    this.props.onStateChange(type, event);
  };

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    event.preventDefault();
    store.dispatch({ type: REQUESTS_CLEAR });
    this.setState({ error: {}, isLoading: true });

    const email = this.state.email.replace(' ','').toLowerCase().trim();

    Auth.forgotPassword(email)
      .then(resp => {

        if ( resp.CodeDeliveryDetails && resp.CodeDeliveryDetails.DeliveryMedium === 'EMAIL') {
          this.changeAuthState(Constant.AUTH_CHANGE_PASS, { username: email });
          this.setState(() => ({ ...INITIAL_STATE }));
        } else {
          this.setState(() => ({ isLoading: false }));
        }
      })
      .catch(error => {
        if (error.code === 'UserNotConfirmedException') {
          this.changeAuthState(Constant.AUTH_CONFIRM_SIGN_UP, { username: this.state.email });
        } else if (error.code === 'PasswordResetRequiredException') {
          this.changeAuthState(Constant.AUTH_REQUIRE_NEW_PASSWORD, { username: this.state.email });
        }

        this.setState({ error: handleAPIError(error), isLoading: false });
      });
  }

  render() {
    if (this.props.authState !== Constant.AUTH_FORGOT_PASS) return null;

    return (
      <div className='auth-container signin'>
        <Grid centered className='auth-grid'>
          <Grid.Column className='auth-column'>
            <Header size='medium'>Reset your password
              <Header.Subheader>
                Remembered your password?&nbsp;&nbsp;<a href='#signin' onClick={() => this.changeAuthState(Constant.AUTH_SIGN_IN)}>Sign In</a>
              </Header.Subheader>
            </Header>
            <Form onSubmit={ this.handleSubmit } error={ (this.state.error.message ? true : false) }>
              <Segment size='large' secondary>
                <Form.Input label='Email Address' value={ this.state.email } name='email' placeholder='Email Address'
                  required autoComplete='email' onChange={ this.handleChange }
                />
              <Form.Button positive loading={ this.state.isLoading } className='centered'>Reset Password</Form.Button>
                <Message error className='centered' negative size='small'>
                  { this.state.error.message }
                </Message>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default ForgotPassword;
