import React from 'react';

import { VIEW_IS_CLEAN, VIEW_IS_DIRTY } from '../js/actionTypes';
import store from '../js/store';

class ListView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      className: 'list-view',
      title: '',

      loading: false,
    };
  }

  dispatch = (action) => { return store.dispatch(action); }

  isClean = () => { return store.dispatch({ type: VIEW_IS_CLEAN }); }

  isDirty = () => { return store.dispatch({ type: VIEW_IS_DIRTY }); }

  setTitle = () => {
    let title = 'Tharrp';
    if (this.state.title) {
      title += `: ${ this.state.title }`;
    }
    document.title = title;
  }
}

ListView.prototype.componentDidMount = function() {
  this.initialize();
  this.setTitle();
  this.isClean();
}

ListView.prototype.initialize = async function() {
  this.setState({ loading: 'Loading' });
  await this.fetch();
  this.onLoad();
  this.setState({ loading: false });
}

ListView.prototype.fetch = async function() {}

ListView.prototype.onLoad = function() {}

export default ListView;
