import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SidebarMenu from '../../components/SidebarMenu';

import TrainingAgencyProfile from './TrainingAgencyProfile';
import TrainingAgencyAnnualDeclaration from './TrainingAgencyAnnualDeclaration';
import TrainingAgencyTowerCraneReimbursements from './TrainingAgencyTowerCraneReimbursements';
import TrainingAgencyTowerCraneReimbursement from './TrainingAgencyTowerCraneReimbursement';

import { PATH_TRAINING_AGENCY, PATH_REIMBURSEMENTS, PATH_PROFILE, PATH_TIMESTAMP, PATH_ID, PATH_DECLARATION } from '../../js/paths';

class TrainingAgency extends React.Component {
  render() {
    const towerCraneReimbursementsPath = `/${ PATH_TRAINING_AGENCY }/${ PATH_REIMBURSEMENTS }`;
    const profilePath = `/${ PATH_TRAINING_AGENCY }/${ PATH_PROFILE }`;
    const declarationPath = `/${ PATH_TRAINING_AGENCY }/${ PATH_DECLARATION }`;

    const menus = [{
      header: '',
      items: [
        { name: 'Tower Crane Reimbursement', path: towerCraneReimbursementsPath },
        { name: 'Annual Declaration', path: declarationPath }
      ]
    }, {
      header: 'Account',
      items: [
        { name: 'Profile', path: profilePath }
      ]
    }];

    return (
      <SidebarMenu menus={ menus } location={ this.props.location }>
        <main className='pg-content'>
          <Route render={() => (
            <Switch>
              <Redirect exact from={ `/${ PATH_TRAINING_AGENCY }` } to={ towerCraneReimbursementsPath } />

              <Route exact path={ towerCraneReimbursementsPath } component={ TrainingAgencyTowerCraneReimbursements }/>
              <Route exact path={ `${ towerCraneReimbursementsPath }/:${ PATH_TIMESTAMP }` } component={ TrainingAgencyTowerCraneReimbursement }/>
              <Route exact path={ `${ towerCraneReimbursementsPath }/:${ PATH_TIMESTAMP }/:${ PATH_ID }` } component={ TrainingAgencyTowerCraneReimbursement }/>
              <Route exact path={ declarationPath } component={ TrainingAgencyAnnualDeclaration }/>
              <Route exact path={ profilePath } component={ TrainingAgencyProfile }/>
            </Switch>
          )}/>
        </main>
      </SidebarMenu>
    );
  }
}

export default TrainingAgency;
