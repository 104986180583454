import React from 'react';
import { Auth } from 'aws-amplify';

import { Grid, Form, Message, Modal, Button } from 'semantic-ui-react';

import store from '../../js/store';
import { adminResetUser } from '../../api/userAPI';

import { REQUESTS_BEGIN, REQUESTS_END } from '../../js/actionTypes';
//
export default class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previousPassword: "",
      password: "",
      passwordConfirmation: "",
      error: "",
      isLoading: false,
      show: false
    };
  }
  //Utils
  validateForm() { 
    return this.state.passwordConfirmation.length > 0 && this.state.password.length > 0 && (this.state.previousPassword.length > 0 || this.props.adminFlow); 
  }
  //Actions
  handleShow() {
    this.setState({
      previousPassword: "",
      password: "",
      passwordConfirmation: "",
      error: "",
      isLoading: false,
      show: true
    });
  }
  handleHide() { this.setState({show: false}); }
  handleChange = (event, { name, value }) => { this.setState({ [name]: value, error: null }); }
  handleChangePassword = async event => {
    event.preventDefault();
    //
    if (this.state.passwordConfirmation !== this.state.password) {
      this.setState({error: { message: 'Passwords dont match!' }});
      return;
    }
    this.startLoading();
    //
    if (this.props.adminFlow) {
      const resp = await adminResetUser({ username: this.props.currentUser.contact_email, password: this.state.password });
      console.log(resp);
      if (resp.success) this.handleHide();
    } else {
      await Auth.changePassword(this.props.currentUser.cognitoUser, this.state.previousPassword, this.state.password)
      .then(data => {
        this.state.show = false;
        alert('Success! \nPassword changed with success! New password will take effect on next login.');
      })
      .catch(err => { this.setState({error: {message: err["message"]}}) });
    }
    //
    this.stopLoading();
	}
  handleKeySubmit(e) {
    if (e.keyCode === 13) {
      if (this.validateForm()) this.handleChangePassword(e);
    }
  }
  //Loading
  startLoading() {
    this.setState({ isLoading: true });
    store.dispatch({ type: REQUESTS_BEGIN });
  }
  stopLoading() {
    this.setState({ isLoading: false });
    store.dispatch({ type: REQUESTS_END });
  }
  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Modal size='mini' closeIcon open={this.state.show} onClose={this.handleHide.bind(this)}>
        <Modal.Header>{this.props.adminFlow ? 'Reset password and confirm:' : 'Change password:'}</Modal.Header>
         <Modal.Content>
           {this.state.error && this.state.error.message && <Message error header='Error' content={this.state.error.message} style={{ marginBottom: '40px' }}/>}
           <Grid columns='one' centered>
             <Form onSubmit={this.handleChangePassword.bind(this)} onKeyDown={this.handleKeySubmit.bind(this)} style={{width: '100%'}}>
               {!this.props.adminFlow && <Grid.Row >
                 <Grid.Column>
                   <h5 style={{ textAlign: 'left' }}>Previous password:</h5>
                   <Form.Group>
                     <Form.Input value={this.state.previousPassword} name="previousPassword" placeholder="Previous password" tabIndex={1}
                                 onChange={this.handleChange.bind(this)} type="password" width={16} required autoComplete='current-password'/>
                   </Form.Group>
                  </Grid.Column>
                </Grid.Row>} 
                <Grid.Row>
                 <Grid.Column>
                   <h5 style={{ textAlign: 'left' }}>New password:</h5>
                   <Form.Group>
                     <Form.Input value={this.state.password} name="password" placeholder="Password" tabIndex={2}
                                 onChange={this.handleChange.bind(this)} type="password" width={16} required data-dd-privacy="mask"/>
                   </Form.Group>
                  </Grid.Column>
                </Grid.Row> <Grid.Row>
                  <Grid.Column>
                    <Form.Group>
                      <Form.Input value={this.state.passwordConfirmation} name="passwordConfirmation" placeholder="Password confirmation"
                                  onChange={this.handleChange.bind(this)} type="password" width={16} required tabIndex={3} data-dd-privacy="mask"/>
                    </Form.Group>
                   </Grid.Column>
                 </Grid.Row>
               </Form>
             </Grid>
           </Modal.Content>
           <Modal.Actions>
             <Button positive disabled={!this.validateForm() || isLoading} loading={isLoading}
                     onClick={this.handleChangePassword.bind(this)}>
                    {this.props.adminFlow ? 'Reset user password & confirm' : 'Update password'}
              </Button>
            </Modal.Actions>
          </Modal>
    );
  }
}
