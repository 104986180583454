import React from 'react';

import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import IconButton from './IconButton';

class AddButton extends React.Component {
  render() {
    const { pathname, className, ...props } = this.props;

    const button = (
      <IconButton name='add square' color='blue' className={ 'add-button' + (className || '') } { ...props }/>
    );

    return pathname ? <NavLink to={ pathname }>{ button }</NavLink> : button;
  }
};

AddButton.propTypes = {
  pathname: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default AddButton;
