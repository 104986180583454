import React from 'react';

import { Segment, Header, Message } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import AddButton from './AddButton';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import IconButton from './IconButton';
import SortTable from './SortTable';

import { DATE_SHORT_MONTH_DAY_YEAR } from '../js/constants';
import { REIMBURSEMENT_COURSES } from '../js/lists';

import { formatDateTime, sortableDateTime } from '../utils/date';
import ChosenFile from '../utils/file';
import { sort } from '../utils/utils';

class EquivalencySegment extends React.Component {
  handleIconClick = (file) => {
    if (file.uploaded) {
      this.download(file);
    }
  }

  download = async (file) => {
    const downloadUrl = await file.downloadUrl();

    if (downloadUrl) {
      window.open(downloadUrl);
    }
  }

  updateUIState = (uiState) => {
    this.props.onUIStateChange({ equivalencies: uiState });
  }

  render = () => {
    return (
      <Segment disabled={ this.props.disabled } className='segment-component'>
        <Header size='small'>
          <Header.Content>Prior Training Requests</Header.Content>
          <AddButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'equivalency', {}) }/>
        </Header>

        {(() => {
          if (this.props.courses.length === 0) {
            return <Message info compact size='tiny'>No prior training requests</Message>;
          }

          let courses = this.props.courses.map(c => {
            let course = { ...c };
            course.displayCourse = REIMBURSEMENT_COURSES[course.coursename];
            course.sortableDate = sortableDateTime(course.dateattended);
            course.displayDate = formatDateTime(course.dateattended, DATE_SHORT_MONTH_DAY_YEAR);
            course.file = new ChosenFile();
            course.certName = '';
            if (course.certificate) {
              course.file.parse(course.certificate);
              if (course.file.name) {
                course.certName = course.file.name;
              }
            }
            return course;
          });

          courses = sort(courses, this.props.ui.sortField);
          if (this.props.ui.sortDescending) {
            courses.reverse();
          }

          return <SortTable collapsing sortField={ this.props.ui.sortField } sortDescending={ this.props.ui.sortDescending }
            onSort={ this.updateUIState } size='small'
            headers={[
              { field: 'displayCourse',         title: 'Course',      width: '4' },
              { field: 'sortableDate',          title: 'Attended',    width: '4' },
              { field: 'certName',              title: 'Certificate', width: '6' },
              { field: 'buttons', noSort: true, title: '',            width: '2' },
            ]}
          >
            { courses.map((course, index) => (
              <SortTable.Row key={ index } id={ `course_${ course.coursename }`}>
                <SortTable.Cell>{ course.displayCourse }</SortTable.Cell>
                <SortTable.Cell>{ course.displayDate }</SortTable.Cell>
                <SortTable.Cell>
                  <IconButton hide={ !course.file.uploaded } name='cloud download' color='green' style={{ marginRight: '0.3em' }}
                    onClick={ this.handleIconClick.bind(this, course.file) }
                  />
                  { course.certName }
                </SortTable.Cell>
                <SortTable.Cell textAlign='right'>
                  <EditButton hide={ this.props.disabled } onClick={ this.props.edit.bind(this, 'equivalency', course) }/>
                  <DeleteButton hide={ this.props.disabled } content='Delete the course equivalency request?' onConfirm={ this.props.remove.bind(this, 'equivalency', course) }/>
                </SortTable.Cell>
              </SortTable.Row>
            ))}
          </SortTable>;
        })()}
      </Segment>
    );
  }
};

EquivalencySegment.propTypes = {
  courses: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  onUIStateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default EquivalencySegment;
