import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon } from 'semantic-ui-react';

import { changeFireDepartmentReimbursement } from '../../api/administratorAPI';
import { getAllFireDepartmentReimbursements } from '../../api/fireDepartmentAPI';

import { UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS_UI } from '../../js/actionTypes';
import { PATH_ADMIN, PATH_REIMBURSEMENTS, PATH_TRAINING_EXPENSES, PATH_SUMMARY } from '../../js/paths';

import { sort } from '../../utils/utils';

import ApprovalButton from '../../components/ApprovalButton';
import SortTable from '../../components/SortTable';
import EditButton from '../../components/EditButton';
import ViewButton from '../../components/ViewButton';
import ViewLoader from '../../components/ViewLoader';
import CurrencyText from '../../components/CurrencyText';
import SearchableFilter from '../../components/SearchableFilter';

import RejectReasonEditDialog from '../../dialogs/RejectReasonEditDialog';

import ListView from '../ListView';

class AdminReimbursements extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: this.props.ui.sortField || 'sortSubmissionDate',
        sortDescending: this.props.ui.sortDescending === true,
      },

      editing: null
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    if (this.filterObjYrs !== undefined) this.filterObjYrs.setYears();
    if (this.filterObj !== undefined) this.filterObj.setFilterItems(this.getAvailableStates());
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getAllFireDepartmentReimbursements(this.props.fireDepartments, null, this.filterObjYrs.getSelectedText());
    this.setState({ loading: false });
  }

  update = async (data) => {
    this.setState({ loading: 'Updating' });
    await changeFireDepartmentReimbursement(data);
    this.setState({ loading: false });
  }

  handleApprove = async (id, timestamp) => {
    await this.update({
      id: id,
      timestamp: timestamp,
      status: 'Approved'
    });
    await this.fetch();
  }

  handleReject = (id, timestamp) => {
    this.getRejectReason({
      id: id,
      timestamp: timestamp,
      status: 'Rejected'
    });
  }

  closeDialog = () => { this.setState({ editing: null }); }

  saveRejectReason = async (rejectreason) => {
    let data = { ...this.state.editing };
    data.rejectreason = rejectreason;
    this.closeDialog();
    await this.update(data);
    await this.fetch();
  }

  getRejectReason = (data) => {
    this.setState({ editing: data });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_FIRE_DEPARTMENT_REIMBURSEMENTS_UI, fireDepartmentReimbursements: this.state.ui });
    });
  }

  //Filter support
  handleFilterChange(filter, value) {
    if (filter !== this.filterObj) { this.fetch(); }
    else { this.forceUpdate(); }
  }

  getAvailableStates() {
    return ["All","Pending","Approved","Rejected"];
  }

  render() {
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={6} floated='left'>
            <Header size='medium'>Reimbursements</Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingBottom: 0}} width={8} floated='right'>
            <SearchableFilter ref={(r)=>this.filterObj=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={"Pending"}/>
            <SearchableFilter ref={(r)=>this.filterObjYrs=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={new Date().getFullYear()}/>
          </Grid.Column>
          <Grid.Column width={15}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              console.log("reimbursementList:", this.props.reimbursementList)

              if (!this.props.reimbursementList.length) {
                return <Message info size='large'>
                  You don&apos;t have any reimbursements.
                </Message>;
              }

              let reimbursements = sort(this.props.reimbursementList, this.state.ui.sortField);

              //filter with status
              if (this.filterObj && this.filterObj.getSelectedText() !== "") {
                let nReimbursements = [];
                let filterStatus = this.filterObj.getSelectedText();
                for (var i = 0; i < reimbursements.length; i++) {
                  if (reimbursements[i].status === filterStatus || filterStatus === "All") nReimbursements.push(reimbursements[i]);
                }
                reimbursements = nReimbursements;
              }

              if (this.state.ui.sortDescending) {
                reimbursements.reverse();
              }

              const right = { textAlign: 'right' };

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'sortSubmissionDate',    title: 'Submission Date', width: '2'               },
                { field: 'fdName',                title: 'Fire Department', width: '4'               },
                { field: 'trainerName',           title: 'Trainer',         width: '3'               },
                { field: 'displayCourse',         title: 'Course Name',     width: '2'               },
                { field: 'status',                title: 'Status',          width: '2'               },
                { field: 'requestNumber',         title: 'Number',          width: '2'               },
                { field: 'totalamountaftertax',   title: 'Amount',          width: '2', style: right },
                { field: 'buttons', noSort: true, title: '',                width: '2'               },
              ]}>
                { reimbursements.map((reimbursement) => (
                  <SortTable.Row key={ reimbursement.key }>
                    <SortTable.Cell>{ reimbursement.displaySubmissionDate }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.fdName }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.trainerName }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.displayCourse }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.status }</SortTable.Cell>
                    <SortTable.Cell>{ reimbursement.requestNumber }</SortTable.Cell>
                    <SortTable.Cell {...right}>{ <CurrencyText value={ reimbursement.totalamountaftertax }/> }</SortTable.Cell>
                    <SortTable.Cell textAlign='right'>
                    { reimbursement.status === 'Pending' &&
                      <ApprovalButton
                        onApproval={ this.handleApprove.bind(this, reimbursement.id, reimbursement.timestamp) }
                        onRejection={ this.handleReject.bind(this, reimbursement.id, reimbursement.timestamp) }
                      />
                    }
                    { reimbursement.status === 'Approved' &&
                      <Icon name='clipboard check' size='large' color='green' />
                    }
                    { reimbursement.status === 'Rejected' &&
                      <Icon name='clipboard' size='large' color='red' />
                    }
                      <ViewButton popup='View reimbursement request summary'
                        pathname={ `/${ PATH_ADMIN }/${ PATH_REIMBURSEMENTS }/${ PATH_SUMMARY }/${ reimbursement.id }/${ reimbursement.timestamp }` }
                      />
                      <EditButton view={ reimbursement.status !== 'Pending' } popup={ reimbursement.status !== 'Pending' ? 'View reimbursement request' : 'Edit reimbursement request' }
                        pathname={ `/${ PATH_ADMIN }/${ PATH_REIMBURSEMENTS }/${ PATH_TRAINING_EXPENSES }/${ reimbursement.id }/${ reimbursement.timestamp }` }
                      />
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
            { this.state.editing &&
              <RejectReasonEditDialog open={ !!this.state.editing } onSave={ this.saveRejectReason } onClose={ this.closeDialog } />
            }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    reimbursementList: storeState.models.fireDepartmentReimbursements,
    ui: storeState.ui.fireDepartmentReimbursements,
    fireDepartments: storeState.lookups.fireDepartments,
  };
}

export default connect(mapStoreStateToProps)(AdminReimbursements);
