import React from 'react';

import { Grid, Container, List } from 'semantic-ui-react';

class Bottom extends React.Component {
  render() {
    return (
      <Grid padded verticalAlign='middle' className='app-footer'>
        <Grid.Column floated='left' width={6}>
          <Container>&copy; BC Construction Safety Alliance 2019</Container>
        </Grid.Column>
        <Grid.Column floated='right' width={10}>
        <List horizontal floated='right' size='tiny'>
          <List.Item>#400, 625 Agnes Street, New Westminster, BC V3M 5Y4</List.Item>
          <List.Item>T 604.636.3675</List.Item>
          <List.Item>F 604.636.3676</List.Item>
          <List.Item>TF 1.877.860.3675</List.Item>
        </List>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Bottom;
