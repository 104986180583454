import React from 'react';

import { Modal, Segment, Form, Message } from 'semantic-ui-react';

import CurrencyInput from '../components/CurrencyInput';
import EditDialog from '../components/EditDialog';

import PropTypes from 'prop-types';

const INITIAL_STATE = {

  firedeptstipendamount: 0,
  trainersstipendamount: 0,
  sitesurveyreimbamount: 0,
  trainerskillstrainingamount: 0,

  errors: {
    error: false
  }
};

class AnnualSubmissionEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, ...this.props.data };

    this.didChange = this.didChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  // handleTypeChange = (event, { value }) => {
  //   this.setState({
  //     type: value,
  //     rate: value ? this.rates[value] : 0
  //   }, () => { this.validate(); });
  // }

  // handleDateChange = (name, value) => {
  //   this.setState({ [name]: value }, () => { this.validate(); });
  // }

  didChange = () => {
    if (this.state.firedeptstipendamount !== this.props.data.firedeptstipendamount) { return true; }
    if (this.state.trainersstipendamount !== this.props.data.trainersstipendamount) { return true; }
    if (this.state.sitesurveyreimbamount !== this.props.data.sitesurveyreimbamount) { return true; }
    if (this.state.trainerskillstrainingamount !== this.props.data.trainerskillstrainingamount) { return true; }

    return false;
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  handleCurrencyChange = (name, value) => {
    const stateUpdate = { [name]: value };
    this.setState(stateUpdate, () => { this.validate(); });
  }

  isValid = () => {
    let errors = { error: false };

    ['firedeptstipendamount', 'trainersstipendamount', 'sitesurveyreimbamount', 'trainerskillstrainingamount'].forEach((v) => {
      if (this.state[v] != undefined) return;
      errors.error = true;
      errors[v] = 'Please enter a value.';
    });

    this.setState({ errors: errors });

    return !errors.error;
  }

  onSave = () => {
    const { errors, ...state } = this.state;
    console.log(this.props.onSave)
    this.props.onSave({ ...state });
  }

  render = () => {
    const { onSave, onClose, data, ...props } = this.props;
    return (
      <EditDialog size='small' header='Edit Annual Submission' onSave={ this.onSave } onClose={ onClose }
        isValid={ this.isValid } didChange={ this.didChange } error={ this.state.errors.error } { ...props }
      >
        <Modal.Header><strong>Submission:</strong>{ ` ${data.fdName} (${(data.timestamp || '').substring(0, 4)})` }</Modal.Header>
        <Segment.Group size='large'>
          <Segment disabled={ this.props.disabled }>
            <Form.Group>
              <Form.Field required label={ <label>FD Stipend Amount</label> }
                value={ this.state.firedeptstipendamount } name='firedeptstipendamount' width={ 8 }
                error={ !!this.state.errors.firedeptstipendamount }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field required label={ <label>Instructors Stipend Amount</label> }
                value={ this.state.trainersstipendamount } name='trainersstipendamount' width={ 8 }
                error={ !!this.state.errors.trainersstipendamount }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Field required label={ <label>Crane Surveys Amount</label> }
                value={ this.state.sitesurveyreimbamount } name='sitesurveyreimbamount' width={ 8 }
                error={ !!this.state.errors.sitesurveyreimbamount }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
              <Form.Field required label={ <label>Training Skills Amount</label> }
                value={ this.state.trainerskillstrainingamount } name='trainerskillstrainingamount' width={ 8 }
                error={ !!this.state.errors.trainerskillstrainingamount }
                control={ CurrencyInput } onChange={ this.handleCurrencyChange }
              />
            </Form.Group>
            <Message error>
              { this.state.errors.firedeptstipendamount } { this.state.errors.trainersstipendamount }
              { this.state.errors.sitesurveyreimbamount } { this.state.errors.trainerskillstrainingamount }
            </Message>
          </Segment>
        </Segment.Group>
      </EditDialog>
    );
  }
}

AnnualSubmissionEditDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default AnnualSubmissionEditDialog;
